<template>
  <div class="pie-chart-container">
    <div ref="chartContainer" class="chart"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "PieChart",
  props: {
    data: {
      type: Array,
      required: true,
    },
    innerRadius: {
      type: Number,
      default: 30,
    },
    outerRadius: {
      type: Number,
      default: 140,
    },
    showLegend: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      chart: null,
      PURPLE_COLORS: ["#613DC1", "#8562E4", "#AA8BFC", "#C5B0FF", "#DCCFFF"],
    };
  },
  methods: {
    generateMoreShades(baseColors, totalNeeded) {
      if (totalNeeded <= baseColors.length)
        return baseColors.slice(0, totalNeeded);

      const result = [...baseColors];
      const remaining = totalNeeded - baseColors.length;

      // Simple approach: repeat the colors
      for (let i = 0; i < remaining; i++) {
        result.push(baseColors[i % baseColors.length]);
      }

      return result;
    },
    initChart() {
      if (!this.$refs.chartContainer) return;

      // Initialize ECharts instance
      this.chart = echarts.init(this.$refs.chartContainer);

      this.updateChart();

      // Handle resize
      window.addEventListener("resize", () => {
        this.chart && this.chart.resize();
      });
    },

    updateChart() {
      if (!this.chart) return;
      const containerWidth = this.$refs.chartContainer.clientWidth;
      const dynamicOuterRadius = Math.min(containerWidth / 3, 140);
      // Generate enough colors for all data points
      const colors = this.generateMoreShades(
        this.PURPLE_COLORS,
        this.data?.length
      );

      // Prepare data for ECharts
      const seriesData = this.data?.map((item) => ({
        name: item.name,
        value: item.value,
      }));

      // Set chart options
      const options = {
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c}% ({d}%)",
        },
        legend: {
          show: this.showLegend,
          orient: "horizontal",
          left: "center",
          top: "bottom",
          formatter: "{name}",
        },
        series: [
          {
            type: "pie",
            radius: [`${this.innerRadius}px`, `${dynamicOuterRadius}px`],
            center: ["50%", "50%"],
            data: seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            itemStyle: {
              color: (params) => {
                return colors[params.dataIndex % colors.length];
              },
            },
            label: {
              show: false,
            },
          },
        ],
      };

      // Apply options to chart
      this.chart.setOption(options);
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.initChart();
      }, 200);
    });
  },
  watch: {
    data: {
      handler() {
        this.updateChart();
      },
      deep: true,
    },
  },
  beforeUnmount() {
    // Clean up chart instance to prevent memory leaks
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
    window.removeEventListener("resize", this.chart && this.chart.resize);
  },
};
</script>

<style scoped>
.pie-chart-container {
  width: -webkit-fill-available;
  max-width: 100%;
  height: auto;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  min-height: 300px;
  display: flex;
  aspect-ratio: 1/1;
}
</style>
