<template>
  <div class="financial-tables">
    <!-- Equity & Liabilities Table -->
    <div class="report-section-wrapper finance-section-wrapper">
      <div class="report-section">
        <div class="section-header-wrapper">
          <div class="section-header">
            <div
              style="display: flex; width: 20px"
              v-html="financeIcons?.incomeStatement"
            ></div>
            <h2>Income Statement</h2>
          </div>
          <div class="currency">{{ data?.currency }}</div>
        </div>
        <div class="finace-section-wrapper-1">
          <div class="finance-section-content">
            <!-- Equity & Liabilities Table -->
            <div class="table-container">
              <table class="data-table">
                <thead>
                  <tr>
                    <th>{{ data?.profitAndLoss.headers[0] }}</th>
                    <th
                      v-for="(year, index) in data?.profitAndLoss.headers.slice(
                        1
                      )"
                      :key="'year-' + index"
                    >
                      {{ year }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, rowIndex) in data?.profitAndLoss.rows"
                    :key="'eq-liab-' + rowIndex"
                    :class="{
                      'font-bg':
                        row.name.toLowerCase().includes('total') ||
                        [
                          'Operating Profit EBITDA',
                          'Profit Before Interest and Tax',
                          'Profit Before Tax and Exceptional Items Before Tax',
                          'Profit Before Tax',
                          'Profit for the Period',
                        ].includes(row.name),
                    }"
                  >
                    <td
                      :class="{
                        'font-bold':
                          row.name.toLowerCase().includes('total') ||
                          [
                            'Operating Profit EBITDA',
                            'Profit Before Interest and Tax',
                            'Profit Before Tax and Exceptional Items Before Tax',
                            'Profit Before Tax',
                            'Profit for the Period',
                          ].includes(row.name),
                      }"
                    >
                      {{ row.name }}
                    </td>
                    <td
                      v-for="(
                        year, yearIndex
                      ) in data?.profitAndLoss.headers.slice(1)"
                      :key="'eq-liab-value-' + yearIndex"
                      :class="{
                        'font-bold':
                          row.name.toLowerCase().includes('total') ||
                          [
                            'Operating Profit EBITDA',
                            'Profit Before Interest and Tax',
                            'Profit Before Tax and Exceptional Items Before Tax',
                            'Profit Before Tax',
                            'Profit for the Period',
                          ].includes(row.name),
                      }"
                    >
                      {{ row[year]?.value || "-" }}
                      <span
                        v-if="
                          getDirection(
                            row[year]?.value,
                            getPreviousYearValue(
                              data?.profitAndLoss.rows,
                              row.name,
                              year
                            )
                          )
                        "
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            :d="
                              getDirection(
                                row[year]?.value,
                                getPreviousYearValue(
                                  data?.profitAndLoss.rows,
                                  row.name,
                                  year
                                )
                              ) === 'up' ||
                              getDirection(
                                row[year]?.value,
                                getPreviousYearValue(
                                  data?.profitAndLoss.rows,
                                  row.name,
                                  year
                                )
                              ) === 'neutral'
                                ? 'M7 14L12 9L17 14H7Z'
                                : 'M7 10L12 15L17 10H7Z'
                            "
                            :fill="
                              getArrowColor(
                                getDirection(
                                  row[year]?.value,
                                  getPreviousYearValue(
                                    data?.profitAndLoss.rows,
                                    row.name,
                                    year
                                  )
                                )
                              )
                            "
                          />
                        </svg>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import FinanceIcons from "@/assets/DigitalProduct/FinanceIcons.js";

export default {
  name: "BalanceSheet",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    financeIcons() {
      return FinanceIcons;
    },
    currencyLabel() {
      const route = useRoute();
      return route.params.country === "India" ? "INR, Million" : "USD, Million";
    },
  },
  methods: {
    getPreviousYearValue(rows, itemName, currentYear) {
      const previousYear = (parseInt(currentYear.split("-")[2]) - 1).toString();
      const previousYearKey = currentYear.replace(/\d{4}/, previousYear);
      const row = rows.find((r) => r.name === itemName);
      return row ? row[previousYearKey]?.value || null : null;
    },
    getColor(current, previous) {
      current = this.parseNumericValue(current);
      previous = this.parseNumericValue(previous);
      if (previous === null || isNaN(current) || isNaN(previous))
        return "#11365a";
      return Number(current) > Number(previous)
        ? "green"
        : Number(current) < Number(previous)
        ? "#ff007f"
        : "#11365a";
    },
    getDirection(current, previous) {
      current = this.parseNumericValue(current);
      previous = this.parseNumericValue(previous);

      if (previous === null || isNaN(current) || isNaN(previous))
        return "neutral";

      return Number(current) > Number(previous)
        ? "up"
        : Number(current) < Number(previous)
        ? "down"
        : "neutral";
    },
    getArrowColor(direction) {
      return direction === "up"
        ? "#27BE69"
        : direction === "down"
        ? "#FF0000"
        : "#A0A0A0";
    },
    parseNumericValue(value) {
      if (!value || value === "#REF!") return null;
      return parseFloat(value.toString().replace("%", "").trim());
    },
  },
};
</script>

<style scoped>
.report-section-wrapper {
  display: flex;
  padding: 24px 20px;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;
  border: 1.5px solid #e0f5ff;
  /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25); */
}
.finance-section-wrapper {
  flex-direction: column;
}
.report-section {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: -webkit-fill-available;
}
.section-header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  width: -webkit-fill-available;
}
.currency {
  color: #224096;
  font-family: Figtree;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 5px 8px;
  border-radius: 8px;
  border: 1px solid #224096;
  background: #e0f5ff;
}
.section-header {
  display: flex;
  align-items: center;
  gap: 12px;
}
.finance-section-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 20px;
}
h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  font-family: Figtree;
  color: #142d4d;
}

h3 {
  margin: 0 0 12px 0;
  font-size: 16px;
  font-weight: 600;
}
.table-container {
  width: -webkit-fill-available;
  overflow-x: auto;
  background: #fff;
  box-shadow: 0px 0px 12.7px 0px #e0f5ff;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #e0f5ff;
}

.data-table th:first-child,
.data-table td:first-child {
  width: 50%;
  min-width: 200px;
  max-width: 50%;
  word-wrap: break-word;
}

.data-table th:not(:first-child),
.data-table td:not(:first-child) {
  text-align: right;
}

.data-table td {
  text-align: left;
  font-size: 13px;
  color: #101828;
  font-weight: 500;
  font-family: "Figtree";
}

.data-table th {
  background-color: #e0f5ff;
  font-family: "Figtree";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  color: #1c1c1c;
  border-bottom: 1px solid rgba(34, 64, 150, 0.2);
}

.data-table tr:last-child td {
  border-bottom: none;
}

/* .data-table tr:hover {
  background-color: #f9f9f9;
} */

.font-bold {
  font-weight: 800 !important;
}

.font-bg {
  background: #e8f6ff73;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  color: #020617;
  margin-bottom: 16px;
  text-decoration: underline;
}

.last-updated {
  font-size: 14px;
  color: #64748b;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .financial-tables {
    -webkit-overflow-scrolling: touch;
  }
}
</style>
