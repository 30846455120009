<template>
  <div class="summary-info">
    <TabTitle
      :title="data?.title"
      :lastUpdated="data?.lastUpdated || 'N/A'"
      :completionPercentage="data?.completion_percentage || 0"
      :toggleisEditable="toggleIsEditable"
    />
    <div class="corporate-info">
      <!-- Related Corporates Section -->
      <section
        class="section"
        v-show="data?.relatedCorporates && Object.keys(data?.relatedCorporates)"
      >
        <h2 class="section-title">Related Corporates</h2>
        <div
          class="director-section"
          v-for="(item, index) in formattedRelatedCorporates"
          :key="index"
        >
          <h3 class="director-name">{{ formatLabel(item?.key) }}</h3>
          <table v-if="Array.isArray(item?.value)" class="table">
            <thead>
              <tr>
                <th
                  class="header-cell"
                  v-for="(key, i) in Object.keys(item?.value[0])"
                  :key="i"
                >
                  {{ formatLabel(key) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, rowIndex) in item?.value" :key="rowIndex">
                <td class="table-cell" v-for="(value, key) in row" :key="key">
                  {{ value || "-" }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="header-cell" v-else>
            {{ formatLabel(item?.value) }}
          </div>
        </div>
      </section>

      <!-- Director-wise Company Associations Section -->
      <section
        class="section"
        v-show="
          data?.directorWiseCompanyAssociation &&
          Object.keys(data?.directorWiseCompanyAssociation)
        "
      >
        <h2 class="section-title">Director-wise Company Associations</h2>

        <div
          class="director-section"
          v-for="(director, index) in data?.directorWiseCompanyAssociation"
          :key="index"
        >
          <h3 class="director-name">{{ index }}</h3>
          <div class="companies-table">
            <div class="table-header">
              <div
                class="header-cell"
                v-for="(key, index) in Object.keys(
                  data?.directorWiseCompanyAssociation?.[index] || {}
                )"
                :key="index"
              >
                {{ formatLabel(key) }}
              </div>
            </div>
            <div class="table-row">
              <div
                class="table-cell"
                v-for="(value, index) in Object.values(
                  data?.directorWiseCompanyAssociation?.[index] || {}
                )"
                :key="index"
              >
                {{ formatLabel(value) }}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import TabTitle from "@/components/CompanyDashboard/tabs/TabTitle.vue";

export default {
  name: "RelatedPartiesInfo",
  components: {
    TabTitle,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    lastUpdated: {
      type: String,
      default: "N/A",
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEditable: false,
    };
  },
  computed: {
    formattedRelatedCorporates() {
      return Object.entries(this.data?.relatedCorporates || {}).map(
        ([key, value]) => {
          return { key, value };
        }
      );
    },
  },
  methods: {
    toggleIsEditable() {
      this.isEditable = !this.isEditable;
    },
    formatLabel(key) {
      return key
        .replace(/_/g, " ")
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    },
  },
};
</script>

<style scoped>
.summary-info {
  background-color: #fff;
  /* border: 1px solid #e2e8f0; */
  border-radius: 8px;
  margin-top: 0;
  text-align: left;
}

.corporate-info {
  padding: 24px;
}

.section {
  margin-bottom: 32px;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  color: #020617;
  margin-bottom: 16px;
  text-decoration: underline;
}

.related-corporates {
  border-radius: 8px;
  overflow: hidden;
}

.corporate-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #e2e8f0;
}

/* .corporate-row:nth-child(odd) {
  background-color: #f1f5f9;
} */

.corporate-label {
  padding: 16px;
  font-weight: 500;
  background-color: #f1f5f9;
}

.corporate-value {
  padding: 16px;
  color: #64748b;
}

.director-section {
  margin-bottom: 24px;
}

.director-name {
  font-weight: bold;
  margin-bottom: 8px;
}

.companies-table {
  /* border: 1px solid #e2e8f0; */
  border-radius: 8px;
  overflow: hidden;
}

.table-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: #f1f5f9;
  font-weight: 600;
  border-bottom: 1px solid #e2e8f0;
}

.header-cell {
  padding: 16px;
  font-size: 14px;
}

.table-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: 1px solid #e2e8f0;
}

.table-row:nth-child(odd) {
  background-color: #f1f5f9;
}

.table-cell {
  padding: 16px;
  color: #64748b;
  font-size: 14px;
}
.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
}

table th {
  text-align: left;
  padding: 12px;
  background: #f1f5f9;
  font-weight: 600;
  color: #1c2024;
  font-size: 14px;
  border-right: 1px solid #e2e8f0;
}

.table td {
  padding: 12px;
  border-bottom: 1px solid #e2e8f0;
  font-size: 14px;
  color: #64748b;
}

@media (max-width: 768px) {
  .summary-info {
    padding: 16px;
  }
}
</style>
