// store.js
import { createStore } from 'vuex';
import VueCookies from 'vue-cookies';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    isLoggedIn: VueCookies.get('token') !== undefined,
    sessionExpired: false,
    cartCount: 0,
    referralCode: '',
    credits: 0,
    isReferralCodePresent: false,
    userDetails: null,
    filters: []
  },
  mutations: {
    setLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    setSessionExpired(state, value) {
        state.sessionExpired = value;
    },
    setCartCount(state, count) {
      state.cartCount = count;
    },
    setReferralCode(state, referralCode) {
      state.referralCode = referralCode;
    },
    setCredits(state, credits) {
      state.credits = credits;
    },
    setUserDetails(state, userDetails) {
      state.userDetails = userDetails;
    },
    setIsReferralCodePresent(state, value) {
      state.isReferralCodePresent = value;
    },
    setFilter(state, { screenName, filters }) {
      const existingFilterIndex = state.filters.findIndex(
        (item) => item.screenName === screenName
      );
      if (existingFilterIndex !== -1) {
        state.filters[existingFilterIndex].filters = filters;
      } else {
        state.filters.push({ screenName, filters });
      }
    },
    clearFilter(state, screenName) {
      const index = state.filters.findIndex(
        (item) => item.screenName === screenName
      );
      if (index !== -1) {
        state.filters.splice(index, 1);
      }
    },
    clearUserState(state) {
      state.referralCode = '';
      state.credits = 0;
      state.cartCount = 0;
      state.isLoggedIn = false;
      state.userDetails = null;
      state.filters = [];
    },
  },
  actions: {
    processLogout({ commit }) {
      commit('clearUserState');
      commit('setSessionExpired', true);
    //   window.location.replace('/');
    },
    clearData({ commit }) {
      commit('clearUserState');
    },
    updateCartCount({ commit }, count) {
      commit('setCartCount', count);
    },
    setReferralCode({ commit }, referralCode) {
      commit('setReferralCode', referralCode);
    },
    setCredits({ commit }, credits) {
      commit('setCredits', credits);
    },
    setIsReferralCodePresent({ commit }, value) {
      commit('setIsReferralCodePresent', value);
    },
    setUserDetails({ commit }, userDetails) {
      commit('setUserDetails', userDetails);
    },
    saveFilters({ commit }, payload) {
      commit("setFilter", payload);
    },
    clearFilters({ commit }, screenName) {
      commit("clearFilter", screenName);
    }
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    sessionExpired: state => state.sessionExpired,
    cartCount: state => state.cartCount,
    referralCode: state => state.referralCode,
    credits: state => state.credits,
    isReferralCodePresent: state => state.isReferralCodePresent,
    userDetails: state => state.userDetails,
    getFiltersForScreen: (state) => (screenName) => {
      const filterObj = state.filters.find(
        (item) => item.screenName === screenName
      );
      return filterObj ? filterObj.filters : {};
    }
  },
  plugins: [createPersistedState()],
});
