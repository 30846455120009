const CompanyInfoIcons = {
    keyInfoComapny: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.90705 17.2558C10.3181 17.2044 10.6929 17.496 10.7443 17.907L11.2443 21.907C11.2957 22.318 11.0041 22.6929 10.5931 22.7442C10.1821 22.7956 9.80725 22.5041 9.75587 22.093L9.25587 18.093C9.20449 17.682 9.49604 17.3072 9.90705 17.2558Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.9378 5.50365C12.0801 5.34238 12.2849 5.25 12.5 5.25H22C22.4142 5.25 22.75 5.58579 22.75 6V22C22.75 22.4142 22.4142 22.75 22 22.75H10.5C10.2057 22.75 9.93864 22.5779 9.81704 22.3099C9.69544 22.0419 9.74179 21.7276 9.93558 21.5061L13.2141 17.7593L11.7558 6.09303C11.7291 5.87956 11.7954 5.66492 11.9378 5.50365ZM13.3496 6.75L14.7442 17.907C14.7708 18.1193 14.7053 18.3328 14.5644 18.4939L12.1528 21.25H21.25V6.75H13.3496Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.75 14C12.75 13.5858 13.0858 13.25 13.5 13.25H17.5C17.9142 13.25 18.25 13.5858 18.25 14C18.25 14.4142 17.9142 14.75 17.5 14.75H13.5C13.0858 14.75 12.75 14.4142 12.75 14Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.25 10C12.25 9.58579 12.5858 9.25 13 9.25H17.5C17.9142 9.25 18.25 9.58579 18.25 10C18.25 10.4142 17.9142 10.75 17.5 10.75H13C12.5858 10.75 12.25 10.4142 12.25 10Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 2C1.25 1.58579 1.58579 1.25 2 1.25H12C12.3782 1.25 12.6973 1.53165 12.7442 1.90697L14.7442 17.907C14.7709 18.1204 14.7046 18.3351 14.5623 18.4964C14.4199 18.6576 14.2151 18.75 14 18.75H2C1.58579 18.75 1.25 18.4142 1.25 18V2ZM2.75 2.75V17.25H13.1504L11.3379 2.75H2.75Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 6C4.25 5.58579 4.58579 5.25 5 5.25H8.5C8.91421 5.25 9.25 5.58579 9.25 6C9.25 6.41421 8.91421 6.75 8.5 6.75H5C4.58579 6.75 4.25 6.41421 4.25 6Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 10C4.25 9.58579 4.58579 9.25 5 9.25H9C9.41421 9.25 9.75 9.58579 9.75 10C9.75 10.4142 9.41421 10.75 9 10.75H5C4.58579 10.75 4.25 10.4142 4.25 10Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 14C4.25 13.5858 4.58579 13.25 5 13.25H9.5C9.91421 13.25 10.25 13.5858 10.25 14C10.25 14.4142 9.91421 14.75 9.5 14.75H5C4.58579 14.75 4.25 14.4142 4.25 14Z" fill="#1C1B1F"/>
</svg>
`,
aboutCompany:`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.75 3.25V22C4.75 22.4142 4.41421 22.75 4 22.75C3.58579 22.75 3.25 22.4142 3.25 22V3C3.25 2.30965 3.80965 1.75 4.5 1.75H14.5C15.1904 1.75 15.75 2.30966 15.75 3V22C15.75 22.4142 15.4142 22.75 15 22.75C14.5858 22.75 14.25 22.4142 14.25 22V3.25H4.75Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.357 7.11427C14.5701 6.75908 15.0308 6.64388 15.386 6.85697L20.386 9.85662C20.6119 9.99216 20.7501 10.2363 20.7501 10.4998V22.0001C20.7501 22.4143 20.4143 22.7501 20.0001 22.7501C19.5859 22.7501 19.2501 22.4143 19.2501 22.0001V10.9244L14.6143 8.14325C14.2591 7.93016 14.1439 7.46947 14.357 7.11427Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 22C1.25 21.5858 1.58579 21.25 2 21.25H22C22.4142 21.25 22.75 21.5858 22.75 22C22.75 22.4142 22.4142 22.75 22 22.75H2C1.58579 22.75 1.25 22.4142 1.25 22Z" fill="#1C1B1F"/>
</svg>
`,
contactInfo:`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 12.4142 22.4142 12.75 22 12.75C21.5858 12.75 21.25 12.4142 21.25 12C21.25 6.89136 17.1086 2.75 12 2.75C6.89136 2.75 2.75 6.89136 2.75 12C2.75 17.1086 6.89136 21.25 12 21.25C14.3078 21.25 16.4166 20.4058 18.0372 19.0084C18.3509 18.738 18.8245 18.773 19.095 19.0867C19.3654 19.4004 19.3304 19.874 19.0167 20.1445C17.1341 21.7677 14.6808 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.25 12C7.25 9.37664 9.37664 7.25 12 7.25C14.6234 7.25 16.75 9.37664 16.75 12C16.75 14.6234 14.6234 16.75 12 16.75C9.37664 16.75 7.25 14.6234 7.25 12ZM12 8.75C10.2051 8.75 8.75 10.2051 8.75 12C8.75 13.7949 10.2051 15.25 12 15.25C13.7949 15.25 15.25 13.7949 15.25 12C15.25 10.2051 13.7949 8.75 12 8.75Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16 11.25C16.4142 11.25 16.75 11.5858 16.75 12C16.75 13.2426 17.7574 14.25 19 14.25C20.2426 14.25 21.25 13.2426 21.25 12C21.25 11.5858 21.5858 11.25 22 11.25C22.4142 11.25 22.75 11.5858 22.75 12C22.75 14.0711 21.0711 15.75 19 15.75C16.9289 15.75 15.25 14.0711 15.25 12C15.25 11.5858 15.5858 11.25 16 11.25Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.25C16.4142 7.25 16.75 7.58579 16.75 8V12.5C16.75 12.9142 16.4142 13.25 16 13.25C15.5858 13.25 15.25 12.9142 15.25 12.5V8C15.25 7.58579 15.5858 7.25 16 7.25Z" fill="#1C1B1F"/>
</svg>
`,
businessActivities:`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.74983 12.5C3.74983 7.94366 7.44347 4.25003 11.9998 4.25003C16.5562 4.25003 20.2498 7.94366 20.2498 12.5C20.2498 17.0564 16.5562 20.75 11.9998 20.75C7.44347 20.75 3.74983 17.0564 3.74983 12.5ZM11.9998 5.75003C8.2719 5.75003 5.24983 8.77209 5.24983 12.5C5.24983 16.228 8.2719 19.25 11.9998 19.25C15.7278 19.25 18.7498 16.228 18.7498 12.5C18.7498 8.77209 15.7278 5.75003 11.9998 5.75003Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.34519 5.13422C9.54722 4.77262 10.0041 4.64326 10.3657 4.84529C10.858 5.12032 11.3797 5.48445 11.7756 5.94956C12.1778 6.42212 12.4808 7.03899 12.4269 7.77737C12.3803 8.41554 12.0658 8.80855 11.8356 9.06297C11.8144 9.0865 11.7947 9.1082 11.7766 9.12825C11.6045 9.31828 11.567 9.35967 11.5527 9.39513C11.5548 9.41075 11.5599 9.44397 11.5733 9.50272C11.5766 9.51706 11.5809 9.53463 11.5859 9.55497C11.6155 9.67608 11.6692 9.89555 11.6781 10.1171C11.6902 10.4157 11.6304 10.7447 11.4266 11.0792C11.233 11.397 10.9347 11.6782 10.5391 11.942C10.0077 12.2964 9.01155 12.7717 7.87649 13.0205C6.7473 13.2681 5.36017 13.3171 4.14342 12.6599C3.77897 12.4631 3.64309 12.0081 3.83993 11.6436C4.03677 11.2792 4.49179 11.1433 4.85624 11.3401C5.62499 11.7553 6.59596 11.7657 7.55525 11.5553C8.50867 11.3463 9.33141 10.9444 9.70697 10.694C9.99158 10.5042 10.103 10.3687 10.1456 10.2988C10.178 10.2456 10.1809 10.2161 10.1794 10.1777C10.1769 10.1172 10.1653 10.068 10.1387 9.95542C10.1309 9.92245 10.1219 9.88404 10.1114 9.83833C10.0756 9.68232 10.009 9.38394 10.0902 9.0556C10.2019 8.60403 10.5151 8.27566 10.6701 8.11325C10.691 8.09136 10.709 8.07249 10.7232 8.05673C10.8744 7.88959 10.9207 7.80721 10.9309 7.66809C10.948 7.43376 10.8627 7.19137 10.6333 6.92178C10.3975 6.64475 10.0442 6.38389 9.63412 6.15477C9.27252 5.95274 9.14316 5.49582 9.34519 5.13422Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.826 3.04016L21.1144 2.80324C21.826 3.04016 21.8261 3.03991 21.826 3.04016L21.8256 3.04125L21.8251 3.04292L21.8233 3.048L21.8176 3.065C21.8126 3.07935 21.8056 3.09968 21.7964 3.12574C21.7779 3.17786 21.7508 3.25291 21.7147 3.34884C21.6425 3.54067 21.5343 3.81617 21.3874 4.1589C21.0939 4.84399 20.6454 5.79979 20.021 6.89388C18.7749 9.07744 16.814 11.8375 13.9648 14.0885C11.1863 16.2837 8.55133 17.9009 6.41234 18.6782C5.34742 19.0651 4.34287 19.2673 3.47692 19.1817C2.57734 19.0927 1.79982 18.684 1.34446 17.8642C0.650491 16.6148 1.16097 15.2075 1.81714 14.1746C2.49841 13.1022 3.50771 12.1457 4.35037 11.6153C4.70092 11.3946 5.16397 11.5 5.38462 11.8505C5.60527 12.2011 5.49996 12.6641 5.14941 12.8848C4.49207 13.2985 3.6434 14.0972 3.08324 14.979C2.49798 15.9002 2.38357 16.6458 2.65576 17.1359C2.82526 17.441 3.11017 17.6381 3.6246 17.6889C4.17267 17.7432 4.93373 17.6195 5.90006 17.2684C7.82356 16.5694 10.3136 15.0616 13.0349 12.9115C15.6857 10.8172 17.532 8.22892 18.7182 6.15041C19.2101 5.28837 19.586 4.51845 19.8568 3.91462L18.6932 4.22473C18.293 4.33141 17.8821 4.09343 17.7754 3.69319C17.6687 3.29295 17.9067 2.88201 18.3069 2.77533L20.9212 2.07854C21.1877 2.00752 21.4716 2.08846 21.6606 2.28931C21.8496 2.49016 21.9131 2.77851 21.826 3.04016Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.0002 14.5V13.75C19.4144 13.75 19.7502 14.0858 19.7502 14.5C19.7502 14.9135 19.4155 15.2489 19.0023 15.25C19.0021 15.25 19.0026 15.25 19.0023 15.25L18.9907 15.2502C18.9794 15.2505 18.9603 15.251 18.9339 15.2522C18.881 15.2546 18.7995 15.2595 18.6946 15.2699C18.4842 15.2906 18.1828 15.3324 17.8312 15.4168C17.1224 15.5869 16.2442 15.9212 15.488 16.5695C14.7519 17.2004 14.3101 18.0636 14.0525 18.8078C13.9255 19.175 13.8479 19.4999 13.8024 19.7309C13.7797 19.846 13.7652 19.9367 13.7567 19.9964C13.7524 20.0262 13.7496 20.0482 13.7479 20.0616L13.7464 20.0749C13.7463 20.076 13.7462 20.0766 13.7462 20.0765L13.7464 20.0749C13.7464 20.0751 13.7464 20.0748 13.7464 20.0749L13.0001 20C12.2539 19.9254 12.2539 19.9252 12.2539 19.925L12.2541 19.9231L12.2544 19.9199L12.2554 19.9107L12.2588 19.8816C12.2617 19.8576 12.266 19.8244 12.2719 19.7829C12.2838 19.7001 12.3025 19.5838 12.3306 19.4411C12.3867 19.1564 12.4809 18.7625 12.635 18.3172C12.9398 17.4365 13.4979 16.2996 14.5117 15.4306C15.5053 14.5789 16.6273 14.1632 17.4812 13.9582C17.9109 13.8551 18.2815 13.8033 18.5478 13.7771C18.6812 13.7639 18.7892 13.7572 18.8663 13.7537C18.9048 13.752 18.9357 13.7511 18.9582 13.7506L18.9857 13.7501L18.9946 13.75L18.9978 13.75L18.9991 13.75L19.0002 13.75C19.0004 13.75 19.0002 13.75 19.0002 14.5ZM13.0001 20L12.2539 19.925C12.2127 20.3371 12.5134 20.7051 12.9255 20.7463C13.3376 20.7875 13.705 20.4869 13.7464 20.0749C13.7464 20.0747 13.7464 20.0746 13.0001 20Z" fill="#1C1B1F"/>
</svg>
`,
nameHistory:`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 3C1.25 2.58579 1.58579 2.25 2 2.25H15C15.4142 2.25 15.75 2.58579 15.75 3V21C15.75 21.4142 15.4142 21.75 15 21.75H2C1.58579 21.75 1.25 21.4142 1.25 21V3ZM2.75 3.75V20.25H14.25V3.75H2.75Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6 2.25C6.41421 2.25 6.75 2.58579 6.75 3V21C6.75 21.4142 6.41421 21.75 6 21.75C5.58579 21.75 5.25 21.4142 5.25 21V3C5.25 2.58579 5.58579 2.25 6 2.25Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.25 3C17.25 2.58579 17.5858 2.25 18 2.25H22C22.4142 2.25 22.75 2.58579 22.75 3V19C22.75 19.1989 22.671 19.3897 22.5303 19.5303L20.5303 21.5303C20.2374 21.8232 19.7626 21.8232 19.4697 21.5303L17.4697 19.5303C17.329 19.3897 17.25 19.1989 17.25 19V3ZM18.75 3.75V18.6893L20 19.9393L21.25 18.6893V3.75H18.75Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.25 6C17.25 5.58579 17.5858 5.25 18 5.25H22C22.4142 5.25 22.75 5.58579 22.75 6C22.75 6.41421 22.4142 6.75 22 6.75H18C17.5858 6.75 17.25 6.41421 17.25 6Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 3C1.25 2.58579 1.58579 2.25 2 2.25H15C15.4142 2.25 15.75 2.58579 15.75 3C15.75 3.41421 15.4142 3.75 15 3.75H2C1.58579 3.75 1.25 3.41421 1.25 3Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 21C1.25 20.5858 1.58579 20.25 2 20.25H15C15.4142 20.25 15.75 20.5858 15.75 21C15.75 21.4142 15.4142 21.75 15 21.75H2C1.58579 21.75 1.25 21.4142 1.25 21Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18 2.25C18.4142 2.25 18.75 2.58579 18.75 3V11C18.75 11.4142 18.4142 11.75 18 11.75C17.5858 11.75 17.25 11.4142 17.25 11V3C17.25 2.58579 17.5858 2.25 18 2.25Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22 2.25C22.4142 2.25 22.75 2.58579 22.75 3V11C22.75 11.4142 22.4142 11.75 22 11.75C21.5858 11.75 21.25 11.4142 21.25 11V3C21.25 2.58579 21.5858 2.25 22 2.25Z" fill="#1C1B1F"/>
</svg>
`,
lockIconSvg:`<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.1667 18.834V12.1673C12.1667 9.95718 13.0446 7.83756 14.6074 6.27476C16.1702 4.71196 18.2899 3.83398 20.5 3.83398C22.7101 3.83398 24.8298 4.71196 26.3926 6.27476C27.9554 7.83756 28.8333 9.95718 28.8333 12.1673V18.834M8.83333 18.834H32.1667C34.0076 18.834 35.5 20.3264 35.5 22.1673V33.834C35.5 35.6749 34.0076 37.1673 32.1667 37.1673H8.83333C6.99238 37.1673 5.5 35.6749 5.5 33.834V22.1673C5.5 20.3264 6.99238 18.834 8.83333 18.834Z" stroke="#fd4c8c" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`
}

export default CompanyInfoIcons;
