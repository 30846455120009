<template>
  <div class="company-report-page">
    <div>
      <div v-if="isLoading && !data" class="loader-container">
        <span class="loader"></span>
      </div>
      <div v-else class="content-wrapper">
        <CompanyHeader :header="header" />
        <CompanyTiles :header="header" />
        <CompanyTabs @tab-changed="handleTabChange" />
        <component
          :is="currentTabComponent"
          :title="currentTab"
          lastUpdated="07 January 2025"
          :data="tabData"
          :financialsAvailable="financialsAvailable"
          @update:data="updateParentData"
          @tabChange="handleTabChange"
          :lockedTabs="accessControlArray"
        />
      </div>
      <CompanyFooter />
      <div class="body-head-card" v-if="isSaveVisible">
        <div class="button-group">
          <button class="button" @click="cancelChanges">Cancel</button>
          <button class="button2" @click="saveData">Save</button>
        </div>
      </div>
    </div>
    <div class="report-search-container">
      <ReportSearch :companyName="companyName" :countryName="countryName" />
    </div>
  </div>
  <div class="loader-wrapper" v-if="isLoading">
    <LoaderAnimation />
  </div>
  <Toastify ref="toastContainer" />
</template>

<script>
import CompanyHeader from "@/components/CompanyDashboard/CompanyHeader.vue";
import CompanyFooter from "@/components/CompanyDashboard/CompanyFooter.vue";
import CompanyTiles from "@/components/CompanyDashboard/CompanyTiles.vue";
import CompanyTabs from "@/components/CompanyDashboard/CompanyTabs.vue";
import SUMMARY from "@/components/CompanyDashboard/tabs/SummaryInfo.vue";
import COMPANY from "@/components/CompanyDashboard/tabs/CompanyInfo.vue";
import FINANCE from "@/components/CompanyDashboard/tabs/FinanceInfo.vue";
import SHAREHOLDING from "@/components/CompanyDashboard/tabs/ShareholdingInfo.vue";
import MANAGEMENT from "@/components/CompanyDashboard/tabs/Management.vue";
import COMPLIANCE from "@/components/CompanyDashboard/tabs/ComplianceInfo.vue";
import LEGAL from "@/components/CompanyDashboard/tabs/LegalInfo.vue";
import RISK from "@/components/CompanyDashboard/tabs/RiskInfo.vue";
import RELATED_PARTIES from "@/components/CompanyDashboard/tabs/RelatedPartiesInfo.vue";
import EMPLOYEES from "@/components/CompanyDashboard/tabs/EmployeesInfo.vue";
import CREDIT_RATINGS from "@/components/CompanyDashboard/tabs/CreditRatingsInfo.vue";
import PEER_COMPARISON from "@/components/CompanyDashboard/tabs/PeerComparisonInfo.vue";
import DOCUMENTS from "@/components/CompanyDashboard/tabs/DocumentsInfo.vue";
import ReportSearch from "@/components/CompanyDashboard/ReportSearch.vue";
import TRADE from "@/components/CompanyDashboard/tabs/TradeInfo.vue";
// import dataJson from "@/assets/data.json";
import {
  GetCompanyData,
  GetCredenceSearchCompanyData,
  UpdateCompanyData,
} from "@/api/APIs";
import LoaderAnimation from "@/assets/Animation/LoaderAnimation.vue";
import Toastify from "@/components/Toastify.vue";

export default {
  name: "CompanyReportPage",
  components: {
    Toastify,
    CompanyHeader,
    CompanyFooter,
    CompanyTiles,
    CompanyTabs,
    SUMMARY,
    COMPANY,
    FINANCE,
    SHAREHOLDING,
    MANAGEMENT,
    COMPLIANCE,
    LEGAL,
    RISK,
    RELATED_PARTIES,
    EMPLOYEES,
    CREDIT_RATINGS,
    PEER_COMPARISON,
    DOCUMENTS,
    ReportSearch,
    TRADE,
    LoaderAnimation,
  },
  data() {
    return {
      isEditable: false,
      parentData: {},
      objectIds: [],
      currentTab: "SUMMARY",
      data: null,
      isLoading: false,
      header: null,
      financialsAvailable: false,
      token: null,
      orderId: null,
      email: null,
      assessmentData: {
        riskLevels: [3, 4, 3.5, 4, 3.5, 3],
      },
      financialData: {
        industry: "Agro",
        segments: "Animal Feeds",
        financialMetrics: [
          {
            name: "Revenue (INR, Million)",
            fy2024: "174.73",
            fy2023: "144.72",
            fy2022: "157.32",
          },
          {
            name: "Revenue Growth (%)",
            fy2024: "174.73",
            fy2023: "144.72",
            fy2022: "157.32",
          },
          {
            name: "Net Margin (%)",
            fy2024: "174.73",
            fy2023: "144.72",
            fy2022: "157.32",
          },
          {
            name: "Return on Equity (%)",
            fy2024: "174.73",
            fy2023: "144.72",
            fy2022: "157.32",
          },
          {
            name: "Debt / Equity",
            fy2024: "174.73",
            fy2023: "144.72",
            fy2022: "157.32",
          },
        ],
        peers: [
          {
            name: "MEAL FOODS INDUSTRIES PRIVATE LIMITED",
            city: "DEWAS",
            revenue: "144.72",
          },
          {
            name: "SOUMAK FLOATING FEED MILLS PRIVATE LIMITED",
            city: "NADIA",
            revenue: "157.32",
          },
          {
            name: "G M BIOCHEM PRIVATE LIMITED",
            city: "Ahmedabad",
            revenue: "182.1",
          },
          {
            name: "MEAL FOODS INDUSTRIES PRIVATE LIMITED",
            city: "PATIALA",
            revenue: "174.73",
          },
        ],
      },
      documents: [
        {
          name: "Annual Report 2024",
          type: "PDF",
          size: "5.2 MB",
          lastUpdated: "2025-01-15",
        },
        {
          name: "Financial Statements 2024",
          type: "XLSX",
          size: "9.1 MB",
          lastUpdated: "2025-01-15",
        },
        {
          name: "Board Resolution - New Director",
          type: "PDF",
          size: "4.5 MB",
          lastUpdated: "2024-11-30",
        },
        {
          name: "Shareholder Agreement",
          type: "PDF",
          size: "7.4 MB",
          lastUpdated: "2024-09-20",
        },
        {
          name: "Company Bylaws",
          type: "PDF",
          size: "11 MB",
          lastUpdated: "2024-09-20",
        },
      ],
      accessControlArray: ["EMPLOYEES", "SUMMARY"],
    };
  },
  computed: {
    isSaveVisible() {
      return this.isEditable; // Show Save button only if active child is editable
    },
    companyName() {
      const comp = this.$route.params.company
        ? decodeURIComponent(this.$route.params.company)
        : "";
      return comp;
    },
    countryName() {
      return this.$route.params.country
        ? decodeURIComponent(this.$route.params.country)
        : "";
    },
    currentTabComponent() {
      return this.currentTab;
    },
    tabData() {
      switch (this.currentTab) {
        case "SUMMARY":
          return this.data?.summary;
        case "FINANCE":
          return this.data?.finance;
        case "COMPANY":
          return this.data?.company;
        case "COMPLIANCE":
          return this.data?.compliance;
        case "LEGAL":
          return this.data?.legal;
        case "RISK":
          return { ...this.assessmentData, ...this.data?.risk };
        case "RELATED_PARTIES":
          return this.data?.related_parties;
        case "EMPLOYEES":
          return this.data?.employees;
        case "PEER_COMPARISON":
          return this.financialData;
        case "DOCUMENTS":
          return this.documents;
        case "TRADE":
          return this.data?.trade_tabs;
        case "SHAREHOLDING":
          return this.data?.shareholding;
        case "MANAGEMENT":
          return this.data?.management;
        default:
          return {};
      }
    },
    selectedModule() {
      if (this.currentTab == "TRADE") {
        return ["TRADE_TABS"];
      } else {
        return [this.currentTab];
      }
    },
  },
  watch: {
    currentTab: "GetCompanyDetails",
  },
  mounted() {
    this.token = this.$route.query.token || localStorage.getItem("token");
    this.orderId = this.$route.query.orderId || "";
    this.email = this.$route.query.email || "";

    const currentDomain = window.location.hostname;
    console.log ("Iframe details: ", window.self, window.top, currentDomain, window.self !== window.top && currentDomain.includes("credencedata.com"))
    // if (window.self !== window.top) {
    //   window.addEventListener("message", this.handleMessage, false);
    // } else {
      this.fetchCompanyData();
    // }
  },
  beforeUnmount() {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("orderId");
  },
  methods: {
    handleMessage(event) {
      const allowedOrigins = "*";

      if (allowedOrigins !== "*" && !allowedOrigins.includes(event.origin)) {
        return;
      }
      if (event.data?.token) {
        this.token = event.data.token;
        this.orderId = event.data.orderId;
        sessionStorage.setItem("token", event.data.token);
        sessionStorage.setItem("email", event.data.email);
        sessionStorage.setItem("orderId", event.data.orderId);
        if (event.data?.source) {
          sessionStorage.setItem("dataSource", event.data.source);
        }
        this.fetchCompanyData();
      } else {
        this.fetchCompanyData();
      }
    },
    fetchCompanyData() {
      this.GetHeaderDetails();
      this.GetCompanyDetails();
    },
    updateParentData(updatedSection, reportId, isEditable) {
      this.parentData = { ...this.parentData, ...updatedSection };
      this.objectIds = reportId;
      this.isEditable = isEditable;
    },

    saveData() {
      console.log("Saving Data:", this.parentData);
      this.updateCompanyDetails();
    },
    handleTabChange(tabName) {
      this.currentTab = tabName;
      this.isEditable = false; // Reset editable state when switching tabs
    },
    async GetHeaderDetails() {
      const payload = {
        companyName: this.companyName,
        modules: ["HEADER"],
        countryCode: this.countryName,
      };
      const dataSource = sessionStorage.getItem("dataSource");
      try {
        let response;
        if (dataSource === "credenceSearch") {
          response = await GetCredenceSearchCompanyData(payload);
        } else {
          response = await GetCompanyData(payload);
        }
        if (response) {
          this.header = response?.data?.data?.header;
        } else {
          this.$refs.toastContainer.addToast("", { type: "error" });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      }
    },
    async GetCompanyDetails() {
      this.isLoading = true;
      const payload = {
        companyName: this.companyName,
        modules: this.selectedModule,
        countryCode: this.countryName,
      };
      const dataSource = sessionStorage.getItem("dataSource");
      try {
        let response;
        if (dataSource === "credenceSearch") {
          response = await GetCredenceSearchCompanyData(payload);
        } else {
          response = await GetCompanyData(payload);
        }
        if (response) {
          this.data = response.data.data;
          if (this.currentTab === "SUMMARY") {
            const financials = this.data?.summary?.financialMetrics || [];
            const hasFinancialData = financials.some(
              (metric) => metric.value && metric.value !== "Not available"
            );

            this.financialsAvailable = hasFinancialData;
          }
        } else {
          this.$refs.toastContainer.addToast("", { type: "error" });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      } finally {
        this.isLoading = false;
      }
    },
    async updateCompanyDetails() {
      this.isLoading = true;
      const payload = {
        recordIds: this.objectIds,
        updatePayload: this.parentData,
        countryCode: this.countryName,
        companyName: this.companyName,
        module: this.selectedModule && this.selectedModule[0],
      };
      try {
        let response = await UpdateCompanyData(payload);
        if (response) {
          this.GetHeaderDetails();
          this.GetCompanyDetails();
          // this.data = response?.data?.updatedDoc?.result;
        } else {
          this.$refs.toastContainer.addToast("", { type: "error" });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.button {
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  min-width: 80px;
  color: #0f172a;
  cursor: pointer;
}

.button2 {
  border-radius: 6px;
  background-color: #0f172a;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  box-sizing: border-box;
  min-width: 80px;
  color: #f8fafc;
  cursor: pointer;
}

.button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.body-head-card {
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #e2e8f0;
  border-radius: 0 0 8px 8px;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  color: #0f172a;
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.loader-wrapper {
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 8%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.company-report-page {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow-y: auto;
  justify-content: space-between;
}

.content-wrapper {
  border-radius: 0px;
  background-color: #eef7ff;
  /* border: 1px solid #e2e8f0; */
  border-top-width: 1px;
  align-self: start;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: start;
  width: -webkit-fill-available;
  /* padding: 24px 24px 15px 24px; */
  flex: 1;
}
.report-search-container {
  flex: 0 0 auto;
  position: sticky;
  top: 0;
}
@media (max-width: 991px) {
  .content-wrapper {
    max-width: 100%;
    padding: 0 20px;
  }
}
</style>
