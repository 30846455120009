<template>
  <div
    id="userMenu"
    v-if="
      $route.path !== '/login' &&
      $route.path !== '/whatsapp/termsandconditions' &&
      !userTokenAvailable
    "
    :class="appClass"
  >
    <div class="frame-35582">
      <div v-if="showMobileMenu" class="hamburger-menu" @click="toggleDrawer">
        <div class="iconly-sharp-menu-instance">
          <svg
            width="30"
            height="30"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.625 18C29.625 17.3787 29.1213 16.875 28.5 16.875H7.5C6.87868 16.875 6.375 17.3787 6.375 18C6.375 18.6213 6.87868 19.125 7.5 19.125H28.5C29.1213 19.125 29.625 18.6213 29.625 18Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.625 10.5C29.625 9.87868 29.1213 9.375 28.5 9.375H7.5C6.87868 9.375 6.375 9.87868 6.375 10.5C6.375 11.1213 6.87868 11.625 7.5 11.625H28.5C29.1213 11.625 29.625 11.1213 29.625 10.5Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.625 25.5C29.625 24.8787 29.1213 24.375 28.5 24.375H7.5C6.87868 24.375 6.375 24.8787 6.375 25.5C6.375 26.1213 6.87868 26.625 7.5 26.625H28.5C29.1213 26.625 29.625 26.1213 29.625 25.5Z"
              fill="black"
            />
          </svg>
        </div>
      </div>

      <!-- Drawer Navigation -->
      <div v-if="showDrawer" class="bg-b-lue" v-on:click.self="toggleDrawer">
        <transition name="slide">
          <div class="drawer-navigation">
            <div
              class="drawer-item"
              v-if="!userTokenAvailable"
              @click="showLogin('Login')"
            >
              <div class="partnership" style="text-align: left">Login</div>
            </div>
            <div
              class="drawer-item"
              v-if="!userTokenAvailable"
              @click="showLogin('SignUp')"
            >
              <div class="blogs" style="text-align: left">Sign Up</div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
  <div
    id="userMenu"
    v-if="userTokenAvailable && showMobileMenu"
    :class="appClass"
  >
    <div class="frame-355821">
      <div class="navbar-left">
        <div
          class="menu-wrapper"
          @click="
            this.drawerOpen = false;
            this.showDrawer = !this.showDrawer;
          "
          :class="{ active: this.showDrawer }"
        >
          <div class="menu-bar one"></div>
          <div class="menu-bar two"></div>
          <div class="menu-bar three"></div>
        </div>
        <div class="frame-7">
          <div @click="navBarClicked()" class="router-link-no-hover">
            <div class="frame-1">
              <img class="image-2" src="@/assets/logo.webp" alt="Credence" />
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 18px;
        "
      >
        <div class="cart-icon-wrapper" ref="profile2" @click="profileClicked2">
          <img
            src="@/assets/Icons/user.webp"
            alt="Credence User"
            class="icon-image"
            style="
              width: 25px;
              height: 25px;
              border-radius: 50%;
              box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.18);
              cursor: pointer;
            "
          />
          <div
            class="profile-menu-2"
            v-if="profileMenuActive2"
            ref="profileMenu2"
          >
            <div
              class="profile-menu-item"
              @click="
                goToProfile();
                navBarClicked();
              "
            >
              User Profile
            </div>
            <div
              class="profile-menu-item"
              @click="
                processLogout();
                navBarClicked();
              "
            >
              Log Out
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showDrawer"
        class="bg-b-lue-mobile"
        v-on:click.self="toggleDrawer"
      >
        <transition name="slide">
          <div class="drawer-navigation-loggedin">
            <div class="navbar-optins" style="height: auto">
              <template v-for="(option, index) in options" :key="index">
                <router-link
                  :to="option.route"
                  :class="[
                    'navbar-option',
                    { 'active-link': isActiveRoute(option.route) },
                  ]"
                  @click="navBarClicked()"
                >
                  <img
                    :src="require(`@/assets/Icons/${option.icon}`)"
                    :alt="'Credence ' + option.label"
                    class="icon-image"
                  />
                  <div
                    class="option-lable"
                    :style="{
                      color: isActiveRoute(option.route) ? 'white' : '#11365A',
                    }"
                  >
                    {{ option.label }}
                  </div>
                </router-link>
              </template>
            </div>
            <div
              class="profile-button"
              @click="profileClicked"
              v-if="userName !== ''"
              ref="profile"
            >
              <div class="user-profile">
                <img
                  src="@/assets/Icons/user.webp"
                  alt="Credence User"
                  class="icon-image"
                  style="
                    width: 35px;
                    height: 35px;
                    /* border: 1.5px solid #11365a; */
                    border-radius: 50%;
                    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.18);
                  "
                />
                <div class="user-name">{{ truncatedUserName }}</div>
              </div>
              <svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 6.5L6 1.5L11 6.5"
                  stroke="#11365A"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
              <div
                class="profile-menu"
                v-if="profileMenuActive"
                ref="profileMenu"
              >
                <div
                  class="profile-menu-item"
                  @click="
                    goToProfile();
                    toggleDrawer();
                    navBarClicked();
                  "
                >
                  User Profile
                </div>
                <div
                  class="profile-menu-item"
                  @click="
                    processLogout();
                    toggleDrawer();
                    navBarClicked();
                  "
                >
                  Log Out
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
  <div
    style="
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      position: fixed;
      width: 100%;
      z-index: 1;
      pointer-events: none;
    "
    v-if="userTokenAvailable && !showMobileMenu"
  >
    <div
      :class="['dashboard-navigation-container', { collapsed: isCollapsed }]"
    >
      <div
        :class="['collapse-icon', { rotated: isCollapsed }]"
        :title="isCollapsed ? 'Expand' : 'Collapse'"
        @click="toggleCollapse"
      >
        <div style="width: 20px; height: 20px; align-content: center">
          <i class="fas fa-angle-left"></i>
        </div>
      </div>
      <div
        class="dasboard-nav-menu"
        :style="{
          width: isCollapsed ? '90%' : '',
          padding: isCollapsed ? '0' : '',
        }"
      >
        <div class="router-link-no-hover" :style="{
            width: isCollapsed ? '-webkit-fill-available' : '',
          }" @click="navBarClicked()">
          <div class="frame-1">
            <img
              class="image-2"
              :src="
                isCollapsed
                  ? require('@/assets/logo2.webp')
                  : require('@/assets/logo.webp')
              "
              alt="Credence"
              :style="{ width: isCollapsed ? 'auto' : '' }"
            />
          </div>
        </div>
        <div class="line-16"></div>
        <div class="navbar-optins-wrapper">
          <div class="navbar-optins">
            <template v-for="(option, index) in options" :key="index">
              <router-link
                :to="option.route"
                :class="[
                  'navbar-option',
                  { 'active-link': isActiveRoute(option.route) },
                ]"
                :style="{
                  padding: isCollapsed ? '12px' : '',
                  alignContent: isCollapsed ? 'center' : '',
                  flexWrap: isCollapsed ? 'wrap' : '',
                }"
                :title="option.label"
                @click="navBarClicked()"
              >
                <img
                  :src="require(`@/assets/Icons/${option.icon}`)"
                  :alt="'Credence ' + option.label"
                  class="icon-image"
                />
                <div
                  class="option-lable"
                  :style="{
                    color: isActiveRoute(option.route) ? 'white' : '#11365A',
                    display: isCollapsed ? 'none' : '',
                  }"
                >
                  {{ option.label }}
                </div>
              </router-link>
            </template>
          </div>
          <div
            class="profile-button"
            @click="profileClicked"
            v-if="userName !== ''"
            ref="profile"
            title="Profile"
            :style="{
              border: isCollapsed ? 'none' : '',
              padding: isCollapsed ? '5px 0px' : '',
              justifyContent: isCollapsed ? 'center' : '',
            }"
          >
            <div class="user-profile">
              <img
                src="@/assets/Icons/user.webp"
                alt="Credence User"
                class="icon-image"
                style="
                  width: 35px;
                  height: 35px;
                  /* border: 1.5px solid #11365a; */
                  border-radius: 50%;
                  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.18);
                "
              />
              <div class="user-name" v-if="!isCollapsed">
                {{ truncatedUserName }}
              </div>
              <div
                class="profile-menu"
                v-if="profileMenuActive"
                ref="profileMenu"
              >
                <div
                  class="profile-menu-item"
                  @click="
                    goToProfile();
                    navBarClicked();
                  "
                >
                  User Profile
                </div>
                <div
                  class="profile-menu-item"
                  @click="
                    processLogout();
                    navBarClicked();
                  "
                >
                  Log Out
                </div>
              </div>
            </div>
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              v-if="!isCollapsed"
            >
              <path
                d="M1 6.5L6 1.5L11 6.5"
                stroke="#11365A"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 45px;
        padding: 0 4vw;
        background: #e2eaf5;
        pointer-events: auto;
      "
    >
      <div class="navbar-title">
        {{ currentScreenName }}
        <span class="excludes" v-if="shouldShowExcludes"
          >( Excludes India-China )</span
        >
      </div>
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 25px;
        "
      >
        <div class="cart-icon-wrapper" ref="profile2" @click="profileClicked2">
          <img
            src="@/assets/Icons/user.webp"
            alt="Credence User"
            class="icon-image"
            style="
              width: 25px;
              height: 25px;
              border-radius: 50%;
              box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.18);
              cursor: pointer;
            "
          />
          <div
            class="profile-menu-2"
            v-if="profileMenuActive2"
            ref="profileMenu2"
          >
            <div
              class="profile-menu-item"
              @click="
                goToProfile();
                navBarClicked();
              "
            >
              User Profile
            </div>
            <div
              class="profile-menu-item"
              @click="
                processLogout();
                navBarClicked();
              "
            >
              Log Out
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <LoaderPopup v-if="showLoaderPopup" />
  <session-expired-modal v-if="sessionExpired" @login="redirectToLogin" />
  <CartCheckout
    v-if="toggleCheckoutModal"
    @close="toggleCheckout"
    @placeorder="placeBulkOrder"
    :cartLoader="cartLoader"
  />
  <BulkOrderConfirmation
    v-if="bulkOrderConfirmation"
    @close="toggleBulkOrderConfirmation"
  />
  <InviteNewUser v-if="showInviteNewUser" @close="toggleInviteNewUser()" />
  <Toastify ref="toastContainer" />
  <router-view />
</template>
<script>
// import { ref } from "vue";
import VueCookies from "vue-cookies";
import LoaderPopup from "@/components/LoaderPopup.vue";
import { mapGetters, mapActions } from "vuex";
import SessionExpiredModal from "@/views/Modals/SessionExpiredModal.vue";
import { Logout, CheckoutCart, DeleteFromCart } from "./api/APIs";
import CartCheckout from "@/views/Modals/CartCheckout.vue";
import Toastify from "@/components/Toastify.vue";
import BulkOrderConfirmation from "@/views/Modals/BulkOrderConfirmation.vue";
import "floating-vue/dist/style.css";
import config from "@/config.js";

export default {
  name: "App",
  components: {
    LoaderPopup,
    SessionExpiredModal,
    CartCheckout,
    BulkOrderConfirmation,
    Toastify,
  },
  data() {
    return {
      guestUser: true,
      userName: "",
      showLoaderPopup: false,
      showLoginPopup: false,
      showLogoutPopup: false,
      showHistoryPopup: false,
      showOrdersPopup: false,
      showOrderDetailsPopup: false,
      orderDetailsModel: null,
      manualOrderForm: false,
      availableCredits: 0,
      userToken: null,
      showDrawer: false,
      showMobileMenu: window.innerWidth < 1023,
      mobile: window.innerWidth <= 767,
      clickOutsideDrawer: false,
      loginType: "",
      isProduction: process.env.NODE_ENV === "production",
      profileMenuActive: false,
      profileMenuActive2: false,
      carouselSettings: [
        {
          dots: false,
          infinite: true,
          arrows: false,
          speed: 10000,
          slidesToShow: 5,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 0,
          variableWidth: true,
          cssEase: "linear",
          rtl: false,
          pauseOnHover: false,
        },
        {
          dots: false,
          infinite: true,
          arrows: false,
          speed: 10000,
          slidesToShow: 5,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 0,
          variableWidth: true,
          cssEase: "linear",
          rtl: true,
          pauseOnHover: false,
        },
      ],
      cartItemsList: [],
      toggleCheckoutModal: false,
      cartLoader: false,
      bulkOrderConfirmation: false,
      isCheckoutOpen: false,
      tooltipTimeout: null,
      showBoxShadow: false,
      showBlinking: false,
      myReferralList: [],
      showInviteNewUser: false,
      isTooltipOpen: false,
      referralCredits: config.referralCredits,
      drawerOpen: false,
      cartValidity: false,
      cartValidityData: null,
      isCollapsed: false,
    };
  },
  computed: {
    ...mapGetters(["cartCount", "sessionExpired", "userDetails"]),
    shouldShowExcludes() {
      return this.$route.path === "/allocated-to-checker";
    },
    currentScreenName() {
      const routeName = this.$route.name || "";

      return routeName
        .replace(/-/g, " ")
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/^./, (str) => str.toUpperCase());
    },
    userTokenAvailable() {
      return this.userToken !== null;
    },
    appClass() {
      return this.userTokenAvailable ? "#app.logged-in" : "#app.not-logged-in";
    },
    isActiveRoute() {
      return (path, query = {}) => {
        const isSamePath = this.$route.path === path;
        const isSameQuery = Object.keys(query).every(
          (key) => this.$route.query[key] === query[key]
        );
        const hasNoQuery = Object.keys(query).length === 0;
        return isSamePath && (isSameQuery || hasNoQuery);
      };
    },

    options() {
      const routeMap = {
        REPEAT: {
          label: "Search Internal Db",
          route: "/internal-db",
          icon: "repeat.webp",
        },
        NEW_ORDERS: {
          label: "Master Orders",
          route: "/MyOrders",
          icon: "order.webp",
        },
        INTERNAL_ALLOCATION: {
          label: "Internal Allocation",
          route: "/internal-allocation",
          icon: "resource-allocation.webp",
        },
        ALLOCATED_TO_MAKER: {
          label: "Makers Bucket",
          route: "/allocated-to-maker",
          icon: "cost-allocation.webp",
        },
        ALLOCATED_TO_CHECKER: {
          label: "Checkers Bucket",
          route: "/allocated-to-checker",
          icon: "checker.webp",
        },
        "URGENT/REPEAT": {
          label: "Urgent",
          route: "/urgent-repeat",
          icon: "immediate.webp",
        },
        INDIA: {
          label: "India",
          route: "/india",
          icon: "india.webp",
        },
        CHINA: {
          label: "China",
          route: "/china",
          icon: "china.webp",
        },
        READY_TO_SHARE: {
          label: "Ready To Share",
          route: "/ready-to-share",
          icon: "ready.webp",
        },
        UPDATE_ORDERS: {
          label: "Order Correction",
          route: "/update-orders",
          icon: "update.webp",
        },
        BUSINESS_DASHBOARD: {
          label: "Business Dashboard",
          route: "/business-dashboard",
          icon: "dashboard.webp",
        },
        ALLOCATION_SUMMARY: {
          label: "Allocation Summary",
          route: "/allocation-summary",
          icon: "speedometer.webp",
        },
        PROCUREMENT_INVOICING: {
          label: "Data Procurement",
          route: "/data-procurement",
          icon: "integration.webp",
        },
        CLARIFICATION_PENDING: {
          label: "Clarification Pending",
          route: "/clarification-pending",
          icon: "clarification.webp",
        },
      };
      return this.userDetails?.hasAccessTo
        ?.map((option) => routeMap[option])
        .filter(Boolean);
    },
    truncatedUserName() {
      const user = this.userDetails?.name;
      if (user) {
        return user;
      } else {
        return "";
      }
    },
  },
  beforeMount() {
    this.userName = this.userDetails?.name || "";
    this.userToken = VueCookies.get("token");
  },
  watch: {
    $route(to) {
      if (to.path !== "/login") {
        this.handleMountedLogic();
      }
      if (
        VueCookies.get("token") &&
        this.userDetails?.OrderCount == 0 &&
        localStorage.getItem("effectTriggered") === "false"
      ) {
        this.triggerBoxShadow();
        localStorage.setItem("effectTriggered", true);
      }
    },
  },
  mounted() {
    const storedState = sessionStorage.getItem("isCollapsed");
    if (storedState !== null) {
      this.isCollapsed = storedState === "true";
    }
    if (this.$route.path !== "/login") {
      this.handleMountedLogic();
    }
    window.addEventListener("resize", this.handleResize);
    document.addEventListener("click", this.handleClickOutside);
    document.addEventListener("click", this.handleClickOutside2);
    document.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    document.removeEventListener("click", this.handleClickOutside);
    document.removeEventListener("click", this.handleClickOutside2);
    window.removeEventListener("scroll", this.handleScroll);
  },
  updated() {
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions(["updateCartCount"]),
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
      sessionStorage.setItem("isCollapsed", this.isCollapsed);
    },
    initiateTokenExpiryHandler() {
      const tokenCreationTime = VueCookies.get("token-creation-time");
      const currentTime = Date.now();

      if (tokenCreationTime) {
        const tokenAge = currentTime - new Date(tokenCreationTime).getTime();
        const expiryTime = 12 * 60 * 60 * 1000;
        const remainingTime = expiryTime - tokenAge;

        if (remainingTime > 0) {
          setTimeout(() => {
            this.$store.dispatch("processLogout");
          }, remainingTime);
        } else {
          this.$store.dispatch("processLogout");
        }
      } else {
        console.warn("Token creation time not found.");
      }
    },
    handleScroll() {
      if (this.profileMenuActive2) {
        this.profileMenuActive2 = false;
      }
    },
    navBarClicked() {
      this.drawerOpen = false;
    },
    toggleTooltip() {
      this.isTooltipOpen = !this.isTooltipOpen;
    },
    triggerBoxShadow() {
      this.showBoxShadow = true;
      this.showBlinking = true;
      setTimeout(() => {
        this.showBoxShadow = false;
        this.showBlinking = false;
      }, 20000);
    },
    toggleCheckoutTooltip() {
      if (this.tooltipTimeout) {
        clearTimeout(this.tooltipTimeout);
      }

      this.isCheckoutOpen = !this.isCheckoutOpen;

      if (this.isCheckoutOpen) {
        this.tooltipTimeout = setTimeout(() => {
          this.isCheckoutOpen = false;
        }, 3000);
      }
    },
    async toggleWishlist() {
      try {
        await this.GetCartItems();
        if (this.mobile) {
          this.showDrawer = false;
        }
        this.drawerOpen = !this.drawerOpen;
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    },
    async toggleCheckout() {
      try {
        this.toggleCheckoutModal = !this.toggleCheckoutModal;
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    },
    toggleBulkOrderConfirmation() {
      this.bulkOrderConfirmation = !this.bulkOrderConfirmation;
    },
    async placeBulkOrder(deliveryEmail, isDiscountApplied) {
      this.cartLoader = true;
      const payload =
        this.isRetail === true
          ? {
              email: deliveryEmail,
              isDiscountApplied: isDiscountApplied,
            }
          : {
              email: deliveryEmail,
            };
      try {
        const response = await CheckoutCart(payload);
        if (response.message === "SUCCESS") {
          if (this.isRetail === true) {
            this.cartLoader = false;
            this.GetCartItems();
            this.toggleCheckout();
            this.drawerOpen = false;
            window.location.href = response?.result?.paymentLink;
          } else {
            this.cartLoader = false;
            this.GetCartItems();
            this.toggleCheckout();
            this.drawerOpen = false;
            this.toggleBulkOrderConfirmation();
          }
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    async deleteFromCart(item, clearCart) {
      const payload = clearCart
        ? { clearCart: clearCart }
        : { itemId: item.id };
      try {
        const response = await DeleteFromCart(payload);
        if (response.message === "Deleted from cart") {
          this.GetCartItems();
        } else if (response.message === "Cart cleared") {
          this.GetCartItems();
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    redirectToLogin() {
      this.tokenExpired();
      this.$store.commit("setSessionExpired", false);
    },
    profileClicked() {
      this.profileMenuActive = !this.profileMenuActive;
    },
    profileClicked2() {
      this.profileMenuActive2 = !this.profileMenuActive2;
    },
    handleClickOutside2(event) {
      if (
        this.profileMenuActive2 &&
        this.$refs.profileMenu2 &&
        this.$refs.profile2 &&
        !this.$refs.profileMenu2.contains(event.target) &&
        !this.$refs.profile2.contains(event.target)
      ) {
        this.profileMenuActive2 = false;
      }
    },
    handleClickOutside(event) {
      if (
        this.profileMenuActive &&
        this.$refs.profileMenu &&
        this.$refs.profile &&
        !this.$refs.profileMenu.contains(event.target) &&
        !this.$refs.profile.contains(event.target)
      ) {
        this.profileMenuActive = false;
      }
    },
    goToProfile() {
      this.profileMenuActive = false;
      this.profileMenuActive2 = false;
      this.$router.push("/profile?tab=myaccount");
    },
    redirectToAboutUs() {
      this.$router.push("/aboutus");
      this.showDrawer = false;
    },
    redirectToBlogs() {
      this.$router.push("/blogs");
    },
    redirectToProducts() {
      this.$router.push("/product");
      this.showDrawer = false;
    },
    redirectToHome() {
      if (this.$route.path === "/") {
        location.reload();
      } else {
        this.$router.push("/");
        this.showDrawer = false;
      }
    },
    handleMountedLogic() {
      this.userToken = VueCookies.get("token");
      this.userName = this.userDetails?.name;
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
    handleResize() {
      this.showMobileMenu = window.innerWidth < 1023;
    },
    showLoader() {
      this.showLoaderPopup = true;
    },
    hideLoader() {
      this.showLoaderPopup = false;
    },
    showLogin(actionType) {
      this.loginType = actionType;
      this.$router.push({ path: "/login", query: { type: actionType } });
    },
    async processLogout() {
      const user = this.userDetails?.email;
      const password = VueCookies.get("token");
      try {
        const response = await Logout(user, password);
        if (response) {
          this.profileMenuActive = false;
          localStorage.setItem("effectTriggered", false);
          const cookiesToRemove = [
            "token",
            "user",
            "email",
            "user-type",
            "id",
            "designation",
            "designationId",
            "phone",
            "order-count",
            "entityId",
            "branch",
            "RM",
            "phone_w_countryCode",
            "token-creation-time",
          ];
          if (sessionStorage.getItem("isCollapsed")) {
            sessionStorage.removeItem("isCollapsed");
          }
          cookiesToRemove.forEach((cookie) => {
            if (VueCookies.get(cookie)) {
              VueCookies.remove(cookie);
            }
          });
          this.userToken = null;
          this.userName = "";
          this.$store.dispatch("clearData");
          this.$router.push("/");
          // window.location.reload();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async tokenExpired() {
      const user = this.userDetails?.email;
      const password = VueCookies.get("token");
      try {
        const response = await Logout(user, password);
        if (response) {
          this.profileMenuActive = false;
          this.profileMenuActive2 = false;
          VueCookies.remove("token");

          this.userToken = null;
          this.userName = "";
          this.$router.push({ path: "/login", query: { type: "Login" } });
        }
      } catch (error) {
        console.error(error);
      }
    },
    getCDNlink(fileKey) {
      let baseUrl = "";
      if (
        process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "staging"
      ) {
        baseUrl = config.stagingCDN;
      } else if (process.env.NODE_ENV === "production") {
        baseUrl = config.prodCDN;
      }

      return `${baseUrl}${fileKey}`;
    },
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
@keyframes blinking {
  0% {
    box-shadow: 0px 0px 24px 0px rgba(143, 200, 255, 0);
  }
  50% {
    box-shadow: 0px 0px 24px 0px rgba(143, 200, 255, 1);
  }
  100% {
    box-shadow: 0px 0px 24px 0px rgba(143, 200, 255, 0);
  }
}
#app {
  font-family: "Montserrat-Medium", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: white;
  min-height: 100vh;
  transition: margin-top 0.3s;
}
#app.logged-in {
  margin-top: 0;
}
.link {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600 !important;
}
#app.not-logged-in {
  margin-top: 60px;
}
.icon-image {
  width: 25px;
  height: 25px;
}
.navbar-title {
  color: var(--Secondary-Grey-900, #2b3674);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.48px;
}
.excludes {
  color: var(--Secondary-Grey-900, red);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.48px;
  margin-left: 0.3rem;
}
.wrapper-class {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.referral-title-2 {
  color: var(--Secondary-Grey-900, #2b3674);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.refer {
  display: flex;
  padding: 5px 14px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  border-radius: 13px;
  background: #11365a;
  color: #fcd600;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  cursor: pointer;
}
.social-media-icons {
  width: 20px;
}
.tooltip-content-profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 8px;
  width: 140px;
  gap: 13px;
}
.tooltip-message-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  gap: 10px;
  cursor: pointer;
}
.available-credits {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid rgb(181, 181, 181);
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  padding: 5px 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  min-width: 85px;
}
.share-invitation-main {
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 13px;
  border: 2px solid #11365a;
  background: #fff;
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  min-width: 85px;
}
.invitationstatus-main {
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 33px;
}
.invitation-status-main {
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
  text-transform: capitalize;
}
.referred-email-main {
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.32px;
  opacity: 0.9px;
}
.cart-items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  height: 65%;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0;
}
.cart-icon-wrapper {
  position: relative;
  display: inline-block;
}
.info-wrapper-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
}
.cart-item-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.cart-count {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #11365a;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.2px;
  min-width: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  box-sizing: border-box;
}
.tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 180px;
  padding-top: 8px;
  /* gap: 15px; */
}
.tooltip-checkout {
  background-color: #11365a;
  border-radius: 10px;
  color: white;
  height: 40px;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 18px;
  font-weight: 500;
  align-self: stretch;
  cursor: pointer;
  outline: none;
  width: 100%;
  text-align: center;
}
.cart-items {
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.2px;
  align-self: stretch;
  padding: 12px;
  border-radius: 16px;
  border: 1px solid rgba(24, 195, 223, 0.2);
  background: #fff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.08);
}
.report-type-nav {
  font-size: 14px;
  font-weight: 500;
  color: #3e4855;
  opacity: 0.5;
}
.wishlist-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.wishlist-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-family: "Montserrat-Medium", sans-serif;
  cursor: pointer;
}
.clear-cart {
  color: var(--Main-COlor, #26bbc2);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  cursor: pointer;
}
input[type="text"],
input[type="textarea"] {
  height: 30px;
  border-radius: 0.5em;
  box-shadow: 0 1px 2px #aaa;
  margin: 8px 0;
  padding: 0px 20px;
}
textarea {
  height: 100px;
  border-radius: 0.5em;
  box-shadow: 0 1px 2px #aaa;
  margin: 8px 0;
  padding: 7px 20px;
}
button {
  font-weight: bold;
  font-size: 15px;
  width: 100px;
  height: 35px;
  border-radius: 0.5em;
  color: #ffffff;
  background-color: #d43451;
}
#userMenu {
  overflow: hidden;
  background-color: white;
  border-bottom: 1px solid #82d7db;
  position: sticky;
  top: 0;
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  flex-wrap: wrap;
}
#footer {
  margin-top: 20rem;
  padding-top: 1rem;
  overflow: hidden;
  display: flex;
  background-color: #ffffff;
  align-items: flex-start;
  border-top: 1px solid #82d7db;
  bottom: 0;
  width: 100%;
  flex-direction: column;
}
#footer a {
  float: right;
  display: block;
  color: #1d375b;
  font-weight: bold;
  text-align: center;
  padding: 16px 16px;
  text-decoration: none;
}
#userMenu p {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  margin-left: 16px;
}
#userMenu a {
  float: right;
  display: flex;
  color: #1d375b;
  font-weight: bold;
  text-align: center;
  padding: 16px 16px;
  text-decoration: none;
  position: relative;
  left: -3%;
}
#userMenu a:hover {
  background: #f1f8f8;
  color: black;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup .popup-inner {
  background: transparent;
  padding: 32px;
}
.scBrand_list {
  display: flex;
  flex-direction: row;
}
.scBrand_list_One {
  display: flex;
  flex-direction: row;
}
.frame-35582 {
  /* padding: 0 80px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 90%;
  position: relative;
}
.frame-355821 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  width: 90%;
  position: relative;
}
.navbar-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 10px;
}
.frame-7 {
  display: flex;
  flex-direction: row;
  gap: 36px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-1 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.image-2 {
  flex-shrink: 0;
  width: 173px;
  height: 40px;
  position: relative;
  object-fit: cover;
}
.frame-6 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.solutions {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}
.iconly-sharp-arrow-down-2-instance {
  flex-shrink: 0 !important;
}
.frame-5 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.pricing {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}
.frame-4 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.partnership {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}
.frame-72 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.blogs {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}
.frame-8 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.tools {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}
.tooltip-message {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.frame-9 {
  border-radius: 16px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.about {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}
.frame-82 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  position: relative;
}
.logout-button {
  display: flex;
  min-width: 150px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 13px;
  background: #11365a;
  height: auto;
  border: none;
}
.hamburger-menu {
  cursor: pointer;
  margin-top: 10px;
}
.drawer-navigation {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 999;
  width: 30%;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
}
.drawer-navigation-loggedin {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: white;
  z-index: 999;
  gap: 8px;
  width: 60%;
  /* height: 100%; */
  padding: 20px;
  box-shadow: 4px 5px 15px rgba(0, 0, 0, 0.2);
}
.drawer-item {
  padding: 10px 0;
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  backdrop-filter: blur(10px);
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
.bg-b-lue-mobile {
  background: rgba(0, 0, 0, 8%);
  backdrop-filter: blur(10px);
  z-index: 999;
  position: fixed;
  top: 70px;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
}
.router-link-no-hover {
  background-color: transparent !important;
}

.dashboard-navigation-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 16vw;
  height: 100vh;
  /* position: fixed; */
  z-index: 1000;
  flex-wrap: wrap;
  background-color: white;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.18);
  pointer-events: auto;
  transition: width 0.3s ease;
  position: relative;
}
.dashboard-navigation-container.collapsed {
  width: 60px;
  justify-content: center;
  /* display: none; */
  /* overflow: hidden; */
}
.collapse-icon {
  position: absolute;
  top: 29px;
  right: -15px;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  z-index: 100;
  border: 1px solid #11365a;
  transition: transform 0.3s ease;
}
.collapse-icon:hover {
  background-color: #11365a;
  border: 1px solid transparent;
  color: white !important;
}

.collapse-icon i.iconly-sharp-arrow-down-2-instance {
  color: #11365a;
}
.collapse-icon.rotated {
  transform: rotate(180deg);
}
.dasboard-nav-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 5%;
  width: 18vw;
  height: 100%;
  position: relative;
  gap: 10px;
}
.navbar-optins-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.navbar-optins {
  border-radius: 6px;
  /* padding: 12px 0; */
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 82vh;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background: linear-gradient(
    to top,
    rgba(17, 54, 90, 0.05) 0%,
    rgba(17, 54, 90, 0.05) 0%,
    transparent 1%,
    transparent 99%,
    rgba(17, 54, 90, 0.05) 100%,
    rgba(17, 54, 90, 0.05) 100%
  );
}
.navbar-option {
  border-radius: 16px;
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  text-decoration: none;
}
.navbar-option:hover {
  background-color: #11365a1f;
  border-radius: 16px;
}
.active-link {
  background: #11365a !important;
  border-radius: 16px;
}
.option-lable {
  /* color: #11365a; */
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  position: relative;
}
.line-16 {
  border-style: solid;
  border-color: var(--secondary-grey-300, #f4f7fe);
  border-width: 1px 0 0 0;
  align-self: stretch;
  flex-shrink: 0;
  height: 0px;
  position: relative;
}
.profile-button {
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid rgba(17, 54, 90, 0.5);
  padding: 5px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
}
.profile-button:hover {
  background: #11365a1f;
}
.user-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.user-name {
  color: var(--dark-blue, #11365a);
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  position: relative;
  overflow-wrap: anywhere;
}
.profile-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 15px;
  border: 2px solid rgba(38, 187, 194, 0.4);
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.25);
  width: 25vw;
  background-color: white;
  top: -133px;
  overflow: hidden;
}
.profile-menu-2 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 15px;
  border: 2px solid rgba(38, 187, 194, 0.4);
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.25);
  width: 25vw;
  background-color: white;
  top: 40px;
  right: -20px;
  overflow: hidden;
}
.profile-menu-item {
  display: flex;
  padding: 16px;
  align-items: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: var(--dark-blue, #11365a);
  text-align: left;
  align-self: stretch;
  cursor: pointer;
}
.profile-menu-item:hover {
  background-color: #26bac237;
}

.menu-wrapper {
  width: 30px;
  height: 18px;
  cursor: pointer;
  position: relative;
}

.menu-bar {
  position: absolute;
  width: 30px;
  height: 2px;
  background: #000000;
  /* left: 0%; */
}

.one {
  top: 0px;
  animation-delay: 0.1s;
  transition: all 0.3s;
}

.two {
  top: 8px;
  transition: all 0.3s;
}

.three {
  top: 16px;
  transition: all 0.3s;
}
.validity {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 16px; */
  align-self: stretch;
  border-radius: 13px;
  background: var(--Danger-Light-BG, #ffc9c9);
}
.validity-title1 {
  color: var(--Danger-Text, #861f1f);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-align: left;
}
.validity-title2 {
  color: var(--Danger-Text, #861f1f);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
}
@keyframes slideOut {
  0% {
    width: 100%;
    left: 0%;
    right: auto;
  }

  50% {
    width: 0%;
    left: 0%;
    right: auto;
  }
  51% {
    width: 0%;
    right: 0%;
    left: auto;
  }

  100% {
    width: 100%;
    right: 0%;
    left: auto;
  }
}

/**/

.menu-wrapper:hover .menu-bar.active {
  animation: none;
}
.active .one {
  top: 50%;
  left: 0%;
  transform: rotate(45deg);
}
.active .two {
  opacity: 0;
  transition: opacity 0.2s;
}
.active .three {
  top: 50%;
  left: 0%;
  transform: rotate(-45deg);
}
.reward {
  height: 23px;
  width: 23px;
}
.router-link-no-hover {
  margin-top: 5px;
}

@media only screen and (max-width: 767px) {
  #userMenu a {
    padding: 8px 8px;
    width: 100%;
  }
  .image-2 {
    width: 110px;
    height: auto;
  }
  .menu-bar {
    width: 28px;
  }
  .profile-menu {
    width: 89%;
    /* bottom: 97px; */
  }
  .wishlist-title h3 {
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
  .cart-items {
    font-size: 14px;
  }
  .validity-title1,
  .validity-title2 {
    font-size: 14px;
  }
  .profile-menu-2 {
    position: fixed;
    width: 44vw;
    top: 60px;
    right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  #userMenu a {
    padding: 0;
  }
  .image-2 {
    width: 136.5px;
    height: auto;
  }
  .bg-b-lue-mobile {
    top: 81px;
  }
  .drawer-navigation-loggedin {
    width: 30%;
  }
  #userMenu a {
    padding: 8px 8px;
    width: 100%;
  }
  .profile-menu {
    width: 89%;
    /* bottom: 97px; */
  }
  .validity-title1,
  .validity-title2 {
    font-size: 14px;
  }
  .profile-menu-2 {
    position: fixed;
    width: 20vw;
    top: 60px;
    right: 20px;
  }
}

@media only screen and (min-width: 1023px) and (max-width: 1220px) {
  .solutions,
  .pricing,
  .partnership,
  .blogs,
  .tools,
  .about {
    font-size: 16px;
  }
  .frame-82 {
    /* margin-left: 2%; */
    gap: 20px;
    justify-content: space-between;
    /* width: 30%; */
  }
  .router-link-no-hover {
    padding: 0 !important;
  }
  /* .frame-7 {
    gap: 0;
    justify-content: space-between;
    width: 70%;
  } */
}

@media only screen and (min-width: 1024px) {
}
</style>
