<template>
  <header class="company-header">
    <div class="company-info">
      <h1 class="company-name">{{ header?.company_name }}</h1>
      <p class="company-address">
        {{ header?.address }}
      </p>
    </div>
    <div class="report-actions">
      <button class="download-button" @click="downloadReport">
        Download Report
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="download-icon"
        >
          <path
            d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10M4.66667 6.66667L8 10M8 10L11.3333 6.66667M8 10V2"
            stroke="#F5F5F5"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <p class="update-date">Report as on {{ header?.delivered_on }}</p>
    </div>
  </header>
</template>

<script>
export default {
  name: "CompanyHeader",
  props: {
    header: {
      type: Object,
      required: true,
    },
  },
  computed: {
    companyName() {
      const comp = this.$route.params.company
        ? decodeURIComponent(this.$route.params.company)
        : "";
      return comp;
    },
  },
  methods: {
    downloadReport() {},
  },
};
</script>

<style scoped>
.company-header {
  display: flex;
  width: -webkit-fill-available;
  gap: 40px 100px;
  font-family: Geist, sans-serif;
  justify-content: space-between;
  padding: 24px 24px 0 24px;
  background: white;
}
@media (max-width: 991px) {
  .company-header {
    max-width: 100%;
  }
}
.company-info {
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
@media (max-width: 991px) {
  .company-info {
    max-width: 100%;
  }
}
.company-name {
  color: #000;
  font-family: Figtree;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.25px;
  margin: 0;
}
@media (max-width: 991px) {
  .company-name {
    max-width: 100%;
  }
}
.company-address {
  color: #282828;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Figtree;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
@media (max-width: 991px) {
  .company-address {
    max-width: 100%;
  }
}
.report-actions {
  align-self: start;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: start;
}
.download-button {
  display: flex;
  width: 180px;
  height: 38px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 12px;
  background: #0172f4;
  border: none;
}
.download-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  align-self: stretch;
  margin: auto 0;
}
.update-date {
  overflow: hidden;
  color: #6e6e6e;
  text-align: right;
  line-height: 1;
  transform: translateY(-1px);
  text-overflow: ellipsis;
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}
</style>
