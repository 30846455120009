<template>
  <div class="desktop-2">
    <div class="bg-white" :class="{ 'shrink-in': showLogin }">
      <div class="frame-35629">
        <div class="router-link-no-hover">
          <svg
            class="frame-1"
            width="40vw"
            height="auto"
            viewBox="0 0 509 81"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.0581 80.0635C12.8961 80.0635 0 67.8872 0 50.7978C0 33.7083 12.8961 21.6389 31.0581 21.6389C42.2347 21.6389 51.1545 26.2317 55.5606 34.8832L45.2438 40.8645C41.8048 35.4173 36.6464 32.9607 30.9506 32.9607C21.0636 32.9607 13.5409 39.7965 13.5409 50.7978C13.5409 61.9059 21.0636 68.6349 30.9506 68.6349C36.6464 68.6349 41.8048 66.1783 45.2438 60.731L55.5606 66.7123C51.1545 75.2571 42.2347 80.0635 31.0581 80.0635Z"
              fill="#11365A"
            />
            <path
              d="M79.9616 30.6109C83.8304 24.6296 90.8158 21.6389 100.38 21.6389V34.3492C99.1983 34.1356 98.2311 34.0288 97.2638 34.0288C87.0545 34.0288 80.6064 40.0101 80.6064 51.6523V79.3158H67.173V22.2798H79.9616V30.6109Z"
              fill="#11365A"
            />
            <path
              d="M165.2 51.1182C165.2 52.1863 165.093 53.7884 164.985 55.0702H119.956C121.568 63.4013 128.554 68.7417 138.548 68.7417C144.996 68.7417 150.047 66.7123 154.131 62.5468L161.331 70.7711C156.173 76.8592 148.113 80.0635 138.226 80.0635C118.989 80.0635 106.523 67.7804 106.523 50.7978C106.523 33.8151 119.097 21.6389 136.184 21.6389C152.949 21.6389 165.2 33.2811 165.2 51.1182ZM136.184 32.3198C127.479 32.3198 121.138 37.8739 119.849 46.205H152.411C151.337 37.9807 144.996 32.3198 136.184 32.3198Z"
              fill="#11365A"
            />
            <path
              d="M220.876 0.0634766H234.309V79.3158H221.413V71.946C216.899 77.3932 210.236 80.0635 202.391 80.0635C185.734 80.0635 173.375 68.5281 173.375 50.7978C173.375 33.0675 185.734 21.6389 202.391 21.6389C209.807 21.6389 216.362 24.0955 220.876 29.3292V0.0634766ZM204.003 68.6349C213.675 68.6349 221.091 61.6923 221.091 50.7978C221.091 39.9033 213.675 32.9607 204.003 32.9607C194.331 32.9607 186.916 39.9033 186.916 50.7978C186.916 61.6923 194.331 68.6349 204.003 68.6349Z"
              fill="#11365A"
            />
            <path
              d="M306.449 51.1182C306.449 52.1863 306.342 53.7884 306.234 55.0702H261.205C262.817 63.4013 269.803 68.7417 279.797 68.7417C286.245 68.7417 291.296 66.7123 295.38 62.5468L302.58 70.7711C297.422 76.8592 289.362 80.0635 279.475 80.0635C260.238 80.0635 247.772 67.7804 247.772 50.7978C247.772 33.8151 260.346 21.6389 277.433 21.6389C294.198 21.6389 306.449 33.2811 306.449 51.1182ZM277.433 32.3198C268.728 32.3198 262.388 37.8739 261.098 46.205H293.661C292.586 37.9807 286.245 32.3198 277.433 32.3198Z"
              fill="#26BBC2"
            />
            <path
              d="M351.915 21.6389C365.671 21.6389 375.881 29.436 375.881 46.6322V79.3158H362.447V48.3412C362.447 38.1943 357.396 33.2811 348.799 33.2811C339.234 33.2811 332.786 39.0488 332.786 50.4774V79.3158H319.353V22.2798H332.141V29.6496C336.548 24.4159 343.533 21.6389 351.915 21.6389Z"
              fill="#26BBC2"
            />
            <path
              d="M419.776 80.0635C401.614 80.0635 388.718 67.8872 388.718 50.7978C388.718 33.7083 401.614 21.6389 419.776 21.6389C430.953 21.6389 439.872 26.2317 444.279 34.8832L433.962 40.8645C430.523 35.4173 425.364 32.9607 419.669 32.9607C409.782 32.9607 402.259 39.7965 402.259 50.7978C402.259 61.9059 409.782 68.6349 419.669 68.6349C425.364 68.6349 430.523 66.1783 433.962 60.731L444.279 66.7123C439.872 75.2571 430.953 80.0635 419.776 80.0635Z"
              fill="#26BBC2"
            />
            <path
              d="M509 51.1182C509 52.1863 508.893 53.7884 508.785 55.0702H463.756C465.368 63.4013 472.354 68.7417 482.348 68.7417C488.796 68.7417 493.847 66.7123 497.931 62.5468L505.131 70.7711C499.973 76.8592 491.913 80.0635 482.026 80.0635C462.789 80.0635 450.323 67.7804 450.323 50.7978C450.323 33.8151 462.896 21.6389 479.984 21.6389C496.749 21.6389 509 33.2811 509 51.1182ZM479.984 32.3198C471.279 32.3198 464.938 37.8739 463.649 46.205H496.211C495.137 37.9807 488.796 32.3198 479.984 32.3198Z"
              fill="#26BBC2"
            />
          </svg>
        </div>
        <div class="machine-efficiency-human-intelligence">
          Machine Efficiency, Human Intelligence
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="bg-blue" :class="{ 'slide-in': showLogin }">
        <div class="frame-35628">
          <div
            class="log-in-with"
            v-if="!showSignUpOtp && !showPasswordReset && !showPasswordResetOtp"
          >
            {{ type === "Login" ? "Log In" : "Create an Account" }}
          </div>
          <div v-else>
            <div class="log-in-with" v-if="showSignUpOtp">
              A verification code has been sent to your email
            </div>
            <div class="log-in-with" v-if="showPasswordReset">
              Forgot Password?
            </div>
            <div class="log-in-with" v-if="showPasswordResetOtp">
              Enter the verification code sent on {{ username }}
            </div>
          </div>
          <div v-if="showSignUpOtp">
            <p style="text-align: center; color: #11365a">
              Didn't receive an email?
              <strong
                :style="{
                  opacity: resendEnabled1 ? '1' : '0.6',
                  cursor: resendEnabled1 ? 'pointer' : 'not-allowed',
                  textDecoration: 'underline',
                }"
                @click="resendEnabled1 ? submitEmail('Resend') : null"
                >Resend Email</strong
              >
              <span v-if="!resendEnabled1"> ({{ timerText1 }})</span>
            </p>
          </div>
          <p
            style="text-align: center; color: #11365a"
            v-if="showPasswordReset"
          >
            You will receive a code to verify the password reset process.
          </p>
          <div v-if="showPasswordResetOtp">
            <p style="text-align: center; color: #11365a">
              Didn't receive an email?
              <strong
                :style="{
                  opacity: resendEnabled2 ? '1' : '0.6',
                  cursor: resendEnabled2 ? 'pointer' : 'not-allowed',
                  textDecoration: 'underline',
                }"
                @click="resendEnabled2 ? submitEmail('Resend') : null"
                >Resend Email</strong
              >
              <span v-if="!resendEnabled2"> ({{ timerText2 }})</span>
            </p>
          </div>
          <!-- Login fields -->
          <div class="frame-35635" v-if="type === 'Login'">
            <div
              class="frame-35626"
              v-if="!showPasswordReset && !showPasswordResetOtp"
            >
              <div style="display: flex; flex-direction: column; width: 100%">
                <div class="frame-3">
                  <input
                    type="email"
                    placeholder="Enter Email Address"
                    class="enter-email-address"
                    autocomplete="username"
                    id="username"
                    v-model="username"
                    for="username"
                  />
                </div>
              </div>
              <div style="display: flex; flex-direction: column; width: 100%">
                <div class="frame-4">
                  <input
                    :type="passwordInputType"
                    name="password"
                    placeholder="Enter Password"
                    class="enter-password"
                    autocomplete="off"
                    v-model="token"
                    for="token"
                    @keyup.enter="loginButtonActive ? null : processLogin()"
                  />
                  <div
                    class="eye-closed-instance"
                    v-on:click="togglePasswordVisibility"
                  >
                    <svg
                      v-if="!passwordVisible"
                      class="eye-closed"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 9.5C20 9.5 19.6797 10.1674 19 11.0144M12 14.5C10.392 14.5 9.04786 14.0878 7.94861 13.5M12 14.5C13.608 14.5 14.9521 14.0878 16.0514 13.5M12 14.5V18M4 9.5C4 9.5 4.35367 10.2368 5.10628 11.1448M7.94861 13.5L5 16.5M7.94861 13.5C6.6892 12.8266 5.75124 11.9228 5.10628 11.1448M16.0514 13.5L18.5 16.5M16.0514 13.5C17.3818 12.7887 18.3535 11.8202 19 11.0144M5.10628 11.1448L2 12.5M19 11.0144L22 12.5"
                        stroke="#142D4D"
                        stroke-linecap="round"
                      />
                    </svg>
                    <svg
                      v-else
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 12C22 12 19 18 12 18C5 18 2 12 2 12C2 12 5 6 12 6C19 6 22 12 22 12Z"
                        stroke="#142D4D"
                        stroke-linecap="round"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        r="3"
                        stroke="#142D4D"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="frame-35630">
                <div class="frame-35627">
                  <input
                    type="checkbox"
                    id="remember-me"
                    class="rectangle-10"
                    v-model="rememberMe"
                  />
                  <label for="remember-me" class="remember-me"
                    >Remember Me</label
                  >
                </div>
                <div
                  class="forgot-password"
                  v-on:click.prevent="togglePasswordReset()"
                >
                  Forgot Password?
                </div>
              </div>
              <div
                class="button-property-1-primary-hover-false-button-no"
                v-if="type === 'Login'"
                :style="{ opacity: loginButtonActive ? '0.7' : '1' }"
                v-on:click.prevent="loginButtonActive ? null : processLogin()"
              >
                <div class="login">
                  {{ loginButtonActive ? "Submitting..." : "Login" }}
                </div>
              </div>
            </div>
            <div
              class="frame-35636"
              v-if="!showPasswordReset && !showPasswordResetOtp"
            >
              <div class="don-t-have-an-account">
                Don’t have an account?
                <a
                  @click="updateQueryType('SignUp')"
                  style="
                    text-decoration: underline;
                    color: #1e1e1e;
                    cursor: pointer;
                  "
                  >Create an account</a
                >
              </div>
            </div>
            <!-- Password Reset -->
            <div class="frame-35626" v-if="showPasswordReset">
              <div style="display: flex; flex-direction: column; width: 100%">
                <div class="frame-3">
                  <input
                    type="email"
                    placeholder="Enter Email Address"
                    class="enter-email-address"
                    autocomplete="off"
                    id="username"
                    v-model="username"
                    for="username"
                  />
                </div>
              </div>
              <div
                class="button-property-1-primary-hover-false-button-no"
                v-if="type === 'Login'"
                :style="{
                  backgroundColor: '#142d4d',
                  opacity: sendVerificationActive ? '0.7' : '1',
                }"
                v-on:click.prevent="
                  sendVerificationActive ? null : submitEmail('Reset')
                "
              >
                <div class="login">
                  {{
                    sendVerificationActive
                      ? "Sending Verification Code..."
                      : "Send Verification Code"
                  }}
                </div>
              </div>
            </div>
            <div class="frame-35636" v-if="showPasswordReset">
              <div class="don-t-have-an-account">
                <a
                  @click="showPasswordReset = false"
                  style="
                    text-decoration: underline;
                    color: #11365a;
                    cursor: pointer;
                  "
                  >Back to Login</a
                >
              </div>
            </div>
            <!-- Password Reset Verification  -->
            <div class="frame-35626" v-if="showPasswordResetOtp">
              <div style="display: flex; flex-direction: column; width: 100%">
                <div class="frame-3" style="margin-bottom: 4vh">
                  <input
                    type="otp"
                    placeholder="Enter Verification Code"
                    class="enter-email-address"
                    autocomplete="off"
                    id="otp"
                    v-model="resetotp"
                    for="otp"
                  />
                </div>
                <div style="display: flex; flex-direction: column; width: 100%">
                  <div :class="['frame-4', { shake: shakeNewPassword }]">
                    <input
                      :type="passwordInputType"
                      placeholder="Enter New Password"
                      class="enter-password"
                      v-model="newPassword"
                      for="newPassword"
                    />
                    <div
                      v-if="
                        !passwordValidationResetPassword.valid &&
                        newPassword !== ''
                      "
                    >
                      <i
                        class="fa-solid fa-circle-exclamation"
                        :style="{ color: newPassword !== '' ? 'red' : 'gray' }"
                      >
                      </i>
                      <div
                        v-if="
                          !passwordValidationResetPassword.valid &&
                          newPassword !== ''
                        "
                        class="tooltip-bubble"
                      >
                        <span class="tooltip-title"
                          >Password must contain:</span
                        >
                        <div v-for="(rule, index) in invalidRules" :key="index">
                          • {{ rule.message }}
                        </div>
                      </div>
                      <div
                        v-if="
                          !passwordValidationResetPassword.valid &&
                          newPassword !== ''
                        "
                        class="tooltip-bubble-arrrow"
                      >
                        <i
                          class="fa-solid fa-sort-down"
                          style="color: #e0f2ff"
                        ></i>
                      </div>
                    </div>
                    <div v-if="passwordValidationResetPassword.valid">
                      <i
                        class="fa-solid fa-check"
                        style="color: green; font-size: large"
                      ></i>
                    </div>
                    <div
                      class="eye-closed-instance"
                      v-on:click="togglePasswordVisibility"
                    >
                      <svg
                        v-if="!passwordVisible"
                        class="eye-closed"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 9.5C20 9.5 19.6797 10.1674 19 11.0144M12 14.5C10.392 14.5 9.04786 14.0878 7.94861 13.5M12 14.5C13.608 14.5 14.9521 14.0878 16.0514 13.5M12 14.5V18M4 9.5C4 9.5 4.35367 10.2368 5.10628 11.1448M7.94861 13.5L5 16.5M7.94861 13.5C6.6892 12.8266 5.75124 11.9228 5.10628 11.1448M16.0514 13.5L18.5 16.5M16.0514 13.5C17.3818 12.7887 18.3535 11.8202 19 11.0144M5.10628 11.1448L2 12.5M19 11.0144L22 12.5"
                          stroke="#142D4D"
                          stroke-linecap="round"
                        />
                      </svg>
                      <svg
                        v-else
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22 12C22 12 19 18 12 18C5 18 2 12 2 12C2 12 5 6 12 6C19 6 22 12 22 12Z"
                          stroke="#142D4D"
                          stroke-linecap="round"
                        />
                        <circle
                          cx="12"
                          cy="12"
                          r="3"
                          stroke="#142D4D"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin-top: 4vh;
                  "
                >
                  <div class="frame-4">
                    <input
                      type="password"
                      placeholder="Confirm New Password"
                      class="enter-password"
                      v-model="confirmNewPassword"
                      for="confirmNewPassword"
                    />
                  </div>
                </div>
              </div>
              <div
                class="button-property-1-primary-hover-false-button-no"
                v-if="type === 'Login'"
                :style="{
                  backgroundColor: '#142d4d',
                  opacity: submitResetOTPActive ? '0.7' : '1',
                }"
                v-on:click.prevent="
                  submitResetOTPActive ? null : submitResetOTP()
                "
              >
                <div class="login">
                  {{ submitResetOTPActive ? "Submitting..." : "Submit" }}
                </div>
              </div>
            </div>
          </div>
          <!-- Create account fields -->
          <div class="frame-35635" v-if="type === 'SignUp'">
            <div class="frame-35626" v-if="!showSignUpOtp">
              <div style="display: flex; flex-direction: column; width: 100%">
                <div class="frame-3">
                  <input
                    type="name"
                    placeholder="Full Name"
                    class="enter-email-address"
                    autocomplete="off"
                    id="name"
                    v-model="name"
                    for="name"
                  />
                </div>
              </div>
              <div style="display: flex; flex-direction: column; width: 100%">
                <div class="frame-3">
                  <input
                    type="email"
                    placeholder="Enter Email Address"
                    class="enter-email-address"
                    autocomplete="off"
                    id="username"
                    v-model="username"
                    for="username"
                    :disabled="this.$route.query.email ? true : false"
                  />
                </div>
              </div>
              <div style="display: flex; flex-direction: column; width: 100%">
                <div :class="['frame-4', { shake: shakePassword }]">
                  <input
                    :type="passwordInputType"
                    placeholder="Enter Password"
                    class="enter-password"
                    autocomplete="off"
                    v-model="password"
                    for="password"
                    required
                  />
                  <div v-if="!passwordValidation.valid">
                    <i
                      class="fa-solid fa-circle-exclamation"
                      :style="{ color: password !== '' ? 'red' : 'green' }"
                    >
                    </i>
                    <div
                      v-if="!passwordValidation.valid && password !== ''"
                      class="tooltip-bubble"
                    >
                      <span class="tooltip-title">Password must contain:</span>
                      <div v-for="(rule, index) in invalidRules" :key="index">
                        • {{ rule.message }}
                      </div>
                    </div>
                    <div
                      v-if="!passwordValidation.valid && password !== ''"
                      class="tooltip-bubble-arrrow"
                    >
                      <i
                        class="fa-solid fa-sort-down"
                        style="color: #e0f2ff"
                      ></i>
                    </div>
                  </div>
                  <!--
                    <div class="password-rule" style="" v-if="!passwordValidation.valid && password !== ''">
                      {{ rule.message }}
                    </div>
                  -->
                  <div v-if="passwordValidation.valid">
                    <i
                      class="fa-solid fa-check"
                      style="color: green; font-size: large"
                    ></i>
                  </div>
                  <div
                    class="eye-closed-instance"
                    v-on:click="togglePasswordVisibility"
                  >
                    <svg
                      v-if="!passwordVisible"
                      class="eye-closed"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 9.5C20 9.5 19.6797 10.1674 19 11.0144M12 14.5C10.392 14.5 9.04786 14.0878 7.94861 13.5M12 14.5C13.608 14.5 14.9521 14.0878 16.0514 13.5M12 14.5V18M4 9.5C4 9.5 4.35367 10.2368 5.10628 11.1448M7.94861 13.5L5 16.5M7.94861 13.5C6.6892 12.8266 5.75124 11.9228 5.10628 11.1448M16.0514 13.5L18.5 16.5M16.0514 13.5C17.3818 12.7887 18.3535 11.8202 19 11.0144M5.10628 11.1448L2 12.5M19 11.0144L22 12.5"
                        stroke="#142D4D"
                        stroke-linecap="round"
                      />
                    </svg>
                    <svg
                      v-else
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 12C22 12 19 18 12 18C5 18 2 12 2 12C2 12 5 6 12 6C19 6 22 12 22 12Z"
                        stroke="#142D4D"
                        stroke-linecap="round"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        r="3"
                        stroke="#142D4D"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div style="display: flex; flex-direction: column; width: 100%">
                <div class="frame-4">
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    class="enter-password"
                    v-model="token"
                    for="token"
                  />
                </div>
              </div>
              <!-- <div style="display: flex; flex-direction: column; width: 100%">
                <div class="frame-4">
                  <input
                    :type="string"
                    placeholder="Enter Referral Code (if any)"
                    class="enter-password"
                    v-model="enteredReferralCode"
                    for="enteredReferralCode"
                  />
                </div>
              </div> -->
              <div class="frame-35630">
                <div class="frame-35627">
                  <input
                    type="checkbox"
                    id="remember-me"
                    class="rectangle-10"
                    v-model="terms"
                    for="terms"
                  />
                  <label for="terms-and-conditions" class="remember-me">
                    I accept all
                    <a
                      href="/privacypolicy"
                      target="_blank"
                      style="text-decoration: underline; color: #11365a"
                      >Terms and Conditions</a
                    >
                  </label>
                </div>
              </div>
              <div
                class="button-property-1-primary-hover-false-button-no"
                v-if="type === 'SignUp'"
                :style="{
                  backgroundColor: '#142d4d',
                  opacity: createButtonActive ? '0.7' : '1',
                }"
                v-on:click.prevent="
                  createButtonActive ? null : toggleSignUpOtp()
                "
              >
                <div class="login">
                  {{ createButtonActive ? "Submitting..." : "Create" }}
                </div>
              </div>
              <div class="frame-35636">
                <div class="don-t-have-an-account">
                  Already have an account?
                  <a
                    @click="updateQueryType('Login')"
                    style="
                      text-decoration: underline;
                      color: #1e1e1e;
                      cursor: pointer;
                    "
                    >Login</a
                  >
                </div>
              </div>
            </div>
            <!-- OTP -->
            <div class="frame-35626" v-if="showSignUpOtp">
              <div style="display: flex; flex-direction: column; width: 100%">
                <div class="frame-3">
                  <input
                    type="otp"
                    placeholder="Enter Verification Code"
                    class="enter-email-address"
                    autocomplete="off"
                    id="otp"
                    v-model="otp"
                    for="otp"
                  />
                </div>
              </div>
              <div
                class="button-property-1-primary-hover-false-button-no"
                v-if="type === 'SignUp'"
                :style="{
                  backgroundColor: '#142d4d',
                  opacity: submitOTPActive ? '0.7' : '1',
                }"
                v-on:click.prevent="submitOTPActive ? null : submitOTP()"
              >
                <div class="login">
                  {{ submitOTPActive ? "Verifying..." : "Submit" }}
                </div>
              </div>
            </div>
            <div class="frame-35636" v-if="showSignUpOtp">
              <div class="don-t-have-an-account">
                <a
                  @click="showSignUpOtp = false"
                  style="
                    text-decoration: underline;
                    color: #11365a;
                    cursor: pointer;
                  "
                  >Back to SignUp</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="loader-wrapper" v-if="isLoading">
      <LoaderAnimation />
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
import {
  Login,
  UserDetails,
  Signup,
  OTPVerification,
  PasswordAction,
  CompletePasswordAction,
} from "../api/APIs";
import VueCookies from "vue-cookies";
// import { useReCaptcha } from "vue-recaptcha-v3";
import Toastify from "@/components/Toastify.vue";
import LoaderAnimation from "@/assets/Animation/LoaderAnimation.vue";
import { mapActions } from "vuex";

export default {
  name: "LoginScreen",
  components: {
    Toastify,
    LoaderAnimation,
  },
  props: {},
  data() {
    return {
      rule: [
        {
          message: "8+ characters",
          regex: /^.{8,}$/,
          isValid: false,
        },
        {
          message: "1 uppercase letter",
          regex: /^(?=.*[A-Z]).+$/,
          isValid: false,
        },
        {
          message: "1 number",
          regex: /^(?=.*\d).+$/,
          isValid: false,
        },
        {
          message: "1 special character",
          regex: /^(?=.*[^a-zA-Z0-9]).+$/,
          isValid: false,
        },
      ],
      showLogin: false,
      name: "",
      siteKey: "6Ldwj_cpAAAAAMFNhKnnTgLeT7yboKC_Nv8zZ0cx",
      secretValue: "",
      username: "",
      password: "",
      token: "",
      terms: false,
      passwordVisible: false,
      showSignUpOtp: false,
      showPasswordReset: false,
      showPasswordResetOtp: false,
      otp: "",
      resetotp: "",
      type: null,
      newPassword: "",
      confirmNewPassword: "",
      isLoading: false,
      invitationToken: "",
      loginButtonActive: false,
      isPasswordValid: false,
      createButtonActive: false,
      submitOTPActive: false,
      sendVerificationActive: false,
      submitResetOTPActive: false,
      enteredReferralCode: "",
      shakeNewPassword: false,
      shakePassword: false,
      resendEnabled1: false,
      resendEnabled2: false,
      resendTimeLeft1: 0,
      resendTimeLeft2: 0,
      resendTimeout1: null,
      resendTimeout2: null,
    };
  },
  computed: {
    timerText1() {
      const minutes = Math.floor(this.resendTimeLeft1 / 60);
      const seconds = this.resendTimeLeft1 % 60;
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    },
    timerText2() {
      const minutes = Math.floor(this.resendTimeLeft2 / 60);
      const seconds = this.resendTimeLeft2 % 60;
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    },
    passwordInputType() {
      return this.passwordVisible ? "text" : "password";
    },
    passwordValidation() {
      let errors = [];

      this.rule.forEach((rule) => {
        rule.isValid = rule.regex.test(this.password);
        if (!rule.isValid) {
          errors.push(rule.message);
        }
      });

      return { valid: errors.length === 0 };
    },
    passwordValidationResetPassword() {
      let errors = [];

      this.rule.forEach((rule) => {
        rule.isValid = rule.regex.test(this.newPassword);
        if (!rule.isValid) {
          errors.push(rule.message);
        }
      });

      return { valid: errors.length === 0 };
    },
    invalidRules() {
      return this.rule.filter((rule) => !rule.isValid);
    },
  },
  created() {
    this.type = this.$route.query.type;
    if (this.$route?.query?.tok && this.$route?.query?.email) {
      this.username = this.$route.query.email;
      this.invitationToken = this.$route.query.tok;
    }
    if (this.$route?.query?.userEmail && this.$route?.query?.refCode) {
      this.username = this.$route?.query?.userEmail;
      this.enteredReferralCode = this.$route?.query?.refCode;
    }
    if (this.$route?.query?.userEmail) {
      this.username = this.$route?.query?.userEmail;
    }
    // if (this.$route?.query?.refCode) {
    //   this.enteredReferralCode = this.$route?.query?.refCode;
    //   this.$store.dispatch("setIsReferralCodePresent", true);
    // }
    // const { executeRecaptcha, recaptchaLoaded } = useReCaptcha({
    //   siteKey: this.siteKey,
    //   size: "invisible",
    //   badge: "inline",
    // });

    // this.executeRecaptcha = executeRecaptcha;
    // this.recaptchaLoaded = recaptchaLoaded;
  },
  beforeMount() {
    const shouldRefresh = localStorage.getItem("shouldRefresh");

    if (shouldRefresh) {
      localStorage.removeItem("shouldRefresh");
      window.location.reload(true);
    } else {
      localStorage.setItem("shouldRefresh", "true");
    }
  },
  mounted() {
    setTimeout(() => {
      this.showLogin = true;
    }, 500);
  },
  methods: {
    ...mapActions([
      "setIsReferralCodePresent",
      "updateCartCount",
      "setUserDetails",
    ]),
    async recaptcha() {
      await this.recaptchaLoaded();
      const token = await this.executeRecaptcha("login");
      return token;
    },
    shakePasswordField() {
      this.shakePassword = true;
      setTimeout(() => {
        this.shakePassword = false;
      }, 500);
      return;
    },
    shakeNewPasswordField() {
      this.shakeNewPassword = true;
      setTimeout(() => {
        this.shakeNewPassword = false;
      }, 500);
      return;
    },
    updateQueryType(type) {
      this.type = type;
      this.$router.push({ path: "/login", query: { type: type } });
    },
    async toggleSignUpOtp() {
      this.createButtonActive = true;
      // const token = await this.recaptcha();
      // if (!token) {
      //   this.createButtonActive = false;
      //   return this.$refs.toastContainer.addToast(
      //     "Something went wrong! Please try again later.",
      //     { type: "error" }
      //   );
      // }
      this.emailError = "";
      this.passwordError = "";
      this.loginError = "";
      this.termsError = "";
      if (!this.name.trim()) {
        this.createButtonActive = false;
        return this.$refs.toastContainer.addToast("Please enter the name", {
          type: "error",
        });
      }
      if (!this.username.trim()) {
        this.createButtonActive = false;
        return this.$refs.toastContainer.addToast("Please enter the email", {
          type: "error",
        });
      }
      if (!this.password.trim()) {
        this.createButtonActive = false;
        return this.$refs.toastContainer.addToast("Please enter the password", {
          type: "error",
        });
      }
      if (this.terms == false) {
        this.createButtonActive = false;
        return this.$refs.toastContainer.addToast(
          "Please accept the terms and conditions",
          { type: "error" }
        );
      }
      if (!this.token.trim()) {
        this.createButtonActive = false;
        return this.$refs.toastContainer.addToast(
          "Please confirm the password",
          { type: "error" }
        );
      }
      if (!this.passwordValidation.valid) {
        this.shakePasswordField();
        this.createButtonActive = false;
        return;
      } else if (this.password !== this.token) {
        this.createButtonActive = false;
        return this.$refs.toastContainer.addToast("Passwords do not match", {
          type: "error",
        });
      }

      if (
        this.name.trim() &&
        this.username.trim() &&
        this.token.trim() &&
        this.password.trim()
      ) {
        // this.$root.showLoader();

        try {
          const SignupResponse = await Signup(
            this.name,
            this.username,
            this.token,
            this.enteredReferralCode
          );
          if (SignupResponse && SignupResponse.message === "SUCCESS") {
            this.createButtonActive = false;
            this.secretValue = SignupResponse.secretValue;
            this.showSignUpOtp = !this.showSignUpOtp;
            this.resendEnabled1 = false;
            this.startResendTimer1();
          } else {
            this.createButtonActive = false;
            return this.$refs.toastContainer.addToast(SignupResponse.message, {
              type: "error",
            });
          }
        } catch (error) {
          this.createButtonActive = false;
          return this.$refs.toastContainer.addToast(
            "Error occurred while creating the account",
            { type: "error" }
          );
        }

        // this.$root.hideLoader();
      }
    },
    async togglePasswordReset() {
      this.showPasswordReset = !this.showPasswordReset;
    },
    async togglePasswordResetOtp() {
      this.showPasswordReset = false;
      this.showPasswordResetOtp = !this.showPasswordResetOtp;
    },
    async submitEmail(action) {
      this.sendVerificationActive = true;
      if (action === "Resend") {
        if (this.resendEnabled1) {
          this.resendEnabled1 = false;
          this.startResendTimer1();
        }
        if (this.resendEnabled2) {
          this.resendEnabled2 = false;
          this.startResendTimer2();
        }
      }
      // const token = await this.recaptcha();
      // if (!token) {
      //   this.sendVerificationActive = false;
      //   return this.$refs.toastContainer.addToast(
      //     "Something went wrong! Please try again later",
      //     { type: "error" }
      //   );
      // }
      this.resetEmailError = "";
      if (!this.username.trim()) {
        this.sendVerificationActive = false;
        return this.$refs.toastContainer.addToast("Please enter the email", {
          type: "error",
        });
      }
      if (this.username == "") {
        this.sendVerificationActive = false;
        return this.$refs.toastContainer.addToast("Please enter the email", {
          type: "error",
        });
      }
      if (!this.isValidEmail(this.username)) {
        this.sendVerificationActive = false;
        return this.$refs.toastContainer.addToast(
          "Please enter the valid email",
          {
            type: "error",
          }
        );
      }
      try {
        const response = await PasswordAction(
          this.username,
          action,
          this.secretValue
        );
        if (response && response.message === "SUCCESS") {
          this.sendVerificationActive = false;
          this.secretValue = response?.secretValue;
          if (action === "Resend") {
            this.$refs.toastContainer.addToast(
              "The email has been resent successfully",
              {
                type: "success",
              }
            );
          }
          if (action === "Reset") {
            this.resendEnabled2 = false;
            this.startResendTimer2();
            this.togglePasswordResetOtp();
          }
        } else {
          this.sendVerificationActive = false;
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch {
        this.sendVerificationActive = false;
        return this.$refs.toastContainer.addToast(
          "Oops! Something went wrong, Please try again later",
          {
            type: "error",
          }
        );
      }
    },
    startResendTimer1() {
      this.resendTimeLeft1 = 60;
      this.resendInterval1 = setInterval(() => {
        this.resendTimeLeft1 -= 1;
        if (this.resendTimeLeft1 <= 0) {
          clearInterval(this.resendInterval1);
          this.resendEnabled1 = true;
        }
      }, 1000);
    },
    startResendTimer2() {
      this.resendTimeLeft2 = 60;
      this.resendInterval2 = setInterval(() => {
        this.resendTimeLeft2 -= 1;
        if (this.resendTimeLeft2 <= 0) {
          clearInterval(this.resendInterval2);
          this.resendEnabled2 = true;
        }
      }, 1000);
    },
    isValidEmail(email) {
      return email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    async submitResetOTP() {
      this.submitResetOTPActive = true;
      this.resetOtpError = "";
      if (!this.resetotp.trim()) {
        this.submitResetOTPActive = false;
        return this.$refs.toastContainer.addToast("Please enter the OTP", {
          type: "error",
        });
      }
      if (!this.newPassword.trim()) {
        this.submitResetOTPActive = false;
        return this.$refs.toastContainer.addToast(
          "Please enter the new passowrd",
          {
            type: "error",
          }
        );
      }
      if (!this.confirmNewPassword.trim()) {
        this.submitResetOTPActive = false;
        return this.$refs.toastContainer.addToast(
          "Please confirm the new passowrd",
          {
            type: "error",
          }
        );
      }
      if (!this.passwordValidationResetPassword.valid) {
        this.shakeNewPasswordField();
        this.submitResetOTPActive = false;
        return;
      }
      if (this.confirmNewPassword !== this.newPassword) {
        this.submitResetOTPActive = false;
        return this.$refs.toastContainer.addToast("Passwords do not match", {
          type: "error",
        });
      }
      try {
        const OTPVerificationResponse = await CompletePasswordAction(
          this.username,
          this.secretValue,
          this.resetotp,
          this.confirmNewPassword
        );
        if (
          OTPVerificationResponse &&
          OTPVerificationResponse.message === "SUCCESS"
        ) {
          VueCookies.set("token", OTPVerificationResponse?.secretValue);
          await VueCookies.set("token-creation-time", new Date().toISOString());
          this.$root.initiateTokenExpiryHandler();
          const response = await UserDetails(
            this.username,
            OTPVerificationResponse?.secretValue
          );
          if (response) {
            this.$store.dispatch("setUserDetails", response);
            switch (response?.hasAccessTo[0]) {
              case "REPEAT":
                return this.$router.push({ name: "InternalDb" });

              case "NEW_ORDERS":
                return this.$router.push({ name: "Orders" });

              case "UPDATE_ORDERS":
                return this.$router.push({ name: "UpdateOrders" });

              case "INTERNAL_ALLOCATION":
                return this.$router.push({ name: "InternalAllocation" });

              case "ALLOCATED_TO_MAKER":
                return this.$router.push({ name: "AllocatedToMaker" });

              case "ALLOCATED_TO_CHECKER":
                return this.$router.push({ name: "AllocatedToChecker" });

              case "URGENT/REPEAT":
                return this.$router.push({ name: "Urgent" });

              case "INDIA":
                return this.$router.push({ name: "India" });

              case "CHINA":
                return this.$router.push({ name: "China" });

              case "READY_TO_SHARE":
                return this.$router.push({ name: "ReadyToShare" });

              case "BUSINESS_DASHBOARD":
                return this.$router.push({ name: "Business Dashboard" });

              case "ALLOCATION_SUMMARY":
                return this.$router.push({ name: "AllocationSummary" });

              case "PROCUREMENT_INVOICING":
                return this.$router.push({ name: "DataProcurement" });

              default:
                return this.$router.push({ name: "NotFound" });
            }
          }
        } else {
          this.submitResetOTPActive = false;
          return this.$refs.toastContainer.addToast(
            OTPVerificationResponse.message,
            {
              type: "error",
            }
          );
        }
      } catch (error) {
        this.submitResetOTPActive = false;
        return this.$refs.toastContainer.addToast(
          "Error occurred during login.",
          {
            type: "error",
          }
        );
      }
    },
    async submitOTP() {
      this.submitOTPActive = true;
      this.otpError = "";
      if (!this.otp.trim()) {
        this.submitOTPActive = false;
        return this.$refs.toastContainer.addToast("Please enter the OTP", {
          type: "error",
        });
      }
      try {
        const OTPVerificationResponse = await OTPVerification(
          this.username,
          this.secretValue,
          this.otp
        );
        if (
          OTPVerificationResponse &&
          OTPVerificationResponse.message === "SUCCESS"
        ) {
          VueCookies.set("token", OTPVerificationResponse.webToken);
          await VueCookies.set("token-creation-time", new Date().toISOString());
          this.$root.initiateTokenExpiryHandler();
          const response = await UserDetails(
            this.username,
            OTPVerificationResponse.webToken
          );
          if (response) {
            this.submitOTPActive = false;
            this.$store.dispatch("setUserDetails", response);

            switch (response?.hasAccessTo[0]) {
              case "REPEAT":
                return this.$router.push({ name: "InternalDb" });

              case "NEW_ORDERS":
                return this.$router.push({ name: "Orders" });

              case "UPDATE_ORDERS":
                return this.$router.push({ name: "UpdateOrders" });

              case "INTERNAL_ALLOCATION":
                return this.$router.push({ name: "InternalAllocation" });

              case "ALLOCATED_TO_MAKER":
                return this.$router.push({ name: "AllocatedToMaker" });

              case "ALLOCATED_TO_CHECKER":
                return this.$router.push({ name: "AllocatedToChecker" });

              case "URGENT/REPEAT":
                return this.$router.push({ name: "Urgent" });

              case "INDIA":
                return this.$router.push({ name: "India" });

              case "CHINA":
                return this.$router.push({ name: "China" });

              case "READY_TO_SHARE":
                return this.$router.push({ name: "ReadyToShare" });

              case "BUSINESS_DASHBOARD":
                return this.$router.push({ name: "Business Dashboard" });

              case "ALLOCATION_SUMMARY":
                return this.$router.push({ name: "AllocationSummary" });

              case "PROCUREMENT_INVOICING":
                return this.$router.push({ name: "DataProcurement" });

              default:
                return this.$router.push({ name: "NotFound" });
            }
          }
        } else {
          this.submitOTPActive = false;
          return this.$refs.toastContainer.addToast(
            OTPVerificationResponse.message,
            {
              type: "error",
            }
          );
        }
      } catch (error) {
        this.submitOTPActive = false;
        return this.$refs.toastContainer.addToast(
          "Error occurred during login.",
          {
            type: "error",
          }
        );
      }
    },
    async processLogin() {
      this.loginButtonActive = true;
      // const token = await this.recaptcha();
      // if (!token) {
      //   this.loginButtonActive = false;
      //   return this.$refs.toastContainer.addToast(
      //     "Something went wrong! Please try again later.",
      //     { type: "error" }
      //   );
      // }
      this.emailError = "";
      this.passwordError = "";
      this.loginError = "";

      if (!this.username.trim()) {
        this.loginButtonActive = false;
        return this.$refs.toastContainer.addToast("Please enter the email", {
          type: "error",
        });
      }
      if (!this.token.trim()) {
        this.loginButtonActive = false;
        return this.$refs.toastContainer.addToast("Please enter the password", {
          type: "error",
        });
      }
      if (this.username.trim() && this.token.trim()) {
        // this.$root.showLoader();
        this.isLoading = true;
        try {
          const loginResponse = await Login(this.username, this.token);
          if (loginResponse && loginResponse.message === "SUCCESS") {
            VueCookies.set("token", loginResponse.webToken);
            await VueCookies.set(
              "token-creation-time",
              new Date().toISOString()
            );
            this.$root.initiateTokenExpiryHandler();
            const response = await UserDetails(
              this.username,
              loginResponse.webToken
            );
            if (response) {
              this.$store.dispatch("setUserDetails", response);

              switch (response?.hasAccessTo[0]) {
                case "REPEAT":
                  return this.$router.push({ name: "InternalDb" });

                case "NEW_ORDERS":
                  return this.$router.push({ name: "Orders" });

                case "UPDATE_ORDERS":
                  return this.$router.push({ name: "UpdateOrders" });

                case "INTERNAL_ALLOCATION":
                  return this.$router.push({ name: "InternalAllocation" });

                case "ALLOCATED_TO_MAKER":
                  return this.$router.push({ name: "AllocatedToMaker" });

                case "ALLOCATED_TO_CHECKER":
                  return this.$router.push({ name: "AllocatedToChecker" });

                case "URGENT/REPEAT":
                  return this.$router.push({ name: "Urgent" });

                case "INDIA":
                  return this.$router.push({ name: "India" });

                case "CHINA":
                  return this.$router.push({ name: "China" });

                case "READY_TO_SHARE":
                  return this.$router.push({ name: "ReadyToShare" });

                case "BUSINESS_DASHBOARD":
                  return this.$router.push({ name: "Business Dashboard" });

                case "ALLOCATION_SUMMARY":
                  return this.$router.push({ name: "AllocationSummary" });

                case "PROCUREMENT_INVOICING":
                  return this.$router.push({ name: "DataProcurement" });

                default:
                  return this.$router.push({ name: "NotFound" });
              }
            }
          } else {
            // this.$root.hideLoader();
            this.loginButtonActive = false;
            this.isLoading = false;
            return this.$refs.toastContainer.addToast(
              "Wrong credentials, Please try again",
              { type: "error" }
            );
          }
        } catch (error) {
          // this.$root.hideLoader();
          this.loginButtonActive = false;
          this.isLoading = false;
          return this.$refs.toastContainer.addToast(
            "Error occurred during login",
            { type: "error" }
          );
        }

        // this.$root.hideLoader();
        this.isLoading = false;
      }
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
};
</script>
<style scoped>
.desktop-2,
.desktop-2 * {
  box-sizing: border-box;
}
.desktop-2 {
  background: #ffffff;
  height: 100vh;
  display: flex;
  overflow: hidden;
}
.bg-b-lue {
  background: #202331;
  width: 50%;
  height: 100%;
  position: relative;
  top: 0px;
}

.bg-white {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-blue {
  width: 0;
  overflow: hidden;
}

.shrink-in {
  width: 50%;
  height: 100vh;
  animation: slideInRight1 1s ease forwards;
}
input:-internal-autofill-selected {
  background-color: white !important;
  appearance: default;
}
.slide-in {
  background: #82d7db;
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: slideInRight 1s ease forwards;
  box-shadow: 0px 0px 24px 8px rgba(17, 54, 90, 0.2);
}
.frame-35628 {
  display: flex;
  flex-direction: column;
  gap: 5vh;
  align-items: center;
  justify-content: flex-start;
  width: 75%;
  position: relative;
}
.log-in-with {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 36px;
  font-weight: 700;
  position: relative;
}
.frame-35635 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35631 {
  display: flex;
  flex-direction: row;
  gap: 46px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-35632 {
  background: #ffffff;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.socials-instance {
  flex-shrink: 0 !important;
  width: 36px !important;
  height: 36px !important;
}
.frame-35633 {
  background: #ffffff;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.socials-instance2 {
  flex-shrink: 0 !important;
  width: 36px !important;
  height: 36px !important;
}
.frame-35634 {
  background: #ffffff;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.socials-instance3 {
  flex-shrink: 0 !important;
  width: 36px !important;
  height: 36px !important;
}
.or {
  color: #ffffff;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 18px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
}
.frame-35626 {
  display: flex;
  flex-direction: column;
  gap: 4vh;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-3 {
  background: white;
  border-radius: 16px;
  border-style: solid;
  border-color: transparent;
  border-width: 1px;
  padding: 1.8vh 24px 1.8vh 24px;
  display: flex;
  flex-direction: row;
  /* gap: 100px; */
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  min-width: 150px;
  position: relative;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
}
.enter-email-address {
  color: #1e1e1e !important;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
  border: none;
  outline: none;
  text-align: left;
  box-shadow: none;
  width: 100%;
  background-color: transparent;
}
.frame-4 {
  background: white;
  border-radius: 16px;
  border-style: solid;
  border-color: transparent;
  border-width: 1px;
  padding: 1.8vh 24px 1.8vh 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  min-width: 150px;
  position: relative;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
}
.enter-password {
  color: #1e1e1e;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
  background-color: transparent;
  border: none;
  outline: none;
  text-align: left;
  box-shadow: none;
  width: 90%;
  padding: 0;
  height: 24px;
}
input[type="text"] {
  margin: 0;
  padding: 0;
}
.eye-closed-instance {
  /* flex-shrink: 0 !important; */
  display: flex;
  align-items: center;
}
.frame-35630 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35627 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.rectangle-10 {
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid #142d4d;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.rectangle-10:checked {
  background-color: #142d4d;
}
.rectangle-10:checked:after {
  content: "\2713";
  color: white;
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.remember-me {
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 18px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
}
.forgot-password {
  color: #1e1e1e;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 18px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
  cursor: pointer;
}
.button-instance {
  background: var(--other-white, #ffffff) !important;
  padding: 17px 24px 17px 24px !important;
  align-self: stretch !important;
}
.frame-35636 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.don-t-have-an-account {
  color: #1e1e1e;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 18px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
}
.button-instance2 {
  border-style: solid !important;
  border-color: var(--other-white, #ffffff) !important;
  border-width: 2px !important;
  padding: 17px 24px 17px 24px !important;
  align-self: stretch !important;
  background: unset !important;
}
.frame-35629 {
  display: flex;
  flex-direction: column;
  gap: 29px;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  width: 40vw;
}
.frame-1 {
  display: flex;
  flex-direction: row;
  gap: 42.45px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.machine-efficiency-human-intelligence {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 20px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
}
.button-property-1-primary-hover-false-button-no {
  background: #11365a;
  border-radius: 13px;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: 150px;
  width: 100%;
  cursor: pointer;
}
.login {
  color: white;
  text-align: center;
  font-family: "NunitoSans-SemiBold", sans-serif;
  font-size: 18px;
  font-weight: 600;
  flex: 1;
}
.error-message {
  color: rgb(251, 85, 85);
  font-size: 12px;
  text-align: left;
  margin-left: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.router-link-no-hover {
  background-color: transparent !important;
}
.loader-wrapper {
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 8%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.tooltip-bubble {
  /* width: 65%; */
  display: flexbox;
  background: #e0f2ff;
  color: #000000;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: small;
  text-align: left;
  line-height: 22px;
  border-radius: 6px;
  padding: 15px 20px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  right: -15px;
  box-shadow: 0px 2px 15px 0px;
}
.tooltip-title {
  font-weight: 600;
  margin-bottom: 10px;
}
.tooltip-bubble-arrrow {
  width: 65%;
  font-size: large;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 82%;
  right: -120px;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.5s;
}
@media only screen and (max-width: 767px) {
  .desktop-2 {
    flex-direction: column;
  }
  .shrink-in {
    width: 100%;
    height: 15%;
  }
  .frame-35629 {
    gap: 10px;
  }
  .machine-efficiency-human-intelligence {
    font-size: 12px;
  }
  .frame-35629 {
    width: 80vw;
  }
  .slide-in {
    width: 100%;
    height: 85%;
  }
  .router-link-no-hover {
    width: 50%;
  }
  .log-in-with {
    font-size: 22px;
  }
  .frame-35628 {
    gap: 4vh;
    width: 80%;
  }
  .frame-3,
  .frame-4 {
    padding: 1vh 24px 1vh 24px;
  }
  .enter-email-address,
  .enter-password {
    font-size: 16px;
  }
  .remember-me,
  .don-t-have-an-account,
  .forgot-password {
    font-size: 14px;
  }
  .rectangle-10 {
    width: 18px;
    height: 18px;
  }
  .frame-35626 {
    gap: 3vh;
  }
  input[type="text"] {
    height: auto;
  }
  .frame-35627 {
    gap: 10px;
  }
  .tooltip-bubble-arrrow {
    width: 65%;
    font-size: large;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 80%;
    right: -38px;
  }
  @keyframes slideInRight1 {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideInRight {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .log-in-with {
    font-size: 26px;
  }
  .machine-efficiency-human-intelligence {
    font-size: 16px;
  }
  .forgot-password {
    font-size: 16px;
  }
  .remember-me {
    font-size: 16px;
  }
  .don-t-have-an-account {
    font-size: 16px;
  }
  .rectangle-10 {
    width: 20px;
    height: 20px;
  }
  .exclamationIcon {
    color: green;
  }
  .tooltip-bubble-arrrow {
    width: 65%;
    font-size: large;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    left: 220px;
    bottom: 85%;
  }
  @keyframes slideInRight1 {
    0% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slideInRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
}
@media only screen and (min-width: 1156px) {
  @keyframes slideInRight1 {
    0% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slideInRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
}
</style>
