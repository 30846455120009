<template>
  <div class="banking">
    <!-- Banking Information -->
    <div class="report-section-wrapper finance-section-wrapper" v-if="banking?.bank_facilities || banking?.any_other_information">
      <div class="report-section">
        <div>
          <div class="section-header">
            <div
              style="display: flex; width: 20px"
              v-html="financeIcons?.banking"
            ></div>
            <h2>Banking Information</h2>
          </div>
        </div>
        <div class="summary-box">
          <p v-if="banking?.bank_facilities">
            • {{ banking?.bank_facilities }}
          </p>
          <p v-if="banking?.any_other_information">
            • {{ banking?.any_other_information }}
          </p>
        </div>
      </div>
    </div>
    <!-- Bank Names -->
    <div
      class="report-section-wrapper finance-section-wrapper"
      v-if="banking?.bankNames.length > 0"
    >
      <div class="report-section">
        <div>
          <div class="section-header">
            <div
              style="display: flex; width: 20px"
              v-html="financeIcons?.bankNames"
            ></div>
            <h2>Bank Names</h2>
          </div>
        </div>
        <div class="finace-section-wrapper-1">
          <div class="finance-section-content">
            <div class="table-container">
              <table class="data-table">
                <thead>
                  <tr>
                    <th>Names</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, rowIndex) in banking?.bankNames"
                    :key="`compliance-row-${rowIndex}`"
                  >
                    <td>{{ row }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Note
          title="Note on Share Capital"
          :text="data?.noteOnShareCapital"
          v-if="
            data?.noteOnShareCapital &&
            data?.noteOnShareCapital !== 'Not available'
          "
        />
      </div>
    </div>
    <!-- Charges Section -->
    <div
      class="report-section-wrapper finance-section-wrapper"
      v-if="banking?.charges?.length"
    >
      <div class="report-section">
        <div>
          <div class="section-header">
            <div
              style="display: flex; width: 20px"
              v-html="financeIcons?.bankNames"
            ></div>
            <h2>Banking & Secured Charges</h2>
          </div>
        </div>
        <div class="finance-section-wrapper-1">
          <div class="finance-section-content">
            <div class="table-container">
              <table class="data-table">
                <thead>
                  <tr>
                    <th
                      v-for="(column, index) in filteredColumns"
                      :key="`col-${index}`"
                    >
                      {{ formatColumnName(column) }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(charge, rowIndex) in banking.charges"
                    :key="`row-${rowIndex}`"
                  >
                    <td
                      v-for="(column, colIndex) in filteredColumns"
                      :key="`cell-${rowIndex}-${colIndex}`"
                    >
                      {{ charge[column] || "N/A" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Note
          title="Note on Banking & Secured Charges"
          :text="data?.noteOnBankingCharges"
          v-if="
            data?.noteOnBankingCharges &&
            data?.noteOnBankingCharges !== 'Not available'
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import financeIcons from "@/assets/DigitalProduct/FinanceIcons.js";

export default {
  name: "BankingTab",
  components: {},
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    financeIcons() {
      return financeIcons;
    },
    banking() {
      return this.data;
    },
    filteredColumns() {
      if (!this.banking?.charges?.length) return [];
      const keys = Object.keys(this.banking.charges[0]);
      return keys.filter((key) =>
        this.banking.charges.some((charge) => charge[key] && charge[key] !== "")
      );
    },
  },
  methods: {
    formatColumnName(key) {
      if (typeof key !== "string") return key;
      return key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    },
  },
};
</script>

<style scoped>
.banking {
  font-family: system-ui, -apple-system, sans-serif;
  color: #333;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 0;
}
.report-section-wrapper {
  display: flex;
  padding: 24px 20px;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;
  border: 1.5px solid #e0f5ff;
  /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25); */
}
.finance-section-wrapper {
  flex-direction: column;
}
.report-section {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: -webkit-fill-available;
}
.section-header {
  display: flex;
  align-items: center;
  gap: 12px;
}
.finance-section-content {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 20px;
}
h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  font-family: Figtree;
  color: #142d4d;
}

h3 {
  margin: 0 0 12px 0;
  font-size: 16px;
  font-weight: 600;
}
.table-container {
  width: -webkit-fill-available;
  overflow-x: auto;
  box-shadow: 0px 0px 12.7px 0px #e0f5ff;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #e0f5ff;
}

.data-table td {
  text-align: left;
  font-size: 14px;
  color: #101828;
  font-family: "Figtree";
}

.data-table th {
  background-color: #e0f5ff;
  font-family: "Figtree";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #1c1c1c;
  border-bottom: 1px solid rgba(34, 64, 150, 0.2);
}

.data-table tr:last-child td {
  border-bottom: none;
}

.status-cell {
  width: 60px;
}

.status-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  font-size: 14px;
}

.summary-box {
  display: flex;
  flex-direction: column;
  padding: 12px;
  justify-content: center;
  align-items: start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(34, 64, 150, 0.5);
}
.summary-box p,
.summary-box h3 {
  text-align: left;
  margin: 0;
}
.summary-box p {
  font-family: "Figtree";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #1c1c1c;
}
.summary-box h3 {
  font-family: "Figtree";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #1c1c1c;
}
</style>
