import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@fortawesome/fontawesome-free/css/all.css';
import "vue3-toastify/dist/index.css";
import Vue3Toastify from 'vue3-toastify';
import { createHead } from '@unhead/vue'
// import { VueReCaptcha } from "vue-recaptcha-v3";
// import VueCookies from "vue-cookies";
import store from './store';
import VueLazyload from 'vue-lazyload';

const app = createApp(App);
const head = createHead();

app.use(router);
app.use(store);
app.use(Vue3Toastify, {
  autoClose: 3000,
  limit: 3,
  clearOnUrlChange: true,
  style: {
    opacity: '1',
    userSelect: 'initial',
  },
});
app.use(head);
app.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
});

// let reCaptchaScriptLoaded = false;

// router.beforeEach((to, from, next) => {
//   if (to.name === 'Login' || to.name === 'ShareYourThoughts' && VueCookies.get('token') !== undefined) {
//     if (!reCaptchaScriptLoaded) {
//         app.use(VueReCaptcha, { siteKey: "6Ldwj_cpAAAAAMFNhKnnTgLeT7yboKC_Nv8zZ0cx" });
//         reCaptchaScriptLoaded = true;
//         loadReCaptchaScript();
//         next();
//     } else {
//       loadReCaptchaScript();
//       next();
//     }
//   } else {
//     removeReCaptchaScript();
//     next();
//   }
// });

// function loadReCaptchaScript() {
//   if (!document.getElementById('recaptcha-script')) {
//     const script = document.createElement('script');
//     script.id = 'recaptcha-script';
//     script.src = `https://www.google.com/recaptcha/api.js?render=6Ldwj_cpAAAAAMFNhKnnTgLeT7yboKC_Nv8zZ0cx`;
//     script.async = true;
//     document.head.appendChild(script);
//   }
// }

// function removeReCaptchaScript() {
//   const script = document.getElementById('recaptcha-script');
//   if (script) {
//     script.remove();
//   }

//   const badge = document.querySelector('.grecaptcha-badge');
//   if (badge) {
//     badge.remove();
//   }
// }

app.mount('#app');
