<template>
  <div class="summary-card">
    <div class="card-header">
      <h2 class="section-title">{{ formattedTitle }}</h2>
      <!-- <p class="last-updated">Last Updated: {{ lastUpdated }}</p> -->
    </div>

    <div class="action-group">
      <!-- <button class="action-button edit-button" @click="toggleIsEditable">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/826710fffe0a4a2288bbf423f58c604905cac28f3133fb5c4b45b6e7b9331f22"
          alt="Edit"
          class="button-icon"
        />
        Edit
      </button> -->
      <div class="toggle-container">
        <span class="toggle-label">{{ isLocked ? "Locked" : "Unlocked" }}</span>
        <label class="switch">
          <input type="checkbox" :checked="isLocked" @change="toggleLock" />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    lastUpdated: { type: String, default: "N/A" },
    completionPercentage: { type: Number, required: true },
    toggleIsEditable: { type: Function, required: true },
  },
  data() {
    return {
      animatedOffset: 2 * Math.PI * 45,
      isLocked: true,
    };
  },
  computed: {
    formattedTitle() {
      return this.title?.replace(/([a-z])([A-Z])/g, "$1 $2");
    },
    progressCircumference() {
      return 2 * Math.PI * 45;
    },
    progressOffset() {
      return this.progressCircumference * (1 - this.completionPercentage / 100);
    },
    progressColor() {
      const percentage = this.completionPercentage;
      if (percentage < 10) return "#ef4444"; // Red
      if (percentage < 20) return "#f97316"; // Orange-Red
      if (percentage < 30) return "#fb923c"; // Orange
      if (percentage < 40) return "#facc15"; // Yellow
      if (percentage < 50) return "#a3e635"; // Yellow-Green
      if (percentage < 60) return "#4ade80"; // Light Green
      if (percentage < 70) return "#22c55e"; // Green
      if (percentage < 80) return "#16a34a"; // Bright Green
      if (percentage < 90) return "#02a375"; // Darker Green
      return "#047857"; // Deep Green
    },
  },
  watch: {
    completionPercentage: {
      immediate: true, // Run on component mount
      handler() {
        this.animateProgress();
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.animatedOffset = this.progressOffset;
    }, 100);
  },
  methods: {
    toggleLock(event) {
      this.$emit("update:isLocked", event.target.checked); // Emit event with new state
    },
    animateProgress() {
      this.animatedOffset = this.progressCircumference;
      setTimeout(() => {
        this.animatedOffset = this.progressOffset;
      }, 100);
    },
  },
};
</script>

<style scoped>
.summary-card {
  background-color: #d3f1ff;
  display: flex;
  padding: 12px 24px;
  align-items: center;
  gap: 16px;
  font-family: Geist, sans-serif;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: 0px 2px 4px 0px rgba(20, 45, 77, 0.2);
  /* border-top-left-radius: 7px;
  border-top-right-radius: 7px; */
}

.card-header {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  flex-basis: 0%;
}

.section-title {
  font-size: 20px;
  font-weight: 600;
  color: #142d4d;
  margin: 0;
  text-align: left;
  font-family: "Figtree";
}

.last-updated {
  font-size: 14px;
  color: #64748b;
  margin: 0 0 24px;
}

.action-group {
  align-self: stretch;
  display: flex;
  /* min-width: 240px; */
  align-items: center;
  gap: 16px;
  white-space: nowrap;
  justify-content: center;
}

.completion-status {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.progress-circle {
  width: 50px;
  height: 50px;
  position: relative;
}

svg {
  transform: rotate(-90deg);
}

.progress-background {
  fill: none;
  stroke: #e5e7eb;
  stroke-width: 8;
}

.progress-bar {
  fill: none;
  stroke-width: 8;
  stroke-linecap: round;
  transition: stroke-dashoffset 1s ease-in-out;
}

.progress-value {
  font-size: 20px;
  font-weight: bold;
}

.status-text {
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 0;
}

.action-button {
  border-radius: 6px;
  display: flex;
  min-width: 80px;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  padding: 8px 12px;
  cursor: pointer;
}

.edit-button {
  background-color: #fff;
  color: #000;
  border: 1px solid #e2e8f0;
}

.lock-button {
  background-color: #0f172a;
  color: white;
  border: none;
}

.button-icon {
  width: 16px;
}

.toggle-container {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 7px;
}

.toggle-label {
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 600;
  text-align: left;
  font-family: Figtree;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #fd4c8c;
}

input:focus + .slider {
  box-shadow: 0 0 1px #fd4c8c;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
