import { createWebHistory, createRouter } from "vue-router";
import VueCookies from "vue-cookies";
import NotFound from "@/views/NotFound.vue";
// import MyOrders from "@/views/MyOrders.vue";
import BankUserOrders from "@/views/BankUserOrders.vue";
import LoginScreen from "@/views/Login.vue";
import UserProfile from "@/views/RegisterdUser/UserProfile.vue";
// import BlogEditor from "@/components/Blogs/BlogsEditor.vue";
import InternalAllocation from "@/views/InternalAllocation.vue";
import UrgentAndRepeat from "@/views/Urgent-Repeat.vue";
import AllocatedOrders from "@/views/AllocatedOrders.vue";
import India from "@/views/India.vue";
import China from "@/views/China.vue";
import AllocatedToChecker from "@/views/AllocatedToChecker.vue";
import RepeatOrders from "@/views/RepeatOrders.vue";
import ReadyToShare from "@/views/ReadyToShare.vue";
import UpdateOrders from "@/views/UpdateOrder.vue";
import AllocationSummary from "@/views/AllocationSummary.vue";
import DataProcurement from "@/views/DataProcurement.vue";
import ClarificationPending from "@/views/ClarificationPending.vue";

const routes = [
  {
    path: "/",
    name: "new-home",
    component: LoginScreen,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (token) {
        next({ name: "InternalDb" });
      } else {
        next({ name: "Login", query: { type: "Login" } });
      }
    },
  },
  {
    path: "/internal-allocation",
    name: "InternalAllocation",
    component: InternalAllocation,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "Login", query: { type: "Login" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/data-procurement",
    name: "DataProcurement",
    component: DataProcurement,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "Login", query: { type: "Login" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/allocated-to-maker",
    name: "AllocatedToMaker",
    component: AllocatedOrders,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "Login", query: { type: "Login" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/allocated-to-checker",
    name: "AllocatedToChecker",
    component: AllocatedToChecker,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "Login", query: { type: "Login" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/india",
    name: "India",
    component: India,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "Login", query: { type: "Login" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/allocation-summary",
    name: "AllocationSummary",
    component: AllocationSummary,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "Login", query: { type: "Login" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/china",
    name: "China",
    component: China,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "Login", query: { type: "Login" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/ready-to-share",
    name: "ReadyToShare",
    component: ReadyToShare,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "Login", query: { type: "Login" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/clarification-pending",
    name: "ClarificationPending",
    component: ClarificationPending,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "Login", query: { type: "Login" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/update-orders",
    name: "UpdateOrders",
    component: UpdateOrders,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "Login", query: { type: "Login" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/urgent-repeat",
    name: "Urgent",
    component: UrgentAndRepeat,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "Login", query: { type: "Login" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/internal-db",
    name: "InternalDb",
    component: RepeatOrders,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "Login", query: { type: "Login" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/profile",
    name: "YourProfile",
    props: true,
    component: UserProfile,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "Login", query: { type: "Login" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/MyOrders",
    name: "Orders",
    component: BankUserOrders,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "Login", query: { type: "Login" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
];

routes.push({
  path: "/login",
  name: "Login",
  props: true,
  component: LoginScreen,
});

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = VueCookies.get("token");
  if (to.name === "Login" && token) {
    next({ name: "InternalDb" });
  } else {
    next();
  }
});

export default router;
