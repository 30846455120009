<template>
  <nav class="company-tabs" aria-label="Company information sections">
    <button
      v-for="(tab, index) in tabs"
      :key="index"
      :class="['tab-item', { active: tab.active }]"
      @click="selectTab(index)"
      :aria-selected="tab.active"
      :tabindex="tab.active ? 0 : -1"
    >
      <!-- <img :src="tab.iconSrc" alt="" class="tab-icon" /> -->
      <span class="tab-label">{{ tab.label }}</span>
    </button>
  </nav>
</template>

<script>
export default {
  name: "CompanyTabs",
  data() {
    return {
      tabs: [
        {
          label: "Summary",
          iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/9fcf92e68a552cc22ca0f7108e6057cef73eda669e1a7c68bcf5722fc58b7a1a?placeholderIfAbsent=true&apiKey=50f0e2e811a94f4e8a863fba3bee16fd",
          active: true,
          component: "SUMMARY",
        },
        {
          label: "Company",
          iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/ed2a253fd4e9623570aee82d48a31fe1a866cfa94ae000e3ea82bf18dd4b0339?placeholderIfAbsent=true&apiKey=50f0e2e811a94f4e8a863fba3bee16fd",
          active: false,
          component: "COMPANY",
        },
        {
          label: "Finance",
          iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/b6725ab4f79700ce4693b17582a7b896c09029fa3652624bd80431cedb3bc508?placeholderIfAbsent=true&apiKey=50f0e2e811a94f4e8a863fba3bee16fd",
          active: false,
          component: "FINANCE",
        },
        {
          label: "Shareholding",
          iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/2051fe2302478fe0d73409d0cd6f7bcffbcec22209cd7866871626bfae8c4e9d?placeholderIfAbsent=true&apiKey=50f0e2e811a94f4e8a863fba3bee16fd",
          active: false,
          component: "SHAREHOLDING",
        },
        {
          label: "Management",
          iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/2051fe2302478fe0d73409d0cd6f7bcffbcec22209cd7866871626bfae8c4e9d?placeholderIfAbsent=true&apiKey=50f0e2e811a94f4e8a863fba3bee16fd",
          active: false,
          component: "MANAGEMENT",
        },
        {
          label: "Compliance",
          iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/54a843dd6f52a0fe3ae1d18741792562d8e414887f7d828c50d4fe1872bb56b3?placeholderIfAbsent=true&apiKey=50f0e2e811a94f4e8a863fba3bee16fd",
          active: false,
          component: "COMPLIANCE",
        },
        // {
        //   label: "Legal",
        //   iconSrc:
        //     "https://cdn.builder.io/api/v1/image/assets/TEMP/783d7d980a65777fdef14760c72e0d6689300824181f993acc2142cad2408199?placeholderIfAbsent=true&apiKey=50f0e2e811a94f4e8a863fba3bee16fd",
        //   active: false,
        //   component: "LEGAL",
        // },
        // {
        //   label: "Risk",
        //   iconSrc:
        //     "https://cdn.builder.io/api/v1/image/assets/TEMP/057926c678a0579247fbd0b82553f81138199d8a4febabba656dfad003c1acef?placeholderIfAbsent=true&apiKey=50f0e2e811a94f4e8a863fba3bee16fd",
        //   active: false,
        //   component: "RISK",
        // },
        // {
        //   label: "Related Parties",
        //   iconSrc:
        //     "https://cdn.builder.io/api/v1/image/assets/TEMP/876b1d573a497ae0eb761bf4d95048fe07111a2f6f21872fe757553014a31923?placeholderIfAbsent=true&apiKey=50f0e2e811a94f4e8a863fba3bee16fd",
        //   active: false,
        //   component: "RELATED_PARTIES",
        // },
        {
          label: "Employees",
          iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/1125452ef7a165d0fba52c8cb9a4531d751fd17e5514be1d9e8e1489400abfd4?placeholderIfAbsent=true&apiKey=50f0e2e811a94f4e8a863fba3bee16fd",
          active: false,
          component: "EMPLOYEES",
        },
        // {
        //   label: "Credit Ratings",
        //   iconSrc:
        //     "https://cdn.builder.io/api/v1/image/assets/TEMP/0a6b341dcaf6a2727cfced4c917d442e7c534c488cf7bc6b42c82f843837f87d?placeholderIfAbsent=true&apiKey=50f0e2e811a94f4e8a863fba3bee16fd",
        //   active: false,
        //   component: "CREDIT_RATINGS",
        // },
        // {
        //   label: "Peer Comparison",
        //   iconSrc:
        //     "https://cdn.builder.io/api/v1/image/assets/TEMP/53a1f402849d32e0fbeef6182d86f960ebddb18cf4479b41fa01047cec49d6fa?placeholderIfAbsent=true&apiKey=50f0e2e811a94f4e8a863fba3bee16fd",
        //   active: false,
        //   component: "PEER_COMPARISON",
        // },
        // {
        //   label: "Documents",
        //   iconSrc:
        //     "https://cdn.builder.io/api/v1/image/assets/TEMP/4f67c772e66cf0b607757d75f95a4fc9bd5637328d8d36e4c1b4f17048d2b971?placeholderIfAbsent=true&apiKey=50f0e2e811a94f4e8a863fba3bee16fd",
        //   active: false,
        //   component: "DOCUMENTS",
        // },
        {
          label: "Trade",
          iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/4c7ab04d7544575a7e4c9af7d736d9f86cb21c136f10dbbe89970898a584429d?placeholderIfAbsent=true&apiKey=50f0e2e811a94f4e8a863fba3bee16fd",
          active: false,
          component: "TRADE",
        },
      ],
    };
  },
  methods: {
    selectTab(index) {
      this.tabs.forEach((tab, i) => {
        tab.active = i === index;
      });
      this.$emit("tab-changed", this.tabs[index].component);
    },
  },
};
</script>

<style scoped>
.company-tabs {
  background: white;
  display: flex;
  width: -webkit-fill-available;
  align-items: start;
  gap: 4px;
  /* overflow-x: auto; */
  padding: 20px 24px 0;
  font: 500 14px/1 Geist, sans-serif;
  flex-wrap: wrap;
}
.tab-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-family: Figtree;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #eef7ff;
  border-color: #9ca3af;
  color: #000;
  width: auto;
  font-weight: 500;
}
.tab-item.active {
  background: #d3f1ff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  color: #224096;
  border-bottom: 2px solid var(--Primary---marion-Blue, #224096);
}
.tab-item.active .tab-icon {
  filter: brightness(0) invert(1);
}
.tab-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
@media (max-width: 991px) {
  .company-tabs {
    flex-wrap: nowrap;
    /* overflow-x: auto; */
  }
}
</style>
