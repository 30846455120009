<template>
  <div class="summary-info">
    <TabTitle :title="title" :lastUpdated="lastUpdated" />
    <div class="financial-report">
      <!-- Peer Comparison Section -->
      <section class="peer-section">
        <h2 class="section-title">Peer Comparison</h2>
        <div class="industry-info">
          <p>Industry: {{ data.industry }}</p>
          <p>Segment(s): {{ data.segments }}</p>
        </div>
      </section>

      <!-- Financial Metrics Section -->
      <section class="metrics-section">
        <h2 class="section-title">Financial Metrics</h2>
        <div class="table-wrapper">
          <table class="metrics-table">
            <thead>
              <tr>
                <th>Metrics</th>
                <th>FY 2024</th>
                <th>FY 2023</th>
                <th>FY 2022</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(metric, index) in data.financialMetrics" :key="index">
                <td>{{ metric.name }}</td>
                <td>{{ metric.fy2024 }}</td>
                <td>{{ metric.fy2023 }}</td>
                <td>{{ metric.fy2022 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <!-- Peers by Revenue Section -->
      <section class="peers-section">
        <h2 class="section-title">4 Closest Peers by Revenue</h2>
        <div class="table-wrapper">
          <table class="peers-table">
            <thead>
              <tr>
                <th>Legal Name</th>
                <th>City</th>
                <th>Revenue ({{ currencyLabel }})</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(peer, index) in data.peers" :key="index">
                <td>{{ peer.name }}</td>
                <td>{{ peer.city }}</td>
                <td>{{ peer.revenue }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import TabTitle from "@/components/CompanyDashboard/tabs/TabTitle.vue";

export default {
  name: "FinancialComparison",
  components: {
    TabTitle,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    lastUpdated: {
      type: String,
      default: "N/A",
    },
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currencyLabel() {
      const route = useRoute();
      return route.params.country === "India" ? "INR, Million" : "USD, Million";
    },
  },
};
</script>


<style scoped>
.summary-info {
  background-color: #fff;
  /* border: 1px solid #e2e8f0; */
  border-radius: 8px;
  margin-top: 0;
  text-align: left;
}
.financial-report {
  padding: 20px;
  font-family: system-ui, -apple-system, sans-serif;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
  text-decoration: underline;
}

.industry-info {
  color: #64748b;
  margin-bottom: 24px;
}

.industry-info p {
  margin: 4px 0;
}

.table-wrapper {
  overflow-x: auto;
  margin-bottom: 32px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  background-color: #f1f5f9;
  padding: 12px 16px;
  text-align: left;
  font-weight: 500;
}

td {
  padding: 12px 16px;
  color: #64748b;
}

tbody tr:nth-child(even) {
  background-color: #f8fafc;
}

.metrics-table td:first-child {
  color: #64748b;
}

.peers-table td:first-child {
  max-width: 300px;
}
@media (max-width: 768px) {
  .summary-info {
    padding: 16px;
  }
}
</style>
