<template>
  <div class="summary-info">
    <TabTitle :title="title" :lastUpdated="lastUpdated" />
    <div class="document-list">
      <h2 class="title">Company Documents</h2>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th>Document Name</th>
              <th>Type</th>
              <th>Size</th>
              <th>Last Updated</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="doc in data" :key="doc.name">
              <td class="document-name">{{ doc.name }}</td>
              <td class="document-type">{{ doc.type }}</td>
              <td class="document-size">{{ doc.size }}</td>
              <td class="document-date">{{ doc.lastUpdated }}</td>
              <td>
                <button class="download-button" @click="handleDownload(doc)">
                  <span class="download-icon">↓</span>
                  Download
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import TabTitle from "@/components/CompanyDashboard/tabs/TabTitle.vue";

export default {
  name: "DocumentList",
  components: {
    TabTitle,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    lastUpdated: {
      type: String,
      default: "N/A",
    },
    data: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleDownload(document) {
      // Handle download logic here
      console.log("Downloading:", document.name);
    },
  },
};
</script>

<style scoped>
.summary-info {
  background-color: #fff;
  /* border: 1px solid #e2e8f0; */
  border-radius: 8px;
  margin-top: 0;
  text-align: left;
}
.document-list {
  padding: 20px;
  font-family: system-ui, -apple-system, sans-serif;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  text-decoration: underline;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  background-color: #f1f5f9;
  padding: 16px;
  text-align: left;
  font-weight: 500;
}

td {
  padding: 16px;
  color: #64748b;
}

tbody tr:nth-child(even) {
  background-color: #f8fafc;
}

.document-name {
  color: #64748b;
}

.document-type {
  color: #64748b;
  text-transform: uppercase;
}

.document-size {
  color: #64748b;
}

.document-date {
  color: #64748b;
}

.download-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: white;
  color: #0f172a;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
  width: auto;
}

.download-button:hover {
  background-color: #f8fafc;
}

.download-icon {
  font-size: 16px;
}
@media (max-width: 768px) {
  .summary-info {
    padding: 16px;
  }
}
</style>
