<template>
    <div id="dashboardSvgMap"></div>
</template>

<script>
import svgMap from "svgmap";
import "svgmap/dist/svgMap.min.css";

export default {
    name: "WorldMap",
    props: {
        mapData: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data() {
        return {
            dashboardMapInstance: null, // Stores the svgMap instance
            dashboardMap: {
                targetElementID: "dashboardSvgMap",
                mouseWheelZoomEnabled: false,
                hideFlag: false,
                colorMax: "#0172f4",
                colorMin: "#adc3fc",
                colorNoData: "#e4e9fc",
                data: {
                    data: {
                        totalTx: {
                            name: "Transaction Records -",
                            format: "",
                        },
                    },
                    applyData: "totalTx",
                    values: {},
                },
            },
        };
    },
    mounted() {
        // if (this.mapData?.length) {
        //     this.renderMap();
        // }
    },
    watch: {
        mapData: {
            deep: true,
            immediate: true,
            handler(newData) {
                if (newData?.length) {
                    this.renderMap();
                }
            },
        },
    },
    beforeUnmount() {
        this.destroyMap(); // Ensure cleanup before unmounting
    },
    methods: {
        renderMap() {
            this.destroyMap(); // Ensure old map is fully removed before creating a new one
            this.generateValues();

            this.$nextTick(() => {
                this.dashboardMapInstance = new svgMap(this.dashboardMap);
            });
        },
        generateValues() {
            const values = {};
            this.mapData.forEach((data) => {
                const countryCode = data?.countryCode;
                if (countryCode) {
                    values[countryCode] = {
                        totalTx: data?.value,
                    };
                }
            });
            this.dashboardMap.data.values = values;
        },
        destroyMap() {
            if (this.dashboardMapInstance) {
                document.getElementById("dashboardSvgMap").innerHTML = ""; // Removes old map completely
                this.dashboardMapInstance = null;
            }
        },
    },
};
</script>

<style scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
}

:deep(.svgMap-map-wrapper .svgMap-map-image) {
    background-color: white;
}

:deep(.svgMap-map-wrapper .svgMap-country) {
    stroke: #dbe1ef !important;
    stroke-width: 1.5 !important;
    transition: fill 250ms, stroke 100ms !important;
}

:deep(.svgMap-tooltip) {
    background: white !important;
    color: #11365a !important;
    border-radius: 10px !important;
}

:deep(.svgMap-tooltip-pointer::after) {
    background-color: white !important;
}

:deep(.svgMap-tooltip .svgMap-tooltip-content),
:deep(.svgMap-tooltip .svgMap-tooltip-content table tbody tr td span) {
    color: #11365a !important;
}

:deep(.svgMap-tooltip-no-data) {
    color: rgb(156, 178, 200) !important;
}
</style>
