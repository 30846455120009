<template>
  <TabTitle
    :title="data?.title || 'Company'"
    :lastUpdated="data?.lastUpdated"
    :completionPercentage="data.completion_percentage || 0"
    :toggleIsEditable="toggleIsEditable"
  />
  <!-- Lock Section -->
  <div class="company-profile lock-wrapper" v-if="isLocked">
    <LockAnimation />
  </div>
  <!-- Unlock Section -->
  <div class="company-profile" v-else>
    <!-------- Key Information Section ------------>
    <div class="profile-grid" v-show="editedData?.keyInformation">
      <div class="frame-group">
        <div class="key-info-wrapper">
          <div
            style="display: contents"
            v-html="CompanyIcons?.keyInfoComapny"
          ></div>
          <div class="heading-3">Key Information</div>
        </div>
        <div class="frame-container">
          <template
            v-for="(chunk, index) in chunkedObjectsData(
              editedData?.keyInformation
            )"
            :key="index"
          >
            <!-- Remove separation from the first section and first item after a horizontal divider -->
            <div
              :class="[
                'frame-div',
                { separation: index !== 0 && index % 3 !== 0 },
              ]"
            >
              <div v-for="(value, key) in chunk" :key="key" class="name-parent">
                <div class="name">{{ formatLabel(key) }}</div>
                <div class="value">{{ value || "-" }}</div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!---------About Company ------------------->
    <div class="profile-grid" v-show="editedData?.aboutTheComapny">
      <div class="frame-group">
        <div class="frame-group1">
          <div class="key-info-wrapper">
            <div
              style="display: contents"
              v-html="CompanyIcons?.aboutCompany"
            ></div>
            <div class="heading-3">About the Company</div>
          </div>
          <div class="sub-heading">
            The firm is a Proprietorship engaged in Trader - Retailer of Bengal
            Deshi
          </div>
        </div>
        <div class="frame-container">
          <template
            v-for="(chunk, index) in chunkedObjectsData(
              editedData?.aboutTheComapny
            )"
            :key="index"
          >
            <div
              :class="[
                'frame-div',
                { separation: index !== 0 && index % 3 !== 0 },
              ]"
            >
              <div v-for="(value, key) in chunk" :key="key" class="name-parent">
                <div class="name">{{ formatLabel(key) }}</div>
                <div class="value">{{ value || "-" }}</div>
              </div>
            </div>

            <!-- Horizontal Divider after the first 3 columns -->
            <!-- <div v-if="(index + 1) % 3 === 0 && index !== chunkedData(editedData?.aboutTheComapny)?.length - 1"
              class="horizontal-divider"></div> -->
          </template>
        </div>
      </div>
    </div>
    <!---------Contact Info & Address ------------>
    <div class="profile-grid" v-show="editedData?.contactInformation">
      <div class="frame-group">
        <div class="key-info-wrapper">
          <div
            style="display: contents"
            v-html="CompanyIcons?.contactInfo"
          ></div>
          <div class="heading-3">Contact Information</div>
        </div>
        <div class="frame-container1">
          <template
            v-for="(chunk, index) in chunkedObjectsData(
              editedData?.contactInformation
            )"
            :key="index"
          >
            <div
              :class="[
                'frame-div',
                { separation: index !== 0 && index % 3 !== 0 },
              ]"
            >
              <div v-for="(value, key) in chunk" :key="key" class="name-parent">
                <div class="name" v-if="key !== 'addresses'">
                  {{ formatLabel(key) }}
                </div>
                <div class="value">
                  <ul
                    v-if="
                      Array.isArray(value) &&
                      value?.length &&
                      key !== 'addresses'
                    "
                    class="ul-items"
                  >
                    <li v-for="(item, i) in value" :key="i">
                      <a
                        v-if="key === 'website'"
                        :href="item"
                        target="_blank"
                        rel="noopener noreferrer"
                        >{{ item }}</a
                      >
                      <span v-else>{{ item }}</span>
                    </li>
                  </ul>
                  <span
                    v-else-if="
                      Array.isArray(value) &&
                      value?.length === 0 &&
                      key !== 'addresses'
                    "
                  >
                    {{ "-" }}
                  </span>
                  <span v-else-if="key === 'website'">
                    <a
                      :href="value"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ value }}</a
                    >
                  </span>
                  <span v-else-if="key !== 'addresses'">
                    {{ value || "-" }}
                  </span>
                </div>
              </div>
            </div>

            <!-- Horizontal Divider after every 3 columns -->
            <!-- <div
              v-if="(index + 1) % 3 === 0 && index !== chunkedObjectsData(editedData?.contactInformation)?.length - 1"
              class="horizontal-divider">
            </div> -->
          </template>
        </div>
        <!-- Address & Map Section -->
        <div
          class="frame-group"
          v-show="editedData?.contactInformation?.addresses"
        >
          <div class="key-info-wrapper">
            <div class="heading-3">Addresses</div>
          </div>
          <div class="frame-container2">
            <table class="table-container">
              <thead class="table-headers">
                <tr class="table-header-row">
                  <th
                    class="table-header-cell"
                    v-for="(header, index) in Object.keys(
                      editedData?.contactInformation?.addresses[0] || {}
                    )"
                    :key="index"
                  >
                    {{ formatLabel(header) }}
                  </th>
                </tr>
              </thead>
              <tbody class="table-body">
                <tr
                  class="table-header-row"
                  v-for="(entry, index) in editedData?.contactInformation
                    ?.addresses"
                  :key="index"
                  :style="{
                    'border-bottom':
                      index !==
                      editedData?.contactInformation?.addresses?.length - 1
                        ? '1px solid #e0f5ff'
                        : 'none',
                  }"
                >
                  <td class="table-header-cell">
                    {{ entry?.addressType || "-" }}
                  </td>
                  <td class="table-header-cell">{{ entry?.country }}</td>
                  <td class="table-header-cell">{{ entry?.address }}</td>
                </tr>
              </tbody>
            </table>
            <!-- Map -->
            <div class="map-container" ref="mapContainer"></div>
          </div>
        </div>
      </div>
    </div>
    <!----------Business Activites --------------->
    <div
      class="profile-grid profile-grid-table"
      v-show="editedData?.businessActivities"
    >
      <div class="frame-group">
        <div class="key-info-wrapper">
          <div
            style="display: contents"
            v-html="CompanyIcons?.businessActivities"
          ></div>
          <div class="heading-3">Business Activites</div>
        </div>

        <!-- Non-array key-value pairs -->
        <div class="frame-container">
          <div
            class="frame-div"
            v-for="([key, value], index) in editedData?.businessActivities
              ? Object.entries(editedData.businessActivities).filter(
                  ([_, val]) => !Array.isArray(val)
                )
              : []"
            :key="key"
            :class="{ separation: index > 0 }"
          >
            <div class="name-parent">
              <div class="name">{{ formatLabel(key) }}</div>
              <div class="value">{{ value || "-" }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Table Data -->
      <div
        v-for="([key, arrayData], tableIndex) in editedData?.businessActivities
          ? Object.entries(editedData.businessActivities).filter(
              ([_, value]) => Array.isArray(value) && value.length
            )
          : []"
        :key="tableIndex + key"
        class="name-history-table"
      >
        <table class="table-container" style="min-width: 950px">
          <thead class="table-headers">
            <tr class="table-header-row">
              <th
                class="table-header-cell"
                v-for="(header, hIndex) in Object.keys(arrayData[0] || {})"
                :key="hIndex"
              >
                {{ formatLabel(header) }}
              </th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr
              class="table-header-row"
              v-for="(entry, rowIndex) in arrayData"
              :key="rowIndex"
              :style="{
                'border-bottom':
                  rowIndex !== arrayData.length - 1
                    ? '1px solid #e0f5ff'
                    : 'none',
              }"
            >
              <td
                v-for="(field, fieldKey) in entry"
                :key="fieldKey"
                class="table-header-cell"
              >
                {{ field || "-" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!----------Principal Business ACtivites --------------->
    <div
      class="profile-grid profile-grid-table"
      v-show="editedData?.principalBusinessActivities"
    >
      <div class="frame-group">
        <div class="key-info-wrapper">
          <div
            style="display: contents"
            v-html="CompanyIcons?.businessActivities"
          ></div>
          <div class="heading-3">Business Activites</div>
        </div>
        <div class="frame-container">
          <div
            class="frame-div separation"
            v-for="(
              [key, value], index
            ) in editedData?.principalBusinessActivities
              ? Object.entries(editedData.principalBusinessActivities).filter(
                  ([_, val]) => !Array.isArray(val)
                )
              : []"
            :key="key"
            :class="{ separation: index !== 0 }"
          >
            <div class="name-parent">
              <div class="name">{{ formatLabel(key) }}</div>
              <div class="value">{{ value || "-" }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Table -->
      <div
        v-for="(
          [key, arrayData], index
        ) in editedData?.principalBusinessActivities &&
        typeof editedData.principalBusinessActivities === 'object' &&
        Object.entries(editedData.principalBusinessActivities).filter(
          ([_, value]) => Array.isArray(value) && value.length
        )"
        :key="index + key"
        class="name-history-table"
      >
        <table class="table-container" style="min-width: 950px">
          <thead class="table-headers">
            <tr class="table-header-row">
              <th
                class="table-header-cell"
                v-for="(header, hIndex) in Object.keys(arrayData[0] || {})"
                :key="hIndex"
              >
                {{ formatLabel(header) }}
              </th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr
              class="table-header-row"
              v-for="(entry, rowIndex) in arrayData"
              :key="rowIndex"
              :style="{
                'border-bottom':
                  rowIndex !== arrayData.length - 1
                    ? '1px solid #e0f5ff'
                    : 'none',
              }"
            >
              <td
                v-for="(field, fieldKey) in entry"
                :key="fieldKey"
                class="table-header-cell"
              >
                {{ field || "-" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Name History Section -->
    <div
      class="profile-grid profile-grid-table"
      v-show="
        editedData?.otherNames &&
        Object.values(editedData.otherNames).some((arr) => arr?.length > 0)
      "
    >
      <div
        v-for="(entries, key) in editedData?.otherNames"
        :key="key"
        class="name-history-table"
      >
        <div class="frame-group" v-if="entries?.length">
          <div class="key-info-wrapper">
            <div
              style="display: contents"
              v-html="CompanyIcons?.nameHistory"
            ></div>
            <div class="heading-3">{{ formatLabel(key) }}</div>
          </div>
        </div>
        <!-- Table -->
        <div class="frame-group" v-if="entries?.length">
          <table class="table-container" style="min-width: 950px">
            <thead class="table-headers">
              <tr class="table-header-row">
                <th
                  class="table-header-cell"
                  v-for="(header, index) in Object.keys(entries[0] || {})"
                  :key="index"
                >
                  {{ formatLabel(header) }}
                </th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr
                class="table-header-row"
                v-for="(entry, index) in entries"
                :key="index"
              >
                <td
                  v-for="(value, field) in entry"
                  :key="field"
                  class="table-header-cell"
                >
                  {{ value || "-" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global google */
import TabTitle from "@/components/CompanyDashboard/tabs/TabTitle.vue";
import CompanyInfoIcons from "@/assets/DigitalProduct/CompanyInfoIcons.js";
import LockAnimation from "@/assets/Animation/LockAnimation.vue";

export default {
  name: "COMPANY",
  components: {
    TabTitle,
    LockAnimation
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    title: {
      type: String,
      required: true,
    },
    lastUpdated: {
      type: String,
      default: "-",
    },
    lockedTabs: { type: Object, required: true },
  },
  data() {
    return {
      map: null,
      isEditable: false,
      editedData: { ...this.data },
      localEfilingStatus: { ...this.data.efiling_status },
      isLocked: false
    };
  },
  watch: {
    data: {
      // deep: true,
      handler(newData) {
        if (newData.contactInformation.addresses) {
          this.initMap();
        }
        this.editedData = { ...newData };
        this.isEditable = false;
      },
    },
  },
  computed: {
    CompanyIcons() {
      return CompanyInfoIcons;
    },
  },
  mounted() {
    // this.initMap();
    if (this.lockedTabs?.length && this.lockedTabs?.includes('COMPANY')) {
      this.isLocked = true
    }
  },
  methods: {
    chunkedData(data) {
      let chunks = [];
      if (data) {
        const entries = Object.entries(data);
        for (let i = 0; i < entries.length; i += 2) {
          chunks.push(Object.fromEntries(entries.slice(i, i + 2)));
        }
      }
      return chunks;
    },

    chunkedObjectsData(data) {
      let chunks = [];
      if (data) {
        const entries = Object.entries(data);
        chunks = entries.map(([key, value]) => ({ [key]: value }));
      }
      return chunks;
    },
    toggleIsEditable() {
      this.isEditable = !this.isEditable;
      if (this.isEditable) {
        this.editedData = { ...this.data };
      }
    },
    emitUpdate(section) {
      const updatedSection = { [section]: this.editedData[section] };
      this.$emit(
        "update:data",
        updatedSection,
        this.data?.recordIds,
        this.isEditable
      );
    },
    capitalize(text) {
      if (!text) return "";
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    getLinkHref(key, value) {
      return key === "email"
        ? `mailto:${value}`
        : value.startsWith("http")
        ? value
        : `https://${value}`;
    },
    initMap() {
      if (typeof google !== "undefined" && google.maps && !this.isLocked) {
        const addresses = this.data?.contactInformation?.addresses || [];
        let selectedAddress =
          addresses.find((addr) => addr?.addressType === "Registered Address")
            ?.address ||
          addresses.find((addr) => addr?.addressType === "Business Address")
            ?.address ||
          addresses[0]?.address ||
          "Other Address";

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: selectedAddress }, (results, status) => {
          if (status === "OK") {
            const location = results[0].geometry.location;
            this.map = new google.maps.Map(this.$refs.mapContainer, {
              center: location,
              zoom: 15,
              disableDefaultUI: true,
              draggable: true,
              zoomControl: false,
              streetViewControl: false,
              fullscreenControl: true,
              mapTypeControl: false,
            });

            new google.maps.Marker({
              position: location,
              map: this.map,
              title: "Business Location",
            });
          } else {
            console.error("Geocode was not successful: " + status);
          }
        });
      } else {
        console.error("Google Maps API is not loaded");
      }
    },

    flattenObject(obj, prefix = "") {
      let result = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const newKey = prefix
            ? `${prefix} ${this.formatLabel(key)}`
            : this.formatLabel(key);
          if (Array.isArray(obj[key])) {
            result[newKey] = obj[key].join(", ");
          } else if (typeof obj[key] === "object" && obj[key] !== null) {
            Object.assign(result, this.flattenObject(obj[key], newKey));
          } else {
            result[newKey] = obj[key] || "-";
          }
        }
      }
      return result;
    },
    formatLabel(key) {
      return key
        .replace(/_/g, " ")
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    },
    formatValue(value) {
      return Array.isArray(value)
        ? value.join(", ") // Join arrays as a comma-separated string
        : typeof value === "object" && value !== null
        ? JSON.stringify(value) // Convert objects to a readable string
        : value || "Not available"; // Default to "Not available" if empty
    },
  },
};
</script>

<style scoped>
/* ----------------------------------------------- */
.company-profile {
  padding: 20px;
  color: #333;
  display: flex;
  gap: 12px;
  flex-direction: column;
  font-family: system-ui, -apple-system, sans-serif;
}
.lock-wrapper {
  z-index: 1000;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #edfaff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  font-family: Figtree;
  font-size: 14px;
}
.profile-grid {
  display: flex;
  padding: 24px 20px;
  gap: 20px;
  align-self: stretch;
  /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25); */
  width: 100%;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  border: 1.5px solid #e0f5ff;
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  color: #142d4d;
}

.name-history-table {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.profile-grid-table {
  display: flex;
  flex-direction: column;
}

.frame-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.frame-group1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.sub-heading {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  color: #1c1c1c;
  font-family: Figtree;
}

.key-info-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: -webkit-fill-available;
}

.heading-3 {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Figtree;
  color: #142d4d;
  text-align: left;
}

.frame-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px 60px;
  align-items: start;
  justify-content: flex-start;
  font-size: 14px;
  color: #224096;
}

.value a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}

.frame-container1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px 180px;
  align-items: start;
  justify-content: flex-start;
  font-size: 14px;
  color: #224096;
}

.frame-container2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 20px;
  align-items: start;
  justify-content: flex-start;
  font-size: 14px;
  color: #224096;
}

.frame-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  width: -webkit-fill-available;
}

.name-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
}

.name {
  color: #224096;
  font-family: "Figtree", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}

.value {
  color: #1c1c1c;
  font-family: "Figtree", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.separation {
  border-left: 1.5px solid #e0f5ff;
  padding-left: 20px;
  margin: 0px 25px;
}

.horizontal-divider {
  grid-column: span 3;
  height: 1px;
  background-color: #e0f5ff;
  margin: 10px 0;
}

.ul-items {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

.table-headers {
  width: 100%;
  position: relative;
  background-color: #e0f5ff;
  border-bottom: 1px solid rgba(34, 64, 150, 0.2);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #1c1c1c;
  font-family: "Figtree", sans-serif;
}

.table-body {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #e0f5ff;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #1c1c1c;
  font-family: "Figtree", sans-serif;
}

.table-container {
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 12.7px #e0f5ff;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 5px;
  text-align: left;
  font-size: 18px;
  color: #101828;
  font-family: "Figtree", sans-serif;
}

.table-header-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 24px;
  text-align: left;
  font-size: 14px;
  color: #1c1c1c;
  font-family: "Figtree", sans-serif;
}

.table-header-cell {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #101828;
}

/* ----------------------------------------------------- */

.input-field {
  flex: 1;
  width: 100%;
  position: relative;
  border-radius: 6px;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #e2e8f0;
  border-top: 1px solid #e2e8f0;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 12px;
  text-align: left;
  font-size: 14px;
  color: #64748b;
  outline: none;
}

.input-field-textarea {
  flex: 1;
  width: 100% !important;
  position: relative;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 12px;
  text-align: left;
  font-size: 14px;
  color: #64748b;
  outline: none;
  min-height: 100px;
  max-height: 300px;
  overflow: auto;
  white-space: pre-wrap;
  box-shadow: none;
  font-family: "Montserrat-Medium", sans-serif;
}

.table-input {
  border: none;
  width: 100% !important;
  height: 100%;
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  outline: none;
  max-height: 150px;
  color: #2c3e50;
  box-shadow: none;
  font-family: "Montserrat-Medium", sans-serif;
}

.sidebar {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.status-card {
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 16px;
}

.map-container {
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  overflow: hidden;
  min-height: 200px;
  height: 100%;
}

h3 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin: 0 0 8px;
}
</style>
