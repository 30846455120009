<template>
  <div class="footer">
    <div class="frame-35567">
      <div class="frame-7" v-if="!showTab">
        <div class="frame-1">
          <img class="image-2" src="@/assets/logo.webp" alt="Credence due diligence reports and cross border trade"/>
        </div>
      </div>
      <div class="frame-35612">
        <!-- <div class="frame-35563">
          <div class="solutions2">Solutions</div>
          <div class="frame-35564">
            <div class="page-1">Page 1</div>
            <div class="page-1">Page 2</div>
            <div class="page-1">Page 3</div>
            <div class="page-1">Page 4</div>
          </div>
        </div> -->
        <div class="frame-355642">
          <div class="links">Links</div>
          <div class="frame-35564">
            <!-- <div class="blogs2" @click="redirectToBlogs">Blogs</div> -->
            <div class="blogs2" @click="redirectToProducts">Product</div>
            <div class="blogs2" @click="redirectToContactUs">Contact Us</div>
            <div class="blogs2" @click="redirectToAboutUs">About</div>
          </div>
        </div>
      </div>
      <div class="frame-35570">
        <div class="frame-35561">
          <div class="subscribe-to-newsletter">Subscribe</div>
          <div class="here-s-what-we-ve-be2">
            Stay updated with our latest news and offers
          </div>
        </div>
        <div class="frame-35569">
          <div class="frame-35560">
            <input
              class="news-letter-email"
              style="width: auto"
              type="text"
              v-model="email"
              :placeholder="email == null ? 'Enter email address' : `${email}`"
              required
            />
            <div
              class="button-instance"
              style="display: flex; flex-direction: row; gap: 20px"
            >
              <span class="button" v-on:click.prevent="subscribeNewsLetter()"
                >Join</span
              >
            </div>
          </div>
          <div class="here-s-what-we-ve-be3">
            By subscribing, you agree to our Privacy Policy
          </div>
        </div>
      </div>
    </div>
    <div class="frame-35571">
      <div v-if="!show" class="frame-355643">
        <div class="_2023-credence-all-rights-reserved">
          © 2024 Credence. All rights reserved.
        </div>
        <div class="privacy-policy" @click="redirectToPrivacyPolicy">
          Privacy Policy
        </div>
        <div class="privacy-policy" @click="redirectToPrivacyPolicy">Terms of Service</div>
        <!-- <div class="privacy-policy">Cookies Settings</div> -->
      </div>
      <!-- <div class="frame-35572">
        <FacebookNegative class="facebook-negative-instance"></FacebookNegative>
        <LinkedInNegative
          class="linked-in-negative-instance"
        ></LinkedInNegative>
        <InstagramNegative
          class="instagram-negative-instance"
        ></InstagramNegative>
        <TwitterNegative class="twitter-negative-instance"></TwitterNegative>
        <YouTubeNegative class="you-tube-negative-instance"></YouTubeNegative>
      </div> -->
      <div v-if="show" class="frame-355643">
        <div class="privacy-policy" @click="redirectToPrivacyPolicy">
          Privacy Policy
        </div>
        <div class="privacy-policy" @click="redirectToPrivacyPolicy">Terms of Service</div>
        <!-- <div class="privacy-policy">Cookies Settings</div> -->
        <div class="_2023-credence-all-rights-reserved">
          © 2024 Credence. All rights reserved.
        </div>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
// import FacebookNegative from "../FacebookNegative/FacebookNegative.vue";
// import LinkedInNegative from "../LinkedInNegative/LinkedInNegative.vue";
// import InstagramNegative from "../InstagramNegative/InstagramNegative.vue";
// import TwitterNegative from "../TwitterNegative/TwitterNegative.vue";
// import YouTubeNegative from "../YouTubeNegative/YouTubeNegative.vue";
import { NewsLetter } from "@/api/APIs";
// import VueCookies from "vue-cookies";
import Toastify from "@/components/Toastify.vue";

export default {
  name: "Footer-new",
  components: {
    // FacebookNegative,
    // LinkedInNegative,
    // InstagramNegative,
    // TwitterNegative,
    // YouTubeNegative,
    Toastify,
  },
  props: {},
  data() {
    return {
      show: window.innerWidth < 767,
      showTab: window.innerWidth < 1023 && window.innerWidth > 768,
      email: this.$root.userDetails.email,
    };
  },
  mounted() {
    this.handleResize();
    this.handleResizeTab();
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("resize", this.handleResizeTab);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("resize", this.handleResizeTab);
  },
  methods: {
    async subscribeNewsLetter() {
      if (!this.email) {
        return this.$refs.toastContainer.addToast(
          "Please enter the email to subscribe to our Newsletter.",
          { type: "error" }
        );
      }
      try {
        const response = await NewsLetter(this.email);
        if (response && response?.message == "SUCCESS") {
          return this.$refs.toastContainer.addToast(
            "Thank you for subscribing to our News and Offers! 🎉",
            { type: "success" }
          );
        } else {
          return this.$refs.toastContainer.addToast(
            "You have already subscribed to our Newsletter. Stay tuned for exciting updates! 👍🏻",
            { type: "info" }
          );
        }
      } catch (error) {
        this.emailError = "Something went wrong! Please try again.";
        console.error(error);
      }
    },
    handleResize() {
      this.show = window.innerWidth < 767;
    },
    handleResizeTab() {
      this.showTab = window.innerWidth < 1023 && window.innerWidth > 768;
    },
    redirectToPrivacyPolicy() {
      this.$router.push("/privacypolicy");
    },
    redirectToContactUs() {
      this.$router.push("/contact");
    },
    redirectToAboutUs() {
      this.$router.push("/aboutus");
    },
    redirectToBlogs() {
      this.$router.push("/blogs");
    },
    redirectToProducts() {
      this.$router.push("/product");
    },
  },
};
</script>
<style scoped>
.footer,
.footer * {
  box-sizing: border-box;
}
.footer {
  background: #ffffff;
  border-style: solid;
  border-color: #82d7db;
  border-width: 1px 0px 0px 0px;
  padding: 40px 7.5% 20px 7.5%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.news-letter-email {
  padding: 16.5px;
  border: 1px solid rgba(87, 87, 87, 0.5);
  border-radius: 13px;
  height: auto !important;
  box-shadow: none;
  margin: 0;
  width: 100% !important;
  outline: none;
}
.frame-35567 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.frame-7 {
  display: flex;
  flex-direction: row;
  gap: 36px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-1 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.image-2 {
  flex-shrink: 0;
  width: 173px;
  height: 40px;
  position: relative;
  object-fit: cover;
}
.frame-35612 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-35563 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  width: 140px;
  position: relative;
}
.frame-35564 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.page-1,
.blogs2 {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  cursor: pointer;
}
.frame-355642 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  width: 140px;
  position: relative;
}
.links {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-ExtraBold", sans-serif;
  font-size: 18px;
  font-weight: 800;
  position: relative;
}
.subscribe-to-newsletter {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}
.frame-35569 {
  display: flex;
  flex-direction: column;
  gap: 9px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-35560 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  width: 100%;
}
.frame-35559 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: #11365a;
  border-width: 1px;
  padding: 15px 24px 15px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 343px;
  min-width: 150px;
  position: relative;
  box-shadow: 0px 8px 14px 0px rgba(0, 0, 0, 0.1);
}
.enter-email-address {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}
.here-s-what-we-ve-be2 {
  color: #3e4855;
  text-align: left;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
}
.frame-35571 {
  border-style: solid;
  border-color: #3e4855;
  border-width: 1px 0px 0px 0px;
  padding: 40px 0px 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.frame-355643 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 55%;
  position: relative;
}
._2023-credence-all-rights-reserved {
  color: #3e4855;
  text-align: left;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  line-height: 180%;
  font-weight: 500;
  position: relative;
}
.privacy-policy {
  color: #11365a;
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  cursor: pointer;
}
.frame-35572 {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.facebook-negative-instance {
  flex-shrink: 0 !important;
  width: 16px !important;
  height: 16px !important;
}
.linked-in-negative-instance {
  flex-shrink: 0 !important;
  width: 16px !important;
  height: 16px !important;
}
.instagram-negative-instance {
  flex-shrink: 0 !important;
  width: 16px !important;
  height: 16px !important;
}
.twitter-negative-instance {
  flex-shrink: 0 !important;
  width: 16px !important;
  height: 16px !important;
}
.you-tube-negative-instance {
  flex-shrink: 0 !important;
  width: 16px !important;
  height: 16px !important;
}
.button {
  vertical-align: super;
  border-radius: 13px;
  background-color: #11365a;
  padding: 15px 24px 15px 24px;
  color: white;
  text-align: center;
  font-family: NunitoSans-SemiBold, sans-serif;
  font-size: 18px;
  font-weight: 600;
  position: relative;
}
.here-s-what-we-ve-be3 {
  color: #3e4855;
  text-align: left;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  line-height: 180%;
  font-weight: 500;
}

/* Media query */
@media only screen and (max-width: 767px) {
  /* Header */
  .frame-35567 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .frame-35563,
  .frame-355642 {
    align-items: center;
  }
  .frame-35564 {
    gap: 10px;
    align-items: center;
  }
  .image-2 {
    height: 45px;
    width: auto;
  }
  .solutions2,
  .links {
    font-size: 14px;
    font-weight: 800;
  }
  .page-1,
  .blogs2 {
    font-size: 14px;
  }
  .subscribe-to-newsletter {
    font-size: 16px;
  text-align: center;
  }
  .here-s-what-we-ve-be2 {
    font-size: 12px;
    text-align: center;
  }
  .frame-35561 {
    gap: 6px;
  }
  .frame-35559 {
    padding: 4% 4%;
    width: 90%;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .enter-email-address {
    font-size: 12px;
  }
  .button {
    /* padding: 28% 50%; */
    font-size: 14px;
  }
  /* .here-s-what-we-ve-be3 {
    font-size: 12px;
    text-align: center;
  } */
  .frame-35571 {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .frame-35572 {
    justify-content: space-between;
    width: 60%;
    gap: 0;
  }
  .frame-355643 {
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 2rem;
  }
  .privacy-policy,
  ._2023-credence-all-rights-reserved, .here-s-what-we-ve-be3{
    font-size: 12px;
    text-align: center;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .frame-35567 {
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
  }
  .image-2 {
    height: 45px;
    width: auto;
    top: -12px;
  }
  .solutions2,
  .links {
    font-size: 16px;
    font-weight: 800;
  }
  .page-1,
  .blogs2 {
    font-size: 16px;
  }
  .subscribe-to-newsletter {
    font-size: 16px;
  }
  .here-s-what-we-ve-be2 {
    font-size: 14px;
  }
  .frame-35561 {
    gap: 6px;
  }
  .frame-35559 {
    padding: 4% 4%;
    width: 90%;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .enter-email-address {
    font-size: 14px;
  }
  .button {
    /* padding: 28% 50%; */
    font-size: 16px;
  }
  .here-s-what-we-ve-be3 {
    font-size: 16px;
  }
  .frame-35571 {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  .frame-35612 {
    gap: 0;
  }
  .frame-35572 {
    justify-content: space-between;
    width: 20%;
    gap: 0;
  }
  .frame-355643 {
    flex-direction: row;
    width: 55%;
  }
  .privacy-policy,
  ._2023-credence-all-rights-reserved {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1024px) {
}
</style>
