const ComplianceInfoIcons = {
  amlKyc: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M2 1.25C1.58579 1.25 1.25 1.58579 1.25 2V6C1.25 6.41421 1.58579 6.75 2 6.75C2.41421 6.75 2.75 6.41421 2.75 6V2.75H6C6.41421 2.75 6.75 2.41421 6.75 2C6.75 1.58579 6.41421 1.25 6 1.25H2ZM17.25 2C17.25 1.58579 17.5858 1.25 18 1.25H22C22.4142 1.25 22.75 1.58579 22.75 2V6C22.75 6.41421 22.4142 6.75 22 6.75C21.5858 6.75 21.25 6.41421 21.25 6V2.75H18C17.5858 2.75 17.25 2.41421 17.25 2ZM9.75 8C9.75 6.75736 10.7574 5.75 12 5.75C13.2426 5.75 14.25 6.75736 14.25 8C14.25 9.24264 13.2426 10.25 12 10.25C10.7574 10.25 9.75 9.24264 9.75 8ZM12 4.25C9.92893 4.25 8.25 5.92893 8.25 8C8.25 9.52578 9.16123 10.8387 10.4692 11.4243C7.4787 12.1178 5.25 14.7986 5.25 18C5.25 18.4142 5.58579 18.75 6 18.75C6.41421 18.75 6.75 18.4142 6.75 18C6.75 15.1005 9.10051 12.75 12 12.75C14.8995 12.75 17.25 15.1005 17.25 18C17.25 18.4142 17.5858 18.75 18 18.75C18.4142 18.75 18.75 18.4142 18.75 18C18.75 14.7986 16.5213 12.1178 13.5308 11.4243C14.8388 10.8387 15.75 9.52578 15.75 8C15.75 5.92893 14.0711 4.25 12 4.25ZM22 17.25C22.4142 17.25 22.75 17.5858 22.75 18V22C22.75 22.4142 22.4142 22.75 22 22.75H18C17.5858 22.75 17.25 22.4142 17.25 22C17.25 21.5858 17.5858 21.25 18 21.25H21.25V18C21.25 17.5858 21.5858 17.25 22 17.25ZM2.75 18C2.75 17.5858 2.41421 17.25 2 17.25C1.58579 17.25 1.25 17.5858 1.25 18V22C1.25 22.4142 1.58579 22.75 2 22.75H6C6.41421 22.75 6.75 22.4142 6.75 22C6.75 21.5858 6.41421 21.25 6 21.25H2.75V18Z" fill="#1C1B1F"/>
</svg>`,
  compliance: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 5C9.25 4.58579 9.58579 4.25 10 4.25H22C22.4142 4.25 22.75 4.58579 22.75 5C22.75 5.41421 22.4142 5.75 22 5.75H10C9.58579 5.75 9.25 5.41421 9.25 5Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 12C9.25 11.5858 9.58579 11.25 10 11.25H22C22.4142 11.25 22.75 11.5858 22.75 12C22.75 12.4142 22.4142 12.75 22 12.75H10C9.58579 12.75 9.25 12.4142 9.25 12Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 19C9.25 18.5858 9.58579 18.25 10 18.25H22C22.4142 18.25 22.75 18.5858 22.75 19C22.75 19.4142 22.4142 19.75 22 19.75H10C9.58579 19.75 9.25 19.4142 9.25 19Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4 10.75C3.30964 10.75 2.75 11.3096 2.75 12C2.75 12.6904 3.30964 13.25 4 13.25C4.69036 13.25 5.25 12.6904 5.25 12C5.25 11.3096 4.69036 10.75 4 10.75ZM1.25 12C1.25 10.4812 2.48122 9.25 4 9.25C5.51878 9.25 6.75 10.4812 6.75 12C6.75 13.5188 5.51878 14.75 4 14.75C2.48122 14.75 1.25 13.5188 1.25 12Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4 17.75C3.30964 17.75 2.75 18.3096 2.75 19C2.75 19.6904 3.30964 20.25 4 20.25C4.69036 20.25 5.25 19.6904 5.25 19C5.25 18.3096 4.69036 17.75 4 17.75ZM1.25 19C1.25 17.4812 2.48122 16.25 4 16.25C5.51878 16.25 6.75 17.4812 6.75 19C6.75 20.5188 5.51878 21.75 4 21.75C2.48122 21.75 1.25 20.5188 1.25 19Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.03033 2.96967C7.32322 3.26256 7.32322 3.73744 7.03033 4.03033L4.03033 7.03033C3.73744 7.32322 3.26256 7.32322 2.96967 7.03033L1.46967 5.53033C1.17678 5.23744 1.17678 4.76256 1.46967 4.46967C1.76256 4.17678 2.23744 4.17678 2.53033 4.46967L3.5 5.43934L5.96967 2.96967C6.26256 2.67678 6.73744 2.67678 7.03033 2.96967Z" fill="#1C1B1F"/>
</svg>`,
  cmt: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 4.5C2.25 3.25736 3.25736 2.25 4.5 2.25H19.5C20.7427 2.25 21.75 3.25737 21.75 4.5V19.5C21.75 20.7427 20.7427 21.75 19.5 21.75H4.5C3.25737 21.75 2.25 20.7427 2.25 19.5V4.5ZM4.5 3.75C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08578 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08578 19.9142 3.75 19.5 3.75H4.5Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4685 11.9144C17.792 12.1731 17.8444 12.6451 17.5857 12.9685L13.5857 17.9685C13.3269 18.292 12.855 18.3444 12.5315 18.0857L10.0315 16.0857C9.70806 15.8269 9.65562 15.355 9.91438 15.0315C10.1731 14.7081 10.6451 14.6556 10.9685 14.9144L12.8829 16.4459L16.4144 12.0315C16.6731 11.7081 17.1451 11.6556 17.4685 11.9144Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 7.5C6.25 7.08579 6.58579 6.75 7 6.75H17C17.4142 6.75 17.75 7.08579 17.75 7.5C17.75 7.91421 17.4142 8.25 17 8.25H7C6.58579 8.25 6.25 7.91421 6.25 7.5Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 11.5C6.25 11.0858 6.58579 10.75 7 10.75H11C11.4142 10.75 11.75 11.0858 11.75 11.5C11.75 11.9142 11.4142 12.25 11 12.25H7C6.58579 12.25 6.25 11.9142 6.25 11.5Z" fill="#1C1B1F"/>
</svg>`,
  statutory: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 4C1.25 3.58579 1.58579 3.25 2 3.25H22C22.4142 3.25 22.75 3.58579 22.75 4V19C22.75 19.4142 22.4142 19.75 22 19.75H14.8107L12.5303 22.0303C12.2374 22.3232 11.7626 22.3232 11.4697 22.0303L9.18934 19.75H2C1.58579 19.75 1.25 19.4142 1.25 19V4ZM2.75 4.75V18.25H9.5C9.69891 18.25 9.88968 18.329 10.0303 18.4697L12 20.4393L13.9697 18.4697C14.1103 18.329 14.3011 18.25 14.5 18.25H21.25V4.75H2.75Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 10.75C12.4142 10.75 12.75 11.0858 12.75 11.5V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V11.5C11.25 11.0858 11.5858 10.75 12 10.75Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 7.25C12.4142 7.25 12.75 7.58579 12.75 8V8.5C12.75 8.91421 12.4142 9.25 12 9.25C11.5858 9.25 11.25 8.91421 11.25 8.5V8C11.25 7.58579 11.5858 7.25 12 7.25Z" fill="#1C1B1F"/>
</svg>`,
};

export default ComplianceInfoIcons;
