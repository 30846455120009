<template>
  <div class="summary-info">
    <TabTitle :title="data?.title" :lastUpdated="data?.lastUpdated"
      :completionPercentage="data?.completion_percentage || 0" :toggleIsEditable="toggleIsEditable" />
    <div class="risk-assessment">
      <!-- <div class="chart-card">
        <h2>Risk Assessment</h2>
        <div class="chart-container">
          <Radar :data="chartConfig.data" :options="chartConfig.options" />
        </div>
      </div> -->

      <div class="table-section" v-show="editedData?.credenceData">
        <h2>Credence Categorization</h2>
        <table>
          <thead>
            <tr>
              <th v-for="(header, index) in tableHeaders" :key="index">
                {{ formatHeader(header) }}
              </th>
            </tr>
          </thead>
          <!-- If Editable -->
          <tbody v-if="isEditable">
            <tr v-for="(item, index) in editedData.credenceData" :key="index">
              <td v-for="(value, key) in item" :key="key" class="table-cell date-cell activities">
                <textarea v-model="editedData.credenceData[index][key]" class="table-input"
                  @input="emitUpdate('credenceData')"></textarea>
              </td>
            </tr>
          </tbody>
          <!-- Else -->
          <tbody v-else>
            <tr v-for="(row, index) in data.credenceData" :key="index" :class="{ alternate: index % 2 === 0 }">
              <td v-for="(value, key) in row" :key="key">
                {{ value }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import TabTitle from "@/components/CompanyDashboard/tabs/TabTitle.vue";
// import { Radar } from "vue-chartjs";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default {
  name: "RiskAssessment",
  components: {
    // Radar,
    TabTitle,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    lastUpdated: {
      type: String,
      default: "N/A",
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEditable: false,
      editedData: { ...this.data },
    };
  },
  watch: {
    data: {
      // deep: true,
      handler(newData) {
        this.editedData = { ...newData };
        this.isEditable=false;

      },
    },
  },

  computed: {
    tableHeaders() {
      if (!this.editedData.credenceData || this.editedData.credenceData.length === 0) return [];
      return Object.keys(this.editedData.credenceData[0]);
    },
    chartConfig() {
      return {
        data: {
          labels: [
            "Financial Risk",
            "Operational Risk",
            "Market Risk",
            "Legal Risk",
            "Reputational Risk",
            "Computational Risk",
          ],
          datasets: [
            {
              data: this.data.riskLevels,
              backgroundColor: "rgba(42, 157, 144, 0.2)",
              borderColor: "rgb(42, 157, 144)",
              borderWidth: 2,
              pointBackgroundColor: "rgb(42, 157, 144)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgb(42, 157, 144)",
            },
          ],
        },
        options: {
          scales: {
            r: {
              beginAtZero: true,
              max: 5,
              ticks: {
                stepSize: 1,
                display: false,
              },
              grid: {
                color: "#e2e8f0",
              },
              angleLines: {
                color: "#e2e8f0",
              },
              pointLabels: {
                color: "#64748b",
                font: {
                  family: "Inter",
                  size: 12,
                },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      };
    },
  },
  methods: {
    formatHeader(header) {
      return header.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
    },
    toggleIsEditable() {
      this.isEditable = !this.isEditable;
      if (this.isEditable) {
        this.editedData = { ...this.data };
      }
    },
    emitUpdate(section) {
      const updatedSection = { [section]: this.editedData[section] };
      this.$emit("update:data", updatedSection,this.data?.recordIds, this.isEditable);
    },
  }
};
</script>

<style scoped>
.table-input {
  border: none;
  width: 100% !important;
  height: 100%;
  box-shadow: none;
  margin: 0px;
  padding: 7px 0px 0px 7px;
  outline: none;
  max-height: 150px;
  color: #2c3e50;
  box-shadow: none;
  font-family: "Montserrat-Medium", sans-serif;
}

.summary-info {
  background-color: #fff;
  /* border: 1px solid #e2e8f0; */
  border-radius: 8px;
  margin-top: 0;
  text-align: left;
}

.risk-assessment {
  padding: 24px;
  max-width: 1400px;
  margin: 0 auto;
}

.chart-card {
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 32px;
}

.chart-card h2 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 32px;
  color: #01012e;
}

.chart-container {
  max-width: 450px;
  margin: 0 auto;
}

.table-section h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #01012e;
}

table {
  width: 100%;
  border-collapse: collapse;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

th {
  text-align: left;
  padding: 12px 24px;
  color: #01012e;
  font-weight: 600;
  border-bottom: 1px solid #e2e8f0;
  background: #f1f5f9;
  font-size: 14px;
}

td {
  padding: 12px 24px;
  color: #64748b;
  border-bottom: 1px solid #e2e8f0;
  font-size: 14px;
}

tr.even {
  background: #f8fafc;
}

tr:last-child td {
  border-bottom: none;
}

@media (max-width: 768px) {
  .summary-info {
    padding: 16px;
  }

  .risk-assessment {
    padding: 16px;
  }

  th,
  td {
    padding: 12px 16px;
  }
}
</style>
