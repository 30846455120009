<template>
  <div class="info-note">
    <div
      style="display: flex; width: 20px"
      v-html="shareholdingIcons?.note"
    ></div>
    <div class="info-note-content">
      <h3 class="info-note-title" v-if="title?.length">{{ title }}</h3>
      <p class="info-note-text">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
import ShareholdingInfoIcons from "@/assets/DigitalProduct/ShareholdingInfoIcons.js";

export default {
  name: "InfoNote",
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    shareholdingIcons() {
      return ShareholdingInfoIcons;
    },
  },
};
</script>

<style scoped>
.info-note {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  border: 1px solid #909fca;
  border-radius: 8px;
  padding: 12px;
}

.info-note-content {
  flex: 1;
}

.info-note-title {
  color: #1c1c1c;
  font-family: "Figtree";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}

.info-note-text {
  color: #1c1c1c;
  font-family: "Figtree";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}
</style>
