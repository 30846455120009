<template>
  <footer class="company-footer">
    <div class="separation-line"></div>
    <div class="footer-content">
      <span class="footer-text">
        Private & Confidential: This report is provided on an "as is" and "as
        available" basis and without warranties of any kind, either express or
        implied. This report is not intended to be used as the sole basis for
        any decision-making and is based upon data and information sourced from
        third parties and various sources.
      </span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "CompanyFooter",
};
</script>

<style scoped>
.separation-line {
  width: -webkit-fill-available;
  height: 1px;
  background: rgba(0, 0, 0, 0.5);
}
.company-footer {
  width: -webkit-fill-available;
  color: #1c1c1c;
  font-family: "Figtree";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background-color: #eef7ff;
  padding: 0 20px;
}

.footer-content {
  padding: 6px 0;
  text-align: left;
}

.footer-text {
  color: #1c1c1c;
  font-family: "Figtree";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
</style>
