<template>
  <TabTitle :title="tabsData?.title || 'International Trade'" :lastUpdated="tabsData?.lastUpdated || 'N/A'"
    :completionPercentage="tabsData?.completion_percentage || 0" :toggleIsEditable="toggleIsEditable" />
  <!-- Lock Section -->
  <div class="company-profile lock-wrapper" v-if="isLocked">
    <LockAnimation />
  </div>
  <!-- Unlock Section -->
  <div v-else>
    <!-- Nav Bar-->
    <div class="header-tab-container">
      <!-- Country Tabs -->
      <div class="header-section">
        <div class="tab-header-container">
          <button v-for="tab in (this.data?.tabs || [])" :key="tab"
            :class="['tab-buttons', { 'is-active': selectedTab === tab }]" @click="selectedTab = tab">
            {{ tab?.toLowerCase() }}
          </button>
        </div>
      </div>
      <!-- Import-Export Tabs not included for now-->
      <!-- <div class="header-section">
        <div class="tab-header-container">
          <div class="right-buttons-container">
            <div class="button-wrapper1">
              <div style="display: contents" v-html="TradeIcons?.importButtonIcon"></div>
              <button class="button1">Import</button>
            </div>
            <div class="button-wrapper2">
              <div style="display: contents" v-html="TradeIcons?.exportButtonIcon"></div>
              <button class="button2">Export</button>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="company-profile">

      <!-- Trade Summary -->
      <div class="profile-grid" v-show="editedData?.tradeSummary">
        <div class="frame-group">
          <div class="key-info-wrapper">
            <div style="display: contents" v-html="TradeIcons?.globeIcon"></div>
            <div class="heading-3">Trade Summary</div>
          </div>
          <div class="frame-container">
            <template v-for="(chunk, index) in editedData?.tradeSummary &&
              Object.values(editedData?.tradeSummary)" :key="index">
              <div class="section-content">
                <div class="section-icon" v-html="TradeIcons?.commentIcon"></div>
                <p class="section-value">{{ chunk || "-" }}</p>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- Small Card -->
      <div class="small-cards-parent">
        <div class="small-cards" v-show="editedData?.metrices?.numberOfTransactions">
          <div style="display: contents" v-html="TradeIcons?.colouredEarthIcon"></div>
          <div class="label-group">
            <div class="label">Number of Shipments</div>
            <b class="num-value">{{
              editedData?.metrices?.numberOfTransactions || 0
              }}</b>
          </div>
        </div>
        <div class="small-cards" v-show="editedData?.metrices?.numberOfCountriesImporting">
          <div style="display: contents" v-html="TradeIcons?.flagIcon"></div>
          <div class="text">
            <div class="label-group">
              <div class="label">No. of Countries Importing</div>
              <b class="num-value">24</b>
            </div>
          </div>
        </div>
        <div class="small-cards" v-show="editedData?.metrices?.numberOfCompaniesImporting">
          <div style="display: contents" v-html="TradeIcons?.homeIcon"></div>
          <div class="label-group">
            <div class="label">No. of Companies Importing</div>
            <b class="num-value">83</b>
          </div>
        </div>

        <div class="small-cards" v-show="editedData?.metrices?.totalHSCode">
          <div style="display: contents" v-html="TradeIcons?.chartsIcon"></div>
          <div class="label-group">
            <div class="label">Total HS Codes</div>
            <b class="num-value">{{ editedData?.metrices?.totalHSCode || 0 }}</b>
          </div>
        </div>
      </div>
      <!-- World Map & Import Tx Section -->
      <div class="profile-grid" v-if="tabsData?.mapData?.length && selectedTab == 'GLOBAL'">
        <div class="frame-group">
          <div class="key-info-wrapper">
            <div style="display: contents" v-html="TradeIcons?.globeTxIcon"></div>
            <div class="heading-3">Global Trading Areas</div>
          </div>
          <div class="frame-map-container">
            <div class="grid-section">
              <!-- Map -->
              <div class="map-container">
                <WorldMap property1="mouse-enter" version="3" style="flex-shrink: 0; width: 100%"
                  :mapData="editedData?.mapData"></WorldMap>
              </div>
              <!-- Right Section -->
              <div class="map-right-section">
                <div class="key-info-wrapper">
                  <div style="display: contents" v-html="TradeIcons?.booksIcon"></div>
                  <div class="heading-3" style="font-size: 16px">
                    Transactions By Country
                  </div>
                </div>
                <div class="internal-conatiner">
                  <div class="internal-scroll">
                    <div class="text-parent" v-for="(item, index) in tabsData?.mapData" :key="index">
                      <span :class="`flag-icon flag-icon-${item?.countryCode?.toLowerCase()}`"></span>
                      <div class="text-group">
                        <div class="text1">{{ item?.country }}</div>
                        <div class="text-container">
                          <div class="text1">{{ item?.value }}</div>
                          <div class="icon-parent">
                            <div style="display: contents" v-html="TradeIcons?.positiveIndicator" v-if="
                              item?.indicator == 'positive' &&
                              item?.percentageChange !== '0%'
                            "></div>
                            <div style="display: contents" v-html="TradeIcons?.negativeIndicator" v-else-if="
                              item?.indicator == 'negative' &&
                              item?.percentageChange !== '0%'
                            "></div>
                            <div class="text1" :style="{
                              color:
                                item?.indicator === 'positive' &&
                                  item?.percentageChange !== '0%'
                                  ? '#27be69'
                                  : item?.indicator === 'negative' &&
                                    item?.percentageChange !== '0%'
                                    ? '#d72626'
                                    : null,
                            }">
                              {{ item?.percentageChange }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Not Included for now -->
                <!-- <button class="check-import-button">Check the Import History
                <div style="display: contents" v-html="TradeIcons?.sideArrow"></div>
              </button> -->
              </div>
            </div>
          </div>
          <Note title="" :text="tabsData?.mapDataNote" v-if="
            tabsData?.mapDataNote && tabsData?.mapDataNote !== 'Not available'
          " />
        </div>
      </div>
      <!-- Products Imported -->
      <div class="profile-grid profile-grid-table" v-show="editedData?.productsTraded?.length">
        <div class="name-history-table">
          <div class="frame-group">
            <div class="key-info-wrapper">
              <div style="display: contents" v-html="TradeIcons?.productsTradedTag"></div>
              <div class="heading-3">Products Traded</div>
            </div>
          </div>
          <!-- Table -->
          <div class="frame-group">
            <table class="table-container">
              <thead class="table-headers">
                <tr class="table-header-row">
                  <th v-for="(key, index) in Object.keys(
                    tabsData?.productsTraded?.[0] || {}
                  )" :key="index" class="table-header-cell">
                    {{ formatLabel(key) }}
                  </th>
                </tr>
              </thead>
              <tbody class="table-body">
                <tr v-for="(item, index) in tabsData?.productsTraded" :key="index" class="table-header-row">
                  <td v-for="(value, key) in item" :key="key" class="table-header-cell">
                    {{ value || "-" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Note title="" :text="tabsData?.productsTradedNote" v-if="
            tabsData?.productsTradedNote &&
            tabsData?.productsTradedNote !== 'Not available'
          " />
        </div>
      </div>
      <!-- Top Traded Partners -->
      <div class="profile-grid profile-grid-table" v-show="editedData?.topTradePartners?.length">
        <div class="name-history-table">
          <div class="frame-group">
            <div class="key-info-wrapper">
              <div style="display: contents" v-html="TradeIcons?.partnersIcon"></div>
              <div class="heading-3">Top Traded Partners</div>
            </div>
          </div>
          <!-- Table -->
          <div class="frame-group">
            <table class="table-container">
              <thead class="table-headers">
                <tr class="table-header-row">
                  <th v-for="(key, index) in Object.keys(
                    tabsData?.topTradePartners?.[0] || {}
                  )" :key="index" class="table-header-cell">
                    {{ formatLabel(key) }}
                  </th>
                </tr>
              </thead>
              <tbody class="table-body">
                <tr v-for="(item, index) in tabsData?.topTradePartners" :key="index" class="table-header-row">
                  <td v-for="(value, key) in item" :key="key" class="table-header-cell">
                    <span :class="`flag-icon flag-icon-${item?.countryCode?.toLowerCase()}`"
                      v-if="key === 'country' && key === 'countryCode'"></span>
                    {{ value || "-" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Note title="" :text="tabsData?.topTradedPartnersNote" v-if="
            tabsData?.topTradedPartnersNote &&
            tabsData?.topTradedPartnersNote !== 'Not available'
          " />
        </div>
      </div>
      <!-- Import History Not Included for now-->
      <!-- <div class="profile-grid profile-grid-table" v-show="editedData?.importHistory">
      <div class="name-history-table">
        <div class="frame-group">
          <div class="key-info-wrapper">
            <div style="display: contents" v-html="TradeIcons?.importHistory"></div>
            <div class="heading-3">Import History (Selected Transactions)</div>
          </div>
        </div>
        <div class="frame-group">
          <table class="table-container">
            <thead class="table-headers">
              <tr class="table-header-row">
                <th v-for="(key, index) in Object.keys(
                  data?.importHistory?.[0] || {}
                )" :key="index" class="table-header-cell">
                  {{ formatLabel(key) }}
                </th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr v-for="(item, index) in data?.importHistory" :key="index" class="table-header-row">
                <td v-for="(value, key) in item" :key="key" class="table-header-cell">
                  {{ value || "-" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div> -->
    </div>
  </div>
  <div class="loader-wrapper" v-if="isLoading">
    <LoaderAnimation />
  </div>
  <Toastify ref="toastContainer" />

</template>

<script>
import TabTitle from "@/components/CompanyDashboard/tabs/TabTitle.vue";
import TradeInfoIcons from "@/assets/DigitalProduct/TradeInfoIcons.js";
import WorldMap from "@/components/CompanyDashboard/WorldMap.vue";
import Note from "../Note.vue";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { GetCompanyData, GetCredenceSearchCompanyData } from "@/api/APIs";
import Toastify from "@/components/Toastify.vue";
import LoaderAnimation from "@/assets/Animation/LoaderAnimation.vue";
import LockAnimation from "@/assets/Animation/LockAnimation.vue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "TradeInfo",
  components: {
    TabTitle,
    WorldMap,
    Note,
    Toastify,
    LoaderAnimation,
    LockAnimation
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    lockedTabs: { type: Object, required: true },

  },
  data() {
    return {
      isLoading: false,
      selectedTab: "",
      tabs: ["Global", "Bangladesh"],
      isEditable: false,
      isLocked: false,
      tabsData: {},
      editedData: { ...this.tabsData },
    };
  },
  mounted() {
    if (this.lockedTabs?.length && this.lockedTabs?.includes('TRADE')) {
      this.isLocked = true
    }
  },
  watch: {
    tabsData: {
      // deep: true,
      handler(newData) {
        this.editedData = { ...newData };
        this.isEditable = false;
      },
    },
    data: {
      handler(newTabs) {
        this.selectedTab = newTabs?.tabs[0];
      },
    },
    selectedTab: {
      immediate: true,
      handler() {
        if (this.selectedTab) {
          this.GetCompanyDetails();
        }
      },
    },
  },
  computed: {
    TradeIcons() {
      return TradeInfoIcons;
    },
    chartData() {
      const barGraphData =
        this.tabsData?.totalImportTradeRecordsFound?.dataBarGraph?.tabsData ||
        [];
      const values = barGraphData.map((item) => item.value);
      const allValuesEmpty = values.every((value) => !value);
      if (allValuesEmpty) return null;
      return {
        labels: barGraphData.map((item) => `${item.month}-${item.year}`),
        datasets: [
          {
            label:
              this.tabsData?.totalImportTradeRecordsFound?.dataBarGraph
                ?.title || "Trade Data",
            tabsData: values.map((value) => value || 0),
            backgroundColor: "#01012e",
            barThickness: 40,
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
      };
    },
    companyName() {
      const comp = this.$route.params.company
        ? decodeURIComponent(this.$route.params.company)
        : "";
      return comp;
    },
    countryName() {
      return this.$route.params.country
        ? decodeURIComponent(this.$route.params.country)
        : "";
    },
  },
  methods: {
    async GetCompanyDetails() {
      this.isLoading = true;
      const formattedTab =
        this.selectedTab?.toLowerCase() === "global"
          ? ["TRADE"]
          : [`TRADE_${this.selectedTab.toUpperCase().replace(/\s+/g, "_")}`];
      const payload = {
        companyName: this.companyName,
        modules: formattedTab,
        countryCode: this.countryName,
      };
      const dataSource = sessionStorage.getItem("dataSource");
      try {
        let response;
        if (dataSource === "credenceSearch") {
          response = await GetCredenceSearchCompanyData(payload);
        } else {
          response = await GetCompanyData(payload);
        }
        if (response && response.data && response.data.data) {
          const lowerCaseTab = this.selectedTab?.toLowerCase();
          if (lowerCaseTab === "global") {
            this.tabsData = response.data.data.trade || {};
          } else {
            this.tabsData = response.data.data[`trade_${lowerCaseTab}`] || {};
          }
        } else {
          this.$refs.toastContainer.addToast("", { type: "error" });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      } finally {
        this.isLoading = false;
      }
    },
    toggleIsEditable() {
      this.isEditable = !this.isEditable;
      if (this.isEditable) {
        this.editedData = { ...this.tabsData };
      }
    },
    emitUpdate(section) {
      const updatedSection = { [section]: this.editedData[section] };
      this.$emit(
        "update:data",
        updatedSection,
        this.tabsData?.recordIds,
        this.isEditable
      );
    },
    formatLabel(key) {
      return key
        .replace(/_/g, " ")
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    },
  },
};
</script>

<style scoped>
.loader-wrapper {
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 8%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.lock-wrapper {
  z-index: 1000;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #edfaff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  font-family: Figtree;
  font-size: 14px;
}

.company-profile {
  padding: 20px;
  color: #333;
  display: flex;
  gap: 12px;
  flex-direction: column;
  font-family: system-ui, -apple-system, sans-serif;
}

.header-tab-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Figtree", sans-serif;
  font-size: 14px;
  /* padding-top: 13px; */
  background-color: #e0f5ff;
}

.header-section {
  padding: 0px;
}

.is-active {
  border-bottom: 1px solid #47444e !important;
  border-radius: 0px;
}

.tab-buttons {
  background-color: #e0f5ff;
  border: none;
  color: #47444e;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0px;
  font-size: 14px;
  font-family: "Figtree", sans-serif;
  text-transform: capitalize;
  padding-bottom: 10px;
}

.tab-header-container {
  align-self: stretch;
  overflow: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  z-index: 0;
  margin: 18px 24px 0px;
}

.button-wrapper1 {
  border-radius: 5px 0px 0px 5px;
  background-color: #224096;
  border: 1px solid #224096;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 0px;
}

.right-buttons-container {
  border-radius: 8px;
  border: 1px solid #224096;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 16px 0px 0px;
  gap: 16px;
}

.button-wrapper2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  color: #224096;
}

.button1 {
  position: relative;
  line-height: 22.4px;
  font-weight: 600;
  border: none;
  background-color: #224096;
  width: 100%;
  letter-spacing: 0.4px;
  height: 100%;
  font-size: 14px;
  font-family: "Figtree", sans-serif;
}

.button2 {
  position: relative;
  line-height: 22.4px;
  font-weight: 600;
  border: none;
  background-color: #e0f5ff;
  color: #224096;
  width: 100%;
  letter-spacing: 0.4px;
  height: 100%;
  font-size: 14px;
  font-family: "Figtree", sans-serif;
}

.profile-grid {
  display: flex;
  padding: 24px 20px;
  gap: 20px;
  align-self: stretch;
  /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25); */
  width: 100%;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  border: 1.5px solid #e0f5ff;
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  color: #142d4d;
}

.frame-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: -webkit-fill-available;
}

.key-info-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: -webkit-fill-available;
}

.heading-3 {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Figtree;
  color: #142d4d;
  text-align: left;
}

.frame-container {
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.section-content {
  margin: 0px;
  border-radius: 10px;
  border: 1px solid #909fca;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.section-icon {
  display: flex !important;
  align-items: center;
  display: contents;
  margin-top: 3px;
}

.section-value {
  color: #1c1c1c;
  font-family: "Figtree";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin: 0px;
}

.small-cards-parent {
  width: 100%;
  position: relative;
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  text-align: left;
  font-size: 14px;
  color: #4a4a4a;
  font-family: Figtree;
}

.small-cards {
  align-self: stretch;
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 13px 17px;
  gap: 18px;
}

.label-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}

.text {
  width: 163px;
  position: relative;
  height: 64px;
}

.num-value {
  position: relative;
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-family: Figtree;
  color: #2b3674;
}

.label {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 24px;
  font-weight: 500;
}

/* Table */
.profile-grid-table {
  display: flex;
  flex-direction: column;
}

.name-history-table {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.table-container {
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 12.7px #e0f5ff;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 5px;
  text-align: left;
  font-size: 18px;
  color: #101828;
  font-family: "Figtree", sans-serif;
}

.table-header-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 24px;
  text-align: left;
  font-size: 14px;
  color: #1c1c1c;
  font-family: "Figtree", sans-serif;
}

.table-header-cell {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #101828;
  padding-right: 35px;
}

.table-headers {
  width: 100%;
  position: relative;
  background-color: #e0f5ff;
  border-bottom: 1px solid rgba(34, 64, 150, 0.2);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #1c1c1c;
  font-family: "Figtree", sans-serif;
}

.table-body {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #e0f5ff;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #1c1c1c;
  font-family: "Figtree", sans-serif;
}

.frame-map-container {
  width: -webkit-fill-available;
}

.grid-section {
  display: grid;
  grid-template-columns: 70% calc(30% - 20px);
  gap: 20px;
  align-items: start;
  justify-content: flex-start;
  font-size: 14px;
  color: #224096;
}

.map-right-section {
  border-radius: 12px;
  border: 1px solid #e0f5ff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #e0f5ff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.map-container {
  /* min-width: 765px;
  min-height: 350px; */
  width: -webkit-fill-available;
  position: relative;
  box-shadow: 0px 0px 12.7px #e0f5ff;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 5px;
  text-align: left;
  font-size: 18px;
  color: #101828;
  font-family: "Figtree", sans-serif;
  background: #ffffff;
  border-radius: 10px;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.internal-conatiner {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  color: #101828;
  font-family: Figtree;
  gap: 10px;
  padding: 5px 18px;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.internal-scroll {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  width: 100%;
}

.text-parent {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: white;
  border-radius: 8px;
}

.text-group {
  display: flex;
  flex-direction: column;
}

.text-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.text1 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.icon {
  width: 24px;
  position: relative;
  height: 24px;
}

button {
  font-weight: 600 !important;
}

.icon-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  font-size: 12px;
  color: #1c1c1c;
}

.check-import-button {
  width: 100%;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #0172f4;
  box-sizing: border-box;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 10px;
  text-align: left;
  font-size: 16px;
  color: #1c1c1c;
  font-family: Poppins;
}

.flag-icon {
  width: 27px;
  height: 17px;
  margin-right: 8px;
}
</style>
