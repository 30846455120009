<template>
  <div class="summary-info">
    <TabTitle :title="data?.title" :lastUpdated="data?.lastUpdated"
      :completionPercentage="data?.completion_percentage || 0" :toggleIsEditable="toggleIsEditable" />

    <div class="legal-history" v-show="data?.legalHistory && Object.keys(data?.legalHistory)">
      <h2 class="legal-history__title">{{ data?.title }}</h2>
      <div class="legal-history__grid">
        <div v-for="(value, key) in data?.legalHistory" :key="key" class="legal-history__row">
          <div class="legal-history__label">
            {{ key }}
          </div>
          <!-- If Editable -->
          <template v-if="isEditable">
            <input class="input-field" v-model="editedData.legalHistory[key]" @input="emitUpdate('legalHistory')"
              placeholder="Enter value" />
          </template>
          <!-- Else -->
          <div class="legal-history__value" v-else>
            {{ value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabTitle from "@/components/CompanyDashboard/tabs/TabTitle.vue";

export default {
  name: "LegalHistory",
  components: {
    TabTitle,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEditable: false,
      editedData: { ...this.data },
    };
  },
  watch: {
    data: {
      // deep: true,
      handler(newData) {
        this.editedData = { ...newData };
        this.isEditable=false;

      },
    },
  },
  methods: {
    toggleIsEditable() {
      this.isEditable = !this.isEditable;
      if (this.isEditable) {
        this.editedData = { ...this.data };
      }
    },
    emitUpdate(section) {
      const updatedSection = { [section]: this.editedData[section] };
      this.$emit("update:data", updatedSection,this.data?.recordIds, this.isEditable);
    },
  }
};
</script>

<style scoped>
.input-field {
  flex: 1;
  width: 100%;
  position: relative;
  border-radius: 6px;
  background-color: #fff;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 12px;
  text-align: left;
  font-size: 14px;
  color: #64748b;
  outline: none;
  border: none;
}

.summary-info {
  background-color: #fff;
  /* border: 1px solid #e2e8f0; */
  border-radius: 8px;
  margin-top: 0;
  text-align: left;
}

.legal-history {
  max-width: 1024px;
  margin: 0 auto;
  padding: 24px;
}

.legal-history__title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
}

.legal-history__grid {
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
}

.legal-history__row {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e2e8f0;
}

@media (min-width: 640px) {
  .legal-history__row {
    flex-direction: row;
  }
}

.legal-history__label {
  background-color: #f1f5f9;
  padding: 16px;
  font-weight: 600;
  color: #020617;
  flex: 1;
  font-size: 14px;
}

.legal-history__value {
  padding: 16px;
  color: #64748b;
  background-color: #ffffff;
  flex: 1;
  font-size: 14px;
}

@media (min-width: 640px) {
  /* .legal-history__label,
  .legal-history__value {
    flex: 0 0 50%;
  } */
}

@media (min-width: 768px) {
  .summary-info {
    padding: 16px;
  }
}
</style>
