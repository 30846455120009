<template>
  <div class="company-tiles">
    <div class="tiles-container">
      <div class="tile" v-for="(tile, index) in tiles" :key="index">
        <div class="tile-header">
          <div class="tile-header-wrapper-1">
            <div class="tile-header-wrapper">
              <h2 class="tile-title">{{ tile.title }}</h2>
              <div class="tooltip-container">
                <!-- svg Icons -->
                <svg
                  @mouseover="
                    showTooltip =
                      true && tile?.title?.toLowerCase() === 'credence score'
                  "
                  @mouseleave="showTooltip = false"
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  style="margin-top: 3px"
                >
                  <g clip-path="url(#clip0_126_2225)">
                    <path
                      d="M6 4V6M6 8H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
                      stroke="#1E1E1E"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_126_2225">
                      <rect width="12" height="12" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <!-- Tooltip -->
                <Dropdown
                  :triggers="[]"
                  :shown="
                    showTooltip &&
                    tile?.title?.toLowerCase() === 'credence score'
                  "
                  :autoHide="true"
                  placement="right"
                  :distance="-10"
                  :class="{
                    'tooltip-dropdown':
                      showTooltip &&
                      tile?.title?.toLowerCase() === 'credence score',
                  }"
                  :popperOptions="{
                    modifiers: [
                      {
                        name: 'arrow',
                        enabled: false,
                      },
                    ],
                  }"
                >
                  <template #popper>
                    <div class="frame-group">
                      <table class="table-container">
                        <thead class="table-headers">
                          <tr class="table-header-row">
                            <th class="table-header-cell" style="width: 50%">
                              Category
                            </th>
                            <th class="table-header-cell">Explanation</th>
                            <th class="table-header-cell">Comments</th>
                          </tr>
                        </thead>
                        <tbody class="table-body">
                          <tr
                            v-for="(item, index) in credenceData"
                            :key="index"
                            class="table-header-row"
                          >
                            <td class="table-header-cell" style="width: 50%">
                              {{ item?.category }}
                            </td>
                            <td class="table-header-cell">
                              <span
                                class="value-color-span"
                                :style="{
                                  backgroundColor: item.color,
                                  color: item?.textColor
                                    ? item?.textColor
                                    : '#fff',
                                }"
                              >
                                {{ item?.explanation || "-" }}</span
                              >
                            </td>
                            <td class="table-header-cell">
                              {{ item?.comments }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>
                </Dropdown>
              </div>
              <!-- -------- -->
            </div>
            <p
              class="tile-value"
              :style="{
                color:
                  tile?.title === 'Credence Score' ||
                  tile?.title === 'Risk Assessment'
                    ? getIconColor(header?.credenceScore)
                    : tile?.title === 'Credence Limit'
                    ? '#224096'
                    : tile?.title === 'Legal Status' &&
                      header?.legalStatus === 'Active'
                    ? '#27BE69'
                    : '#FB0808',
              }"
            >
              {{ tile.value }}
            </p>
          </div>
          <div
            style="
              width: 12%;
              max-width: 50px;
              height: auto;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="41"
              height="40"
              viewBox="0 0 41 40"
              fill="none"
              style="height: auto"
              v-if="tile.title === 'Credence Score'"
            >
              <!-- Background Circle -->
              <circle
                cx="20.5"
                cy="20"
                r="20"
                :fill="getIconColor(header?.credenceScore)"
                fill-opacity="0.2"
              />

              <!-- Grouping Paths and Adjusting Position -->
              <g transform="translate(8.5, 8)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.46967 3.46967C7.76256 3.17678 8.23744 3.17678 8.53033 3.46967L12.5303 7.46967C12.8232 7.76256 12.8232 8.23744 12.5303 8.53033C12.2374 8.82322 11.7626 8.82322 11.4697 8.53033L8 5.06066L3.06066 10L12 18.9393L17.4697 13.4697C17.7626 13.1768 18.2374 13.1768 18.5303 13.4697C18.8232 13.7626 18.8232 14.2374 18.5303 14.5303L12.5303 20.5303C12.2374 20.8232 11.7626 20.8232 11.4697 20.5303L1.46967 10.5303C1.17678 10.2374 1.17678 9.76256 1.46967 9.46967L7.46967 3.46967Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.4697 3.46967C15.7626 3.17678 16.2374 3.17678 16.5303 3.46967L22.5303 9.46967C22.8232 9.76256 22.8232 10.2374 22.5303 10.5303L18.5303 14.5303C18.2374 14.8232 17.7626 14.8232 17.4697 14.5303L14 11.0607L11.5303 13.5303C11.287 13.7737 10.9092 13.8202 10.6141 13.6431L8.12854 12.1518C8.07898 12.1235 8.03195 12.0891 7.98857 12.0486C7.97683 12.0376 7.96547 12.0263 7.95449 12.0147C7.82537 11.878 7.75739 11.7046 7.75057 11.5294C7.74272 11.3277 7.81576 11.1236 7.96967 10.9697L11.4697 7.46967C11.4696 7.46975 11.4698 7.46959 11.4697 7.46967L15.4697 3.46967ZM12.5303 8.53033C12.5301 8.53052 12.5305 8.53014 12.5303 8.53033L9.70956 11.3511L10.8837 12.0556L13.4697 9.46967C13.7626 9.17678 14.2374 9.17678 14.5303 9.46967L18 12.9393L20.9393 10L16 5.06066L12.5303 8.53033Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.9697 15.9697C12.2626 15.6768 12.7374 15.6768 13.0303 15.9697L14.5303 17.4697C14.8232 17.7626 14.8232 18.2374 14.5303 18.5303C14.2374 18.8232 13.7626 18.8232 13.4697 18.5303L11.9697 17.0303C11.6768 16.7374 11.6768 16.2626 11.9697 15.9697Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.9697 13.9697C14.2626 13.6768 14.7374 13.6768 15.0303 13.9697L16.5303 15.4697C16.8232 15.7626 16.8232 16.2374 16.5303 16.5303C16.2374 16.8232 15.7626 16.8232 15.4697 16.5303L13.9697 15.0303C13.6768 14.7374 13.6768 14.2626 13.9697 13.9697Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
              </g>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              style="height: auto"
              v-if="tile.title === 'Risk Assessment'"
            >
              <!-- Background Circle -->
              <circle
                cx="20"
                cy="20"
                r="20"
                :fill="getIconColor(header?.credenceScore)"
                fill-opacity="0.2"
              />

              <!-- Grouping Paths and Adjusting Position -->
              <g
                transform="translate(8, 8)"
                v-if="header?.riskLevel === 'Minimum Risk'"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89136 2.75 2.75 6.89136 2.75 12C2.75 17.1086 6.89136 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89136 17.1086 2.75 12 2.75Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.86201 15.1057C7.99867 14.8846 8.24007 14.75 8.5 14.75H15.5C15.7599 14.75 16.0013 14.8846 16.138 15.1057C16.2746 15.3268 16.2871 15.6029 16.1708 15.8354L15.5 15.5C16.1708 15.8354 16.171 15.8351 16.1708 15.8354L16.1701 15.8368L16.1693 15.8384L16.1674 15.8422L16.1624 15.8518L16.1476 15.8797C16.1356 15.9019 16.1192 15.9312 16.0983 15.9667C16.0565 16.0376 15.9964 16.1335 15.9167 16.246C15.7579 16.4702 15.5178 16.7654 15.1858 17.0606C14.5154 17.6564 13.4696 18.25 12 18.25C10.5304 18.25 9.48457 17.6564 8.81423 17.0606C8.48221 16.7654 8.24211 16.4702 8.08329 16.246C8.0036 16.1335 7.94347 16.0376 7.9017 15.9667C7.88079 15.9312 7.86439 15.9019 7.85237 15.8797L7.83758 15.8518L7.83259 15.8422L7.83069 15.8384L7.82988 15.8368C7.82971 15.8365 7.82918 15.8354 8.5 15.5L7.82918 15.8354C7.71293 15.6029 7.72536 15.3268 7.86201 15.1057ZM10.2206 16.25C10.6565 16.5285 11.2394 16.75 12 16.75C12.7606 16.75 13.3435 16.5285 13.7794 16.25H10.2206Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.30143 9.1025C6.66861 8.51503 7.36692 7.75 8.49993 7.75C9.63295 7.75 10.3313 8.51503 10.6984 9.1025C10.8887 9.4069 11.0185 9.70527 11.1006 9.92416C11.1421 10.0348 11.1726 10.1283 11.1932 10.1962C11.2035 10.2302 11.2114 10.2581 11.2171 10.2787L11.2239 10.3041L11.2261 10.3126L11.2269 10.3157L11.2273 10.317C11.2273 10.3173 11.2275 10.3181 10.4999 10.5L11.2275 10.3181C11.328 10.7199 11.0837 11.1271 10.6818 11.2276C10.2803 11.328 9.87346 11.0842 9.77258 10.6829L9.77296 10.6844L9.7708 10.6764C9.76841 10.6677 9.76413 10.6526 9.75786 10.6319C9.74527 10.5905 9.72494 10.5277 9.69612 10.4508C9.63758 10.2947 9.54869 10.0931 9.42643 9.8975C9.16861 9.48497 8.86692 9.25 8.49993 9.25C8.13295 9.25 7.83126 9.48497 7.57343 9.8975C7.45118 10.0931 7.36228 10.2947 7.30374 10.4508C7.27493 10.5277 7.25459 10.5905 7.24201 10.6319C7.23574 10.6526 7.23146 10.6677 7.22907 10.6764L7.22691 10.6844L7.22731 10.6828C7.12665 11.0844 6.71972 11.328 6.31803 11.2276C5.91618 11.1271 5.67186 10.7199 5.77233 10.3181L6.49993 10.5C5.77233 10.3181 5.77226 10.3184 5.77233 10.3181L5.7726 10.317L5.77293 10.3157L5.77372 10.3126L5.77592 10.3041L5.78276 10.2787C5.78843 10.2581 5.79636 10.2302 5.80669 10.1962C5.8273 10.1283 5.85775 10.0348 5.89925 9.92416C5.98133 9.70527 6.11119 9.4069 6.30143 9.1025ZM7.22691 10.6844C7.22678 10.6849 7.2268 10.6848 7.22691 10.6844V10.6844Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.3014 9.1025C13.6686 8.51503 14.3669 7.75 15.4999 7.75C16.6329 7.75 17.3313 8.51503 17.6984 9.1025C17.8887 9.4069 18.0185 9.70527 18.1006 9.92416C18.1421 10.0348 18.1726 10.1283 18.1932 10.1962C18.2035 10.2302 18.2114 10.2581 18.2171 10.2787L18.2239 10.3041L18.2261 10.3126L18.2269 10.3157L18.2273 10.317C18.2273 10.3173 18.2275 10.3181 17.4999 10.5L18.2275 10.3181C18.328 10.7199 18.0837 11.1271 17.6818 11.2276C17.2803 11.328 16.8735 11.0842 16.7726 10.6829L16.773 10.6844L16.7708 10.6764C16.7684 10.6677 16.7641 10.6526 16.7579 10.6319C16.7453 10.5905 16.7249 10.5277 16.6961 10.4508C16.6376 10.2947 16.5487 10.0931 16.4264 9.8975C16.1686 9.48497 15.8669 9.25 15.4999 9.25C15.1329 9.25 14.8313 9.48497 14.5734 9.8975C14.4512 10.0931 14.3623 10.2947 14.3037 10.4508C14.2749 10.5277 14.2546 10.5905 14.242 10.6319C14.2357 10.6526 14.2315 10.6677 14.2291 10.6764L14.2269 10.6844L14.2273 10.6828C14.1266 11.0844 13.7197 11.328 13.318 11.2276C12.9162 11.1271 12.6719 10.7199 12.7723 10.3181L13.4999 10.5C12.7723 10.3181 12.7723 10.3184 12.7723 10.3181L12.7726 10.317L12.7729 10.3157L12.7737 10.3126L12.7759 10.3041L12.7828 10.2787C12.7884 10.2581 12.7964 10.2302 12.8067 10.1962C12.8273 10.1283 12.8578 10.0348 12.8992 9.92416C12.9813 9.70527 13.1112 9.4069 13.3014 9.1025ZM14.2269 10.6844C14.2268 10.6849 14.2268 10.6848 14.2269 10.6844V10.6844Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
              </g>
              <g
                transform="translate(8, 8)"
                v-if="header?.riskLevel === 'Acceptable Risk'"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89136 2.75 2.75 6.89136 2.75 12C2.75 17.1086 6.89136 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89136 17.1086 2.75 12 2.75Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.86201 15.1057C7.99867 14.8846 8.24007 14.75 8.5 14.75H15.5C15.7599 14.75 16.0013 14.8846 16.138 15.1057C16.2746 15.3268 16.2871 15.6029 16.1708 15.8354L15.5 15.5C16.1708 15.8354 16.171 15.8351 16.1708 15.8354L16.1701 15.8368L16.1693 15.8384L16.1674 15.8422L16.1624 15.8518L16.1476 15.8797C16.1356 15.9019 16.1192 15.9312 16.0983 15.9667C16.0565 16.0376 15.9964 16.1335 15.9167 16.246C15.7579 16.4702 15.5178 16.7654 15.1858 17.0606C14.5154 17.6564 13.4696 18.25 12 18.25C10.5304 18.25 9.48457 17.6564 8.81423 17.0606C8.48221 16.7654 8.24211 16.4702 8.08329 16.246C8.0036 16.1335 7.94347 16.0376 7.9017 15.9667C7.88079 15.9312 7.86439 15.9019 7.85237 15.8797L7.83758 15.8518L7.83259 15.8422L7.83069 15.8384L7.82988 15.8368C7.82971 15.8365 7.82918 15.8354 8.5 15.5L7.82918 15.8354C7.71293 15.6029 7.72536 15.3268 7.86201 15.1057ZM10.2206 16.25C10.6565 16.5285 11.2394 16.75 12 16.75C12.7606 16.75 13.3435 16.5285 13.7794 16.25H10.2206Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5 9.25C15.9142 9.25 16.25 9.58579 16.25 10V10.5C16.25 10.9142 15.9142 11.25 15.5 11.25C15.0858 11.25 14.75 10.9142 14.75 10.5V10C14.75 9.58579 15.0858 9.25 15.5 9.25Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.5 9.25C8.91421 9.25 9.25 9.58579 9.25 10V10.5C9.25 10.9142 8.91421 11.25 8.5 11.25C8.08579 11.25 7.75 10.9142 7.75 10.5V10C7.75 9.58579 8.08579 9.25 8.5 9.25Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
              </g>
              <g
                transform="translate(8, 8)"
                v-if="header?.riskLevel === 'Medium Risk'"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89136 2.75 2.75 6.89136 2.75 12C2.75 17.1086 6.89136 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89136 17.1086 2.75 12 2.75Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5 8.25C15.9142 8.25 16.25 8.58579 16.25 9V9.5C16.25 9.91421 15.9142 10.25 15.5 10.25C15.0858 10.25 14.75 9.91421 14.75 9.5V9C14.75 8.58579 15.0858 8.25 15.5 8.25Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.5 8.25C8.91421 8.25 9.25 8.58579 9.25 9V9.5C9.25 9.91421 8.91421 10.25 8.5 10.25C8.08579 10.25 7.75 9.91421 7.75 9.5V9C7.75 8.58579 8.08579 8.25 8.5 8.25Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.16842 15.16C8.98175 14.7929 8.53345 14.6447 8.16451 14.8292C7.79403 15.0144 7.64386 15.4649 7.8291 15.8354L8.49992 15.5C7.8291 15.8354 7.82893 15.8351 7.8291 15.8354L7.8298 15.8368L7.83061 15.8384L7.83251 15.8422L7.8375 15.8518L7.85229 15.8797C7.86431 15.9019 7.88071 15.9312 7.90162 15.9667C7.94339 16.0376 8.00352 16.1335 8.08321 16.246C8.24203 16.4702 8.48213 16.7654 8.81415 17.0606C9.48449 17.6564 10.5303 18.25 11.9999 18.25C13.4695 18.25 14.5153 17.6564 15.1857 17.0606C15.5177 16.7654 15.7578 16.4702 15.9166 16.246C15.9963 16.1335 16.0564 16.0376 16.0982 15.9667C16.1191 15.9312 16.1355 15.9019 16.1475 15.8797L16.1623 15.8518L16.1673 15.8422L16.1692 15.8384L16.17 15.8368C16.1702 15.8365 16.1707 15.8354 15.4999 15.5L16.1707 15.8354C16.356 15.4649 16.2058 15.0144 15.8353 14.8292C15.4664 14.6447 15.0181 14.7929 14.8314 15.16L14.8286 15.1653C14.8248 15.1724 14.8172 15.186 14.8059 15.2052C14.7832 15.2437 14.7457 15.304 14.6926 15.379C14.5858 15.5298 14.4196 15.7346 14.1891 15.9395C13.7345 16.3436 13.0303 16.75 11.9999 16.75C10.9695 16.75 10.2653 16.3436 9.81069 15.9395C9.58021 15.7346 9.41406 15.5298 9.30725 15.379C9.25414 15.304 9.21661 15.2437 9.19393 15.2052C9.18261 15.186 9.17508 15.1724 9.17123 15.1653L9.16842 15.16ZM14.8314 15.16C14.8314 15.16 14.8314 15.16 14.8314 15.16V15.16Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
              </g>
              <g
                transform="translate(8, 8)"
                v-if="header?.riskLevel === 'High Risk'"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89136 2.75 2.75 6.89136 2.75 12C2.75 17.1086 6.89136 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89136 17.1086 2.75 12 2.75Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5 8.25C15.9142 8.25 16.25 8.58579 16.25 9V9.5C16.25 9.91421 15.9142 10.25 15.5 10.25C15.0858 10.25 14.75 9.91421 14.75 9.5V9C14.75 8.58579 15.0858 8.25 15.5 8.25Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.5 8.25C8.91421 8.25 9.25 8.58579 9.25 9V9.5C9.25 9.91421 8.91421 10.25 8.5 10.25C8.08579 10.25 7.75 9.91421 7.75 9.5V9C7.75 8.58579 8.08579 8.25 8.5 8.25Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.81415 13.9394C9.48449 13.3435 10.5303 12.7499 11.9999 12.7499C13.4695 12.7499 14.5153 13.3435 15.1857 13.9394C15.5177 14.2345 15.7578 14.5297 15.9166 14.7539C15.9963 14.8664 16.0564 14.9623 16.0982 15.0332C16.1191 15.0687 16.1355 15.0981 16.1475 15.1203L16.1623 15.1481L16.1673 15.1578L16.1692 15.1615L16.17 15.1631C16.1702 15.1635 16.1707 15.1645 15.5136 15.4931L16.1707 15.1645C16.356 15.535 16.2058 15.9855 15.8353 16.1708C15.4664 16.3552 15.0181 16.2071 14.8314 15.84L14.8286 15.8347C14.8248 15.8276 14.8172 15.814 14.8059 15.7948C14.7832 15.7563 14.7457 15.6959 14.6926 15.621C14.5858 15.4702 14.4196 15.2654 14.1891 15.0605C13.7345 14.6564 13.0303 14.2499 11.9999 14.2499C10.9695 14.2499 10.2653 14.6564 9.81069 15.0605C9.58021 15.2654 9.41406 15.4702 9.30725 15.621C9.25414 15.6959 9.21661 15.7563 9.19393 15.7948C9.18261 15.814 9.17508 15.8276 9.17123 15.8347L9.16841 15.84C8.98175 16.2071 8.53345 16.3552 8.16451 16.1708C7.79403 15.9855 7.64386 15.535 7.8291 15.1645L8.49992 15.4999C7.8291 15.1645 7.82893 15.1649 7.8291 15.1645L7.8298 15.1631L7.83061 15.1615L7.83251 15.1578L7.8375 15.1481L7.85229 15.1203C7.86431 15.0981 7.88071 15.0687 7.90162 15.0332C7.94339 14.9623 8.00352 14.8664 8.08321 14.7539C8.24203 14.5297 8.48213 14.2345 8.81415 13.9394ZM14.8314 15.84C14.8314 15.84 14.8314 15.84 14.8314 15.84V15.84Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
              </g>
              <g
                transform="translate(8, 8)"
                v-if="header?.riskLevel === 'Very High Risk'"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89136 2.75 2.75 6.89136 2.75 12C2.75 17.1086 6.89136 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89136 17.1086 2.75 12 2.75Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5 8.25C15.9142 8.25 16.25 8.58579 16.25 9V9.5C16.25 9.91421 15.9142 10.25 15.5 10.25C15.0858 10.25 14.75 9.91421 14.75 9.5V9C14.75 8.58579 15.0858 8.25 15.5 8.25Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.5 8.25C8.91421 8.25 9.25 8.58579 9.25 9V9.5C9.25 9.91421 8.91421 10.25 8.5 10.25C8.08579 10.25 7.75 9.91421 7.75 9.5V9C7.75 8.58579 8.08579 8.25 8.5 8.25Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 12.75C10.7574 12.75 9.75 13.7574 9.75 15C9.75 16.2426 10.7574 17.25 12 17.25C13.2426 17.25 14.25 16.2426 14.25 15C14.25 13.7574 13.2426 12.75 12 12.75ZM8.25 15C8.25 12.9289 9.92893 11.25 12 11.25C14.0711 11.25 15.75 12.9289 15.75 15C15.75 17.0711 14.0711 18.75 12 18.75C9.92893 18.75 8.25 17.0711 8.25 15Z"
                  :fill="getIconColor(header?.credenceScore)"
                />
              </g>
              <g
                transform="translate(8, 8)"
                v-if="header?.riskLevel === 'Not Scored'"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89136 2.75 2.75 6.89136 2.75 12C2.75 17.1086 6.89136 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89136 17.1086 2.75 12 2.75Z"
                  fill="#6C6A71"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.25 9.5C7.25 9.08579 7.58579 8.75 8 8.75H10C10.4142 8.75 10.75 9.08579 10.75 9.5C10.75 9.91421 10.4142 10.25 10 10.25H8C7.58579 10.25 7.25 9.91421 7.25 9.5Z"
                  fill="#6C6A71"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.25 9.5C13.25 9.08579 13.5858 8.75 14 8.75H16C16.4142 8.75 16.75 9.08579 16.75 9.5C16.75 9.91421 16.4142 10.25 16 10.25H14C13.5858 10.25 13.25 9.91421 13.25 9.5Z"
                  fill="#6C6A71"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.25 15.5C7.25 15.0858 7.58579 14.75 8 14.75H16C16.4142 14.75 16.75 15.0858 16.75 15.5C16.75 15.9142 16.4142 16.25 16 16.25H8C7.58579 16.25 7.25 15.9142 7.25 15.5Z"
                  fill="#6C6A71"
                />
              </g>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="41"
              height="40"
              viewBox="0 0 41 40"
              fill="none"
              style="height: auto"
              v-if="tile.title === 'Credence Limit'"
            >
              <circle cx="20.5" cy="20" r="20" fill="#EBF0FF" />
              <mask
                id="mask0_126_2263"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="9"
                y="8"
                width="23"
                height="24"
              >
                <rect
                  x="9.07141"
                  y="8.57129"
                  width="22.8571"
                  height="22.8571"
                  fill="#D9D9D9"
                />
              </mask>
              <g mask="url(#mask0_126_2263)">
                <path
                  d="M12.9518 28.1429C12.415 28.1429 11.9556 27.9563 11.5733 27.5833C11.1911 27.2103 11 26.7619 11 26.2381V12.9048C11 12.381 11.1911 11.9325 11.5733 11.5595C11.9556 11.1865 12.415 11 12.9518 11H26.6143C27.1511 11 27.6106 11.1865 27.9928 11.5595C28.375 11.9325 28.5661 12.381 28.5661 12.9048V15.2857H26.6143V12.9048H12.9518V26.2381H26.6143V23.8571H28.5661V26.2381C28.5661 26.7619 28.375 27.2103 27.9928 27.5833C27.6106 27.9563 27.1511 28.1429 26.6143 28.1429H12.9518ZM20.759 24.3333C20.2222 24.3333 19.7627 24.1468 19.3805 23.7738C18.9983 23.4008 18.8072 22.9524 18.8072 22.4286V16.7143C18.8072 16.1905 18.9983 15.7421 19.3805 15.369C19.7627 14.996 20.2222 14.8095 20.759 14.8095H27.5902C28.127 14.8095 28.5865 14.996 28.9687 15.369C29.3509 15.7421 29.542 16.1905 29.542 16.7143V22.4286C29.542 22.9524 29.3509 23.4008 28.9687 23.7738C28.5865 24.1468 28.127 24.3333 27.5902 24.3333H20.759ZM27.5902 22.4286V16.7143H20.759V22.4286H27.5902ZM23.6867 21C24.0933 21 24.4389 20.8611 24.7235 20.5833C25.0082 20.3056 25.1505 19.9683 25.1505 19.5714C25.1505 19.1746 25.0082 18.8373 24.7235 18.5595C24.4389 18.2817 24.0933 18.1429 23.6867 18.1429C23.28 18.1429 22.9344 18.2817 22.6498 18.5595C22.3651 18.8373 22.2228 19.1746 22.2228 19.5714C22.2228 19.9683 22.3651 20.3056 22.6498 20.5833C22.9344 20.8611 23.28 21 23.6867 21Z"
                  fill="#224096"
                />
              </g>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              style="height: auto;"
              v-if="tile.title === 'Legal Status'"
            >
              <circle
                cx="20"
                cy="20"
                r="20"
                :fill="header?.legalStatus === 'Active' ? '#27BE69' : '#FB0808'"
                fill-opacity="0.2"
              />
              <path
                d="M26 15V16C25.9988 18.1563 25.3005 20.2545 24.0093 21.9817C22.7182 23.7088 20.9033 24.9723 18.8354 25.5838C16.7674 26.1952 14.5573 26.1218 12.5345 25.3744C10.5117 24.6271 8.78465 23.246 7.61096 21.4369C6.43727 19.6279 5.87979 17.4879 6.02168 15.3362C6.16356 13.1844 6.99721 11.1362 8.39828 9.49694C9.79935 7.85768 11.6928 6.71525 13.7962 6.24001C15.8996 5.76477 18.1003 5.9822 20.07 6.85986M26 8L16 18L13 15"
                :stroke="
                  header?.legalStatus === 'Active' ? '#27BE69' : '#FB0808'
                "
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                transform="translate(4,4)"
                v-if="header?.legalStatus === 'Active'"
              />
              <g
                transform="translate(8, 8)"
                v-if="header?.legalStatus !== 'Active'"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.25 4C1.25 3.58579 1.58579 3.25 2 3.25H22C22.4142 3.25 22.75 3.58579 22.75 4V19C22.75 19.4142 22.4142 19.75 22 19.75H14.8107L12.5303 22.0303C12.2374 22.3232 11.7626 22.3232 11.4697 22.0303L9.18934 19.75H2C1.58579 19.75 1.25 19.4142 1.25 19V4ZM2.75 4.75V18.25H9.5C9.69891 18.25 9.88968 18.329 10.0303 18.4697L12 20.4393L13.9697 18.4697C14.1103 18.329 14.3011 18.25 14.5 18.25H21.25V4.75H2.75Z"
                  fill="#FB0808"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 10.75C12.4142 10.75 12.75 11.0858 12.75 11.5V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V11.5C11.25 11.0858 11.5858 10.75 12 10.75Z"
                  fill="#FB0808"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 7.25C12.4142 7.25 12.75 7.58579 12.75 8V8.5C12.75 8.91421 12.4142 9.25 12 9.25C11.5858 9.25 11.25 8.91421 11.25 8.5V8C11.25 7.58579 11.5858 7.25 12 7.25Z"
                  fill="#FB0808"
                />
              </g>
            </svg>
          </div>
        </div>
        <div class="tile-content">
          <p class="tile-description">{{ tile.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dropdown } from "floating-vue";
import "floating-vue/dist/style.css";

export default {
  name: "CompanyTiles",
  props: {
    header: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showTooltip: false,
      credenceData: [
        {
          category: "A",
          explanation: "Minimum risk",
          comments:
            "Business dealings permissible with minimum risk of default",
          color: "#11892e",
        },
        {
          category: "B",
          explanation: "Acceptable risk",
          comments:
            "Business dealings permissible with moderate risk of default",
          color: "#0172f4",
        },
        {
          category: "C",
          explanation: "Medium risk",
          comments:
            "Business dealings permissible on a regular monitoring basis",
          color: "#ebbe02",
        },
        {
          category: "D",
          explanation: "High risk",
          comments:
            "Business dealings permissible with moderate risk of default",
          color: "#ff9b0f",
        },
        {
          category: "E",
          explanation: "Very high risk",
          comments: "Business dealing not recommended",
          color: "#a40101",
        },
        {
          category: "F",
          explanation: "Not rated",
          comments: "-",
          color: "#f2effa",
          textColor: "#1c1c1c",
        },
      ],
    };
  },
  components: {
    Dropdown,
  },
  computed: {
    tiles() {
      return [
        {
          title: "Credence Score",
          value: this.header?.credenceScore,
          description:
            this.header?.riskCategoryComment,
        },
        {
          title: "Risk Assessment",
          value: this.header?.riskLevel,
          description: "Based on current assessment",
        },
        {
          title: "Credence Limit",
          value: this.header?.recommendedCreditLimit || 0,
          description: "Maximum suggested open exposure",
        },
        {
          title: "Legal Status",
          value: this.header?.legalStatus,
          description: "Current operational status",
        },
      ];
    },
  },
  methods: {
    getIconColor(credenceScore) {
      const colorMap = {
        A: "#27be69",
        B: "#0172F4",
        C: "#EBBE02",
        D: "#FF9B0F",
        E: "#A40101",
        F: "#6C6A71",
      };
      return colorMap[credenceScore] || "rgba(128, 128, 128, 1)";
    },
    formatLabel(key) {
      return key
        .replace(/_/g, " ")
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    },
  },
};
</script>

<style scoped>
.company-tiles {
  display: flex;
  padding: 16px 24px 0 24px;
  width: -webkit-fill-available;
  background: white;
  align-items: start;
  gap: 16px;
  justify-content: start;
  flex-wrap: wrap;
}

[data-popper-arrow],
.v-popper__arrow {
  display: none !important;
}

@media (max-width: 991px) {
  .company-tiles {
    max-width: 100%;
  }
}

.tiles-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  flex: 1;
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .tiles-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1440px) {
  .tiles-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

.tile {
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  min-width: 200px;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  flex-basis: 0%;
  border: 1px solid #22409680;
  gap: 14px;
  padding: 16px;
}

.tile-header {
  display: flex;
  align-items: start;
  gap: 16px;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .tile-header {
    padding: 0 20px;
  }
}

.tile-title {
  color: #1e1e1e;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 32px; */
  letter-spacing: -0.28px;
  margin: 0;
  text-align: left;
  white-space: nowrap;
}

.tile-header-wrapper-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}

.tile-header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-start;
  gap: 10px;
}

.tile-icon {
  display: flex;
  justify-content: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.tile-content {
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  justify-content: start;
  text-align: left;
}

@media (max-width: 991px) {
  .tile-content {
    padding: 0 20px;
  }
}

.tile-value {
  color: #1e1e1e;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.6px;
  margin: 0;
  text-align: left;
}

.tile-description {
  margin: 4px 0 0;
  font-size: 12px;
  color: #1e1e1e;
  font-weight: 400;
  line-height: 1;
  padding: 2px 0;
}

.tooltip-container {
  position: relative;
  display: inline-flex;
  /* flex-direction: column; */
  /* align-items: center; */
}

.tooltip-dropdown {
  margin-top: 5px;
  margin-left: 20px;
}

.frame-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.table-container {
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 12.7px #e0f5ff;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 5px;
  text-align: left;
  font-size: 12px;
  color: #101828;
  font-family: "Figtree", sans-serif;
}

.table-header-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 24px;
  text-align: left;
  font-size: 12px;
  color: #1c1c1c;
  font-family: "Figtree", sans-serif;
}

.table-header-cell {
  width: 100%;
  padding: 0px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 12px;
  color: #101828;
}

.table-headers {
  width: 100%;
  position: relative;
  background-color: #e0f5ff;
  border-bottom: 1px solid rgba(34, 64, 150, 0.2);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 12px;
  color: #1c1c1c;
  font-family: "Figtree", sans-serif;
}

.table-body {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #e0f5ff;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 12px;
  color: #1c1c1c;
  font-family: "Figtree", sans-serif;
}

.value-color-span {
  position: relative;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 6px 11px;
  box-sizing: border-box;
  text-align: left;
  font-size: 12px;
  color: #fff;
  font-family: Figtree;
}
</style>
