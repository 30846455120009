<template>
  <TabTitle
    :title="data?.title || 'Summary'"
    :lastUpdated="data?.lastUpdated || 'N/A'"
    :completionPercentage="data?.completion_percentage || 0"
    :toggleIsEditable="toggleIsEditable"
  />
  <!-- Lock Section -->
  <div
    class="company-report lock-wrapper"
    v-if="
      isLocked &&
      this.$route.params.company === 'VINOMIG%20SINGAPORE%20PTE.%20LTD'
    "
  >
    <LockAnimation />
  </div>
  <!-- Unlock Section -->
  <div class="company-report" v-else>
    <!-- About the Company Section -->
    <div class="report-section-wrapper">
      <div class="report-section about">
        <div class="header-wrapper">
          <div class="section-header">
            <div
              style="display: flex; width: 20px"
              v-html="aboutCompanyIcon?.aboutCompany"
            ></div>
            <h2>About the Company</h2>
          </div>
        </div>
        <div class="section-content">
          <p class="about-description" :class="{ expanded: isExpanded }">
            {{ truncatedText }}
          </p>
          <div
            v-if="shouldShowReadMore"
            class="read-more"
            @click="toggleReadMore"
          >
            <div>{{ isExpanded ? "Read Less" : "Read More" }}</div>
            <div
              class="read-more-icon"
              :class="{ rotated: isExpanded }"
              v-html="aboutCompanyIcon?.readMore"
            ></div>
          </div>
        </div>
      </div>
      <!-- Key Information Section -->
      <div class="report-section separation">
        <div class="section-header">
          <div
            style="display: flex; width: 20px"
            v-html="aboutCompanyIcon?.keyInfo"
          ></div>
          <h2>Key Information</h2>
        </div>
        <section class="company-info">
          <article
            v-for="(value, key) in data?.key_information"
            :key="key"
            class="info-field"
          >
            <h3 class="info-field__label">{{ formatKey(key) }}</h3>
            <p class="info-field__value" v-html="formatValue(value)"></p>
          </article>
        </section>
      </div>
    </div>
    <!-- Financials Section -->
    <div
      class="report-section-wrapper finance-section-wrapper"
      v-if="
        data?.financialMetrics?.some(
          (metric) => metric.value !== 'Not available'
        ) && data.financials?.graphData.length !== 0
      "
    >
      <div class="report-section">
        <div>
          <div class="section-header">
            <div
              style="display: flex; width: 20px"
              v-html="aboutCompanyIcon?.financials"
            ></div>
            <h2>
              Financials
              <span v-if="data?.consolidated_or_unconsolidated_or_standalone"
                >({{
                  data?.consolidated_or_unconsolidated_or_standalone
                }})</span
              >
            </h2>
          </div>
          <p class="financials-note">** {{ data?.financial_note }}</p>
        </div>
        <div class="finace-section-wrapper-1">
          <div class="financial-year">
            For the financial year ending {{ data?.financials?.date }}
          </div>
          <div class="finance-section-content">
            <div class="financial-metrics">
              <section class="metrics-section">
                <article
                  v-for="(metric, index) in data?.financialMetrics"
                  :key="index"
                  class="metric-item"
                >
                  <header class="metric-header">
                    <h3 class="metric-label">{{ formatKey(metric.title) }}</h3>
                    <div class="metric-trend">
                      <svg
                        :width="metric.indicator === 'positive' ? 10 : 12"
                        :height="metric.indicator === 'positive' ? 11 : 13"
                        viewBox="0 0 12 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="trend-icon"
                        :class="{
                          'trend-icon--up': metric.indicator === 'positive',
                        }"
                      >
                        <path
                          v-if="metric.indicator === 'positive'"
                          d="M9.2 3L2 10.2"
                          stroke="#27BE69"
                          stroke-width="1.2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          v-if="metric.indicator === 'positive'"
                          d="M9.20017 8.28172V3H3.91846"
                          stroke="#27BE69"
                          stroke-width="1.2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          v-if="metric.indicator === 'negative'"
                          d="M8 8.5L2 2.5"
                          stroke="#F2415A"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          v-if="metric.indicator === 'negative'"
                          d="M8 4.09863V8.50006H3.59857"
                          stroke="#F2415A"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span
                        class="metric-percentage"
                        :class="{
                          'metric-percentage--positive':
                            metric.indicator === 'positive',
                          'metric-percentage--negative':
                            metric.indicator === 'negative',
                        }"
                      >
                        {{ metric.percentageChange }}
                      </span>
                    </div>
                  </header>
                  <p class="metric-value">{{ metric.value }}</p>
                </article>
              </section>
            </div>
            <!-- Revenue Chart -->
            <div class="revenue-section separation">
              <div class="section-header">
                <div
                  style="display: contents"
                  v-html="aboutCompanyIcon?.revenue"
                ></div>
                <h3 style="margin: 0">Revenue</h3>
              </div>
              <div class="chart-container">
                <Bar :data="chartData" :options="chartOptions" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Products Traded Section -->
    <div
      class="report-section-wrapper finance-section-wrapper"
      v-if="data?.productsTraded?.note"
    >
      <div class="report-section">
        <div class="section-header">
          <div
            style="display: flex; width: 20px"
            v-html="aboutCompanyIcon?.productsTrade"
          ></div>
          <h2>Products Traded</h2>
        </div>
        <div class="section-content products_traded">
          <p>{{ data?.productsTraded?.note }}</p>
          <div
            class="product-categories"
            v-if="data?.productsTraded?.products.length"
          >
            <span
              v-for="(category, index) in data?.productsTraded?.products"
              :key="index"
              class="category-tag"
            >
              {{ category }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- International Trade Section -->
    <div class="report-section-wrapper finance-section-wrapper">
      <div class="report-section">
        <div class="section-header">
          <div
            style="display: flex; width: 20px"
            v-html="aboutCompanyIcon?.internationalTrade"
          ></div>
          <h2>International Trade</h2>
        </div>
        <div class="section-content">
          <div class="finance-section-content">
            <div class="trades">
              <div
                v-for="(section, key) in data?.internationalTrade"
                :key="key"
                class="trade-section"
              >
                <h3>{{ formatKey(key) }}</h3>
                <p>{{ section.note }}</p>
                <!-- <p>Transactions Verified: {{ section.value }}</p> -->
                <div
                  class="read-more"
                  style="cursor: pointer"
                  @click="toggleCheckDetails"
                >
                  <div>Check {{ formatKey(key) }}</div>
                  <div
                    class="read-more-icon"
                    v-html="aboutCompanyIcon?.readMore"
                  ></div>
                </div>
              </div>
            </div>
            <div class="chart-wrapper">
              <Bar
                :data="tradeChartData"
                :options="tradeChartOptions"
                :height="300"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Compliance Checks Section -->
    <div class="report-section-wrapper finance-section-wrapper">
      <div class="report-section">
        <div class="section-header">
          <div
            style="display: flex; width: 20px"
            v-html="aboutCompanyIcon?.complianceCheck"
          ></div>
          <h2>Compliance Checks</h2>
        </div>
        <div class="section-content">
          <div class="compliance-item">
            <div
              style="display: flex; width: 15px"
              v-html="
                data?.complianceChecksFlag === true
                  ? aboutCompanyIcon?.checks
                  : aboutCompanyIcon.cross
              "
            ></div>
            <p>{{ data?.complianceChecks }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Other Comments Section -->
    <div
      class="report-section-wrapper finance-section-wrapper"
      v-if="data?.otherComments?.note"
    >
      <div class="report-section">
        <div class="section-header">
          <div
            style="display: flex; width: 20px"
            v-html="aboutCompanyIcon?.otherComments"
          ></div>
          <h2>Other Comments</h2>
        </div>
        <div class="section-content">
          <p>{{ data?.otherComments?.note }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabTitle from "@/components/CompanyDashboard/tabs/TabTitle.vue";
import SummaryInfoIcons from "@/assets/DigitalProduct/SummaryInfoIcons.js";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import LockAnimation from "@/assets/Animation/LockAnimation.vue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "CompanyReport",
  components: {
    TabTitle,
    Bar,
    LockAnimation,
  },
  props: {
    title: { type: String, required: true },
    lastUpdated: { type: String, default: "N/A" },
    data: { type: Object, required: true },
    lockedTabs: { type: Object, required: true },
  },
  data() {
    return {
      selectedCurrency: "Euro",
      isExpanded: false,
      isEditable: false,
      isLocked: false,
    };
  },
  mounted() {
    if (this.lockedTabs?.length && this.lockedTabs?.includes("SUMMARY")) {
      this.isLocked = true;
    }
  },
  watch: {
    data: {
      handler(newData) {
        this.editedData = { ...newData };
        this.isEditable = false;
      },
    },
  },
  computed: {
    shouldShowReadMore() {
      return this.data?.aboutTheCompany?.length > 400;
    },
    truncatedText() {
      if (this.isExpanded || !this.shouldShowReadMore) {
        return this.data?.aboutTheCompany;
      }
      return this.data?.aboutTheCompany.slice(0, 400) + "...";
    },
    aboutCompanyIcon() {
      return SummaryInfoIcons;
    },
    chartData() {
      return {
        labels: this.data?.financials?.graphData?.map((item) => item.month),
        datasets: [
          {
            label: "Revenue (USD Million)",
            backgroundColor: "#613DC1",
            data: this.data?.financials?.graphData?.map((item) => item.value),
            borderRadius: 4,
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false, // Hide x-axis grid lines
              drawBorder: false,
            },
            ticks: {
              padding: 10,
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false, // Hide y-axis grid lines
              drawBorder: false,
            },
            ticks: {
              padding: 10,
              callback: function (value) {
                return value.toLocaleString();
              },
            },
          },
        },
      };
    },
    tradeChartData() {
      const tradeData = this.data?.internationalTrade || {};
      const labels = [];
      const values = [];
      const backgroundColors = [];
      const formattedLabels = [];

      // Define colors for each type of transaction
      const colorMap = {
        importTransaction: "#fab1b2", // Light pink from Figma
        exportTransaction: "#f8e6a5", // Light yellow from Figma
        internationalTradeTransactions: "#a8d1d1", // Light teal
        bangladeshTransaction: "#d1c4e9", // Light purple
      };

      // Default colors if key not found in map
      const defaultColors = [
        "#fab1b2",
        "#f8e6a5",
        "#a8d1d1",
        "#d1c4e9",
        "#ffcc80",
      ];

      // Extract data for chart
      Object.entries(tradeData).forEach(([key, data], index) => {
        const formattedKey = this.formatKey(key);
        labels.push(formattedKey);
        let numericValue =
          typeof data.value === "string" && data.value.includes("+")
            ? parseFloat(data.value.replace("+", ""))
            : Number(data.value);

        values.push(numericValue);
        backgroundColors.push(
          colorMap[key] || defaultColors[index % defaultColors.length]
        );
        formattedLabels.push(
          `${formattedKey}: ${numericValue.toLocaleString()}+ transactions`
        );
      });

      return {
        labels,
        datasets: [
          {
            label: "Transactions",
            data: values,
            backgroundColor: backgroundColors,
            borderColor: backgroundColors.map((color) => color),
            borderWidth: 1,
            borderRadius: 4,
            datalabels: {
              formatter: (value, context) => formattedLabels[context.dataIndex],
              color: "#000000",
              anchor: "end",
              align: "top",
              offset: 10,
              font: {
                weight: "bold",
                size: 12,
              },
            },
          },
        ],
      };
    },

    tradeChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return `${context.raw.toLocaleString()}+ transactions`;
              },
            },
          },
          datalabels: {
            display: true,
          },
        },
        scales: {
          x: {
            grid: {
              display: false, // Hide x-axis grid lines
              drawBorder: false,
            },
            ticks: {
              padding: 10,
            },
          },
          y: {
            display: false,
          },
        },
      };
    },
  },
  methods: {
    toggleCheckDetails() {
      this.$emit("tabChange", "TRADE");
    },
    toggleReadMore() {
      this.isExpanded = !this.isExpanded;
    },
    toggleIsEditable() {
      this.isEditable = !this.isEditable;
      if (this.isEditable) {
        this.editedData = { ...this.data };
      }
    },
    emitUpdate(section) {
      const updatedSection = { [section]: this.editedData[section] };
      this.$emit(
        "update:data",
        updatedSection,
        this.data?.recordIds,
        this.isEditable
      );
    },
    emitUpdateSimple(parentKey, key) {
      const updatedData = {
        [parentKey]: {
          ...this.editedData[parentKey],
          [key]: this.editedData[parentKey][key],
        },
      };
      this.$emit(
        "update:data",
        updatedData,
        this.data?.recordIds,
        this.isEditable
      );
    },
    emitUpdateNested(parentKey, key, subKey) {
      const updatedData = {
        [parentKey]: {
          ...this.editedData[parentKey],
          [key]: {
            ...this.editedData[parentKey][key],
            [subKey]: this.editedData[parentKey][key][subKey],
          },
        },
      };
      this.$emit(
        "update:data",
        updatedData,
        this.data?.recordIds,
        this.isEditable
      );
    },
    formatKey(key) {
      return key
        .replace(/_/g, " ")
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    },
    isObject(value) {
      return value && typeof value === "object" && !Array.isArray(value);
    },
    formatValue(value) {
      return value !== null && value !== undefined && value !== ""
        ? value
        : "-";
    },
    handleCurrencyChange(currency) {
      console.log("Currency changed to:", currency);
    },
  },
};
</script>

<style scoped>
.company-report {
  font-family: system-ui, -apple-system, sans-serif;
  padding: 20px;
  color: #333;
  display: flex;
  gap: 12px;
  flex-direction: column;
}
.lock-wrapper {
  z-index: 1000;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #edfaff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  font-family: Figtree;
  font-size: 14px;
}
.report-section-wrapper {
  display: flex;
  padding: 24px 20px;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;
  border: 1.5px solid #e0f5ff;
  /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25); */
}
.finance-section-wrapper {
  flex-direction: column;
}
.report-section {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: -webkit-fill-available;
}

.about {
  width: 130%;
}

.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 12px;
  /* padding: 16px 20px; */
  /* border-bottom: 1px solid #eee; */
}

.icon {
  margin-right: 10px;
  font-size: 18px;
}

h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  font-family: Figtree;
  color: #142d4d;
}

h3 {
  margin: 0 0 12px 0;
  font-size: 16px;
  font-weight: 600;
  color: #142d4d;
}

.finance-section-content {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 20px;
}

.about-description {
  color: #1c1c1c;
  font-family: "Figtree";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: justify;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.read-more {
  color: #fd4c8c;
  font-family: Figtree;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.finance-section-wrapper-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.financial-year {
  color: #142d4d;
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}
.about-description.expanded {
  -webkit-line-clamp: unset;
  overflow: visible;
}

.read-more-icon {
  transition: transform 0.3s ease-in-out;
  display: flex;
}

.read-more-icon.rotated {
  transform: rotate(180deg);
}

.company-info {
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  width: -webkit-fill-available;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.separation {
  border-left: 1.5px solid #e0f5ff;
  padding-left: 20px;
}

.info-field {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
}

.info-field__label {
  color: #224096;
  font-family: "Figtree", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}

.info-field__value {
  color: #1c1c1c;
  font-family: "Figtree", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.section-content {
  color: #1c1c1c;
  font-family: "Figtree";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.products_traded {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.section-content p {
  margin: 0;
}

@media (max-width: 991px) {
  .company-info {
    max-width: 991px;
    padding: 16px;
    gap: 20px;
  }

  .info-field__label,
  .info-field__value {
    font-size: 14px;
  }
}

@media (max-width: 640px) {
  .company-info {
    max-width: 640px;
    grid-template-columns: 1fr;
    padding: 12px;
    gap: 16px;
  }

  .info-field__label,
  .info-field__value {
    font-size: 12px;
  }
  .currency-toggle {
    width: 100%;
    justify-content: center;
  }

  .toggle-button {
    flex: 1;
    text-align: center;
    padding: 8px 12px;
  }

  .toggle-button--inactive {
    padding-right: 12px;
  }
}

.financials-note {
  color: #6c6a71;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  margin: 0;
}

.financial-metrics {
  display: flex;
  flex-direction: column;
  width: 65%;
}

.metrics-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  padding: 20px;
}

.metric-item {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.metric-header {
  display: flex;
  align-items: start;
  gap: 8px;
}

.metric-label {
  color: #6c6a71;
  font-family: Figtree;
  font-size: 12px;
  line-height: 22.4px;
  margin: 0;
  text-align: left;
}

.metric-trend {
  display: flex;
  align-items: center;
  gap: 2px;
}

.trend-icon {
  transform: rotate(0deg);
}

.trend-icon--up {
  transform: rotate(0deg);
}

.metric-percentage {
  font-family: Figtree;
  font-size: 12px;
  font-weight: 600;
  line-height: 19.2px;
}

.metric-percentage--positive {
  color: #27be69;
}

.metric-percentage--negative {
  color: #f2415a;
}

.metric-value {
  color: #231f2b;
  font-family: Figtree;
  font-size: 14px;
  line-height: 25.6px;
  margin: 0;
  text-align: left;
}

.metric-value--pro {
  font-weight: 600;
  filter: blur(2.5px);
}

@media (max-width: 768px) {
  .metrics-section {
    grid-template-columns: 1fr;
  }
}

.chart-container {
  height: 300px;
  margin-top: 20px;
}

.chart-wrapper {
  height: 400px;
  position: relative;
}

.product-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.category-tag {
  background: #fff5f9;
  padding: 2px 10px;
  border-radius: 16px;
  font-size: 12px;
  line-height: 20px;
  color: #231f2b;
}

.trade-section h3 {
  font-size: 14px;
  margin: 0 0 5px 0;
}

.compliance-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  gap: 12px;
}

.check-icon {
  margin-right: 10px;
}

.comment-list {
  padding-left: 20px;
  margin: 16px 0;
}

.comment-list li {
  margin-bottom: 8px;
}

.trades {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
}
@media (max-width: 768px) {
  .info-row {
    flex-direction: column;
    gap: 16px;
  }

  .info-item {
    min-width: auto;
  }

  .chart-container {
    height: 250px;
  }
}
</style>
