<template>
  <TabTitle
    :title="data?.title || 'Compliance'"
    :lastUpdated="data?.lastUpdated"
    :completionPercentage="data?.completion_percentage || 0"
    :toggleIsEditable="toggleIsEditable"
  />
  <!-- Lock Section -->
  <div class="company-profile lock-wrapper" v-if="isLocked">
    <LockAnimation />
  </div>
  <!-- Unlock Section -->
  <div class="company-report" v-else>
    <!-- AML/KYC Check -->
    <div
      class="report-section-wrapper finance-section-wrapper"
      v-if="amlKycData?.amlKycChecks"
    >
      <div class="report-section">
        <div>
          <div class="section-header">
            <div
              style="display: flex; width: 20px"
              v-html="complianceIcons?.amlKyc"
            ></div>
            <h2>AML/KYC Check</h2>
          </div>
        </div>
        <div class="finace-section-wrapper-1">
          <div class="finance-section-content">
            <div class="table-container">
              <table class="data-table">
                <thead>
                  <tr>
                    <th
                      v-for="(column, index) in amlKycData?.amlKycChecks
                        .columns"
                      :key="`aml-col-${index}`"
                    >
                      {{ column }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, rowIndex) in amlKycData?.amlKycChecks.rows"
                    :key="`aml-row-${rowIndex}`"
                  >
                    <td class="status-cell">
                      <span class="status-icon" :class="row.status">
                        <StatusIcon :status="row.status" />
                      </span>
                    </td>
                    <td>{{ row.check }}</td>
                    <td>{{ row.details }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Note
          title="Note on Share Capital"
          :text="data?.noteOnShareCapital"
          v-if="
            data?.noteOnShareCapital &&
            data?.noteOnShareCapital !== 'Not available'
          "
        />
      </div>
    </div>
    <!-- Statutory & Regulatory Default & Penalties -->
    <div
      class="report-section-wrapper finance-section-wrapper"
      v-if="amlKycData?.amlKycChecks"
    >
      <div class="report-section">
        <div>
          <div class="section-header">
            <div
              style="display: flex; width: 20px"
              v-html="complianceIcons?.statutory"
            ></div>
            <h2>Statutory & Regulatory Default & Penalties</h2>
          </div>
        </div>
        <div class="summary-box">
          <h3>{{ amlKycData?.statutoryRegulatory.summary }}</h3>
          <p>{{ amlKycData?.statutoryRegulatory.details }}</p>
        </div>
      </div>
    </div>
    <!-- Compliance Checks Section -->
    <div
      class="report-section-wrapper finance-section-wrapper"
      v-if="amlKycData?.amlKycChecks"
    >
      <div class="report-section">
        <div>
          <div class="section-header">
            <div
              style="display: flex; width: 20px"
              v-html="complianceIcons?.compliance"
            ></div>
            <h2>Compliance Checks</h2>
          </div>
        </div>
        <div class="finace-section-wrapper-1">
          <div class="finance-section-content">
            <div class="table-container">
              <table class="data-table">
                <thead>
                  <tr>
                    <th
                      v-for="(column, index) in amlKycData?.complianceChecks
                        .columns"
                      :key="`compliance-col-${index}`"
                    >
                      {{ column }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, rowIndex) in amlKycData?.complianceChecks.rows"
                    :key="`compliance-row-${rowIndex}`"
                  >
                    <td class="status-cell">
                      <span class="status-icon" :class="row.status">
                        <StatusIcon :status="row.status" />
                      </span>
                    </td>
                    <td>{{ row.area }}</td>
                    <td>{{ row.notes }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Note
          title="Note on Share Capital"
          :text="data?.noteOnShareCapital"
          v-if="
            data?.noteOnShareCapital &&
            data?.noteOnShareCapital !== 'Not available'
          "
        />
      </div>
    </div>
    <!-- CMT Checks Section -->
    <div
      class="report-section-wrapper finance-section-wrapper"
      v-if="amlKycData?.amlKycChecks"
    >
      <div class="report-section">
        <div>
          <div class="section-header">
            <div
              style="display: flex; width: 20px"
              v-html="complianceIcons?.cmt"
            ></div>
            <h2>CMT (Countering Money Laundering and Terrorism) Checks</h2>
          </div>
        </div>
        <div class="finace-section-wrapper-1">
          <div class="finance-section-content">
            <div class="table-container">
              <table class="data-table">
                <thead>
                  <tr>
                    <th
                      v-for="(column, index) in amlKycData?.cmtChecks.columns"
                      :key="`cmt-col-${index}`"
                    >
                      {{ column }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, rowIndex) in amlKycData?.cmtChecks.rows"
                    :key="`cmt-row-${rowIndex}`"
                  >
                    <td class="status-cell">
                      <span class="status-icon" :class="row.status">
                        <StatusIcon :status="row.status" />
                      </span>
                    </td>
                    <td>{{ row.check }}</td>
                    <td>{{ row.details }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Note
          title="Note on Share Capital"
          :text="data?.noteOnShareCapital"
          v-if="
            data?.noteOnShareCapital &&
            data?.noteOnShareCapital !== 'Not available'
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import TabTitle from "@/components/CompanyDashboard/tabs/TabTitle.vue";
import ComplianceIcons from "@/assets/DigitalProduct/ComplianceInfoIcons.js";
import StatusIcon from "@/components/StatusIcon.vue";
import LockAnimation from "@/assets/Animation/LockAnimation.vue";

export default {
  name: "AmlKycCheck",
  components: {
    TabTitle,
    StatusIcon,
    LockAnimation,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: { type: String, required: true },
    lastUpdated: { type: String, default: "N/A" },
    lockedTabs: { type: Object, required: true },
  },
  data() {
    return {
      isEditable: false,
      isLocked: false,
    };
  },
  mounted() {
    if (this.lockedTabs?.length && this.lockedTabs?.includes("COMPLIANCE")) {
      this.isLocked = true;
    }
  },
  computed: {
    complianceIcons() {
      return ComplianceIcons;
    },
    amlKycData() {
      return this.data;
    },
  },
};
</script>

<style scoped>
.company-report {
  font-family: system-ui, -apple-system, sans-serif;
  padding: 20px;
  color: #333;
  display: flex;
  gap: 12px;
  flex-direction: column;
}
.lock-wrapper {
  z-index: 1000;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #edfaff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  font-family: Figtree;
  font-size: 14px;
}
.report-section-wrapper {
  display: flex;
  padding: 24px 20px;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;
  border: 1.5px solid #e0f5ff;
  /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25); */
}
.finance-section-wrapper {
  flex-direction: column;
}
.report-section {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: -webkit-fill-available;
}
.section-header {
  display: flex;
  align-items: center;
  gap: 12px;
}
.finance-section-content {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 20px;
}
h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  font-family: Figtree;
  color: #142d4d;
}

h3 {
  margin: 0 0 12px 0;
  font-size: 16px;
  font-weight: 600;
}
.table-container {
  width: -webkit-fill-available;
  overflow-x: auto;
  box-shadow: 0px 0px 12.7px 0px #e0f5ff;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #e0f5ff;
}

.data-table td {
  text-align: left;
  font-size: 14px;
  color: #101828;
  font-family: "Figtree";
}

.data-table th {
  background-color: #e0f5ff;
  font-family: "Figtree";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #1c1c1c;
  border-bottom: 1px solid rgba(34, 64, 150, 0.2);
}

.data-table tr:last-child td {
  border-bottom: none;
}

.status-cell {
  width: 60px;
}

.status-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  font-size: 14px;
}

.summary-box {
  display: flex;
  flex-direction: column;
  padding: 12px;
  justify-content: center;
  align-items: start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(34, 64, 150, 0.5);
}
.summary-box p,
.summary-box h3 {
  text-align: left;
  margin: 0;
}
.summary-box p {
  font-family: "Figtree";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #1c1c1c;
}
.summary-box h3 {
  font-family: "Figtree";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #1c1c1c;
}
</style>
