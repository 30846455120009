<template>
  <TabTitle
    :title="data?.title || 'Employees'"
    :lastUpdated="data?.lastUpdated"
    :completionPercentage="data?.completion_percentage || 0"
    :toggleIsEditable="toggleIsEditable"
  />
  <!-- Lock Section -->
  <div
    class="company-report lock-wrapper"
    v-if="
      isLocked &&
      this.$route.params.company === 'VINOMIG%20SINGAPORE%20PTE.%20LTD'
    "
  >
    <LockAnimation />
  </div>
  <!-- Unlock Section -->
  <div class="company-report" v-else>
    <!-- Banking Information -->
    <div
      class="report-section-wrapper finance-section-wrapper"
      v-if="employees?.employeeCountNote"
    >
      <div class="report-section">
        <div>
          <div class="section-header">
            <!-- <div
              style="display: flex; width: 20px"
              v-html="employeesIcons?.employee"
            ></div> -->
            <h2>Employees Information</h2>
          </div>
        </div>
        <div class="summary-box">
          <p v-if="employees?.employeeCountNote">
            {{ employees?.employeeCountNote }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabTitle from "@/components/CompanyDashboard/tabs/TabTitle.vue";
import employeesIcons from "@/assets/DigitalProduct/EmployeesInfoIcons.js";
import LockAnimation from "@/assets/Animation/LockAnimation.vue";

export default {
  name: "BankingTab",
  components: { TabTitle, LockAnimation },
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    lastUpdated: {
      type: String,
      default: "-",
    },
    lockedTabs: { type: Object, required: true },
  },
  data() {
    return {
      isEditable: false,
      isLocked: false,
    };
  },
  mounted() {
    if (this.lockedTabs?.length && this.lockedTabs?.includes("EMPLOYEES")) {
      this.isLocked = true;
    }
  },
  computed: {
    employeesIcons() {
      return employeesIcons;
    },
    employees() {
      return this.data;
    },
  },
  methods: {},
};
</script>

<style scoped>
.company-report {
  font-family: system-ui, -apple-system, sans-serif;
  padding: 20px;
  color: #333;
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.lock-wrapper {
  z-index: 1000;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #edfaff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  font-family: Figtree;
  font-size: 14px;
}

.report-section-wrapper {
  display: flex;
  padding: 24px 20px;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;
  border: 1.5px solid #e0f5ff;
  /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25); */
}

.finance-section-wrapper {
  flex-direction: column;
}

.report-section {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: -webkit-fill-available;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.finance-section-content {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 20px;
}

h2 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  font-family: Figtree;
  color: #224096;
}

h3 {
  margin: 0 0 12px 0;
  font-size: 16px;
  font-weight: 600;
}

.summary-box {
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: center;
  align-items: start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  /* border: 1px solid rgba(34, 64, 150, 0.5); */
}

.summary-box p,
.summary-box h3 {
  text-align: left;
  margin: 0;
}

.summary-box p {
  font-family: "Figtree";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #1c1c1c;
}

.summary-box h3 {
  font-family: "Figtree";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #1c1c1c;
}
</style>
