<template>
  <div
    :class="[
      'dashboard',
      { 'logged-in': !isMobile, 'not-logged-in': isMobile, collapsed: this.$root.isCollapsed && !isMobile, },
    ]"
  >
    <div class="frame-35732">
      <div class="frame-35733">
        <div
          class="tab-1"
          :style="
            tab === 'myaccount'
              ? 'box-shadow: 0px -4px 24px 0px #8FC8FF; background: white;'
              : 'box-shadow: -4px -4px 8px 0px #fff, 4px 4px 8px 0px rgba(0, 0, 0, 0.25); background: #ecf4ff;'
          "
        >
          My Account
        </div>
      </div>
      <div class="frame-35734" v-if="tab === 'myaccount'">
        <div class="frame-357341" style="border-bottom: none">
          <div class="frame-35735">My Account Details</div>
          <div class="frame-357351">
            <div class="cancel-button" v-if="!isEditing" @click="toggleEdit">
              Edit
            </div>
            <div
              class="save-button"
              v-if="isEditing && hasChanges"
              @click="saveChanges"
            >
              Save
            </div>
            <div
              class="cancel-button"
              v-if="isEditing"
              @click="confirmCancelEdit"
            >
              Cancel
            </div>
          </div>
        </div>
        <div class="info">
          <div
            class="profile-info-wrapper"
            :style="{
              width: ['HEAD', 'MANAGER', 'EXECUTIVE'].includes(userType)
                ? '65%'
                : '100%',
              borderTop: '2px solid #cccccc',
            }"
          >
            <div class="frame-35736">
              <div class="frame-35737">
                <div class="title">User Name</div>
                <div class="value" v-if="!isEditing">{{ originalUser }}</div>
                <input
                  v-else
                  type="text"
                  :placeholder="
                    originalUser && originalUser !== ''
                      ? originalUser
                      : 'Enter the name'
                  "
                  class="user-name-input"
                  autocomplete="off"
                  id="username"
                  v-model="user"
                  @input="checkChanges"
                />
              </div>
            </div>
            <div class="frame-35736">
              <div class="frame-35737">
                <div class="title">Email Address</div>
                <div class="value">{{ email }}</div>
              </div>
            </div>
            <div class="frame-35736">
              <div class="frame-35737">
                <div class="title">Mobile Number</div>
                <div class="value" v-if="!isEditing">
                  {{
                    phoneWithCountryCode && phoneWithCountryCode !== ""
                      ? phoneWithCountryCode
                      : "-"
                  }}
                </div>
                <div class="input-group-phone" v-else>
                  <select
                    v-model="selectedCountryCode"
                    class="country-dropdown"
                    @change="checkChanges"
                  >
                    <option value="" disabled>Select a country</option>
                    <option
                      v-for="country in countries"
                      :value="country"
                      :key="country.code"
                      class="country-option"
                    >
                      {{ country.name }} - (+{{
                        getFirstDialCode(country.dialCode)
                      }})
                    </option>
                  </select>
                  <input
                    class="phone-input"
                    type="tel"
                    v-model="phone"
                    :placeholder="
                      phone && phone !== '' ? formattedphone : 'Phone'
                    "
                    inputmode="numeric"
                    autocomplete="tel"
                    required
                    pattern="[0-9]*"
                    @input="
                      phone = phone.replace(/\D/g, '');
                      checkChanges();
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
import VueCookies from "vue-cookies";
import {
  AllCountries,
  UpdateUserDetails,
} from "@/api/APIs";
import Toastify from "@/components/Toastify.vue";
import "floating-vue/dist/style.css";

export default {
  name: "UserProfile",
  components: {
    Toastify,
  },
  props: {},
  data() {
    return {
      tab: null,
      user: this.$root.userDetails.name,
      originalUser: this.$root.userDetails.name,
      email: this.$root.userDetails.email,
      userType: this.$root.userDetails.type,
      countries: [],
      prevSelectedCountry: {},
      selectedCountryCode: {
        id: 97,
        name: "India",
        code: "IN",
        dialCode: "91",
        phoneLength: "10",
        flagEmoji: "??",
        flagImage:
          "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg",
        productOptions: {
          REPORT_COMPREHENSIVE: {
            NO_UPDATE: {
              eta_hours: 1,
              purchase_credits: 50,
              purchase_usd: 50,
            },
            UPDATED: {
              eta_hours: 2.5,
              purchase_credits: 76,
              purchase_usd: 76,
            },
            FRESH: {
              eta_hours: 5,
              purchase_credits: 150,
              purchase_usd: 150,
            },
          },
        },
        searchNotes: {
          REPORT_COMPREHENSIVE:
            "For India, Unique English name is a rule for Limited companies. If the name fully matches, the order can be placed.",
        },
        stackOrder: 4,
      },
      teamTableColumns: ["Team Name", "No. of Members", "Action"],
      teamMembersTableColumns: ["Name", "Email", "Role", "Status"],
      teams: null,
      showCreateTeamModal: false,
      showInviteMembersModal: false,
      showInvitationStatus: false,
      phone: VueCookies.get("phone") ? VueCookies.get("phone") : "",
      originalPhone: VueCookies.get("phone") ? VueCookies.get("phone") : "",
      phoneWithCountryCode: VueCookies.get("phone_w_countryCode")
        ? VueCookies.get("phone_w_countryCode")
        : VueCookies.get("phone"),
      isEditing: false,
      hasChanges: false,
      isMobile: window.innerWidth < 1023,
      mobile: window.innerWidth <= 767,
      viewTeam: false,
      selectedTeam: null,
      teamMembers: null,
      pocName: VueCookies.get("RM")?.name,
      pocEmail: VueCookies.get("RM")?.email,
      pocPhone: VueCookies.get("RM")?.phone,
      // referralCode: VueCookies.get("referralCode"),
      invitations: null,
      branchName: VueCookies.get("branch") ? VueCookies.get("branch") : "",
      originalBranchName: VueCookies.get("branch")
        ? VueCookies.get("branch")
        : "",
    };
  },
  watch: {
    "$route.query.tab": {
      immediate: true,
      handler(newTab) {
        this.tab = newTab;
      },
    },
  },
  created() {
    this.tab = this.$route.query.tab;
  },
  mounted() {
    this.fetchCountryList();
    window.addEventListener("resize", this.handleResponsive);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResponsive);
  },
  computed: {
    isEntityUser() {
      return ["HEAD", "MANAGER", "EXECUTIVE"].includes(this.userType);
    },
    isRetailUser() {
      return ["RETAIL"].includes(this.userType);
    },
  },
  methods: {
    removeCountryCode(phone) {
      return phone.split("-").pop();
    },
    formattedphone(event) {
      if (event) {
        this.phone = this.removeCountryCode(event.target.value);
      } else {
        this.phone = this.removeCountryCode(this.phone);
      }
    },
    handleResponsive() {
      this.isMobile = window.innerWidth < 1023;
    },
    toggleEdit() {
      this.isEditing = !this.isEditing;
      if (this.phone && this.phone !== "") {
        this.formattedphone();
      }
      if (this.phone && this.phone !== "") {
        this.matchCountryCode();
      }
    },
    checkChanges() {
      this.hasChanges =
        this.user !== this.originalUser ||
        this.phone !== this.originalPhone ||
        this.prevSelectedCountry !== this.selectedCountryCode
    },
    async saveChanges() {
      const payload = {};
      if (this.user !== this.originalUser) {
        payload.name = this.user;
      }
      if (this.email) {
        payload.email = this.email;
      }
      if (
        this.phone !== this.originalPhone ||
        this.prevSelectedCountry !== this.selectedCountryCode
      ) {
        payload.phone =
          this.phone !== ""
            ? `+${this.selectedCountryCode.dialCode}-${this.phone}`
            : "";
        this.phoneWithCountryCode = payload.phone;
      }
      try {
        const response = await UpdateUserDetails(payload);
        if (response.message == "SUCCESS") {
          VueCookies.set("user", this.user);
          VueCookies.set("phone", this.phone);
          VueCookies.set("phone_w_countryCode", this.phoneWithCountryCode);
          this.originalUser = this.user;
          this.originalPhone = this.phone;
          this.hasChanges = false;
          this.isEditing = false;
          window.location.reload();
        } else {
          console.error("Something went wrong. Please try again later");
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          console.error("Error updating user details:", error);
        }
      }
    },
    confirmCancelEdit() {
      if (this.hasChanges) {
        if (confirm("You have unsaved changes. Do you want to save them?")) {
          this.saveChanges();
        } else {
          this.cancelEdit();
        }
      } else {
        this.cancelEdit();
      }
    },
    cancelEdit() {
      this.isEditing = false;
      this.user = this.originalUser;
      this.phone = this.originalPhone;
      this.hasChanges = false;
    },
    getFirstDialCode(dialCodeString) {
      return dialCodeString.split(",")[0];
    },
    async fetchCountryList() {
      try {
        const response = await AllCountries(false);
        this.countries = await response.allCountries;
      } catch (error) {
        this.loginError = "Error occurred during login.";
        console.error(error);
      }
    },
    matchCountryCode() {
      const parts = this.phoneWithCountryCode.split("-");
      let matchedCode = parts.slice(0, -1).join("-").replace("+", "");
      this.countries.map((country) =>
        country.dialCode.toString() === matchedCode.toString()
          ? (this.selectedCountryCode = country)
          : ""
      );
      this.prevSelectedCountry = this.selectedCountryCode;
    },
  },
};
</script>
<style scoped>
.dashboard,
.dashboard * {
  box-sizing: border-box;
}
.dashboard {
  /* background: linear-gradient(to left, #ecf4ff, #ecf4ff),
    linear-gradient(to left, #e7ebf0, #e7ebf0); */
  display: flex;
  flex-direction: column;
  /* margin-left: 18vw; */
  align-items: center;
  justify-content: flex-start;
  position: relative;
  /* margin-top: 104px; */
  height: 100%;
  /* overflow: hidden; */
}
.logged-in {
  margin-left: 16vw;
  padding-top: 45px;
  transition: margin-left 0.3s ease;
}
.logged-in.collapsed {
  margin-left: 60px;
}

.not-logged-in {
  width: 100%;
}
.navbar-instance {
  background: #ffffff !important;
  flex-shrink: 0 !important;
  width: 1440px !important;
}
.frame-35705 {
  background: #e2eaf5;
  padding: 20px 40px 20px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}
.info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  width: 100%;
}
.profile-info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.poc-details {
  width: 40%;
  display: flex;
  padding-left: 30px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  margin-left: 20px;
  border-left: 2px solid #3e485533;
  margin-top: 25px;
  align-self: stretch;
}
.poc-title {
  color: #11365a;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.24px;
  text-align: left;
}
.poc-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  overflow-wrap: anywhere;
}
.label {
  color: #11365a;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.2px;
}
.social-media-icons {
  width: 20px;
}
.tooltip-content-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* padding: 8px; */
  width: 100%;
  gap: 8px;
}
.tooltip-message-profile {
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #fff;
}
.referal-card {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 16px;
  background: #11365a;
}
.card-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.card-title {
  color: var(--Other-WHITE, #fff);
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.2px;
}
.card-title-2 {
  color: var(--Other-WHITE, #fff);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  align-self: stretch;
}
.referal-code-box {
  display: flex;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 13px;
  background: #fff;
  position: relative;
}
.referal-code {
  color: var(--Text-Mid, #3e4855);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  width: 75%;
}
.no-teams {
  color: #11365a;
  opacity: 0.6;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
}
.copy-code {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  right: 6px;
  top: 10px;
  cursor: pointer;
}
.invite-button {
  display: flex;
  padding: 10px 36px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 16px;
  background: #fcd600;
  color: #415648;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  cursor: pointer;
}
.frame-357042 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.welcome-to-credence {
  color: var(--secondary-grey-900, #2b3674);
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 24px;
  line-height: 42px;
  font-weight: 700;
  position: relative;
}
.frame-35732 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2rem 3rem;
  background-color: #f2f8ff;
}
.frame-35733 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
}
.tab-1 {
  display: flex;
  min-width: 190px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px 16px 0px 0px;
  background: #ecf4ff;
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.4px;
}
.frame-35734 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-height: 90%;
  padding: 20px;
  background: white;
  border-radius: 0px 16px 16px 16px;
  box-shadow: none !important;
  position: relative;
}
.frame-35735 {
  color: #11365a;
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.28px;
  /* width: 100%; */
  padding: 20px 0;
}
.frame-35735-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  gap: 20px;
  color: #11365a;
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.28px;
  padding: 20px 0;
}
.frame-35736 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 15px 0;
  border-bottom: 2px solid #cccccc;
}
.frame-35737 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  overflow-wrap: anywhere;
}
.title {
  color: #11365a;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  text-align: left;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.2px;
}
.value {
  color: #11365a;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  text-align: left;
  width: 100%;
}
.edit-button {
  color: #11365a;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  text-align: left;
  margin-right: 5vw;
}
.user-name-input {
  color: #11365a !important;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
  border-radius: 16px;
  border: 1px solid rgba(17, 54, 90, 0.4);
  outline: none;
  text-align: left;
  box-shadow: none;
  width: 100%;
  background-color: transparent;
  display: flex;
  width: 30vw;
  height: 3rem;
  align-items: center;
}
.frame-35738 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  width: 30vw;
}
.save-button {
  display: flex;
  color: white;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  border-radius: 16px;
  padding: 12px 26px;
  justify-content: center;
  align-items: center;
  background: #11365a;
  flex-shrink: 0;
  flex: 1;
  min-width: 160px;
  cursor: pointer;
}
.cancel-button {
  color: #11365a;
  border: 2px solid #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  border-radius: 16px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-width: 160px;
  cursor: pointer;
}
.frame-357341 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid #cccccc;
}
.frame-357351 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  /* width: 45vw; */
}
.country-dropdown {
  width: 35%;
  color: #11365a !important;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
  border-radius: 16px;
  border: 1px solid rgba(17, 54, 90, 0.4);
  outline: none;
  text-align: left;
  box-shadow: none;
  background-color: transparent;
  display: flex;
  height: 3rem;
  align-items: center;
}
.input-group-phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 30vw;
}
.phone-input {
  color: #11365a !important;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
  border-radius: 16px;
  border: 1px solid rgba(17, 54, 90, 0.4);
  outline: none;
  text-align: left;
  box-shadow: none;
  background-color: transparent;
  display: flex;
  width: 70%;
  height: 3rem;
  align-items: center;
  padding: 0px 20px;
}
.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
  border-bottom: 2px solid #cccccc;
}
#searchResultTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  font-family: "Montserrat-Regular", sans-serif;
}
#searchResultTable th {
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  padding: 10px 16px;
  min-width: 30px;
}
#searchResultTable td {
  text-align: left;
  padding: 10px 16px;
  color: #11365a;
  font-size: 16px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.16px;
}
.button-outer {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  width: 48px;
  background: white;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.18);
  cursor: pointer;
}
.view-team {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.16px;
  cursor: pointer;
}
.button-border {
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 86%;
  height: 86%;
}
.action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 20px;
}
.status-bg {
  padding: 4px 12px;
  border-radius: 8px;
  font-weight: 600;
}
.no-teams-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  height: 50vh;
}
@media only screen and (max-width: 767px) {
  .frame-35732 {
    padding: 1rem 1rem;
  }
  .tab-1 {
    padding: 6px 12px;
    font-size: 16px;
    min-width: 140px;
  }
  .no-teams {
    font-size: 14px;
  }
  .frame-35735 {
    padding: 15px 0;
  }
  .frame-35735,
  .title,
  .poc-title,
  .label {
    font-size: 18px;
  }
  .info {
    flex-direction: column;
  }
  #searchResultDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    width: 100%;
    gap: 20px;
  }
  .result-wrapper {
    display: flex;
    padding: 21.634px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 21.634px;
    border: 1.352px solid rgba(0, 0, 0, 0.15);
    background: #fff;
    margin-bottom: 20px;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    color: #11365a;
    overflow-wrap: anywhere;
  }
  .label {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.216px;
    width: 17vw;
    line-height: 27px;
  }
  .value {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    letter-spacing: -0.216px;
    text-transform: capitalize;
    flex: 1;
  }
  .poc-details {
    width: 100%;
    padding: 0;
    margin-left: 0;
    border-left: none;
    gap: 15px;
  }
  .cancel-button,
  .save-button {
    min-width: 80px;
    line-height: 8px;
    padding: 10px 10px;
    border-radius: 8px;
    font-size: 14px;
  }
  .save-button {
    padding: 12px 12px;
  }
  .frame-35737,
  .user-name-input,
  .input-group-phone {
    width: 100%;
    margin: 0;
    gap: 0.5rem;
  }
  .phone-input,
  .user-name-input,
  .country-dropdown {
    height: 2.5rem;
  }
  .card-title,
  .card-title-2 {
    text-align: left;
  }
  .referal-code-box,
  .invite-button {
    padding: 8px;
  }
  .profile-info-wrapper {
    width: 100% !important;
  }
  .frame-35733 {
    gap: 15px;
  }
  .team-icon {
    width: 25px;
    height: 25px;
  }
  #create-team-button {
    padding: 5px 8px;
  }
  #searchResultTable th,
  #searchResultTable td {
    padding: 10px;
    font-size: 14px;
  }
  .view-team {
    line-height: 20px;
    font-size: 14px;
  }
  .table-wrapper {
    margin: 20px 0 0 0;
  }
  .status-bg {
    padding: 0px 12px;
  }
  .frame-35735-1 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
}
@media only screen and (min-width: 1156px) {
}
</style>
