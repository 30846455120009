<template>
  <TabTitle
    :title="data?.title || 'Shareholding'"
    :lastUpdated="data?.lastUpdated"
    :completionPercentage="data?.completion_percentage || 0"
    :toggleIsEditable="toggleIsEditable"
  />
    <!-- Lock Section -->
    <div class="company-report lock-wrapper" v-if="isLocked">
    <LockAnimation />
  </div>
      <!-- Unlock Section -->
  <div class="company-report" v-else>
    <!-- Shareholding Structure Section -->
    <div class="report-section-wrapper finance-section-wrapper">
      <div class="report-section">
        <div>
          <div class="section-header">
            <div
              style="display: flex; width: 20px"
              v-html="shareholdingIcons?.shareholdingInfo"
            ></div>
            <h2>Shareholder Information / Beneficial Owner</h2>
          </div>
        </div>
        <!-- Table -->
        <div class="finace-section-wrapper-1">
          <div class="finance-section-content">
            <div class="dashboard-grid">
              <div class="table-container">
                <table class="data-table">
                  <thead>
                    <tr>
                      <th
                        v-for="key in columnKeys(
                          this.data
                            ?.share_holder_information_or_beneficial_owner
                        )"
                        :key="key"
                      >
                        {{ formatColumnName(key) }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        item, index
                      ) in data?.share_holder_information_or_beneficial_owner"
                      :key="index"
                    >
                      <td
                        v-for="key in columnKeys(
                          this.data
                            ?.share_holder_information_or_beneficial_owner
                        )"
                        :key="`${index}-${key}`"
                      >
                        {{ item[key] || "" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card chart-card">
                <div class="card-content">
                  <PieChart :data="data?.pieChartData" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Note
          title="Note on Shareholding"
          :text="data?.noteOnShareHolding"
          v-if="
            data?.noteOnShareHolding &&
            data?.noteOnShareHolding !== 'Not available'
          "
        />
      </div>
    </div>
    <!-- Share Capital -->
    <div class="report-section-wrapper finance-section-wrapper">
      <div class="report-section">
        <div>
          <div class="section-header">
            <div
              style="display: flex; width: 20px"
              v-html="shareholdingIcons?.shareCapital"
            ></div>
            <h2>Share Capital</h2>
          </div>
        </div>
            <div class="table-container">
              <table class="data-table">
                <thead>
                  <tr>
                    <th
                      v-for="key in columnKeys(this.data?.shareCapital)"
                      :key="key"
                    >
                      {{ formatColumnName(key) }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in data?.shareCapital" :key="index">
                    <td
                      v-for="key in columnKeys(this.data?.shareCapital)"
                      :key="`${index}-${key}`"
                    >
                      {{ item[key] || "" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        <Note
          title="Note on Share Capital"
          :text="data?.noteOnShareCapital"
          v-if="
            data?.noteOnShareCapital &&
            data?.noteOnShareCapital !== 'Not available'
          "
        />
      </div>
    </div>
    <!-- Ultimate Parent -->
    <div
      class="report-section-wrapper finance-section-wrapper"
      v-if="
        filteredUltimateParentCompany &&
        Object.keys(filteredUltimateParentCompany).length > 0
      "
    >
      <div class="report-section">
        <div>
          <div class="section-header">
            <div
              style="display: flex; width: 20px"
              v-html="shareholdingIcons?.ultimateParent"
            ></div>
            <h2>Ultimate Parent Company</h2>
          </div>
        </div>
        <div class="company-info">
          <div
            v-for="(value, key) in filteredUltimateParentCompany"
            :key="key"
            class="info-item"
          >
            <h3 class="info-key">{{ formatColumnName(key) }}</h3>
            <p class="info-value">{{ value }}</p>
          </div>
        </div>
        <Note
          title="Note on Group Structure"
          :text="data?.ultimateParentCompany?.noteOnGroupStructure"
          v-if="
            data?.ultimateParentCompany?.noteOnGroupStructure &&
            data?.ultimateParentCompany?.noteOnGroupStructure !==
              'Not available'
          "
        />
      </div>
    </div>
    <!-- Ultimate Beneficial Owners -->
    <div class="report-section-wrapper finance-section-wrapper">
      <div class="report-section">
        <div>
          <div class="section-header">
            <div
              style="display: flex; width: 20px"
              v-html="shareholdingIcons?.ultimateParent"
            ></div>
            <h2>Ultimate Parent Company/ Ultimate Beneficial Owners</h2>
          </div>
        </div>
            <div class="table-container">
              <table class="data-table">
                <thead>
                  <tr>
                    <th
                      v-for="key in columnKeys(this.data?.beneficialOwner)"
                      :key="key"
                    >
                      {{ formatColumnName(key) }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in data?.beneficialOwner"
                    :key="index"
                  >
                    <td
                      v-for="key in columnKeys(this.data?.beneficialOwner)"
                      :key="`${index}-${key}`"
                    >
                      {{ item[key] || "" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
                  <Note
          title="Note on Share Capital"
          :text="data?.noteOnBeneficialOwner"
          v-if="
            data?.noteOnBeneficialOwner &&
            data?.noteOnBeneficialOwner !== 'Not available'
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import TabTitle from "@/components/CompanyDashboard/tabs/TabTitle.vue";
import PieChart from "../PieChart.vue";
import ShareholdingInfoIcons from "@/assets/DigitalProduct/ShareholdingInfoIcons.js";
import Note from "../Note.vue";
import LockAnimation from "@/assets/Animation/LockAnimation.vue";

export default {
  name: "ShareholdingInfo",
  components: {
    TabTitle,
    PieChart,
    Note,
    LockAnimation
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    lastUpdated: {
      type: String,
      default: "N/A",
    },
    data: {
      type: Object,
      required: true,
    },
    lockedTabs: { type: Object, required: true },
  },
  data(){
    return{
      isEditable:false,
      isLocked: false
    }
  },
  mounted(){
    if (this.lockedTabs?.length && this.lockedTabs?.includes('SHAREHOLDING')) {
      this.isLocked = true
    }
  },
  computed: {
    shareholdingIcons() {
      return ShareholdingInfoIcons;
    },
    allKeys() {
      const keys = new Set();
      this.data?.share_holder_information_or_beneficial_owner?.forEach(
        (item) => {
          Object.keys(item).forEach((key) => {
            if (item[key] !== undefined && item[key] !== "") {
              keys.add(key);
            }
          });
        }
      );
      return keys;
    },
    filteredUltimateParentCompany() {
      if (!this.data?.ultimateParentCompany) return {};

      return Object.fromEntries(
        Object.entries(this.data.ultimateParentCompany).filter(
          ([key, value]) =>
            value && value !== "Not available" && key !== "noteOnGroupStructure"
        )
      );
    },
  },
  methods: {
    formatColumnName(key) {
      return key
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    columnKeys(tableData) {
      if (!tableData || !Array.isArray(tableData) || tableData.length === 0) {
        return [];
      }

      // Collect all possible keys from the dataset
      const keys = new Set();
      tableData.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (item[key] !== undefined && item[key] !== "") {
            keys.add(key);
          }
        });
      });

      // Convert Set to Array
      const allKeys = Array.from(keys);

      // Prioritize certain columns to appear first
      const priorityOrder = [
        "class",
        "name",
        "city_or_country",
        "number_of_shares",
        "interest_percentage",
      ];

      allKeys.sort((a, b) => {
        const indexA = priorityOrder.indexOf(a);
        const indexB = priorityOrder.indexOf(b);

        if (indexA === -1 && indexB === -1) return a.localeCompare(b);
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
      });

      return allKeys;
    },
  },
};
</script>

<style scoped>
.company-report {
  font-family: system-ui, -apple-system, sans-serif;
  padding: 20px;
  color: #333;
  display: flex;
  gap: 12px;
  flex-direction: column;
}
.lock-wrapper {
  z-index: 1000;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #edfaff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  font-family: Figtree;
  font-size: 14px;
}
.report-section-wrapper {
  display: flex;
  padding: 24px 20px;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;
  border: 1.5px solid #e0f5ff;
  /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25); */
}
.finance-section-wrapper {
  flex-direction: column;
}
.report-section {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: -webkit-fill-available;
}
.section-header {
  display: flex;
  align-items: center;
  gap: 12px;
}
.financial-year {
  color: #142d4d;
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}
.finance-section-content {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 20px;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: -webkit-fill-available;
}

h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  font-family: Figtree;
  color: #142d4d;
}

h3 {
  margin: 0 0 12px 0;
  font-size: 16px;
  font-weight: 600;
}

@media (min-width: 992px) {
  .dashboard-grid {
    grid-template-columns: 2fr 1fr;
  }
}

.card {
  border-radius: 12px;
  border: 1px solid #e0f5ff;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  background-color: #ffffff;
  /* overflow: hidden; */
}

.card-content {
  padding: 20px;
}

.table-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.data-table {
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 12.7px #e0f5ff;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 5px;
  text-align: left;
  font-size: 18px;
  color: #101828;
  font-family: "Figtree", sans-serif;
}

.data-table thead{
  width: 100%;
  position: relative;
  background-color: #e0f5ff;
  border-bottom: 1px solid rgba(34, 64, 150, 0.2);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #1c1c1c;
  font-family: "Figtree", sans-serif;
}

.data-table tbody{
  width: 100%;
  position: relative;
  border-bottom: 1px solid #e0f5ff;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #1c1c1c;
  font-family: "Figtree", sans-serif;
}

.data-table td,
.data-table th {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #101828;
  padding-right: 35px;
}

.data-table tr{
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 24px;
  text-align: left;
  font-size: 14px;
  color: #1c1c1c;
  font-family: "Figtree", sans-serif;
}



.company-info {
  width: -webkit-fill-available;
  text-align: left;
}

.info-item {
  margin-bottom: 24px;
}

.info-key {
  color: #224096;
  font-family: "Figtree";
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 8px 0;
}

.info-value {
  color: #1c1c1c;
  font-family: "Figtree";
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

@media (max-width: 768px) {
}
@media (min-width: 992px) {
  .dashboard-grid {
    grid-template-columns: 2fr 1fr;
  }
}
</style>
