<template>
  <div class="overview-section">
    <div class="dashboard-grid">
      <!-- Revenue Chart -->
      <div class="chart-card">
        <div class="chart-header">
          <div
            style="display: flex; width: 20px"
            v-html="financeIcons?.revenue"
          ></div>
          <h3>Revenue</h3>
        </div>
        <div class="chart-content">
          <Bar :data="revenueChartData" :options="barChartOptions" />
        </div>
      </div>

      <!-- Net Profit Chart -->
      <div class="chart-card">
        <div class="chart-header">
          <div
            style="display: flex; width: 20px"
            v-html="financeIcons?.netProfit"
          ></div>
          <h3>Profit Parameters</h3>
        </div>
        <div class="chart-content">
          <Bar :data="netProfitChartData" :options="barChartOptions" />
        </div>
      </div>

      <!-- Debt to Equity Ratio Chart -->
      <div class="chart-card">
        <div class="chart-header">
          <div
            style="display: flex; width: 20px"
            v-html="financeIcons?.debtToEquity"
          ></div>
          <h3>Debt to equity ratio</h3>
        </div>
        <div class="chart-content">
          <Line :data="debtToEquityChartData" :options="lineChartOptions" />
        </div>
      </div>

      <!-- External Gearing Chart -->
      <div class="chart-card">
        <div class="chart-header">
          <div
            style="display: flex; width: 20px"
            v-html="financeIcons?.gearing"
          ></div>
          <h3>External Gearing</h3>
        </div>
        <div class="chart-content">
          <Bar :data="externalGearingChartData" :options="barChartOptions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Bar, Line } from "vue-chartjs";
import FinanceIcons from "@/assets/DigitalProduct/FinanceIcons.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "OverviewSection",
  components: {
    Bar,
    Line,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false, // Hide x-axis grid lines
              drawBorder: false,
            },
            ticks: {
              padding: 10,
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false, // Hide y-axis grid lines
              drawBorder: false,
            },
            ticks: {
              padding: 10,
              callback: function (value) {
                return value.toLocaleString();
              },
            },
          },
        },
      },
      lineChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
          },
        },
        scales: {
          x: {
            grid: {
              display: false, // Hide x-axis grid lines
              drawBorder: false,
            },
            ticks: {
              padding: 10,
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false, // Hide y-axis grid lines
              drawBorder: false,
            },
            ticks: {
              padding: 10,
              callback: function (value) {
                return value.toLocaleString();
              },
            },
          },
        },
      },
    };
  },
  computed: {
    financeIcons() {
      return FinanceIcons;
    },
    revenueChartData() {
      return {
        labels: this.chartData?.revenueChartData.map((item) => item.month),
        datasets: [
          {
            label: "Revenue",
            data: this.chartData?.revenueChartData.map((item) => item.value),
            backgroundColor: "#613dc1",
            borderColor: "#613dc1",
            borderWidth: 1,
          },
        ],
      };
    },
    netProfitChartData() {
      return {
        labels: this.chartData?.netProfitChartData.map((item) => item.month),
        datasets: [
          {
            label: "Net Profit",
            data: this.chartData?.netProfitChartData.map((item) => item.value),
            backgroundColor: "#51a2ad",
            borderColor: "#51a2ad",
            borderWidth: 1,
          },
        ],
      };
    },
    debtToEquityChartData() {
      if (!this.chartData?.debtToEquityRatioChartData.length) {
        return { labels: [], datasets: [] };
      }

      return {
        labels: this.chartData?.debtToEquityRatioChartData.map(
          (item) => item.month
        ),
        datasets: [
          {
            label: "Current Ratio",
            data: this.chartData?.debtToEquityRatioChartData.map(
              (item) => item.value.currentRatio
            ),
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 2,
            tension: 0.1,
          },
          {
            label: "Debt/Equity Ratio",
            data: this.chartData?.debtToEquityRatioChartData.map(
              (item) => item.value["debt/equityRatio"]
            ),
            backgroundColor: "rgba(34, 64, 150, 0.2)",
            borderColor: "rgba(34, 64, 150, 1)",
            borderWidth: 2,
            tension: 0.1,
          },
        ],
      };
    },
    externalGearingChartData() {
      if (this.chartData?.externalGearingChartData.length === 0) {
        return {
          labels: [],
          datasets: [],
        };
      }

      return {
        labels: this.chartData?.externalGearingChartData.map(
          (item) => item.month
        ),
        datasets: [
          {
            label: "External Gearing",
            data: this.chartData?.externalGearingChartData.map(
              (item) => item.value
            ),
            backgroundColor: "#efc83a",
            borderColor: "#efc83a",
            borderWidth: 1,
          },
        ],
      };
    },
  },
  methods: {
    formatLabel(key) {
      // Convert camelCase to readable format
      return key
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase())
        .replace(/$$([^)]+)$$/g, "($1)"); // Preserve parentheses
    },
  },
};
</script>

<style scoped>
.overview-section {
  font-family: Arial, sans-serif;
  color: #333;
  /* max-width: 1200px;
  margin: 0 auto; */
}

.last-updated {
  text-align: right;
  color: #666;
  margin-bottom: 20px;
  font-size: 14px;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.chart-card {
  background-color: #fff;
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  padding: 20px;
  border: 1.5px solid #e0f5ff;
}

.chart-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.chart-header h3 {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Figtree;
  color: #142d4d;
  text-align: left;
  margin: 0;
}

.chart-content {
  height: 250px;
  position: relative;
}

@media (max-width: 768px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
  }
}
</style>
