<template>
  <div
    :class="[
      'table-container',
      { 'logged-in': !isMobile, 'not-logged-in': isMobile, collapsed: this.$root.isCollapsed && !isMobile, },
    ]"
  >
    <iframe
      src="https://dashboard.credencedata.com/public/dashboard/6b950b34-d313-46a8-b2ef-ab9204000024"
      frameborder="0"
      width="100%"
      height="100%"
      style="border: none; overflow: hidden"
      allowfullscreen
    ></iframe>
  </div>
</template>
<script>
export default {
  name: "AllocationSummary",
  components: {},
  props: {},
  data() {
    return {
      isMobile: window.innerWidth < 1023,
    };
  },
  computed: {},
  created() {
  },
  mounted() {},
  beforeUnmount() {},

  methods: {
  },
};
</script>
<style scoped>
.logged-in {
  margin-left: 16vw;
  padding-top: 45px !important;
  transition: margin-left 0.3s ease;
}
.logged-in.collapsed {
  margin-left: 60px;
}
.not-logged-in {
  width: 100%;
}
.table-container,
.table-container * {
  box-sizing: border-box;
}
.table-container {
  padding-top: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  height: 100vh;
}
@media only screen and (max-width: 767px) {
  .table-container {
    padding-top: 1rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .table-container {
    padding-top: 0;
  }
}
@media only screen and (min-width: 1156px) {
}
</style>
