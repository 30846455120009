<template>
  <div class="report-search">
    <div class="drag-handle" @mousedown="startDrag">
      <div class="arrow-bubble">
        <i class="fa-solid fa-arrows-left-right"></i>
      </div>
    </div>
    <h2 class="search-title">Smart Search</h2>
    <p class="search-description">
      Ask anything about your report and find insights quickly
    </p>
    <div class="search-input-container">
      <textarea
        v-model="searchQuery"
        @keyup.enter="performSearch"
        class="search-input"
        placeholder="Enter your search query"
        aria-label="Search query"
      ></textarea>
      <button
        @click="performSearch"
        class="search-button"
        aria-label="Perform search"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M8.37514 3.61728C8.29479 3.61728 8.25459 3.57039 8.23451 3.49674C8.02694 2.37842 8.04031 2.35165 6.87514 2.13065C6.79479 2.11728 6.74791 2.07039 6.74791 1.99002C6.74791 1.90968 6.79479 1.86279 6.87514 1.84939C8.03362 1.61505 8.00013 1.58825 8.23451 0.483336C8.25459 0.409679 8.29479 0.362793 8.37514 0.362793C8.45551 0.362793 8.49568 0.409679 8.51576 0.483336C8.75014 1.58825 8.70996 1.61505 9.87514 1.84939C9.94879 1.86279 10.0024 1.90968 10.0024 1.99002C10.0024 2.07039 9.94879 2.11728 9.87514 2.13065C8.70996 2.36505 8.72336 2.37842 8.51576 3.49674C8.49568 3.57039 8.45551 3.61728 8.37514 3.61728ZM11.6162 8.22442C11.489 8.22442 11.4019 8.14405 11.3885 8.02354C11.1474 6.23556 11.0872 6.23556 9.23896 5.88068C9.11842 5.86056 9.03139 5.78022 9.03139 5.65299C9.03139 5.53245 9.11842 5.44539 9.23896 5.42531C11.0872 5.17085 11.1542 5.11056 11.3885 3.28914C11.4019 3.16862 11.489 3.08156 11.6162 3.08156C11.7367 3.08156 11.8238 3.16862 11.8372 3.29585C12.0582 5.09048 12.1519 5.08379 13.9867 5.42531C14.1073 5.45211 14.1943 5.53245 14.1943 5.65299C14.1943 5.78691 14.1073 5.86056 13.96 5.88068C12.1385 6.17531 12.0582 6.22219 11.8372 8.01014C11.8238 8.14405 11.7367 8.22442 11.6162 8.22442ZM7.07602 15.6374C6.90191 15.6374 6.77468 15.5101 6.74122 15.3293C6.26576 11.6597 5.75014 11.1039 2.12068 10.7021C1.93316 10.682 1.80594 10.5414 1.80594 10.3673C1.80594 10.1932 1.93316 10.0592 2.12068 10.0324C5.75014 9.63065 6.26576 9.07485 6.74122 5.40522C6.77468 5.22442 6.90191 5.10388 7.07602 5.10388C7.25014 5.10388 7.37736 5.22442 7.40414 5.40522C7.87959 9.07485 8.40191 9.63065 12.0247 10.0324C12.2189 10.0592 12.3461 10.1932 12.3461 10.3673C12.3461 10.5414 12.2189 10.682 12.0247 10.7021C8.40862 11.1775 7.90639 11.6664 7.40414 15.3293C7.37736 15.5101 7.25014 15.6374 7.07602 15.6374Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
    <div ref="chatContainer" class="chat-container" @scroll="handleUserScroll">
      <div v-if="chatMessages.length === 0" class="suggested-questions">
        <p class="suggestion-title">Suggested Questions:</p>
        <div
          v-for="(question, index) in suggestedQuestions"
          :key="index"
          @click="handleSuggestedQuestion(question)"
          class="suggestion-item"
        >
          {{ question }}
        </div>
      </div>
      <div
        v-for="(message, index) in chatMessages"
        :key="index"
        :class="['chat-message', message.sender]"
      >
        <div
          class="message-text"
          v-if="!message.isTyping"
          v-html="message.text"
        ></div>
        <div class="typing-indicator" v-else>
          <span></span><span></span><span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AiChat } from "@/api/APIs";

export default {
  name: "ReportSearch",
  props: {
    companyName: { type: String, required: true },
    countryName: { type: String, required: true },
  },
  data() {
    return {
      searchQuery: "",
      chatMessages: [],
      isUserScrolling: false,
      isDragging: false,
      startX: 0,
      startWidth: 0,
      suggestedQuestions: [
        "What is the revenue trend of the company?",
        "Who are the top competitors?",
        "What are the key financial highlights?",
        "Is the company profitable?",
      ],
    };
  },
  methods: {
    handleSuggestedQuestion(question) {
      this.searchQuery = question;
      this.performSearch();
    },
    startDrag(event) {
      this.isDragging = true;
      this.startX = event.clientX;
      this.startWidth = this.$el.offsetWidth;
      document.addEventListener("mousemove", this.onDrag);
      document.addEventListener("mouseup", this.stopDrag);
    },
    onDrag(event) {
      if (this.isDragging) {
        const deltaX = event.clientX - this.startX;
        const newWidth = this.startWidth - deltaX;
        const minWidth = parseFloat(getComputedStyle(this.$el).minWidth);
        const maxWidth = parseFloat(getComputedStyle(this.$el).maxWidth);

        if (newWidth >= minWidth && newWidth <= maxWidth) {
          this.$el.style.width = `${newWidth}px`;
        }
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("mouseup", this.stopDrag);
    },
    async performSearch() {
      if (this.searchQuery.trim() === "") return;

      this.chatMessages.push({ text: this.searchQuery, sender: "user" });
      this.scrollToBottom(true);

      const queryText = `${this.searchQuery} (Company: ${this.companyName}, Country: ${this.countryName}). Respond naturally and conversationally. Retrieve and answer using only the latest record for ${this.companyName} in ${this.countryName}.Do NOT mention databases, sources, or internal processing details. Keep it simple and user-friendly. Do not process details of any other company, even if explicitly mentioned.`;

      this.searchQuery = "";
      this.chatMessages.push({ isTyping: true, sender: "ai" });
      this.scrollToBottom(true);

      try {
        let response = await AiChat({
          query: queryText,
          companyName: this.companyName,
        });
        this.chatMessages = this.chatMessages.filter((msg) => !msg.isTyping);

        if (response) {
          this.simulateTyping(this.formatResponse(response.data.response));
        } else {
          this.$refs.toastContainer.addToast("", { type: "error" });
        }
      } catch (error) {
        this.chatMessages = this.chatMessages.filter((msg) => !msg.isTyping);
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      }
    },
    simulateTyping(fullText) {
      let messageObj = { text: "", sender: "ai" };
      this.chatMessages.push(messageObj);
      let index = 0;

      const interval = setInterval(() => {
        if (index < fullText.length) {
          this.chatMessages[this.chatMessages.length - 1].text +=
            fullText[index];
          this.chatMessages = [...this.chatMessages];

          if (!this.isUserScrolling) {
            this.scrollToBottom();
          }
          index++;
        } else {
          clearInterval(interval);
        }
      }, 20);
    },
    scrollToBottom(forceScroll = false) {
      this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        if (!container) return;

        const isUserAtBottom =
          container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50;

        if (isUserAtBottom || forceScroll) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },
    handleUserScroll() {
      const container = this.$refs.chatContainer;
      if (!container) return;
      this.isUserScrolling =
        container.scrollHeight - container.scrollTop >
        container.clientHeight + 50;
    },
    formatResponse(responseText) {
      responseText = responseText.replace(
        /\*\*(.*?)\*\*/g,
        "<strong>$1</strong>"
      );
      responseText = responseText.replace(
        /(?:^|\n)- (.*?)(?:\n|$)/g,
        "<ul><li>$1</li></ul>"
      );
      responseText = responseText.replace(/^### (.*$)/gm, "<h3>$1</h3>");
      responseText = responseText.replace(/^## (.*$)/gm, "<h2>$1</h2>");
      responseText = responseText.replace(/^# (.*$)/gm, "<h1>$1</h1>");
      responseText = responseText.replace(/\n/g, "<br>");
      return responseText;
    },
  },
};
</script>

<style scoped>
.report-search {
  background-color: #fff;
  border-left: 1px solid #e2e8f0;
  display: flex;
  min-width: 15vw;
  max-width: 24vw;
  flex-direction: column;
  width: 15vw;
  padding: 24px;
  position: sticky;
  top: 0;
  height: -webkit-fill-available;
}
.drag-handle {
  position: absolute;
  left: -13px;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 20px;
  cursor: ew-resize;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2e8f0;
}
.arrow-bubble {
  color: #9aa4b1;
  font-size: 16px;
}
.search-title {
  color: #142d4d;
  font-family: Figtree;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 6px;
  text-align: left;
  line-height: 28px;
}
.search-description {
  color: #1c1c1c;
  font-family: Figtree;
  font-size: 12px;
  margin: 0 0 16px;
  text-align: left;
}
.search-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  margin-bottom: 8px;
  border: 1px solid #d3f1ff;
  padding: 6px 8px;
  border-radius: 8px;
}
.search-input {
  anchor-name: none;
  font-family: "Montserrat-Medium", sans-serif;
  flex: 1;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  height: 24px;
  width: -webkit-fill-available !important;
  max-height: 50px !important;
  min-height: 24px !important;
  outline: none;
  margin: 0;
  box-shadow: none;
  padding: 7px 7px;
  resize: none;
}
.search-button {
  display: flex;
  width: 30px;
  height: 30px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: #fd4c8c;
  outline: none;
  border: none;
  cursor: pointer;
}
.search-icon {
  width: 20px;
  height: 20px;
}
.chat-container {
  height: -webkit-fill-available;
  overflow-y: auto;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}
.chat-message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  max-width: 80%;
}
.chat-message.user {
  align-self: flex-end;
  background-color: #89d3f6;
  color: #1c1c1c;
  text-align: left;
  overflow-wrap: anywhere;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
}
.chat-message.ai {
  align-self: flex-start;
  background-color: #ddf4fe;
  color: #1c1c1c;
  text-align: left;
  overflow-wrap: anywhere;
}
.message-text {
  font-size: 14px;
}
.typing-indicator {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 10px;
}
.typing-indicator span {
  width: 4px;
  height: 4px;
  background-color: #64748b;
  border-radius: 50%;
  animation: typing 1.5s infinite ease-in-out;
}
.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}
.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}
.suggested-questions {
  background: #D3F1FF;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.suggestion-title {
  color: #1c1c1c;
  text-align: left;
  overflow-wrap: anywhere;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.24px;
}

.suggestion-item {
  background: white;
  color: #1c1c1c;
  text-align: left;
  overflow-wrap: anywhere;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
  padding: 8px;
  margin: 5px 0;
  border-radius: 5px;
  border: 0.5px solid #224096;
  cursor: pointer;
  transition: background 0.3s;
}

.suggestion-item:hover {
  background: #bbdefb;
}
@keyframes typing {
  0%,
  100% {
    opacity: 0.3;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(-5px);
  }
}
</style>
