<template>
  <div
    :class="[
      'table-container',
      { 'logged-in': !isMobile, 'not-logged-in': isMobile },
    ]"
  >
    <div class="frame-35732">
      <FilterComponent
        :searchByColumn="searchByColumn"
        :filters="filters"
        :selectedFilters="selectedFilters"
        :searchQuery="searchQuery"
        :byColumn="byColumn"
        @apply-filters="applyFilters"
        @clear-filters="ClearFilters"
        @handleSearch="handleSearch"
        @refresh="refreshData"
      />
      <div class="tables-wrapper">
        <div class="table-wrapper" v-if="searchResults?.data?.length > 0">
          <!-- Desktop -->
          <table
            id="searchResultTable"
            v-if="!mobile && searchResults?.data?.length > 0"
          >
            <thead style="background: #11365a">
              <tr>
                <th v-for="col in tabelColumns" :key="col">
                  {{ col }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(searchResult, index) in searchResults?.data"
                :key="index"
              >
                <tr
                  id="result-wrapper"
                  :style="[
                    {
                      backgroundColor: index % 2 === 0 ? '#ffffff' : '#ecf4ff',
                    },
                  ]"
                  @click="toggleRow(index)"
                >
                  <td>{{ searchResult?.internal_order_id }}</td>
                  <td>
                    {{ formatDate(formattedDueDate(searchResult?.created_at)) }}
                  </td>
                  <td>
                    {{
                      formatDate(
                        formattedDueDate(searchResult?.report_delivery_by)
                      )
                    }}
                  </td>
                  <td>{{ searchResult?.subject_name }}</td>
                  <td>{{ searchResult?.country }}</td>
                  <td>
                    {{ searchResult?.order_status }}
                  </td>
                  <td>{{ searchResult?.entity_name }}</td>
                  <td>
                    {{
                      searchResult?.order_source === null
                        ? "-"
                        : searchResult?.order_source
                    }}
                  </td>
                </tr>
                <tr v-if="selectedRow === index" class="expanded-row">
                  <td colspan="8">
                    <div class="expanded-content">
                      <p>
                        <strong>Other Company Name:</strong>
                        {{ orderDetails?.otherCompanyName || "-" }}
                      </p>
                      <p v-if="!orderDetails?.otherAddresses?.company_address">
                        <strong>Company Address:</strong>
                        {{ searchResult.Company_Address }}
                      </p>
                      <p v-if="orderDetails?.otherAddresses?.company_address">
                        <strong>Company Address:</strong>
                        {{
                          orderDetails?.otherAddresses?.company_address.value
                        }}
                      </p>
                      <p
                        v-if="
                          orderDetails?.otherAddresses?.registered_addresses
                        "
                      >
                        <strong>Registered Address:</strong>
                        {{
                          orderDetails?.otherAddresses?.registered_addresses
                            .value
                        }}
                      </p>
                      <p v-if="orderDetails?.otherAddresses?.address_by_customer">
                        <strong>Address by Customer:</strong>
                        {{
                          orderDetails?.otherAddresses?.address_by_customer.value
                        }}
                      </p>
                      <p v-if="
                          orderDetails?.otherAddresses?.moreAddresses?.length >
                          0
                        ">
                        <strong>More Addresses:</strong>
                        <ul style="margin: 0; width: 100%; padding-inline-start: 30px;">
                        <li
                      v-for="(address, index) in orderDetails?.otherAddresses?.moreAddresses"
                      :key="index"
                      style="list-style-type: disc !important"
                      >
                        {{ address }}
                    </li>
                  </ul>
                      </p>
                      <p>
                        <strong>More Information:</strong>
                        {{
                          searchResult.more_information === null
                            ? "-"
                            : searchResult.more_information
                        }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Customer Reference:</strong>
                        {{
                          searchResult.reference_number === null
                            ? "-"
                            : searchResult.reference_number
                        }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Company Id: </strong>
                        {{ searchResult?.subject_companyId || "-" }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Order Contact Number: </strong>
                        {{ searchResult?.order_contact_number || "-" }}
                      </p>
                      <CopyButton :fileName="orderDetails?.referenceNumber"/>
                      <p style="width: 33%; display: inline-block">
                        <strong>Hashed Order Id: </strong>
                        {{ searchResult?.hashed_order_id || "-" }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Client Name: </strong>
                        {{ searchResult?.mis_client_name || "-" }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Client country: </strong>
                        {{ searchResult?.mis_client_country || "-" }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Original Order Date: </strong>
                        {{
                          formatDate(
                            formattedDueDate(searchResult?.original_order_date)
                          ) || "-"
                        }}
                      </p>
                      <p
                        style="
                          width: 33%;
                          display: inline-flex;
                          align-items: flex-start;
                          padding-right: 10px;
                        "
                      >
                        <strong>Report Type:</strong>
                        {{ searchResult.report_type }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Delivery Date:</strong>
                        {{
                          formatDate(
                            formattedDueDate(searchResult.report_delivery_by)
                          )
                        }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Customer Type:</strong>
                        {{
                          searchResult.customer_type === null
                            ? "-"
                            : searchResult.customer_type
                        }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Delivery To: </strong>
                        {{
                          searchResult.order_email === null
                            ? "-"
                            : searchResult.order_email
                        }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Delivery To (CC): </strong>
                        {{ searchResult?.order_email_cc || "-" }}
                      </p>
                      <p style="display: flex; align-items: center">
                        <strong>PI File:</strong>
                        <span
                          @click="
                            searchResult.pi_file_key === null
                              ? none
                              : downloadReport(
                                  searchResult?.pi_file_key,
                                  searchResult?.internal_order_id
                                )
                          "
                          style="
                            margin-left: 10px;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                          "
                        >
                          {{
                            searchResult.pi_file_key === null
                              ? "-"
                              : `${searchResult.internal_order_id}_PI_file`
                          }}
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            style="margin-left: 10px"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            v-if="searchResult.pi_file_key !== null"
                          >
                            <path
                              d="M20.2502 13.5001C20.4492 13.5001 20.6399 13.5791 20.7806 13.7198C20.9212 13.8604 21.0002 14.0512 21.0002 14.2501V19.5001C21.0002 19.8979 20.8422 20.2794 20.5609 20.5608C20.2796 20.8421 19.8981 21.0001 19.5002 21.0001H4.50024C4.10242 21.0001 3.72089 20.8421 3.43958 20.5607C3.15828 20.2794 3.00024 19.8979 3.00024 19.5001L3.00024 14.2501C3.00024 14.0512 3.07926 13.8604 3.21991 13.7198C3.36057 13.5791 3.55133 13.5001 3.75024 13.5001C3.94916 13.5001 4.13992 13.5791 4.28057 13.7198C4.42123 13.8604 4.50024 14.0512 4.50024 14.2501L4.50024 19.5001H19.5002V14.2501C19.5002 14.0512 19.5793 13.8604 19.7199 13.7198C19.8606 13.5791 20.0513 13.5001 20.2502 13.5001Z"
                              fill="#11365a"
                            />
                            <path
                              d="M7.71962 11.0301L11.4696 14.7801C11.5393 14.8499 11.622 14.9052 11.713 14.9429C11.8041 14.9807 11.9017 15.0001 12.0002 15.0001C12.0988 15.0001 12.1964 14.9807 12.2874 14.9429C12.3785 14.9052 12.4612 14.8499 12.5309 14.7801L16.2809 11.0301C16.4216 10.8894 16.5007 10.6985 16.5007 10.4995C16.5007 10.3005 16.4216 10.1096 16.2809 9.96889C16.1401 9.82816 15.9493 9.74909 15.7502 9.74909C15.5512 9.74909 15.3603 9.82816 15.2196 9.96889L12.7502 12.4392V3.74951C12.7502 3.5506 12.6712 3.35983 12.5306 3.21918C12.3899 3.07853 12.1992 2.99951 12.0002 2.99951C11.8013 2.99951 11.6106 3.07853 11.4699 3.21918C11.3293 3.35983 11.2502 3.5506 11.2502 3.74951V12.4392L8.78087 9.96889C8.64014 9.82816 8.44927 9.74909 8.25024 9.74909C8.05122 9.74909 7.86035 9.82816 7.71962 9.96889C7.57889 10.1096 7.49983 10.3005 7.49983 10.4995C7.49983 10.6985 7.57889 10.8894 7.71962 11.0301Z"
                              fill="#11365a"
                            />
                          </svg>
                        </span>
                      </p>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div
          class="separation-line"
          v-if="searchResults?.data?.length > 0"
        ></div>
        <div class="pagination" v-if="searchResults?.data?.length > 0">
          <div class="table-title2">
            {{ (currentPage - 1) * pageSize + 1 }}-
            {{ Math.min(currentPage * pageSize, searchResults?.totalCount) }}
            of {{ searchResults?.totalCount }} Orders / Page Size:
            <input
              type="number"
              class="page-size"
              autocomplete="off"
              v-model.number="pageSizeInput"
              @input="checkPageSizeChange"
            />
            <button
              type="button"
              v-if="pageSizeChanged"
              @click="applyPageSize"
              class="apply-btn"
            >
              Apply
            </button>
          </div>
          <vs-pagination
            :total-pages="searchResults?.noOfPages"
            :page-gap="mobile ? 1 : 2"
            :current-page="currentPage"
            @change="changePage"
          ></vs-pagination>
        </div>
      </div>
    </div>
    <div class="no-data-found" v-if="searchResults?.data?.length === 0">
      <NoData />
      <span>No data found</span>
    </div>
  </div>
  <Toastify ref="toastContainer" />
  <div class="loader-wrapper" v-if="isLoading">
    <LoaderAnimation />
  </div>
</template>
<script>
import NoData from "@/assets/Animation/NoData/NoData.vue";
import Toastify from "@/components/Toastify.vue";
import VsPagination from "@vuesimple/vs-pagination";
import LoaderAnimation from "@/assets/Animation/LoaderAnimation.vue";
import { GetMasterOrders, GetColumnList } from "@/api/APIs";
import FilterComponent from "@/components/FilterComponent.vue";
import { mapGetters } from "vuex";
import CopyButton from "@/components/CopyButton.vue";

export default {
  name: "BankUserOrders",
  components: {
    NoData,
    Toastify,
    VsPagination,
    LoaderAnimation,
    FilterComponent,
    CopyButton,
  },
  props: {
    property1: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      tabelColumns: [
        "Int. ID",
        "Order Date",
        "Due Date",
        "Company Name",
        "Country",
        "Order Status",
        "Customer Name",
        "Source",
      ],
      filters: [],
      searchResults: null,
      searchQuery: "",
      selectedResult: null,
      isMobile: window.innerWidth < 1023,
      mobile: window.innerWidth <= 767,
      currentPage: 1,
      isLoading: false,
      selectedRow: null,
      selectedRowInitialValues: {
        Allocated_to: null,
        MD_Maker_DeliveryDate: null,
        Checker_name: null,
      },
      refreshButtonVisible: false,
      pageSize: 20,
      pageSizeInput: 20,
      pageSizeChanged: false,
      showFilters: false,
      changesMade: false,
      searchByColumn: [],
      byColumn: null,
      selectedFilters: {},
      selectedFilterParams: "",
    };
  },
  watch: {
    "searchResults.data": {
      handler() {
        if (this.selectedRow == null) return;
        const selectedResult = this.searchResults?.data[this.selectedRow];
        if (!selectedResult) return;

        this.changesMade =
          selectedResult.Allocated_to !==
            this.selectedRowInitialValues.Allocated_to ||
          selectedResult.MD_Maker_DeliveryDate !==
            this.selectedRowInitialValues.MD_Maker_DeliveryDate ||
          selectedResult.Checker_name !==
            this.selectedRowInitialValues.Checker_name;
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["getFiltersForScreen"]),

    savedFilters() {
      return this.getFiltersForScreen(this.$route.name);
    },
  },
  created() {
    this.pageSizeInput = this.pageSize;
    Promise.all([this.GetFilters()]);
  },
  mounted() {
    this.currentPage = 1;
    window.addEventListener("resize", this.handleResponsive);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResponsive);
  },

  methods: {
    getOrderStatusStyle(status) {
      switch (status) {
        // case 'Order Received':
        //   return {
        //     background: '#FFE3E3',
        //     color: '#A64545',
        //   };
        // case 'Completed':
        //   return {
        //     background: '#E3FFE3',
        //     color: '#45A645',
        //   };
        // case 'In Progress':
        //   return {
        //     background: '#FFF3E3',
        //     color: '#A67A45',
        //   };
        // case 'Cancelled':
        //   return {
        //     background: '#E3E3FF',
        //     color: '#4545A6',
        //   };
        default:
          return {
            background: "transparent",
            color: "#11365A",
          };
      }
    },
    handleSearch(query, column) {
      (this.byColumn = column), (this.searchQuery = query);
      const newQuery = query.trim();
      if (newQuery.length === 0 || newQuery.length > 2) {
        this.MyOrdersList();
      }
    },
    applyFilters(filterData) {
      this.selectedFilterParams = filterData;
      if (this.selectedFilterParams) {
        this.MyOrdersList();
      }
    },
    ClearFilters() {
      this.selectedFilters = { order: "DESC" };
      this.selectedFilterParams = `order=DESC&`;
      this.MyOrdersList();
    },
    formattedDueDate(date) {
      return date ? new Date(date).toISOString().split("T")[0] : null;
    },
    // async downloadReport(fileKey, fileName) {
    //   const fileExtension = fileKey.split('.').pop();

    //   const capitalizedFileName = `${fileName}_PI_file`
    //     ?.toLowerCase()
    //     .replace(/^(.)|\s+(.)/g, function ($1) {
    //       return $1.toUpperCase();
    //     });
    //   let payload = {
    //     fileKey: fileKey,
    //   };
    //   try {
    //     const response = await DownloadReportPDF(payload);
    //     const link = document.createElement("a");
    //     link.href = window.URL.createObjectURL(
    //       new File([response.data], `${capitalizedFileName}.${fileExtension}`, {
    //         type: `application/${fileExtension}`,
    //       })
    //     );
    //     link.download = `${capitalizedFileName}.${fileExtension}`;
    //     document.body.append(link);
    //     link.click();
    //     link.remove();
    //     setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    //   } catch (error) {
    //     if (error.response && error.response.status !== 403) {
    //       return this.$refs.toastContainer.addToast(
    //         "Oops! Something went wrong, Please try again later",
    //         {
    //           type: "error",
    //         }
    //       );
    //     }
    //   }
    // },
    refreshData() {
      this.MyOrdersList();
    },
    saveChanges() {
      this.changesMade = false;
    },
    cancelChanges() {
      const selectedResult = this.searchResults?.data[this.selectedRow];
      if (!selectedResult) return;

      selectedResult.Allocated_to = this.selectedRowInitialValues.Allocated_to;
      selectedResult.MD_Maker_DeliveryDate =
        this.selectedRowInitialValues.MD_Maker_DeliveryDate;
      selectedResult.Checker_name = this.selectedRowInitialValues.Checker_name;

      this.changesMade = false;
    },
    checkPageSizeChange() {
      this.pageSizeChanged = this.pageSize !== this.pageSizeInput;
    },
    async applyPageSize() {
      this.pageSize = this.pageSizeInput;
      this.pageSizeChanged = false;
      await this.MyOrdersList();
    },
    toggleRow(index) {
      if (this.changesMade) {
        this.cancelChanges();
      }
      if (this.selectedRow === index) {
        this.selectedRow = null;
      } else {
        this.selectedRow = index;

        const selectedResult = this.searchResults.data[index];
        this.selectedRowInitialValues = {
          Allocated_to: selectedResult.Allocated_to,
          MD_Maker_DeliveryDate: selectedResult.MD_Maker_DeliveryDate,
          Checker_name: selectedResult.Checker_name,
        };
      }
    },
    changePage(page) {
      this.currentPage = page;
      this.MyOrdersList();
    },
    handleResponsive() {
      this.isMobile = window.innerWidth < 1023;
    },
    detectSafari() {
      const ua = navigator.userAgent.toLowerCase();
      return ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1;
    },
    formatDate(dateString) {
      if (!dateString || dateString === undefined || dateString === "") {
        return null;
      }
      const date = new Date(dateString);
      const options = {
        day: "2-digit",
        month: "short",
        year: "numeric",
      };

      let formattedDate = date.toLocaleDateString("en-US", options);
      formattedDate;

      return formattedDate;
    },
    async MyOrdersList() {
      this.isLoading = true;
      this.selectedRow = null;
      (this.selectedRowInitialValues = {
        Allocated_to: null,
        MD_Maker_DeliveryDate: null,
        Checker_name: null,
      }),
        (this.searchResults = null);
      try {
        let response = await GetMasterOrders(
          this.searchQuery,
          this.selectedFilterParams,
          this.currentPage,
          this.pageSize,
          this.byColumn,
          false,
          ""
        );
        this.searchResults = response;
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      }
      this.isLoading = false;
    },
    async GetFilters() {
      const payload = {
        page: "MASTER",
      };
      try {
        let response = await GetColumnList(payload);
        if (response.message === "SUCCESS") {
          this.searchByColumn = response.data;
          this.filters = response.filterList;
          this.selectedFilters = { order: "DESC" };
          this.selectedFilterParams = `order=DESC&`;
          if (this.savedFilters && Object.keys(this.savedFilters).length == 0) {
            this.MyOrdersList();
          }
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      }
    },
  },
};
</script>
<style scoped>
.no-data-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16%;
  color: #11365a;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  gap: 2vh;
}
.logged-in {
  margin-left: 16vw;
  padding-top: 45px !important;
}
.not-logged-in {
  width: 100%;
}
.no-data-found span {
  margin-left: 7%;
  margin-top: 1rem;
  line-height: 300%;
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Light", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
  opacity: 0.7;
}
#searchResultTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  font-family: "Montserrat-Regular", sans-serif;
}
.tables-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 16px;
  background-color: white;
  position: relative;
}
.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 73vh;
  overflow-y: scroll;
  overflow-x: scroll;
  /* scrollbar-width: none;
  -ms-overflow-style: none; */
  gap: 15px;
}
#searchResultTable th {
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  color: white;
  padding: 10px 16px;
}
#searchResultTable th:nth-child(2),
#searchResultTable th:nth-child(3) {
  min-width: 115px;
}
#searchResultTable td {
  text-align: left;
  padding: 10px 16px;
  color: #11365a;
  font-size: 13px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.16px;
}
.table-container,
.table-container * {
  box-sizing: border-box;
}
.table-container {
  padding-top: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.separation-line {
  width: 100%;
  height: 2px;
  opacity: 0.2;
  background: #3e4855;
}
.frame-35732 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 15px;
  padding: 1.2rem 2rem 0rem 2rem;
}
.loader-wrapper {
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 8%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}
.table-title2 {
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
}
.expanded-row {
  background-color: #f9f9f9;
}
.expanded-content {
  padding: 10px;
  background-color: #f1f8ff;
}
.expanded-content p {
  margin: 5px 0;
}
select {
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
  border-radius: 8px;
  outline: none;
  height: 25px;
  color: #11365a;
}
input[type="date"] {
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
  border-radius: 8px;
  outline: none;
  height: 25px;
  color: #11365a;
}
.filters-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  gap: 20px;
  align-items: stretch;
}
.search-wrapper {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.search-bar {
  padding: 8px 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  outline: none;
  margin: 0;
  height: auto;
  width: 40vw;
  box-shadow: none;
}
.page-size {
  padding: 4px 5px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.16px;
  outline: none;
  width: 80px;
  margin-right: 10px;
}
.btn {
  background: #11365a;
  border: none;
  outline: none;
  height: auto;
  border-radius: 16px;
  padding: 0 5px;
  cursor: pointer;
}
.apply-btn {
  border: 1px solid #11365a;
  background: white;
  color: #11365a;
  outline: none;
  border-radius: 8px;
  width: auto;
}
.filter-btn {
  margin-left: 10px;
  background-color: #11365a;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}
.icon-image {
  width: 35px;
  height: 35px;
}
.buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 13px;
}
.save-btn {
  background: #11365a;
  border: none;
  outline: none;
  height: 25px;
  border-radius: 8px;
}
.search-by-column {
  height: 100%;
  border-radius: 16px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  padding-left: 10px;
  cursor: pointer;
}

.filter-menu {
  background: white;
  border-radius: 13px;
  border: 1px solid #ccc;
  padding: 15px;
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
h4 {
  margin-block-start: 0;
  margin-block-end: 1.33em;
}

.filter-group {
  margin-bottom: 10px;
}

.filter-group label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}
.status-badge {
  border-radius: 13px;
  text-align: center;
  padding: 0 5px;
}

@media only screen and (max-width: 767px) {
  .table-container {
    padding-top: 1rem;
  }
  .tables-wrapper {
    padding: 1.2rem 1rem;
    border-top-right-radius: 0px;
  }
  .table-wrapper {
    gap: 1rem;
  }
  #searchResultDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    width: 100%;
    gap: 20px;
  }
  .result-wrapper {
    display: flex;
    padding: 21.634px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 21.634px;
    border: 1.352px solid rgba(0, 0, 0, 0.15);
    background: #fff;
  }
  .frame-35732 {
    padding: 0.5rem 0.8rem;
  }
  .table-wrapper {
    height: 67vh;
  }
  .pagination {
    justify-content: center;
    flex-direction: column;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .table-container {
    padding-top: 0;
  }
  .table-wrapper {
    height: 73vh;
  }
}
@media only screen and (min-width: 1156px) {
}
</style>
<style>
.vs-pagination {
  /* padding: 0 !important; */
  gap: 5px;
}
.vs-pagination > li.vs-pagination--active a {
  color: white;
  background: #11365a;
}
.vs-pagination > li > a {
  border: 1px solid rgba(17, 54, 90, 0.5);
  border-radius: 5px;
  /* color: #11365A; */
}
</style>
