<template>
  <TabTitle :title="data?.title || 'Finance'" :lastUpdated="data?.lastUpdated || 'N/A'"
    :completionPercentage="data?.completion_percentage || 0" />
  <!-- Lock Section -->
  <div class="company-report lock-wrapper" v-if="isLocked">
    <LockAnimation />
  </div>
  <!-- If financials are not available, show a message -->
  <div class="company-report" v-else-if="!financialsAvailable">
    <p class="no-financials-message">
      Financial data is not available for this company.
    </p>
  </div>
  <!-- Unlock Section -->
  <!-- Navigation Tabs -->
   <div v-else>
  <div class="financial-dashboard__tabs">
    <div class="financial-tab-container">
      <button v-for="tab in tabs" :key="tab" :class="[
        'financial-dashboard__tab-btn',
        { 'is-active': selectedTab === tab },
      ]" @click="selectedTab = tab">
        {{ tab }}
      </button>
    </div>
  </div>
  <div class="company-report">
    <div class="financial-dashboard__container">

      <div class="content">
        <template v-if="selectedTab === 'Overview' && data">
          <Overview :chartData="data.finance" :metrics="data?.metrics" />
        </template>
        <template v-if="selectedTab === 'Income Statement'">
          <IncomeStatement :data="data?.finance_income_statement" />
        </template>
        <template v-if="selectedTab === 'Balance Sheet'">
          <BalanceSheet :data="data?.finance_balance_sheet" />
        </template>
        <template v-if="selectedTab === 'Ratios'">
          <Ratios :data="data?.finance_ratios" />
        </template>
        <template v-if="selectedTab === 'Auditors'">
          <Auditors :data="data?.finance_auditors" />
        </template>
        <template v-if="selectedTab === 'Banking'">
          <Banking :data="data?.finance_banking" />
        </template>
      </div>
    </div>
  </div>
</div>
  <div class="loader-wrapper" v-if="isLoading">
    <LoaderAnimation />
  </div>
  <Toastify ref="toastContainer" />

</template>

<script>
import TabTitle from "@/components/CompanyDashboard/tabs/TabTitle.vue";
import Overview from "./FinanceTabs/Overview.vue";
import IncomeStatement from "./FinanceTabs/IncomeStatement.vue";
import BalanceSheet from "./FinanceTabs/BalanceSheet.vue";
import Ratios from "./FinanceTabs/Ratios.vue";
import Auditors from "./FinanceTabs/Auditors.vue";
import Banking from "./FinanceTabs/Banking.vue";
import { GetCompanyData, GetCredenceSearchCompanyData } from "@/api/APIs";
import LoaderAnimation from "@/assets/Animation/LoaderAnimation.vue";
import Toastify from "@/components/Toastify.vue";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import LockAnimation from "@/assets/Animation/LockAnimation.vue";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "FinanceInfo",
  components: {
    LoaderAnimation,
    TabTitle,
    Overview,
    IncomeStatement,
    BalanceSheet,
    Ratios,
    Auditors,
    Banking,
    Toastify,
    LockAnimation,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    lastUpdated: {
      type: String,
      default: "N/A",
    },
    financialsAvailable: {
      type: Boolean,
      required: true,
    },
    lockedTabs: { type: Object, required: true },
  },
  data() {
    return {
      data: null,
      selectedTab: "Overview",
      isLoading: false,
      metrics: {
        DSO: "60 Days",
        DPO: "30 Days",
        Inventory: "80 Days",
      },
      tabs: [
        "Overview",
        "Income Statement",
        "Balance Sheet",
        "Ratios",
        "Auditors",
        "Banking",
      ],
      isEditable: false,
      isLocked: false
    };
  },
  mounted() {
    if (this.lockedTabs?.length && this.lockedTabs?.includes('FINANCE')) {
      this.isLocked = true
    }
  },
  watch: {
    selectedTab: {
      immediate: true,
      handler() {
        this.GetCompanyDetails();
      },
    },
  },
  computed: {
    companyName() {
      const comp = this.$route.params.company
        ? decodeURIComponent(this.$route.params.company)
        : "";
      return comp;
    },
    countryName() {
      return this.$route.params.country
        ? decodeURIComponent(this.$route.params.country)
        : "";
    },
  },
  methods: {
    async GetCompanyDetails() {
      if (this.selectedTab !== "Overview") {
        this.isLoading = true;
      }
      const formattedTab =
        this.selectedTab === "Overview"
          ? ["FINANCE"]
          : [`FINANCE_${this.selectedTab.toUpperCase().replace(/\s+/g, "_")}`];
      const payload = {
        companyName: this.companyName,
        modules: formattedTab,
        countryCode: this.countryName,
      };
      const dataSource = sessionStorage.getItem("dataSource");
      try {
        let response;
        if (dataSource === "credenceSearch") {
          response = await GetCredenceSearchCompanyData(payload);
        } else {
          response = await GetCompanyData(payload);
        }
        if (response) {
          this.data = response.data.data;
        } else {
          this.$refs.toastContainer.addToast("", { type: "error" });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.company-report {
  font-family: system-ui, -apple-system, sans-serif;
  padding: 10px 20px 20px 20px;
  color: #333;
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.lock-wrapper {
  z-index: 1000;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #edfaff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  font-family: Figtree;
  font-size: 14px;
}

.no-financials-message {
  text-align: center;
  font-family: Figtree;
  font-size: 14px;
  color: #666;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  margin-top: 20px;
}

.financial-dashboard {
  padding: 24px;
  background-color: #ffffff;
  /* min-height: 100vh; */
}

.financial-dashboard__container {
  /* max-width: 1200px;
  margin: 0 auto; */
}

.financial-dashboard__toggle {
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
}

.financial-dashboard__toggle-btn {
  padding: 8px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background: #ffffff;
  color: #020617;
  cursor: pointer;
  transition: all 0.2s;
  width: auto;
}

.financial-tab-container {
  display: flex;
  margin: 18px 24px 0px;
  gap: 25px;
}

.financial-dashboard__toggle-btn.is-active {
  background: #000000;
  color: #ffffff;
}

.financial-dashboard__tabs {
  display: flex;
  gap: 25px;
  margin-bottom: 15px;
  /* border-bottom: 1px solid #e2e8f0; */
  padding-bottom: 5px;
  background-color: #e0f5ff
}

.financial-dashboard__tab-btn {
  background-color: #e0f5ff;
  border: none;
  color: #47444e;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0px;
  font-size: 14px;
  font-weight: 600 !important;
  font-family: "Figtree", sans-serif;
  width: auto;
}

.financial-dashboard__tab-btn.is-active {
  color: #47444e;
  position: relative;
}

.financial-dashboard__tab-btn.is-active::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000000;
}

.financial-dashboard__charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
}

.financial-dashboard__bottom-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.financial-dashboard__chart-container {
  padding: 24px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: #ffffff;
}

.financial-dashboard__chart-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}

.financial-dashboard__metrics {
  padding: 24px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: #ffffff;
}

.financial-dashboard__table {
  width: 100%;
  border-collapse: collapse;
}

.financial-dashboard__table th,
.financial-dashboard__table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e2e8f0;
}

.financial-dashboard__table th {
  font-weight: 500;
  color: #64748b;
}

.loader-wrapper {
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 8%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

@media (max-width: 768px) {

  .financial-dashboard__charts,
  .financial-dashboard__bottom-section {
    grid-template-columns: 1fr;
  }

  .financial-dashboard__tabs {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
}
</style>
