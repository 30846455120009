<template>
  <div class="banking">
    <!-- Auditors Table -->
    <div class="report-section-wrapper finance-section-wrapper">
      <div class="report-section">
        <div>
          <div class="section-header">
            <div
              style="display: flex; width: 20px"
              v-html="financeIcons?.auditors"
            ></div>
            <h2>Auditors - Standalone</h2>
          </div>
        </div>
        <div class="finance-section-wrapper-1">
          <div class="finance-section-content" v-if="data?.auditors?.length">
            <div class="table-container">
              <table class="data-table">
                <thead>
                  <tr>
                    <th
                      v-for="(key, index) in tableHeaders(data?.auditors)"
                      :key="'header-' + index"
                    >
                      {{ formatLabel(key) }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(auditor, rowIndex) in data?.auditors"
                    :key="'row-' + rowIndex"
                  >
                    <td
                      v-for="(key, colIndex) in tableHeaders(data?.auditors)"
                      :key="'col-' + rowIndex + '-' + colIndex"
                    >
                      {{ auditor[key] || "-" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>No Data Found</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import financeIcons from "@/assets/DigitalProduct/FinanceIcons.js";

export default {
  name: "AuditorsSection",
  props: {
    data: {
      type: Object,
      required: false,
      default: () => ({ auditors: [] }),
    },
  },
  computed: {
    financeIcons() {
      return financeIcons;
    },
  },
  methods: {
    tableHeaders(dataValue) {
      if (!Array.isArray(dataValue) || !dataValue.length) return [];

      const allKeys = Object.keys(dataValue[0]);

      return allKeys.filter((key) =>
        dataValue.some(
          (auditor) => auditor[key] !== null && auditor[key] !== ""
        )
      );
    },
    formatLabel(key) {
      return key
        .replace(/_/g, " ")
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    },
  },
};
</script>

<style scoped>
.banking {
  font-family: system-ui, -apple-system, sans-serif;
  color: #333;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 0;
}
.report-section-wrapper {
  display: flex;
  padding: 24px 20px;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;
  border: 1.5px solid #e0f5ff;
  /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25); */
}
.finance-section-wrapper {
  flex-direction: column;
}
.report-section {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: -webkit-fill-available;
}
.section-header {
  display: flex;
  align-items: center;
  gap: 12px;
}
.finance-section-content {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 20px;
}
h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  font-family: Figtree;
  color: #142d4d;
}

h3 {
  margin: 0 0 12px 0;
  font-size: 16px;
  font-weight: 600;
}
.table-container {
  width: -webkit-fill-available;
  overflow-x: auto;
  box-shadow: 0px 0px 12.7px 0px #e0f5ff;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #e0f5ff;
}

.data-table td {
  text-align: left;
  font-size: 14px;
  color: #101828;
  font-family: "Figtree";
}

.data-table th {
  background-color: #e0f5ff;
  font-family: "Figtree";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #1c1c1c;
  border-bottom: 1px solid rgba(34, 64, 150, 0.2);
}

.data-table tr:last-child td {
  border-bottom: none;
}
</style>
