<template>
  <div class="filters-wrapper">
    <div class="search-wrapper">
      <select v-model="localByColumn" class="search-by-column">
        <option value="null" disabled>Search by column</option>
        <option
          v-for="(column, index) in searchByColumn"
          :key="index"
          :value="column.value"
        >
          {{ column.name }}
        </option>
      </select>
      <input
        type="text"
        placeholder="Search"
        class="search-bar"
        autocomplete="off"
        id="searchQuery"
        v-model="localSearchQuery"
        @keydown="debouncedSearch"
      />
      <div style="position: relative; align-content: center">
        <img
          src="@/assets/Icons/setting.webp"
          alt="Filter Icon"
          class="icon-image"
          @click="toggleFilters"
        />
        <span v-if="selectedFilterCount > 0" class="filter-count-bubble">
          {{ selectedFilterCount }}
        </span>
        <div v-if="showFilters" class="filter-menu">
          <div
            v-for="(filter, index) in filters"
            :key="index"
            class="filter-group"
          >
            <label>{{ filter.displayName }}:</label>
            <select v-model="localSelectedFilters[filter.searchParam]">
              <option value="" selected>Select {{ filter.displayName }}</option>
              <option
                v-for="value in filter.values"
                :key="value"
                :value="value"
              >
                {{ value }}
              </option>
            </select>
          </div>
          <div v-if="$route.path !== '/internal-db'" class="filter-group">
            <label>Date Range (Due Date):</label>
            <div class="date-range">
              <input type="date" v-model="startDate" :max="endDate" />
              <span style="margin: 0 8px">to</span>
              <input type="date" v-model="endDate" :min="startDate" />
            </div>
          </div>
          <div class="filter-action">
            <button
              type="submit"
              class="btn"
              :disabled="isApplyDisabled"
              :style="{ opacity: isApplyDisabled ? '0.6' : '1', flexGrow: 1 }"
              @click="applyFilters"
            >
              Apply
            </button>
            <button
              type="submit"
              class="clear-btn"
              style="flex-grow: 1"
              @click="isApplyDisabled ? (showFilters = false) : clearFilter()"
            >
              {{ isApplyDisabled ? "Close" : "Clear" }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      type="submit"
      :disabled="refreshButtonVisible"
      :style="{
        opacity: refreshButtonVisible ? '0.6' : '1',
        height: 'auto',
        borderRadius: '16px',
      }"
      class="btn"
      @click="refreshData"
    >
      <i
        class="fa-solid fa-arrows-rotate"
        style="color: #ffffff; margin-right: 5px"
      ></i>
      Refresh
    </button>
  </div>
  <div
    class="selected-filters-capsules"
    v-if="selectedFilterCount > 0"
  >
    <strong style="font-size: 14px">Filter by:</strong>
    <template
      v-for="(filterValue, filterKey) in appliedFilters"
      :key="filterKey"
    >
      <span v-if="filterValue" class="filter-capsule">
        {{ getDisplayName(filterKey) }}: {{ filterValue }}
        <i
          class="fa-solid fa-xmark close-icon"
          @click="removeFilter(filterKey)"
        ></i>
      </span>
    </template>
    <div class="filter-capsule" v-if="dateFilter">
      {{ dateFilter }}
      <i class="fa-solid fa-xmark close-icon" @click="removeDateFilter()"></i>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    searchByColumn: Array,
    filters: Array,
    selectedFilters: Object,
    searchQuery: String,
    byColumn: String,
  },
  data() {
    return {
      localByColumn: this.byColumn,
      showFilters: false,
      localSearchQuery: this.searchQuery,
      localSelectedFilters: { ...this.selectedFilters },
      appliedFilters: { ...this.selectedFilters },
      debouncedSearch: debounce(this.handleKeyDown, 300),
      startDate: null,
      endDate: null,
      dateFilter: null,
    };
  },
  computed: {
    ...mapGetters(["getFiltersForScreen"]),

    savedFilters() {
      return this.getFiltersForScreen(this.$route.name);
    },
    selectedFilterCount() {
      const appliedFiltersCount = Object.values(this.appliedFilters).filter(
        (filter) => filter !== ""
      ).length;

      const dateFilterCount = this.dateFilter ? 1 : 0;

      return appliedFiltersCount + dateFilterCount;
    },
    isApplyDisabled() {
      return !(
        Object.values(this.localSelectedFilters).some(Boolean) ||
        (this.startDate &&
          this.endDate &&
          this.startDate !== "" &&
          this.endDate !== "")
      );
    },
  },
  mounted() {
    if (this.savedFilters && Object.keys(this.savedFilters).length > 0) {
      setTimeout(() => {
        this.localSelectedFilters = { ...this.savedFilters };
        this.applyFilters();
      }, 500);
    }
  },
  watch: {
    searchQuery(newVal) {
      this.localSearchQuery = newVal;
    },
    selectedFilters(newVal) {
      this.localSelectedFilters = { ...newVal };
    },
    searchByColumn(newValue) {
      if (newValue && newValue.length) {
        this.localByColumn = newValue[0].value;
      }
    },
  },
  methods: {
    ...mapActions(["saveFilters", "clearFilters"]),
    getDisplayName(filterKey) {
      const filter = this.filters.find((f) => f.searchParam === filterKey);
      return filter ? filter.displayName : filterKey;
    },
    removeFilter(filterKey) {
      this.localSelectedFilters[filterKey] = "";
      this.appliedFilters[filterKey] = "";
      this.applyFilters();
    },
    removeDateFilter() {
      this.startDate = null;
      this.endDate = null;
      this.dateFilter = null;
      this.applyFilters();
    },
    handleKeyDown() {
      this.showFilters = false;
      this.$emit("handleSearch", this.localSearchQuery, this.localByColumn);
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    applyFilters() {
      this.appliedFilters = { ...this.localSelectedFilters };
      this.saveFilters({
        screenName: this.$route.name,
        filters: this.appliedFilters,
      });
      this.showFilters = false;
      let dateFilters = "";
      if (this.startDate && this.endDate) {
        dateFilters = `fromDate=${this.startDate}&toDate=${this.endDate}`;
        this.dateFilter = `Date Range: ${this.startDate} to ${this.endDate}`;
      }
      const otherFilters =
        Object.keys(this.localSelectedFilters)
          .filter((key) => this.localSelectedFilters[key] !== "")
          .map((key) => `${key}=${this.localSelectedFilters[key]}`)
          .join("&") + "&";
      const combinedFilters = [dateFilters, otherFilters]
        .filter(Boolean)
        .join("&");
      this.$emit("apply-filters", combinedFilters);
    },
    clearFilter() {
      this.showFilters = false;
      this.startDate = null;
      this.endDate = null;
      this.localSelectedFilters = {};
      this.appliedFilters = {};
      if (this.savedFilters && Object.keys(this.savedFilters).length > 0) {
        this.clearFilters(this.$route.name);
      }
      this.$emit("clear-filters");
      Object.keys(this.localSelectedFilters).forEach((key) => {
        this.localSelectedFilters[key] = "";
      });
    },
    refreshData() {
      this.showFilters = false;
      this.$emit("refresh");
    },
    closeFilter() {
      if (this.showFilters) {
        this.isFilterOpen = false;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.saveFilters({
      screenName: this.$route.name,
      filters: this.localSelectedFilters,
    });
    next();
  },
};
</script>

<style scoped>
.no-data-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16%;
  color: #11365a;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  gap: 2vh;
}
.logged-in {
  margin-left: 16vw;
  padding-top: 45px !important;
  transition: margin-left 0.3s ease;
}
.logged-in.collapsed {
  margin-left: 60px;
}
.not-logged-in {
  width: 100%;
}
.no-data-found span {
  margin-left: 7%;
  margin-top: 1rem;
  line-height: 300%;
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Light", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
  opacity: 0.7;
}
#searchResultTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  font-family: "Montserrat-Regular", sans-serif;
}
.tables-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 16px;
  background-color: white;
  position: relative;
}
.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 73vh;
  overflow-y: scroll;
  overflow-x: scroll;
  /* scrollbar-width: none;
  -ms-overflow-style: none; */
  gap: 15px;
}
#searchResultTable th {
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  color: white;
  padding: 10px 16px;
  min-width: 115px;
}
#searchResultTable th:first-child {
  min-width: 50px;
}
#searchResultTable td {
  text-align: left;
  padding: 10px 16px;
  color: #11365a;
  font-size: 13px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.16px;
}
.table-container,
.table-container * {
  box-sizing: border-box;
}
.table-container {
  padding-top: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.separation-line {
  width: 100%;
  height: 2px;
  opacity: 0.2;
  background: #3e4855;
}
.frame-35732 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 10px;
  padding: 1.2rem 2rem 0rem 2rem;
}
.loader-wrapper {
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 8%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}
.table-title2 {
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
}
.expanded-row {
  background-color: #f9f9f9;
}
.expanded-content {
  padding: 10px;
  background-color: #f1f8ff;
}
.expanded-content p {
  margin: 5px 0;
}
select {
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
  border-radius: 8px;
  outline: none;
  height: 25px;
  color: #11365a;
}
input[type="date"] {
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
  border-radius: 8px;
  outline: none;
  height: 25px;
  color: #11365a;
}
.filters-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  gap: 20px;
  align-items: stretch;
}
.search-wrapper {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.search-bar {
  padding: 8px 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  outline: none;
  margin: 0;
  height: auto;
  width: 40vw;
  box-shadow: none;
}
.page-size {
  padding: 4px 5px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.16px;
  outline: none;
  width: 80px;
  margin-right: 10px;
}
.btn {
  background: #11365a;
  border-radius: 8px;
  border: none;
  outline: none;
  /* height: auto; */
  cursor: pointer;
  padding: 10px 0;
}
.clear-btn {
  border: 1px solid #11365a;
  background: white;
  color: #11365a;
  outline: none;
  border-radius: 8px;
  padding: 10px 0;
  cursor: pointer;
}
.filter-btn {
  margin-left: 10px;
  background-color: #11365a;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}
.icon-image {
  width: 35px;
  height: 35px;
}
.buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 13px;
}
.save-btn {
  background: #11365a;
  border: none;
  outline: none;
  height: 25px;
  border-radius: 8px;
}
.search-by-column {
  height: 100%;
  border-radius: 16px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  padding-left: 10px;
  cursor: pointer;
}

.filter-menu {
  background: white;
  border-radius: 13px;
  border: 1px solid #ccc;
  padding: 15px;
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
h4 {
  margin-block-start: 0;
  margin-block-end: 1.33em;
}

.filter-group {
  margin-bottom: 10px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.filter-group label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
  font-size: 12px;
}
.filter-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  width: 100%;
}
.date-range {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filter-count-bubble {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: bold;
}
.selected-filters-capsules {
  display: flex;
  gap: 8px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.filter-capsule {
  display: inline-flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 16px;
  padding: 4px 12px;
  font-size: 13px;
  color: #11365a;
  position: relative;
}

.close-icon {
  margin-left: 8px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .table-container {
    padding-top: 1rem;
  }
  .tables-wrapper {
    padding: 1.2rem 1rem;
    border-top-right-radius: 0px;
  }
  .table-wrapper {
    gap: 1rem;
  }
  #searchResultDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    width: 100%;
    gap: 20px;
  }
  .result-wrapper {
    display: flex;
    padding: 21.634px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 21.634px;
    border: 1.352px solid rgba(0, 0, 0, 0.15);
    background: #fff;
  }
  .frame-35732 {
    padding: 0.5rem 0.8rem;
  }
  .table-wrapper {
    height: 67vh;
  }
  .pagination {
    justify-content: center;
    flex-direction: column;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .table-container {
    padding-top: 0;
  }
  .table-wrapper {
    height: 73vh;
  }
}
@media only screen and (min-width: 1156px) {
}
</style>
