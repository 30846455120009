const SummaryInfoIcons = {
  shareholdingInfo: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 2C13.3661 2 14.7178 2.2799 15.9715 2.82245C17.2253 3.365 18.3545 4.15868 19.2897 5.15455L12 12V2Z" stroke="#1C1B1F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  note: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 4C1.25 3.58579 1.58579 3.25 2 3.25H22C22.4142 3.25 22.75 3.58579 22.75 4V19C22.75 19.4142 22.4142 19.75 22 19.75H14.8107L12.5303 22.0303C12.2374 22.3232 11.7626 22.3232 11.4697 22.0303L9.18934 19.75H2C1.58579 19.75 1.25 19.4142 1.25 19V4ZM2.75 4.75V18.25H9.5C9.69891 18.25 9.88968 18.329 10.0303 18.4697L12 20.4393L13.9697 18.4697C14.1103 18.329 14.3011 18.25 14.5 18.25H21.25V4.75H2.75Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 10.75C12.4142 10.75 12.75 11.0858 12.75 11.5V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V11.5C11.25 11.0858 11.5858 10.75 12 10.75Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 7.25C12.4142 7.25 12.75 7.58579 12.75 8V8.5C12.75 8.91421 12.4142 9.25 12 9.25C11.5858 9.25 11.25 8.91421 11.25 8.5V8C11.25 7.58579 11.5858 7.25 12 7.25Z" fill="#1C1B1F"/>
</svg>`,
  shareCapital: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 2.5C1.25 2.08579 1.58579 1.75 2 1.75H22C22.4142 1.75 22.75 2.08579 22.75 2.5V8.5C22.75 8.91421 22.4142 9.25 22 9.25H2C1.58579 9.25 1.25 8.91421 1.25 8.5V2.5ZM2.75 3.25V7.75H21.25V3.25H2.75Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8692 12.9692C15.1595 12.6794 15.6289 12.6766 15.9226 12.963L18.1629 15.147C18.4595 15.4361 18.4655 15.911 18.1764 16.2075C17.8872 16.5041 17.4124 16.5102 17.1158 16.221L15.4054 14.5535L11.9073 18.0458C11.6189 18.3338 11.1531 18.3387 10.8587 18.0567L8.10186 15.4166L2.53304 21.0427C2.24164 21.337 1.76678 21.3395 1.47239 21.0481C1.178 20.7567 1.17557 20.2818 1.46696 19.9874L7.55476 13.837C7.84276 13.5461 8.31087 13.5398 8.60654 13.823L11.3665 16.4662L14.8692 12.9692Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M22 7.33594C22.4142 7.33594 22.75 7.67172 22.75 8.08594V21.0859C22.75 21.5002 22.4142 21.8359 22 21.8359C21.5858 21.8359 21.25 21.5002 21.25 21.0859V8.08594C21.25 7.67172 21.5858 7.33594 22 7.33594Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2 7.33594C2.41421 7.33594 2.75 7.67172 2.75 8.08594V15.0859C2.75 15.5002 2.41421 15.8359 2 15.8359C1.58579 15.8359 1.25 15.5002 1.25 15.0859V8.08594C1.25 7.67172 1.58579 7.33594 2 7.33594Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.75781 21.5C5.75781 21.0858 6.0936 20.75 6.50781 20.75H22.0001C22.4143 20.75 22.7501 21.0858 22.7501 21.5C22.7501 21.9142 22.4143 22.25 22.0001 22.25H6.50781C6.0936 22.25 5.75781 21.9142 5.75781 21.5Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 5.5C7.75 5.08579 8.08579 4.75 8.5 4.75H19C19.4142 4.75 19.75 5.08579 19.75 5.5C19.75 5.91421 19.4142 6.25 19 6.25H8.5C8.08579 6.25 7.75 5.91421 7.75 5.5Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 5.49829C4.25 5.08408 4.58579 4.74829 5 4.74829H5.5C5.91421 4.74829 6.25 5.08408 6.25 5.49829C6.25 5.9125 5.91421 6.24829 5.5 6.24829H5C4.58579 6.24829 4.25 5.9125 4.25 5.49829Z" fill="#1C1B1F"/>
</svg>`,
  ultimateParent: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5913 3.37117C11.8398 3.20961 12.1602 3.20961 12.4087 3.37117L22.4087 9.87117C22.6216 10.0095 22.75 10.2461 22.75 10.5V22C22.75 22.4142 22.4142 22.75 22 22.75H2C1.58579 22.75 1.25 22.4142 1.25 22V10.5C1.25 10.2461 1.37841 10.0095 1.59126 9.87117L11.5913 3.37117ZM2.75 10.907V21.25H21.25V10.907L12 4.89451L2.75 10.907Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3636 10.844C10.602 10.9762 10.75 11.2274 10.75 11.5V22C10.75 22.4142 10.4142 22.75 10 22.75C9.58579 22.75 9.25 22.4142 9.25 22V12.8532L6.75 14.4157V22C6.75 22.4142 6.41421 22.75 6 22.75C5.58579 22.75 5.25 22.4142 5.25 22V14C5.25 13.7414 5.38321 13.5011 5.6025 13.364L9.6025 10.864C9.8337 10.7195 10.1251 10.7118 10.3636 10.844Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6364 10.844C13.8749 10.7118 14.1663 10.7195 14.3975 10.864L18.3975 13.364C18.6168 13.5011 18.75 13.7414 18.75 14V22C18.75 22.4142 18.4142 22.75 18 22.75C17.5858 22.75 17.25 22.4142 17.25 22V14.4157L14.75 12.8532V22C14.75 22.4142 14.4142 22.75 14 22.75C13.5858 22.75 13.25 22.4142 13.25 22V11.5C13.25 11.2274 13.398 10.9762 13.6364 10.844Z" fill="#1C1B1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 22C3.25 21.5858 3.58579 21.25 4 21.25H20.5C20.9142 21.25 21.25 21.5858 21.25 22C21.25 22.4142 20.9142 22.75 20.5 22.75H4C3.58579 22.75 3.25 22.4142 3.25 22Z" fill="#1C1B1F"/>
</svg>`,
peopleIcon:`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 2.75C10.2051 2.75 8.75 4.20507 8.75 6C8.75 7.79493 10.2051 9.25 12 9.25C13.7949 9.25 15.25 7.79493 15.25 6C15.25 4.20507 13.7949 2.75 12 2.75ZM7.25 6C7.25 3.37665 9.37665 1.25 12 1.25C14.6234 1.25 16.75 3.37665 16.75 6C16.75 8.62335 14.6234 10.75 12 10.75C9.37665 10.75 7.25 8.62335 7.25 6ZM2.25 22C2.25 16.6152 6.61524 12.25 12 12.25C17.3848 12.25 21.75 16.6152 21.75 22C21.75 22.4142 21.4142 22.75 21 22.75C20.5858 22.75 20.25 22.4142 20.25 22C20.25 17.7943 17.1029 14.3236 13.0353 13.8143L14.7168 19.2794C14.7898 19.5167 14.7407 19.7747 14.5857 19.9685L12.5857 22.4685C12.4433 22.6464 12.2278 22.75 12 22.75C11.7722 22.75 11.5567 22.6464 11.4144 22.4685L9.41436 19.9685C9.25929 19.7747 9.21017 19.5167 9.28317 19.2794L10.9647 13.8143C6.89706 14.3236 3.75 17.7943 3.75 22C3.75 22.4142 3.41421 22.75 3 22.75C2.58579 22.75 2.25 22.4142 2.25 22ZM12 20.7994L13.1665 19.3413L12 15.5503L10.8335 19.3413L12 20.7994Z" fill="#1C1B1F"/>
</svg>
`,
shareholdingIcon:`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 5C4.25 4.58579 4.58579 4.25 5 4.25H19C19.4142 4.25 19.75 4.58579 19.75 5V22C19.75 22.4142 19.4142 22.75 19 22.75H5C4.58579 22.75 4.25 22.4142 4.25 22V5ZM5.75 5.75V21.25H18.25V5.75H5.75Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.4554 1.40398C16.6412 1.54586 16.7502 1.76625 16.7502 2.00001V5.00001C16.7502 5.41422 16.4144 5.75001 16.0002 5.75001C15.586 5.75001 15.2502 5.41422 15.2502 5.00001V2.98194L5.19753 5.72358C4.79791 5.83257 4.3856 5.59696 4.27662 5.19734C4.16763 4.79773 4.40323 4.38542 4.80285 4.27643L15.8028 1.27643C16.0284 1.21493 16.2697 1.26209 16.4554 1.40398Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 10.75C11.3096 10.75 10.75 11.3096 10.75 12C10.75 12.6904 11.3096 13.25 12 13.25C12.6904 13.25 13.25 12.6904 13.25 12C13.25 11.3096 12.6904 10.75 12 10.75ZM9.25 12C9.25 10.4812 10.4812 9.25 12 9.25C13.5188 9.25 14.75 10.4812 14.75 12C14.75 13.5188 13.5188 14.75 12 14.75C10.4812 14.75 9.25 13.5188 9.25 12Z" fill="#1C1B1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 17C9.25 16.5858 9.58579 16.25 10 16.25H14C14.4142 16.25 14.75 16.5858 14.75 17C14.75 17.4142 14.4142 17.75 14 17.75H10C9.58579 17.75 9.25 17.4142 9.25 17Z" fill="#1C1B1F"/>
</svg>
`
};

export default SummaryInfoIcons;
