<template>
  <TabTitle
    :title="data?.title || 'Management'"
    :lastUpdated="data?.lastUpdated || 'N/A'"
    :completionPercentage="data?.completion_percentage || 0"
    :toggleIsEditable="toggleIsEditable"
  />
  <!-- Lock Section -->
  <div class="company-profile lock-wrapper" v-if="isLocked">
    <LockAnimation />
  </div>
  <!-- Unlock Section -->
  <div class="company-profile" v-else>
    <!-- Executive Directors -->
    <div
      class="profile-grid profile-grid-table"
      v-show="
        editedData?.management_director_executiveboard_external_positions
          ?.length
      "
    >
      <div class="name-history-table">
        <div class="frame-group">
          <div class="key-info-wrapper">
            <div
              style="display: contents"
              v-html="shareholdingIcons?.peopleIcon"
            ></div>
            <div class="heading-3">
              Management/ Director/ Executive Board/ External Positions
            </div>
          </div>
        </div>
        <!-- Table -->
        <div class="frame-group">
          <table class="table-container">
            <thead class="table-headers">
              <tr class="table-header-row">
                <th
                  v-for="key in columnKeys(
                    this.data
                      ?.management_director_executiveboard_external_positions
                  )"
                  :key="key"
                  class="table-header-cell"
                >
                  {{ formatColumnName(key) }}
                </th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr
                v-for="(
                  item, index
                ) in data?.management_director_executiveboard_external_positions"
                :key="index"
                class="table-header-row"
              >
                <td
                  v-for="key in columnKeys(
                    this.data
                      ?.management_director_executiveboard_external_positions
                  )"
                  :key="`${index}-${key}`"
                  class="table-header-cell"
                >
                  {{ item[key] || "-" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Shareholding Information -->
    <div
      class="profile-grid profile-grid-table"
      v-show="editedData?.share_holder_information_or_beneficial_owner?.length"
    >
      <div class="name-history-table">
        <div class="frame-group">
          <div class="key-info-wrapper">
            <div
              style="display: contents"
              v-html="shareholdingIcons?.shareholdingIcon"
            ></div>
            <div class="heading-3">
              Shareholding Information/ Beneficial Owner
            </div>
          </div>
        </div>
        <!-- Table -->
        <div class="frame-group">
          <table class="table-container">
            <thead class="table-headers">
              <tr class="table-header-row">
                <th
                  v-for="key in columnKeys(
                    data?.share_holder_information_or_beneficial_owner
                  )"
                  :key="key"
                  class="table-header-cell"
                >
                  {{ formatColumnName(key) }}
                </th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr
                v-for="(
                  item, index
                ) in data?.share_holder_information_or_beneficial_owner"
                :key="index"
                class="table-header-row"
              >
                <td
                  v-for="key in columnKeys(
                    data?.share_holder_information_or_beneficial_owner
                  )"
                  :key="`${index}-${key}`"
                  class="table-header-cell"
                >
                  {{ item[key] || "-" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  
</template>
<script>
import TabTitle from "@/components/CompanyDashboard/tabs/TabTitle.vue";
import ShareholdingInfoIcons from "@/assets/DigitalProduct/ShareholdingInfoIcons.js";
import LockAnimation from "@/assets/Animation/LockAnimation.vue";

export default {
  name: "ManagementInfo",
  components: {
    TabTitle,
    LockAnimation
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    lockedTabs: { type: Object, required: true },
  },
  data() {
    return {
      editedData: { ...this.data },
      isEditable:false,
      isLocked: false
    };
  },
  mounted(){
    if (this.lockedTabs?.length && this.lockedTabs?.includes('MANAGEMENT')) {
      this.isLocked = true
    }
  },
  watch: {
    data: {
      // deep: true,
      handler(newData) {
        this.editedData = { ...newData };
      },
    },
  },
  computed: {
    shareholdingIcons() {
      return ShareholdingInfoIcons;
    },
  },
  methods: {
    formatColumnName(key) {
      return key
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    columnKeys(tableData) {
      if (!tableData || !Array.isArray(tableData) || tableData.length === 0) {
        return [];
      }

      // Collect all possible keys from the dataset
      const keys = new Set();
      tableData.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (item[key] !== undefined && item[key] !== "") {
            keys.add(key);
          }
        });
      });

      // Convert Set to Array
      const allKeys = Array.from(keys);

      // Prioritize certain columns to appear first
      const priorityOrder = [
        "id",
        "class",
        "name",
        "city_or_country",
        "number_of_shares",
        "interest_percentage",
      ];

      allKeys.sort((a, b) => {
        const indexA = priorityOrder.indexOf(a);
        const indexB = priorityOrder.indexOf(b);

        if (indexA === -1 && indexB === -1) return a.localeCompare(b);
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
      });

      return allKeys;
    },
  },
};
</script>
<style scoped>
.company-profile {
  padding: 20px;
  color: #333;
  display: flex;
  gap: 12px;
  flex-direction: column;
  font-family: system-ui, -apple-system, sans-serif;
}
.lock-wrapper {
  z-index: 1000;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #edfaff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  font-family: Figtree;
  font-size: 14px;
}
.profile-grid {
  display: flex;
  padding: 24px 20px;
  gap: 20px;
  align-self: stretch;
  /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25); */
  width: 100%;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  border: 1.5px solid #e0f5ff;
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  color: #142d4d;
}

.profile-grid-table {
  display: flex;
  flex-direction: column;
}

.name-history-table {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.table-container {
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 12.7px #e0f5ff;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 5px;
  text-align: left;
  font-size: 18px;
  color: #101828;
  font-family: "Figtree", sans-serif;
}

.table-header-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 24px;
  text-align: left;
  font-size: 14px;
  color: #1c1c1c;
  font-family: "Figtree", sans-serif;
}

.table-header-cell {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #101828;
  padding-right: 35px;
}

.table-headers {
  width: 100%;
  position: relative;
  background-color: #e0f5ff;
  border-bottom: 1px solid rgba(34, 64, 150, 0.2);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #1c1c1c;
  font-family: "Figtree", sans-serif;
}

.table-body {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #e0f5ff;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #1c1c1c;
  font-family: "Figtree", sans-serif;
}

.frame-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.key-info-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: -webkit-fill-available;
}

.heading-3 {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Figtree;
  color: #142d4d;
  text-align: left;
}

/* ------------------------------- */
.report-section-wrapper {
  display: flex;
  padding: 24px 20px;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;
  border: 1.5px solid #e0f5ff;
  /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25); */
}

.finance-section-wrapper {
  flex-direction: column;
}

.report-section {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: -webkit-fill-available;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.finance-section-content {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 20px;
}

.table-containers {
  width: -webkit-fill-available;
  overflow-x: auto;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #e0f5ff;
}

.data-table td {
  text-align: left;
  font-size: 14px;
  color: #101828;
  font-family: "Figtree";
}

.data-table th {
  background-color: #e0f5ff;
  font-family: "Figtree";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #1c1c1c;
  border-bottom: 1px solid rgba(34, 64, 150, 0.2);
}

.data-table tr:last-child td {
  border-bottom: none;
}

.data-table tr:hover {
  background-color: #f9f9f9;
}
</style>
