<template>
    <div class="animation-wrapper">
      <div class="lock-icon-container">
        <!-- SVG Lock Icon -->
        <div
            style="display: contents"
            v-html="CompanyIcons?.lockIconSvg"
          ></div>
          
      </div>
      <span class="lock-text">The content is locked. Please unlock to access.</span>
    </div>
  </template>
  
  <script>
  import CompanyInfoIcons from "@/assets/DigitalProduct/CompanyInfoIcons.js";

  export default {
    mounted() {
      console.log("Lock icon component loaded.");
    },
    computed: {
    CompanyIcons() {
      return CompanyInfoIcons;
    },
  },
  };
  </script>
  
  <style scoped>
  .animation-wrapper {
    display: flex;
    border-radius: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: transparent;
  }
  
  .lock-icon-container {
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .lock-icon {
    color: #333; /* Adjust color as needed */
    width: 60px;
    height: 60px;
  }
  .lock-text{
    color: #fd4c8c;
  }
  
  @media only screen and (max-width: 767px) {
    .lock-icon {
      width: 40px;
      height: 40px;
    }
  }
  </style>
  