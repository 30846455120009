<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <circle
      cx="20"
      cy="20"
      r="20"
      :fill="
        status === 'success'
          ? '#E3FFEF'
          : status === 'warning'
          ? '#FFFCEE'
          : '#E0F5FF'
      "
    />
    <!-- circle check -->
    <g
      id="Check circle"
      transform="translate(8, 8)"
      v-if="status === 'success'"
    >
      <path
        id="Icon"
        d="M22 11.0799V11.9999C21.9988 14.1563 21.3005 16.2545 20.0093 17.9817C18.7182 19.7088 16.9033 20.9723 14.8354 21.5838C12.7674 22.1952 10.5573 22.1218 8.53447 21.3744C6.51168 20.6271 4.78465 19.246 3.61096 17.4369C2.43727 15.6279 1.87979 13.4879 2.02168 11.3362C2.16356 9.18443 2.99721 7.13619 4.39828 5.49694C5.79935 3.85768 7.69279 2.71525 9.79619 2.24001C11.8996 1.76477 14.1003 1.9822 16.07 2.85986M22 3.99986L12 14.0099L9.00001 11.0099"
        stroke="#27BE69"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <!-- warning -->
    <g
      transform="translate(8, 8)"
      v-if="status === 'warning'"
    >
      <g clip-path="url(#clip0_2070_1567)">
        <path
          d="M12.5 1.75C12.7676 1.75 13.015 1.89261 13.1491 2.12422L24.1491 21.1242C24.2834 21.3563 24.2837 21.6424 24.1497 21.8746C24.0158 22.1069 23.7681 22.25 23.5 22.25H1.5C1.23189 22.25 0.984172 22.1069 0.850257 21.8746C0.716341 21.6424 0.716598 21.3563 0.85093 21.1242L11.8509 2.12422C11.985 1.89261 12.2324 1.75 12.5 1.75ZM2.80083 20.75H22.1992L12.5 3.9969L2.80083 20.75Z"
          fill="#EFCC3A"
        />
        <path
          d="M12.5 16.75C12.9142 16.75 13.25 17.0858 13.25 17.5V18C13.25 18.4142 12.9142 18.75 12.5 18.75C12.0858 18.75 11.75 18.4142 11.75 18V17.5C11.75 17.0858 12.0858 16.75 12.5 16.75Z"
          fill="#EFCC3A"
        />
        <path
          d="M12.4994 8.75026C12.9136 8.74992 13.2497 9.08542 13.25 9.49964L13.2542 14.4994C13.2545 14.9136 12.919 15.2497 12.5048 15.25C12.0906 15.2504 11.7545 14.9148 11.7542 14.5006L11.75 9.50088C11.7497 9.08667 12.0852 8.7506 12.4994 8.75026Z"
          fill="#EFCC3A"
        />
      </g>
      <defs>
        <clipPath id="clip0_2070_1567">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </g>
    <!-- info -->
    <g
      transform="translate(8, 8)"
      v-if="status === 'info'"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.3986 4.3986C6.34317 2.45406 9.0317 1.25 12 1.25C14.9683 1.25 17.6568 2.45406 19.6014 4.3986C21.5459 6.34317 22.75 9.0317 22.75 12C22.75 14.9683 21.5459 17.6568 19.6014 19.6014C17.6568 21.5459 14.9683 22.75 12 22.75C9.0317 22.75 6.34317 21.5459 4.3986 19.6014C2.45406 17.6568 1.25 14.9683 1.25 12C1.25 9.0317 2.45407 6.34316 4.3986 4.3986ZM12 2.75C9.4455 2.75 7.13403 3.78452 5.45926 5.45926C3.78451 7.13404 2.75 9.4455 2.75 12C2.75 14.5545 3.78452 16.866 5.45926 18.5407C7.13403 20.2155 9.4455 21.25 12 21.25C14.5545 21.25 16.866 20.2155 18.5407 18.5407C20.2155 16.866 21.25 14.5545 21.25 12C21.25 9.4455 20.2155 7.13403 18.5407 5.45926C16.866 3.78452 14.5545 2.75 12 2.75Z"
        fill="#0172F4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 5.5C12.6903 5.5 13.25 6.05965 13.25 6.75C13.25 7.44035 12.6903 8 12 8C11.3097 8 10.75 7.44035 10.75 6.75C10.75 6.05965 11.3097 5.5 12 5.5Z"
        fill="#0172F4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5 10C10.5 9.58579 10.8358 9.25 11.25 9.25H12.25C12.6642 9.25 13 9.58579 13 10V17C13 17.4142 12.6642 17.75 12.25 17.75C11.8358 17.75 11.5 17.4142 11.5 17V10.75H11.25C10.8358 10.75 10.5 10.4142 10.5 10Z"
        fill="#0172F4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.75 17C9.75 16.5858 10.0858 16.25 10.5 16.25H14C14.4142 16.25 14.75 16.5858 14.75 17C14.75 17.4142 14.4142 17.75 14 17.75H10.5C10.0858 17.75 9.75 17.4142 9.75 17Z"
        fill="#0172F4"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
      validator: (value) => ['success', 'warning', 'info'].includes(value),
    },
  },
};
</script>