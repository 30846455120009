<template>
  <p class="wrapper-class">
    <strong>Reference Number: </strong>
    <span class="wrapper">
      <span>{{ fileName || "-" }}</span>
      <Dropdown
        :triggers="['hover']"
        :shown="tooltipVisible"
        :autoHide="false"
        style="display: flex"
        v-if="fileName"
      >
        <span
          ><i class="fa-regular fa-copy fa-lg" @click="copyFileName"></i
        ></span>
        <template #popper>
          <div class="tooltip-content">
            <div :class="['tooltip-message', { success: copied }]">
              <img
                :src="require(`@/assets/Icons/check.webp`)"
                alt=""
                class="icon-image"
                v-if="copied"
              />
              {{ copied ? "Copied" : "Copy Value" }}
            </div>
          </div>
        </template>
      </Dropdown>
    </span>
  </p>
</template>

<script>
import { Dropdown } from "floating-vue";

export default {
  name: "CopyButton",
  components: {
    Dropdown,
  },
  props: {
    fileName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      copied: false,
      tooltipVisible: false,
    };
  },
  mounted() {},
  methods: {
    copyFileName() {
      navigator.clipboard.writeText(this.fileName).then(() => {
        this.copied = true;
        this.tooltipVisible = true;
        setTimeout(() => {
          this.copied = false;
          this.tooltipVisible = false;
        }, 2000);
      });
    },
  },
};
</script>

<style scoped>
.wrapper-class {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: 33%;
}
.wrapper {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  overflow-wrap: anywhere;
  margin-right: 5px;
}
.download-pi-file {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #585858;
  width: fit-content;
}
.tooltip-message {
  color: white;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}
.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.icon-image {
  width: 20px;
  height: 20px;
}
</style>
