<template>
  <div
    :class="[
      'table-container',
      { 'logged-in': !isMobile, 'not-logged-in': isMobile },
    ]"
  >
    <div class="frame-35732">
      <FilterComponent
        :searchByColumn="searchByColumn"
        :filters="filters"
        :selectedFilters="selectedFilters"
        :searchQuery="searchQuery"
        :byColumn="byColumn"
        @apply-filters="applyFilters"
        @clear-filters="ClearFilters"
        @handleSearch="handleSearch"
        @refresh="refreshData"
      />
      <div class="tables-wrapper">
        <div class="table-wrapper" v-if="searchResults?.data?.length > 0">
          <!-- Desktop -->
          <table
            id="searchResultTable"
            v-if="!mobile && searchResults?.data?.length > 0"
          >
            <thead style="background: #11365a">
              <tr>
                <th v-for="col in tabelColumns" :key="col">
                  {{ col }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(searchResult, index) in searchResults?.data"
                :key="index"
              >
                <tr
                  id="result-wrapper"
                  :style="[
                    {
                      backgroundColor: index % 2 === 0 ? '#ffffff' : '#ecf4ff',
                    },
                  ]"
                  @click="toggleRow(index)"
                >
                  <td>{{ searchResult?.Internal_order_id }}</td>
                  <td>
                    {{
                      formatDate(formattedDueDate(searchResult?.Request_date))
                    }}
                  </td>
                  <td>
                    {{
                      formatDate(
                        formattedDueDate(
                          searchResult.MD_Maker_DeliveryDate !== null
                            ? searchResult.MD_Maker_DeliveryDate
                            : searchResult?.Due_date
                        )
                      )
                    }}
                  </td>
                  <td>{{ searchResult?.Report_name }}</td>
                  <td>{{ searchResult?.Country }}</td>
                  <td>
                    {{
                      searchResult.Checker_name !== null
                        ? searchResult.Checker_name
                        : "-"
                    }}
                  </td>
                  <td>{{ searchResult?.Bank_name }}</td>
                  <td>
                    {{
                      searchResult?.order_source === null
                        ? "-"
                        : searchResult?.order_source
                    }}
                  </td>
                </tr>
                <tr v-if="selectedRow === index" class="expanded-row">
                  <td colspan="8">
                    <div class="expanded-content">
                      <p>
                        <strong>Company Address:</strong>
                        {{ searchResult.Company_Address }}
                      </p>
                      <p>
                        <strong>Order Status:</strong>
                        <select
                          v-model="searchResult.Order_status"
                          :style="{
                            marginLeft: '15px',
                            color:
                              searchResult.Order_status === null
                                ? '#757575'
                                : '#11365A',
                          }"
                        >
                          <option value="null" disabled>
                            Select Order Status
                          </option>
                          <option
                            v-for="(status, index) in orderStatus"
                            :key="index"
                            :value="status"
                          >
                            {{ status }}
                          </option>
                        </select>
                      </p>
                      <p
                        style="
                          width: 33%;
                          display: inline-flex;
                          align-items: flex-start;
                          padding-right: 10px;
                        "
                      >
                        <strong>Report Type:</strong>
                        {{ orderDetails?.reportType || "-" }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Company Id:</strong>
                        {{
                          searchResult.Company_Id !== null
                            ? searchResult.Company_Id
                            : "-"
                        }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Allocated to:</strong>
                        {{
                          searchResult.Allocated_to !== null
                            ? searchResult.Allocated_to
                            : "-"
                        }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Checker:</strong>
                        {{
                          searchResult.Checker_name !== null
                            ? searchResult.Checker_name
                            : "-"
                        }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Maker Status:</strong>
                        {{
                          searchResult.Status_from_Handler !== null
                            ? searchResult.Status_from_Handler
                            : "-"
                        }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Urgent/ Repeat:</strong>
                        {{
                          searchResult.Repeat_Urgent !== null
                            ? searchResult.Repeat_Urgent
                            : "-"
                        }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Trade Data Shared:</strong>
                        {{
                          searchResult.Trade_date_shared_status !== null
                            ? searchResult.Trade_date_shared_status
                            : "-"
                        }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Financials Updated (Company):</strong>
                        {{
                          searchResult.Financial_Updated_Company !== null
                            ? searchResult.Financial_Updated_Company
                            : "-"
                        }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Financials Updated (Parent):</strong>
                        {{
                          searchResult.Financial_Updated_Parent !== null
                            ? searchResult.Financial_Updated_Parent
                            : "-"
                        }}
                      </p>
                      <p
                        style="
                          width: 100%;
                          display: flex;
                          align-items: flex-start;
                        "
                      >
                        <strong>Remarks:</strong>
                        <textarea
                          class="maker-remark"
                          placeholder="Add Remarks"
                          v-model.trim="searchResult.IMP_Remarks"
                          style="flex-grow: 1; resize: none; height: 50px"
                        ></textarea>
                      </p>
                      <ReportFileName
                        :fileName="searchResult.report_file_name"
                      />
                      <DownloadPIFile
                        :piFileKey="searchResult.pi_file_key"
                        :internalOrderId="searchResult.Internal_order_id"
                      />
                      <div class="buttons-wrapper" v-if="changesMade">
                        <button
                          type="submit"
                          class="save-btn"
                          @click="saveChanges"
                        >
                          Save
                        </button>
                        <button
                          type="submit"
                          style="
                            background: white;
                            border: 1px solid #11365a;
                            color: #11365a;
                          "
                          class="save-btn"
                          @click="cancelChanges"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div
          class="separation-line"
          v-if="searchResults?.data?.length > 0"
        ></div>
        <div class="pagination" v-if="searchResults?.data?.length > 0">
          <div class="table-title2">
            {{ (currentPage - 1) * pageSize + 1 }}-
            {{ Math.min(currentPage * pageSize, searchResults?.totalCount) }}
            of {{ searchResults?.totalCount }} Orders / Page Size:
            <input
              type="number"
              class="page-size"
              autocomplete="off"
              v-model.number="pageSizeInput"
              @input="checkPageSizeChange"
            />
            <button
              type="button"
              v-if="pageSizeChanged"
              @click="applyPageSize"
              class="apply-btn"
            >
              Apply
            </button>
          </div>

          <vs-pagination
            :total-pages="searchResults?.noOfPages"
            :page-gap="mobile ? 1 : 2"
            :current-page="currentPage"
            @change="changePage"
          ></vs-pagination>
        </div>
      </div>
    </div>
    <div class="no-data-found" v-if="searchResults?.data?.length === 0">
      <NoData />
      <span>No data found</span>
    </div>
  </div>
  <Toastify ref="toastContainer" />
  <div class="loader-wrapper" v-if="isLoading">
    <LoaderAnimation />
  </div>
</template>
<script>
import NoData from "@/assets/Animation/NoData/NoData.vue";
import Toastify from "@/components/Toastify.vue";
import VsPagination from "@vuesimple/vs-pagination";
import LoaderAnimation from "@/assets/Animation/LoaderAnimation.vue";
import {
  GetOrders,
  GetColumnList,
  UpdateOrder,
  GetMasterOrderDetails,
} from "@/api/APIs";
import FilterComponent from "@/components/FilterComponent.vue";
import DownloadPIFile from "@/components/DownloadPIFile.vue";
import ReportFileName from "@/components/ReportFileName.vue";
import { mapGetters } from "vuex";

export default {
  name: "ReadyToShare",
  components: {
    NoData,
    Toastify,
    VsPagination,
    LoaderAnimation,
    FilterComponent,
    DownloadPIFile,
    ReportFileName,
  },
  props: {
    property1: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      tabelColumns: [
        "Int. ID",
        "Order Date",
        "Due Date",
        "Company Name",
        "Country",
        "Checker",
        "Customer Name",
        "Source",
      ],
      searchResults: [],
      searchQuery: "",
      selectedResult: null,
      isMobile: window.innerWidth < 1023,
      mobile: window.innerWidth <= 767,
      currentPage: 1,
      isLoading: false,
      selectedRow: null,
      refreshButtonVisible: false,
      pageSize: 20,
      pageSizeInput: 20,
      pageSizeChanged: false,
      showFilters: false,
      selectedCompanies: [],
      makerStatus: ["Working", "Done"],
      selectedRowInitialValues: {
        Order_status: null,
        //   Checker_name: null,
        IMP_Remarks: null,
      },
      changesMade: false,
      searchByColumn: [],
      byColumn: null,
      orderStatus: [],
      filters: [],
      selectedFilters: {},
      selectedFilterParams: "",
      orderDetails: null,
    };
  },
  watch: {
    "searchResults.data": {
      handler() {
        if (this.selectedRow == null) return;
        const selectedResult = this.searchResults?.data[this.selectedRow];
        if (!selectedResult) return;
        this.changesMade =
          // selectedResult.Order_status !==
          //   this.selectedRowInitialValues.Order_status ||
          selectedResult.Order_status !==
          this.selectedRowInitialValues.Order_status;
        selectedResult.IMP_Remarks !==
          this.selectedRowInitialValues.IMP_Remarks;
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["getFiltersForScreen"]),

    savedFilters() {
      return this.getFiltersForScreen(this.$route.name);
    },
  },
  created() {
    this.pageSizeInput = this.pageSize;
    Promise.all([this.GetFilters()]);
  },
  mounted() {
    this.currentPage = 1;
    window.addEventListener("resize", this.handleResponsive);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResponsive);
  },

  methods: {
    async GetDetails(orderId) {
      const payload = {
        internalOrderId: orderId,
      };
      try {
        let response = await GetMasterOrderDetails(payload);
        if (response) {
          this.orderDetails = response;
        } else {
          this.$refs.toastContainer.addToast("", { type: "error" });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      }
    },
    getOrderStatusStyle(status) {
      switch (status) {
        case "Request Received":
          return {
            background: "#FFE3E3",
            color: "#A64545",
          };
        case "Delivered":
          return {
            background: "#E3FFE3",
            color: "#45A645",
          };
        case "Clarification pending from bank":
          return {
            background: "#FFF3E3",
            color: "#A67A45",
          };
        case "With the checker":
          return {
            background: "#daeeff",
            color: "#025d93",
          };
        case "Final Check":
          return {
            background: "#ebdaff",
            color: "#54038c",
          };
        case "Ready_To_Share":
          return {
            background: "#c0d5ff",
            color: "#134cbb",
          };
        case "To_ First Level Check":
          return {
            background: "#ffd6fb",
            color: "#a61d97",
          };
        case "Cancelled":
          return {
            textDecoration: "line-through",
          };
        default:
          return {
            background: "transparent",
            color: "#11365A",
          };
      }
    },
    handleSearch(query, column) {
      (this.byColumn = column), (this.searchQuery = query);
      const newQuery = query.trim();
      if (newQuery.length === 0 || newQuery.length > 2) {
        this.MyOrdersList();
      }
    },
    applyFilters(filterData) {
      this.selectedFilterParams = filterData;
      if (this.selectedFilterParams) {
        this.MyOrdersList();
      }
    },
    ClearFilters() {
      this.selectedFilters = { order: "DESC" };
      this.selectedFilterParams = `order=DESC&`;
      this.MyOrdersList();
    },
    formattedDueDate(date) {
      return date ? new Date(date).toISOString().split("T")[0] : null;
    },
    refreshData() {
      this.MyOrdersList();
    },
    handleKeyDown() {
      const query = this.searchQuery.trim();
      if (query.length === 0 || query.length >= 2) {
        this.MyOrdersList();
      }
    },
    async saveChanges() {
      const selectedResult = this.searchResults?.data[this.selectedRow];
      const payload = {
        internalOrderId: selectedResult?.Internal_order_id,
        updateObject: {},
      };

      Object.keys(this.selectedRowInitialValues).forEach((key) => {
        if (selectedResult[key] !== this.selectedRowInitialValues[key]) {
          payload.updateObject[key] = selectedResult[key];
        }
      });
      try {
        let response = await UpdateOrder(payload);
        if (response.message !== "FAILURE") {
          this.$refs.toastContainer.addToast(response.message, {
            type: "info",
          });
          this.MyOrdersList("save");
        } else {
          this.$refs.toastContainer.addToast(
            "Failed to save the changes. Please try again later.",
            { type: "error" }
          );
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      }
      this.changesMade = false;
    },
    cancelChanges() {
      const selectedResult = this.searchResults?.data[this.selectedRow];
      if (!selectedResult) return;
      selectedResult.Order_status = this.selectedRowInitialValues.Order_status;
      selectedResult.IMP_Remarks = this.selectedRowInitialValues.IMP_Remarks;
      this.changesMade = false;
    },
    checkPageSizeChange() {
      this.pageSizeChanged = this.pageSize !== this.pageSizeInput;
    },
    async applyPageSize() {
      this.pageSize = this.pageSizeInput;
      this.pageSizeChanged = false;
      await this.MyOrdersList();
    },
    toggleRow(index) {
      if (this.changesMade) {
        this.cancelChanges();
      }
      if (this.selectedRow === index) {
        this.selectedRow = null;
      } else {
        this.selectedRow = index;

        const selectedResult = this.searchResults.data[index];
        this.selectedRowInitialValues = {
          Order_status: selectedResult.Order_status,
          IMP_Remarks: selectedResult?.IMP_Remarks,
        };
        this.GetDetails(selectedResult.Internal_order_id);
      }
    },
    changePage(page) {
      this.currentPage = page;
      this.MyOrdersList();
    },
    handleResponsive() {
      this.isMobile = window.innerWidth < 1023;
    },
    detectSafari() {
      const ua = navigator.userAgent.toLowerCase();
      return ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1;
    },
    formatDate(dateString) {
      if (!dateString || dateString === undefined || dateString === "") {
        return null;
      }
      const date = new Date(dateString);
      const options = {
        day: "2-digit",
        month: "short",
        year: "numeric",
      };

      let formattedDate = date.toLocaleDateString("en-US", options);
      formattedDate;

      return formattedDate;
    },
    async MyOrdersList(save) {
      this.isLoading = true;
      this.selectedRow = null;
      this.selectedRowInitialValues = {
        Order_status: null,
        IMP_Remarks: null,
      };
      if (save !== "save") {
        this.searchResults = null;
      }
      try {
        let response = await GetOrders(
          this.searchQuery,
          "order_status=Ready_To_Share&"+this.selectedFilterParams,
          this.currentPage,
          this.pageSize,
          this.byColumn,
          false,
          ""
        );
        this.searchResults = response;
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      }
      this.isLoading = false;
    },
    async GetFilters() {
      const payload = {
        page: "READY_TO_SHARE",
      };
      try {
        let response = await GetColumnList(payload);
        if (response.message === "SUCCESS") {
          this.searchByColumn = response.data;
          this.filters = response.filterList;
          this.orderStatus = response.orderStatusList;
          this.selectedFilters = {
            order: "DESC",
          };
          this.selectedFilterParams = `order=DESC&`;
          if (this.savedFilters && Object.keys(this.savedFilters).length == 0) {
            this.MyOrdersList();
          }
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      }
    },
  },
};
</script>
<style scoped>
.no-data-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16%;
  color: #11365a;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  gap: 2vh;
}
.logged-in {
  margin-left: 16vw;
  padding-top: 45px !important;
}
.not-logged-in {
  width: 100%;
}
.no-data-found span {
  margin-left: 7%;
  margin-top: 1rem;
  line-height: 300%;
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Light", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
  opacity: 0.7;
}
#searchResultTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  font-family: "Montserrat-Regular", sans-serif;
}
.tables-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 16px;
  background-color: white;
  position: relative;
}
.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 73vh;
  overflow-y: scroll;
  overflow-x: scroll;
  /* scrollbar-width: none;
      -ms-overflow-style: none; */
  gap: 15px;
}
#searchResultTable th {
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  color: white;
  padding: 10px 16px;
}
#searchResultTable th:nth-child(2),
#searchResultTable th:nth-child(3) {
  min-width: 115px;
}
#searchResultTable td {
  text-align: left;
  padding: 10px 16px;
  color: #11365a;
  font-size: 13px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.16px;
}
.table-container,
.table-container * {
  box-sizing: border-box;
}
.table-container {
  padding-top: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.separation-line {
  width: 100%;
  height: 2px;
  opacity: 0.2;
  background: #3e4855;
}
.frame-35732 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 15px;
  padding: 1.2rem 2rem 0rem 2rem;
}
.loader-wrapper {
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 8%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}
.table-title2 {
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
}
.expanded-row {
  background-color: #f9f9f9;
}
.expanded-content {
  padding: 10px;
  background-color: #f1f8ff;
}
.expanded-content p {
  margin: 5px 0;
}
select {
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
  border-radius: 8px;
  outline: none;
  height: 25px;
  color: #11365a;
  box-shadow: none;
}
.company-id {
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
  border-radius: 8px;
  outline: none;
  height: 25px;
  color: #11365a;
  box-shadow: none;
}
input[type="date"] {
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
  border-radius: 8px;
  outline: none;
  height: 25px;
  color: #11365a;
}
.filters-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  gap: 20px;
  align-items: stretch;
}
.search-wrapper {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.search-bar {
  padding: 8px 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  outline: none;
  margin: 0;
  height: auto;
  width: 40vw;
  box-shadow: none;
}
.page-size {
  padding: 4px 5px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.16px;
  outline: none;
  width: 80px;
  margin-right: 10px;
}
.btn {
  background: #11365a;
  border: none;
  outline: none;
  height: auto;
  border-radius: 16px;
  padding: 0 5px;
  cursor: pointer;
}
.apply-btn {
  border: 1px solid #11365a;
  background: white;
  color: #11365a;
  outline: none;
  border-radius: 8px;
  width: auto;
}
.filter-btn {
  margin-left: 10px;
  background-color: #11365a;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

.filter-menu {
  background: #f5f5f5;
  border: 1px solid #ccc;
  padding: 10px;
  position: absolute;
  z-index: 100;
  width: 300px;
}

.filter-group {
  margin-bottom: 10px;
}

.filter-group label {
  display: block;
  margin-bottom: 5px;
}
.icon-image {
  width: 35px;
  height: 35px;
}
.buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 13px;
}
.save-btn {
  background: #11365a;
  border: none;
  outline: none;
  height: 25px;
  border-radius: 8px;
  cursor: pointer;
}
.search-by-column {
  height: 100%;
  border-radius: 16px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  padding-left: 10px;
  cursor: pointer;
}
.maker-remark {
  border: 1px solid lightgray;
  border-radius: 8px;
  height: 30px;
  margin: 0;
  padding: 7px 20px;
  margin-left: 15px;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 13.33px;
  font-style: normal;
  box-shadow: none;
  outline: none;
}
.status-badge {
  border-radius: 13px;
  text-align: center;
  padding: 0 5px;
}

@media only screen and (max-width: 767px) {
  .table-container {
    padding-top: 1rem;
  }
  .tables-wrapper {
    padding: 1.2rem 1rem;
    border-top-right-radius: 0px;
  }
  .table-wrapper {
    gap: 1rem;
  }
  #searchResultDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    width: 100%;
    gap: 20px;
  }
  .result-wrapper {
    display: flex;
    padding: 21.634px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 21.634px;
    border: 1.352px solid rgba(0, 0, 0, 0.15);
    background: #fff;
  }
  .frame-35732 {
    padding: 0.5rem 0.8rem;
  }
  .table-wrapper {
    height: 67vh;
  }
  .pagination {
    justify-content: center;
    flex-direction: column;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .table-container {
    padding-top: 0;
  }
  .table-wrapper {
    height: 73vh;
  }
}
@media only screen and (min-width: 1156px) {
}
</style>
<style>
.vs-pagination {
  /* padding: 0 !important; */
  gap: 5px;
  margin-top: 0;
}
.vs-pagination > li.vs-pagination--active a {
  color: white;
  background: #11365a;
}
.vs-pagination > li > a {
  border: 1px solid rgba(17, 54, 90, 0.5);
  border-radius: 5px;
  /* color: #11365A; */
}
</style>
